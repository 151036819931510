import React, { Component } from 'react'

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import {
    Form, Col, Alert, Row
} from 'react-bootstrap'

import {parseDateField} from 'Src/services/formatService';
import {isWeekday} from 'Src/services/validationService';

export class datePickerField extends Component {

    onChange(input,date){
        let withoutFormatDate=date
        date =parseDateField(date)
        input.onChange(date);
        if(this.props.addEndDate){
            this.props.addEndDate(withoutFormatDate)
        }
            
    }

    render() {
    const {    
    name, label, input,
    placeholder, defaultValue, readOnly,withoutSize,
    withWeekend,maxDate,minDate,
    meta: {
        touched,
        error
    } } =this.props
        return (
        <Form.Group as={Row} controlId={name}>
            {label?
                <Form.Label column sm={withoutSize?null:3}>{label}</Form.Label>
            :null}  
            <Col sm={withoutSize?null:9}>
                <DatePicker
                    autoComplete="off"
                    readOnly={readOnly}
                    filterDate={withWeekend?null:isWeekday}
                    maxDate={maxDate}
                    minDate={minDate}
                    {...input}
                    locale="es"
                    className="form-control form-control-sm"
                    placeholderText={placeholder}
                    onChange={date => this.onChange(input,date)}
                    
                />
                {(touched && error) ?
                    <Alert key="error" variant="danger">
                        {error}
                    </Alert>
                    : null}
            </Col>
        </Form.Group>
    )
}
};
