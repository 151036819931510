import {
  REPORTS_FORM_GET_REQUEST,
  REPORTS_FORM_GET_SUCCESS,
  REPORTS_FORM_GET_FAILTURE,
  REPORTS_FORM_LOAD_REQUEST, 
  REPORTS_FORM_LOAD_SUCCESS, 
  REPORTS_FORM_LOAD_FAILTURE,
  REPORTS_FORM_LOAD_CLIENT_CHILDRENS_REQUEST,
  REPORTS_FORM_LOAD_CLIENT_CHILDRENS_SUCCESS,
  REPORTS_FORM_LOAD_CLIENT_CHILDRENS_FAILTURE,
  REPORTS_FORM_WORKORDERSTATE_LOAD_REQUEST, 
  REPORTS_FORM_WORKORDERSTATE_LOAD_SUCCESS, 
  REPORTS_FORM_WORKORDERSTATE_LOAD_FAILTURE
} from "./reportsActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})


export const getRequest = (structureTypeId) => ({
  type: REPORTS_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    structureTypeId
  }
});

export const getSuccess = (response) => ({
  type: REPORTS_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const getFailture = (response) => ({
  type: REPORTS_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});


export const formLoadRequest = () =>
  buildAction(
    REPORTS_FORM_LOAD_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )



export const formLoadSuccess = (clientsOptions, structureTypeTemplate,workOrderStateOptions,entrustedTasksOptionsTree,clientOptionsTree,certificationOptions,invoiceOptions, stateStructureOptions,baseDispatchOptions, structureDispatchOptions) =>
  buildAction(
    REPORTS_FORM_LOAD_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      clientsOptions,
      structureTypeTemplate,
      workOrderStateOptions,
      entrustedTasksOptionsTree,
      clientOptionsTree,
      certificationOptions,
      invoiceOptions,
      stateStructureOptions,
      baseDispatchOptions,
      structureDispatchOptions,
      
    }
  )

export const formLoadFailture = (response) =>
  buildAction(
    REPORTS_FORM_LOAD_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )

  export const clientChildrenRequest = () =>
  buildAction(
    REPORTS_FORM_LOAD_CLIENT_CHILDRENS_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )



export const clientChildrenSuccess = (clientRegions) =>
  buildAction(
    REPORTS_FORM_LOAD_CLIENT_CHILDRENS_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      clientRegions
    }
  )

export const clientChildrenFailture = (response) =>
  buildAction(
    REPORTS_FORM_LOAD_CLIENT_CHILDRENS_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )

  /**/
  export const workOrderStateRequest = () =>
  buildAction(
    REPORTS_FORM_WORKORDERSTATE_LOAD_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )



export const workOrderStateSuccess = (workOrderState) =>
  buildAction(
    REPORTS_FORM_WORKORDERSTATE_LOAD_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      workOrderState
    }
  )

export const workOrderStateFailture = (response) =>
  buildAction(
    REPORTS_FORM_WORKORDERSTATE_LOAD_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )