import {
  httpGet,
  httpPost,
  httpPut,
  httpDelete,
  handleHttpError,
  createCancelation,
  isCancel,
  handleCancelation,
  buildURLQuery,
} from "./httpService";

import { buildDefaultOptions } from "./authService";

// TODO: If the cancelation source/token is creating troubles
// move it to the xxService file
// pass it as parameter
// let get_Cancelation = undefined;

let get_CancelationTokens = [];

export const get = async (
  accessToken,
  entity_uri,
  cancelation,
  filterText,
  pageSize,
  page,
  options
) => {
  let cancelationTokens = get_CancelationTokens[entity_uri];
  if (cancelationTokens) {
    cancelationTokens.cancel();
  }

  get_CancelationTokens[entity_uri] = createCancelation();

  let setbuildURLQuery = {
    filterText,
    pageSize,
    page,
    ...options,
  };

  const queryString = buildURLQuery(setbuildURLQuery);
  let uri = `${entity_uri}?${queryString}`;

  let getOptions = buildDefaultOptions(accessToken);
  getOptions.cancelToken = get_CancelationTokens[entity_uri].token;

  try {
    let response = await httpGet(uri, getOptions);
    return response.data;
  } catch (error) {
    if (isCancel(error)) {
      return handleCancelation(error);
    }

    return handleHttpError(error);
  }
};

export const buildUriById = (entity_uri, id) => `${entity_uri}/${id}`;

export const getById = async (accessToken, entity_uri, id) => {
  const URL = buildUriById(entity_uri, id);

  let options = buildDefaultOptions(accessToken);

  try {
    let response = await httpGet(URL, options);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const post = async (accessToken, entity_uri, params) => {
  let options = buildDefaultOptions(accessToken);
  options.headers["Content-Type"] = "application/json";

  try {
    let response = await httpPost(entity_uri, params, options);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const deleteById = async (accessToken, entity_uri, id) => {
  const URL = buildUriById(entity_uri, id);

  let options = buildDefaultOptions(accessToken);

  try {
    let response = await httpDelete(URL, options);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
};

export const put = async (accessToken, entity_uri, entity) => {
  const URL = buildUriById(entity_uri, entity.id);

  let options = buildDefaultOptions(accessToken);

  try {
    let response = await httpPut(URL, entity, options);
    return response.data;
  } catch (error) {
    return handleHttpError(error);
  }
};
