import * as actionTypes from "./workOrdersCardsActionTypes";
import {
  CLIENTCONTACTS_FORM_ADD_REQUEST,
  CLIENTCONTACTS_FORM_ADD_SUCCESS,
  CLIENTCONTACTS_FORM_ADD_FAILTURE
} from "./clientContactsActionTypes";

// actionsService.js
export const buildAction = (type, payload) => ({
  type,
  payload
})

export const setFilter = (filter,statusWorkOrder) =>
  buildAction(
    actionTypes.WORKORDERS_FILTER_SET,
    {
      filter,
      statusWorkOrder
    }
  )

export const getCardsRequest = () =>
  buildAction(
    actionTypes.WORKORDERS_CARDS_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )

export const getCardsSuccess = (response) =>
  buildAction(
    actionTypes.WORKORDERS_CARDS_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      records: response.records,
      metadata: response.metadata
    }
  )

export const getCardsFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERS_CARDS_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )



export const getOptionsRequest = () =>
  buildAction(
    actionTypes.WORKORDERS_OPTIONS_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )

export const getOptionsSuccess = (response) =>
  buildAction(
    actionTypes.WORKORDERS_OPTIONS_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      response
    }
  )

export const getOptionsFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERS_OPTIONS_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )





export const setPage = (page) =>
  buildAction(
    actionTypes.WORKORDERS_PAGE_SET,
    {
      page
    }
  )

export const createRequest = (workOrder) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_CREATE_REQUEST,
    {
      isFetching: true,
      hasError: false,
      workOrder
    }
  )


export const createSuccess = (response) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_CREATE_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      response
    }
  )

export const createFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_CREATE_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )

export const deleteRequest = (WorkOrderId) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_DELETE_REQUEST,
    {
      isFetching: true,
      hasError: false,
      WorkOrderId
    }
  )

export const deleteSuccess = (WorkOrderId) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_DELETE_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      WorkOrderId
    }
  )

export const deleteFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_DELETE_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )

  export const deleteDocumentRequest = (WorkOrderId) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_DELETE_DOCUMENT_REQUEST,
    {
      isFetching: true,
      hasError: false,
      WorkOrderId
    }
  )

export const deleteDocumentSuccess = (WorkOrderId) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_DELETE_DOCUMENT_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      WorkOrderId
    }
  )

export const deleteDocumentFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_DELETE_DOCUMENT_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )



export const getRequest = (id) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_GET_REQUEST,
    {
      isFetching: true,
      hasError: false,
      id
    }
  )

export const getSuccess = (response,listRegions,listDocuments,listContact) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_GET_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      response,
      listRegions,
      listDocuments,
      listContact
    }
  )


export const getFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_GET_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )

  export const getPreviewRequest = (id) =>
  buildAction(
    actionTypes.WORKORDERS_PREVIEW_GET_REQUEST,
    {
      isFetching: true,
      hasError: false,
      id
    }
  )

export const getPreviewSuccess = (response,listRegions,listDocuments,listContact) =>
  buildAction(
    actionTypes.WORKORDERS_PREVIEW_GET_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      response,
      listRegions,
      listDocuments,
      listContact
    }
  )


export const getPreviewFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERS_PREVIEW_GET_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )

  export const formDuplicateLoadRequest = (id) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_DUPLICATE_GET_REQUEST,
    {
      isFetching: true,
      hasError: false,
      id
    }
  )

export const formDuplicateLoadSuccess = (response,listRegions,listContact) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_DUPLICATE_GET_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      response,
      listRegions,
      listContact
    }
  )


export const formDuplicateLoadFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_DUPLICATE_GET_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )

export const editRequest = (response) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_EDIT_REQUEST,
    {
      isFetching: true,
      hasError: false,
      response
    }
  )

export const editSuccess = (response) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_EDIT_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      response
    }
  )

export const editFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_EDIT_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )

export const cleanCards = () =>
  buildAction(
    actionTypes.WORKORDERS_CARDS_CLEAN,
    {
      isFetching: false,
      hasError: false
    }
  )

export const formLoadRequest = () =>
  buildAction(
    actionTypes.WORKORDERS_FORM_LOAD_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )



export const formLoadSuccess = (clientsOptions, structureTypeTemplate, entrustedTaskTypes,particularDetailsTemplate,contactTypesOptions) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_LOAD_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      clientsOptions,
      structureTypeTemplate,
      entrustedTaskTypes,
      particularDetailsTemplate,
      contactTypesOptions
    }
  )

export const formLoadFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_LOAD_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )

  export const clientChildrenRequest = () =>
  buildAction(
    actionTypes.WORKORDERS_FORM_LOAD_CLIENT_CHILDRENS_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )



export const clientChildrenSuccess = (clientContacts, clientRegions) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_LOAD_CLIENT_CHILDRENS_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      clientContacts,
      clientRegions
    }
  )

export const clientChildrenFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERS_FORM_LOAD_CLIENT_CHILDRENS_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )


  export const addClientRequest = (clientContact) => ({
    type: CLIENTCONTACTS_FORM_ADD_REQUEST,
    payload: {
      isFetching: true,
      hasError: false,
      clientContact
    }
  });

  export const addClientSuccess = (response) => ({
    type: CLIENTCONTACTS_FORM_ADD_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      response
    }
  });

  export const addClientFailture = (response) => ({
    type: CLIENTCONTACTS_FORM_ADD_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });

  export const editStateRequest = (workOrderId,state) => ({
    type: actionTypes.WORKORDERS_EDIT_STATES_REQUEST,
    payload: {
      isFetching: true,
      hasError: false,
      workOrderId,
      state
    }
  });
  
  export const editStateSuccess = (response) => ({
    type: actionTypes.WORKORDERS_EDIT_STATES_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      response
    }
  });
  
  export const editStateFailture = (response) => ({
    type: actionTypes.WORKORDERS_EDIT_STATES_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });
