import {
    REPORTS_PAGE_SET,
    REPORTS_FORM_GET_SUCCESS,
    REPORTS_FORM_LOAD_SUCCESS,
    REPORTS_FORM_LOAD_CLIENT_CHILDRENS_SUCCESS
} from "Src/redux/actions/reportsActionTypes";

const initialMetadata = {
    perPage: 6,
    page: 1
};

const emptyRegion = [{ key: -1, text: "Ninguna" }]

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: ""
    },
    metadata: initialMetadata,
    cards: [],
    ReportsToEdit: undefined,
    clientsOptions:[],
    entrustedTasksOptionsTree: undefined,
    clientOptionsTree: undefined,
    certificationOptions:undefined,   // New
    invoiceOptions:undefined,   // New
    stateStructureOptions:undefined,
    structureTypeTemplate:[],
    regionsOptions: [],
    workOrderStateOptions:[],
    baseDispatchOptions:undefined,
    structureDispatchOptions:undefined,
    
};

const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORTS_FORM_LOAD_SUCCESS: {
            let newClientsOptions = [{ key: -1, text: "Ninguno" }]

            let newCertificationOptions = [{ key: -1, text: "Todos" },
            { key: 1, text: "Parcial" },
            { key: 2, text: "Total" },
            { key: 3, text: "Parcial o Total" }]
            let newInvoiceOptions = [{ key: -1, text: "Todos" },
            { key: 1, text: "Parcial" },
            { key: 2, text: "Total" },
            { key: 3, text: "Parcial o Total" }]

            let newBaseDispatchOptions = [{ key: -1, text: "Todos" },
            { key: 1, text: "Sin despachar" },
            { key: 2, text: "Despachada" }]

            let newStructureDispatchOptions = [{key: -1, text: "Todos"},
            {key: 1, text: "Sin despachar"},
            {key: 2, text: "Despachada"}]

            let newStructureTypeOptions = [{ key: -1, text: "Todos" }]
            let newWorkOrderStateOptions = [{ key: -1, text: "Todos" }]

           let newClientsOptionsTree = []

           let newEntrustedTasksOptionsTree =  []


            let newStateStructureOptions =  [{ title: "En Curso", value: 1, key: 1  },
            { title: "Con retraso", value: 2, key: 2  },
            { title: "Finalizadas", value: 3, key: 3  },
            { title: "Suspendidas", value: 4, key: 4  },
            { title: "Canceladas", value: 5, key: 5 }]


         
            const structureTypeOptions = action.payload.structureTypeTemplate.map(st => ({
                key: st.id,
                text: st.description
            }))

            const clientsOptions = action.payload.clientsOptions.map(c => ({
                key: c.id,
                text: c.comertialName
            }))
            
            const clientOptionsTree = action.payload.clientsOptions.map(te => ({
                title: te.comertialName,
                value: te.id,
                key: te.id }))

                
            const entrustedTasksOptionsTree = action.payload.entrustedTasksOptionsTree.map(pp => ({
                title: pp.description,
                value: pp.id,
                key: pp.id 
            }))


            const workOrderStateOptions = action.payload.workOrderStateOptions.map(c => ({
                key: c.id,
                text: c.description
            }))
            return {
                ...state,
                clientsOptions: newClientsOptions.concat(clientsOptions),
                structureTypeTemplate: newStructureTypeOptions.concat(structureTypeOptions),
                regionsOptions:newClientsOptions,
                workOrderStateOptions:newWorkOrderStateOptions.concat(workOrderStateOptions),
                entrustedTasksOptionsTree: newEntrustedTasksOptionsTree.concat(entrustedTasksOptionsTree),
                clientOptionsTree: newClientsOptionsTree.concat(clientOptionsTree),  
                certificationOptions:newCertificationOptions,
                invoiceOptions:newInvoiceOptions,
                stateStructureOptions:newStateStructureOptions,
                baseDispatchOptions:newBaseDispatchOptions,
                structureDispatchOptions:newStructureDispatchOptions,   


            }
        }
        case REPORTS_FORM_LOAD_CLIENT_CHILDRENS_SUCCESS: {
            const {  clientRegions } = action.payload;
            
            const regionsOptions = emptyRegion.concat(clientRegions.map(c => ({
                key: c.id,
                text: c.description
            })));


            return {
                ...state,
                regionsOptions
            }
        }
        case REPORTS_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        case REPORTS_FORM_GET_SUCCESS: {
            return {
                ...state,
                StructureTypeToEdit: action.payload.response
            };
        }
        default: {
            return state;
        }
    }
};

export default reportsReducer;

export const getreportsState = (state) => state.reportsReducer;

export const getFilter = (state) => getreportsState(state).filter;

export const getIsFetching = (state) => getreportsState(state).isFetching;

export const getMetadata = (state) => getreportsState(state).metadata;

export const getReportsToEdit = (state) => getreportsState(state).StructureTypeToEdit;

export const getClientsOption = (state) => getreportsState(state).clientsOptions;

export const getClientsOptionTree = (state) => getreportsState(state).clientOptionsTree;

export const getEntrustedTasksOptionsTree = (state) => getreportsState(state).entrustedTasksOptionsTree;

export const getCertificationOption = (state) => getreportsState(state).certificationOptions;

export const getInvoiceOption = (state) => getreportsState(state).invoiceOptions;

export const getBaseDispatchOptions = (state) => getreportsState(state).baseDispatchOptions;

export const getStructureDispatchOptions = (state) => getreportsState(state).structureDispatchOptions;

export const getStateStructureOptions = (state) => getreportsState(state).stateStructureOptions;


export const getRegionsOption = (state) => getreportsState(state).regionsOptions;

export const getStructureTypeTemplate = (state) => getreportsState(state).structureTypeTemplate;

export const getWorkOrderStateOptions = (state) => getreportsState(state).workOrderStateOptions;