import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';
import LoadingCard from 'Src/components/loadingCard';

import { ConnectedCreateResponsibleForAreaForm } from 'Src/components/responsibleForArea/responsibleForAreaForm';

import { tryAddResponsibleForArea , tryFormLoad } from 'Src/redux/thunk-creators/responsibleForAreaActionCreator';

import {
    getAreasOptions
} from "Src/redux/reducers/responsibleForAreaReducer";


class ResponsibleForAreaAddPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }


    render() {
        const{
            onResponsibleForAreaAdd,areasOptions
        }=this.props;
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard/>} >
                            <ConnectedCreateResponsibleForAreaForm 
                                title="Crear responsable de área"
                                submitText="Crear"
                                areasOptions={areasOptions}
                                onSubmit={area => onResponsibleForAreaAdd(area)} 
                                onCancel={this.props.goBack}/>
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        areasOptions: getAreasOptions(state),
    };
}

const mapDispatchToProps = dispatch => ({
    onResponsibleForAreaAdd: (area) => dispatch(tryAddResponsibleForArea(area)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResponsibleForAreaAddPage);