import config from 'Config';

import { get as _get } from 'Src/services/entityService'

const API_ENDPOINT = `${config.apis.coreApi.URL}/api`
const ENTITY_URI = `${API_ENDPOINT}/entrustedtasktypes`;

let get_Cancelation = undefined;
export const get = async (accessToken) => 
                            _get(accessToken, ENTITY_URI, get_Cancelation, "",1000,1)

export const getById = async (accessToken, id) => 
                            _getById(accessToken, ENTITY_URI, id)