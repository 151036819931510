import {
    PARTICULARDETAILS_FILTER_SET,
    PARTICULARDETAILS_PAGE_SET,
    PARTICULARDETAILS_FORM_DELETE_SUCCESS,
    PARTICULARDETAILS_FORM_GET_SUCCESS,
    PARTICULARDETAILS_FORM_EDIT_SUCCESS
} from "Src/redux/actions/particularDetailsActionTypes";

const initialMetadata = {
    perPage: 6,
    page: 1
};

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: ""
    },
    metadata: initialMetadata,
    cards: [],
    ParticularDetailToEdit: undefined
};

const ParticularDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PARTICULARDETAILS_FILTER_SET: {
            return {
                ...state,
                filter: {
                    text: action.payload.filter
                },
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case PARTICULARDETAILS_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        case PARTICULARDETAILS_FORM_DELETE_SUCCESS: {
            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload.ParticularDetailId),
                metadata: {
                    ...state.metadata,
                    totalCount: state.metadata.totalCount - 1
                }
            };
        }
        case PARTICULARDETAILS_FORM_GET_SUCCESS: {
            return {
                ...state,
                ParticularDetailToEdit: action.payload.response
            };
        }
        case PARTICULARDETAILS_FORM_EDIT_SUCCESS: {
            return {
                ...state,
                ParticularDetailToEdit: undefined
            };
        }
        default: {
            return state;
        }
    }
};

export default ParticularDetailsReducer;

export const getParticularDetailsState = (state) => state.ParticularDetailsReducer;

export const getFilter = (state) => getParticularDetailsState(state).filter;

export const getIsFetching = (state) => getParticularDetailsState(state).isFetching;

export const getMetadata = (state) => getParticularDetailsState(state).metadata;

export const getParticularDetailToEdit = (state) => getParticularDetailsState(state).ParticularDetailToEdit;