import {
  OPERATIVETASKS_CARDS_REQUEST,
  OPERATIVETASKS_CARDS_SUCCESS,
  OPERATIVETASKS_CARDS_FAILTURE,
  OPERATIVETASKS_CARDS_CLEAN,
  OPERATIVETASKS_FILTER_SET,
  OPERATIVETASKS_CARDS_FILTER_SET,
  OPERATIVETASKS_OPTIONS_SUCCESS,
  OPERATIVETASKS_UPDATED_END_DATE_STATE,
  OPERATIVETASKS_SELECTED_TO_SAVE,
  OPERATIVETASKS_FORMS_EDIT_REQUEST,
  OPERATIVETASKS_FORMS_EDIT_SUCCESS,
  OPERATIVETASKS_FORMS_EDIT_FAILTURE
} from "Src/redux/actions/operativeTasksCardsActionTypes";

import { inputformatDate } from "Src/services/formatService";

const initialMetadata = {
  perPage: 6,
  page: 1,
};

const emptyOption = [{ key: -1, text: "Ninguno" }];

const initialState = {
  hasError: false,
  isFetching: false,
  filter: {
    text: "",
    WorkOrderId: undefined,
  },
  metadataTranckingTask: {
    client: undefined,
    structure: undefined,
    orderDate: undefined,
    WorkOrderId: undefined,
    finish: undefined,
    newDesign: undefined,
  },
  metadata: initialMetadata,
  workOrderOptions: emptyOption,
  cards: [],
  justifications: undefined,
  responsibleForArea: undefined,
  selectedToSave: {}
};

const operativeTasksCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPERATIVETASKS_CARDS_FILTER_SET: {
      const {
        client,
        orderDate,
        WorkOrderId,
        structure,
        newDesign,
        makeComplementarywork,
        makeNewDesign,
        complementaryWork,
      } = action.payload.cardData;
      return {
        ...state,
        filter: {
          WorkOrderId: WorkOrderId,
        },
        metadataTranckingTask: {
          client: client,
          structure: structure,
          orderDate: inputformatDate(orderDate),
          WorkOrderId: WorkOrderId,
          makeNewDesign,
          newDesign,
          makeComplementarywork,
          complementaryWork,
        },
        cards: [],
        metadata: { ...initialMetadata },
      };
    }
    case OPERATIVETASKS_FILTER_SET: {
      return {
        ...state,
        filter: {
          text: action.payload.filter,
        },
        cards: [],
        metadata: { ...initialMetadata },
      };
    }
    case OPERATIVETASKS_FORMS_EDIT_REQUEST:
    case OPERATIVETASKS_CARDS_REQUEST: {
      return {
        ...state,
        cards: [],
        isFetching: true,
      };
    }
    case OPERATIVETASKS_CARDS_SUCCESS: {
      const { records, justifications, responsibleForArea } = action.payload;
      const { operativeTaskItems } = records;
      let justificationsOptions = emptyOption.concat(
        justifications.map((jus) => ({
          key: jus.justificationDelayType.id,
          text: jus.justificationDelayType.name,
        }))
      );

      let responsibleForAreaOptions = emptyOption.concat(
        responsibleForArea.map((rfa) => ({
          key: rfa.id,
          text: rfa.name,
        }))
      );
      const selectedToSave = {}
      operativeTaskItems.map((item) => {
        selectedToSave[item.id] = false
      })
      return {
        ...state,
        isFetching: false,
        cards: operativeTaskItems,
        justifications: justificationsOptions,
        responsibleForArea: responsibleForAreaOptions,
        metadataTranckingTask: {
          ...state.metadataTranckingTask,
          finished: records.finished,
          newDesign: records.newDesign,
          makeNewDesign: records.makeNewDesign,
          complementaryWork: records.complementaryWork,
          makeComplementarywork: records.makeComplementarywork,
        },
        selectedToSave
      };
    }
    case OPERATIVETASKS_FORMS_EDIT_FAILTURE:
    case OPERATIVETASKS_CARDS_FAILTURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case OPERATIVETASKS_FORMS_EDIT_SUCCESS: {
      return {
        ...state,
        cards: [],
        isFetching: false,
      };
    }
    case OPERATIVETASKS_CARDS_CLEAN: {
      return {
        ...state,
        cards: [],
      };
    }
    case OPERATIVETASKS_OPTIONS_SUCCESS: {
      const { response } = action.payload;
      let workOrderOptions = emptyOption.concat(
        response.map((wo) => ({
          key: wo.id,
          text: wo.entrustedTask,
        }))
      );
      return {
        ...state,
        isFetching: false,
        workOrderOptions,
      };
    }
    case OPERATIVETASKS_SELECTED_TO_SAVE: {
      const { selectedToSave } = action.payload;
      return {
        ...state,
        selectedToSave,
        isFetching: false
      }
    }
    case OPERATIVETASKS_UPDATED_END_DATE_STATE: {
      const { operativeTask } = action.payload;
      const otherCards = state.cards.filter((x) => x.id != operativeTask.id);

      const newCards = [...otherCards, operativeTask]


      return {
        ...state,
        cards: newCards.sort((a, b) => a.id - b.id),
      };
    }
    default: {
      return state;
    }
  }
};

export default operativeTasksCardsReducer;

export const getoperativeTasksCardsState = (state) =>
  state.operativeTasksCardsReducer;

export const getFilter = (state) => getoperativeTasksCardsState(state).filter;

export const getIsFetching = (state) =>
  getoperativeTasksCardsState(state).isFetching;

export const getCards = (state) => getoperativeTasksCardsState(state).cards;

export const getJustifications = (state) =>
  getoperativeTasksCardsState(state).justifications;

export const getResponsibleForArea = (state) =>
  getoperativeTasksCardsState(state).responsibleForArea;

export const getMetadata = (state) =>
  getoperativeTasksCardsState(state).metadata;

export const getWorkOrderOption = (state) =>
  getoperativeTasksCardsState(state).workOrderOptions;

export const getMetadataTranckingTask = (state) =>
  getoperativeTasksCardsState(state).metadataTranckingTask;

export const getOperativeTaskSelectedToSave = (state) =>
  getoperativeTasksCardsState(state).selectedToSave;
