import React,{Component} from 'react';
import {
    Card, Button, Spinner,Col,Row
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { textAreaField } from 'Src/components/forms/textAreaField';

//TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')
//const requiredselect = value => (value ? undefined: 'Requerido!')

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}
    errors.scheme = required(values.scheme)
    return errors
}

class ParticularDetailsForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText,
            mode
        } = this.props;

        return (
            <Card className="shadow">
                <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Field name="scheme" label="Estructura" component={textAreaField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit" disabled={pristine || submitting}>
                                    {submitText}
                                    {submitting ? <Spinner animation="grow" size="sm" /> : null}
                                </Button>                            
                            </Col>
                            <Col className="text-right">
                                <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                                &nbsp;                            
                                <Button variant="danger" onClick={onCancel}>Cancelar</Button>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_PARTICULARDETAILS_NAME = 'CREATE_PARTICULARDETAILS_NAME';
export const EDIT_PARTICULARDETAILS_NAME = 'EDIT_PARTICULARDETAILS_NAME';

const CreateParticularDetailsForm = props =>
  <ParticularDetailsForm {...props} mode='create' />;
  
const EditParticularDetailsForm = props =>
  <ParticularDetailsForm {...props} mode='edit' />;

const ConnectedCreateParticularDetailsForm = reduxForm({
    form: CREATE_PARTICULARDETAILS_NAME,
    validate
})(CreateParticularDetailsForm);

const ConnectedEditParticularDetailsForm = reduxForm({
    form: EDIT_PARTICULARDETAILS_NAME,
    validate
})(EditParticularDetailsForm);

export {
    ConnectedCreateParticularDetailsForm,
    ConnectedEditParticularDetailsForm
}