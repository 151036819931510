import React from 'react';
import {
    Card, Button, Spinner
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { FileInputField } from 'Src/components/forms/fileInputField';
import { inputField } from 'Src/components/forms/inputField';

//TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')

const maxLenght = max => value => (value.length > max ? "Cuit debe tener hasta 11 caracteres" : undefined)

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}

    errors.businessName = required(values.businessName)
    errors.address = required(values.address)
    errors.cuit = required(values.cuit)

    if (values.cuit) {
        let isCuitValid = /\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]/g
            .test(values.cuit);

        if (!isCuitValid) {
            errors.cuit = "Cuit Invalido!";
        }else{
            errors.cuit = maxLenght(11)(values.cuit)
        }
    }

    return errors
}

class ClientForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText,
            DocumentConstanciaAfip,DocumentConstanciaIibb,DocumentFormularioCm05,
            mode
        } = this.props;

        return (
            <Card className="shadow">
                <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <Field name="comertialName" label="Nombre Comercial" component={inputField} type="text" />
                        </div>
                        <div>
                            <Field name="tangoCode" label="Código Cliente en Tango" component={inputField} type="text" />
                        </div>
                        <div>
                            <Field name="businessName" label="Razón Social" component={inputField} type="text" />
                        </div>
                        <div>
                            <Field name="address" label="Dirección" component={inputField} type="text" />
                        </div>
                        <div>
                            <Field name="cuit" label="Cuit" component={inputField} type="text" />
                        </div>
                        <div>
                            <Field
                                name="constanciaAfip"
                                label="Constancia inscripción AFIP"
                                type="file"
                                component={FileInputField}
                                document={DocumentConstanciaAfip}
                                />
                        </div>
                        <div>
                            <Field
                                name="contanciaIibb"
                                label="Constancia IIBB"
                                type="file"
                                component={FileInputField}
                                document={DocumentConstanciaIibb} />
                        </div>
                        <div>
                            <Field
                                name="formularioCM05"
                                label="Formulario CM05"
                                type="file"
                                component={FileInputField}
                                document={DocumentFormularioCm05} />
                        </div>
                        <div>
                            <Button variant="primary" type="submit" disabled={pristine || submitting}>
                                {submitText}
                                {submitting ? <Spinner animation="grow" size="sm" /> : null}
                            </Button>
                            {' '}
                            <Button variant="alternative" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                            {' '}
                            <Button variant="link" onClick={onCancel}>Cancelar</Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_CLIENT_NAME = 'CREATE_CLIENT_NAME';
export const EDIT_CLIENT_NAME = 'EDIT_CLIENT_NAME';

const CreateClientForm = props =>
  <ClientForm {...props} mode='create' />;
  
const EditClientForm = props =>
  <ClientForm {...props} mode='edit' />;

const ConnectedCreateClientForm = reduxForm({
    form: CREATE_CLIENT_NAME,
    validate
})(CreateClientForm);

const ConnectedEditClientForm = reduxForm({
    form: EDIT_CLIENT_NAME,
    validate
})(EditClientForm);

export {
    ConnectedCreateClientForm,
    ConnectedEditClientForm
}