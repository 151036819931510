import React,{Component} from 'react';
import {
    Card, Button, Spinner,Col,Row
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { dropdownField } from 'Src/components/forms/dropdownField';
import { datePickerField } from 'Src/components/forms/dateField';

//TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')
//const requiredselect = value => (value ? undefined: 'Requerido!')

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}
    if(values.filterStartDate)
        errors.filterEndDate = required(values.filterEndDate)
    
    if(values.filterEndDate)
        errors.filterStartDate = required(values.filterStartDate)

    return errors
}

class filterMapForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, title, submitText,
            structureTypeOptions,clientsOptions,
            stateStructureOptions,
            mode
        } = this.props;
        return (
                <form onSubmit={handleSubmit}>                        
                    <Row>
                        <Col>
                        <Field name="StructureTypeId" label="Estructura"
                                component={dropdownField}
                                options={structureTypeOptions} />
                        </Col>
                        <Col>
                        <Field name="ClientId" label="Cliente"
                                component={dropdownField}
                                options={clientsOptions} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Field name="StartDate" label="Fecha desde" component={datePickerField} />
                        </Col>
                        <Col>
                            <Field name="EndDate" label="Fecha hasta" component={datePickerField} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Field name="stateStructure" label="Estado de la OT"
                                    component={dropdownField}
                                    options={stateStructureOptions} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant={"primary"} type="submit" disabled={pristine || submitting}>
                                {submitText}
                                {submitting ? <Spinner animation="grow" size="sm" /> : null}
                            </Button>                            
                        </Col>
                        <Col className="text-right">
                            <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                        </Col>
                    </Row>
                </form>
        );
    }
}


export const EDIT_FILTER_DASHBOARD_NAME = 'EDIT_FILTER_DASHBOARD_NAME';

  
const EditfilterMapForm = props =>
  <filterMapForm {...props} mode='edit' />;

const ConnectedEditfilterMapForm = reduxForm({
    form: EDIT_FILTER_DASHBOARD_NAME,
    validate
})(filterMapForm);

export {
    ConnectedEditfilterMapForm
}