import React from 'react'

import {
    Col, Row,Button
} from 'react-bootstrap'
import { Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { InputFieldFileMultiple } from './inputFieldFileMultiple';

export const renderFiles = ({ fields }) => (
    <Row>
        <Col sm="1" className="text-center">
            <Button variant="primary" onClick={() => fields.push({})}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>
            <br/><br/>
            <Button variant="danger" onClick={() => fields.removeAll()}>
                <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
        </Col>
        <Col sm="10">
            {fields.map((file, index) =>
                <Row key={index}>
                    <Col sm="12">
                    <Field
                        name={`${file}_file`}
                        component={InputFieldFileMultiple}
                        onRemove={() => fields.remove(index)}/>
                    </Col>                    
                </Row>  
            )}
        </Col>
    </Row>
);