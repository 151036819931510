import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    setFilter, getCardsRequest, getCardsSuccess, getCardsFailture,
    setPage, addFailture, addRequest, addSuccess,
    deleteRequest, deleteSuccess, deleteFailture,
    getRequest, getSuccess, getFailture,
    editRequest, editSuccess, editFailture, cleanCards
} from "Src/redux/actions/contactTypesCardsActions";

import {
    get, addContactType, deleteContactType, editContactType, getContactType
} from 'Src/services/contactTypesService';

import { CONTACTTYPES_URI, CONTACTTYPES_FORM_URI } from "Src/routes";


const contactTypesCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5

export const setCardsFilter = (filterText) => {
    return async (dispatch, getState) => {
        const { contactTypesCardsReducer } = getState();

        if (filterText.length < 3
            || filterText == contactTypesCardsReducer.filter.text) {
            return;
        }

        dispatch(setFilter(filterText));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText,
            contactTypesCardsReducer.metadata.perPage,
            1));

    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, contactTypesCardsReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            contactTypesCardsReducer.filter.text,
            contactTypesCardsReducer.metadata.perPage,
            1));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, contactTypesCardsReducer } = getState();

        if (!contactTypesCardsReducer.metadata.hasMore) {
            return;
        }

        const nextPage = contactTypesCardsReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            contactTypesCardsReducer.filter.text,
            contactTypesCardsReducer.metadata.perPage,
            nextPage));

    };
};

export const tryGetCardsAction = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {
        dispatch(getCardsRequest());

        let response = await get(accessToken, filter, pageSize, page);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...contactTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar los tipos de contacto. Detalle: '${response.error.message}'`
            }));

            return;
        }

        dispatch(getCardsSuccess(response));
    }
};

export const tryAddContactType = (contactType) => {
    return async (dispatch, getState) => {
        dispatch(addRequest(contactType));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await addContactType(access_token, contactType);
        if (response.hasOwnProperty("error")) {
            dispatch(addFailture(response));
            dispatch(error({
                ...contactTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear el Tipo de Contacto. Detalle: '${response.error.message}'`
            }));

            return;
        }
        if (response.code && response.code == 4) {
            dispatch(addFailture(response));
            dispatch(error({
                ...contactTypesCardsNotification,
                title: "Oops!",
                message: `El tipo de contacto ya existe.`
            }));

            return;
        }
        dispatch(addSuccess(response));

        contactType.id = response.id;
        

        dispatch(success({
            ...contactTypesCardsNotification,
            title: "Tipo de Contacto " + contactType.id,
            message: `Creado exitosamente!`
        }));

        dispatch(push(CONTACTTYPES_URI));
    }
}

export const tryDeleteContactType = (contactTypeId) => {
    return async (dispatch, getState) => {
        let shouldDelete = confirm("Desea borrar el Tipo de Contacto Id: " + contactTypeId);
        if (!shouldDelete) {
            return;
        }

        dispatch(deleteRequest(contactTypeId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await deleteContactType(access_token, contactTypeId);
        if (response.hasOwnProperty("error")) {
            dispatch(deleteFailture(response));
            dispatch(error({
                ...contactTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Borrar el Tipo de Contacto. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(deleteSuccess(response));

        dispatch(success({
            ...contactTypesCardsNotification,
            title: "Tipo de Contacto " + contactTypeId,
            message: `Eliminado exitosamente!`
        }));
    }
}

export const tryGetContactType = (contactTypeId) => {
    return async (dispatch, getState) => {
        if(!contactTypeId) return;

        dispatch(getRequest(contactTypeId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await getContactType(access_token, contactTypeId);
        if (response.hasOwnProperty("error")) {
            dispatch(getFailture(response));
            dispatch(error({
                ...contactTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el tipo de contacto. Detalle: '${response.error.message}'`
            }));
            return;
        }
        dispatch(getSuccess(response));

        dispatch(push(CONTACTTYPES_FORM_URI + "/" + contactTypeId));
    }
}

export const tryEditContactType = (contactType) => {
    return async (dispatch, getState) => {
        dispatch(editRequest(contactType));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await editContactType(access_token, contactType);
        if (response.hasOwnProperty("error")) {
            dispatch(editFailture(response));
            dispatch(error({
                ...contactTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar el tipo de contacto. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(editSuccess(response));

        contactType.id = response.id;
        
        dispatch(success({
            ...contactTypesCardsNotification,
            title: "Tipo de Contacto " + contactType.id,
            message: `Actualizado exitosamente!`
        }));

        dispatch(push(CONTACTTYPES_URI));
        dispatch(cleanCards());
        dispatch(refreshCardsFilter());
    }
}