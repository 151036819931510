export const STRUCTURETYPES_CARDS_REQUEST = "STRUCTURETYPES_CARDS_REQUEST";
export const STRUCTURETYPES_CARDS_SUCCESS = "STRUCTURETYPES_CARDS_SUCCESS";
export const STRUCTURETYPES_CARDS_FAILTURE = "STRUCTURETYPES_CARDS_FAILTURE";
export const STRUCTURETYPES_CARDS_CLEAN = "STRUCTURETYPES_CARDS_CLEAN";

export const STRUCTURETYPES_FILTER_SET = "STRUCTURETYPES_FILTER_SET";
export const STRUCTURETYPES_PAGE_SET = "STRUCTURETYPES_PAGE_SET";

export const STRUCTURETYPES_FORM_ADD_REQUEST = "STRUCTURETYPES_FORM_ADD_REQUEST";
export const STRUCTURETYPES_FORM_ADD_SUCCESS = "STRUCTURETYPES_FORM_ADD_SUCCESS";
export const STRUCTURETYPES_FORM_ADD_FAILTURE = "STRUCTURETYPES_FORM_ADD_FAILTURE";

export const STRUCTURETYPES_FORM_DELETE_REQUEST = "STRUCTURETYPES_FORM_DELETE_REQUEST";
export const STRUCTURETYPES_FORM_DELETE_SUCCESS = "STRUCTURETYPES_FORM_DELETE_SUCCESS";
export const STRUCTURETYPES_FORM_DELETE_FAILTURE = "STRUCTURETYPES_FORM_DELETE_FAILTURE";

export const STRUCTURETYPES_FORM_GET_REQUEST = "STRUCTURETYPES_FORM_GET_REQUEST";
export const STRUCTURETYPES_FORM_GET_SUCCESS = "STRUCTURETYPES_FORM_GET_SUCCESS";
export const STRUCTURETYPES_FORM_GET_FAILTURE = "STRUCTURETYPES_FORM_GET_FAILTURE";

export const STRUCTURETYPES_FORM_EDIT_REQUEST = "STRUCTURETYPES_FORM_EDIT_REQUEST";
export const STRUCTURETYPES_FORM_EDIT_SUCCESS = "STRUCTURETYPES_FORM_EDIT_SUCCESS";
export const STRUCTURETYPES_FORM_EDIT_FAILTURE = "STRUCTURETYPES_FORM_EDIT_FAILTURE";

export const STRUCTURETYPES_FORM_LOAD_REQUEST = "STRUCTURETYPES_FORM_LOAD_REQUEST";
export const STRUCTURETYPES_FORM_LOAD_SUCCESS = "STRUCTURETYPES_FORM_LOAD_SUCCESS";
export const STRUCTURETYPES_FORM_LOAD_FAILTURE = "STRUCTURETYPES_FORM_LOAD_FAILTURE";