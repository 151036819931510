import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    setFilter, getCardsRequest, getCardsSuccess, getCardsFailture,
    setPage, addFailture, addRequest, addSuccess,
    deleteRequest, deleteSuccess, deleteFailture,
    getRequest, getSuccess, getFailture,
    editRequest, editSuccess, editFailture, cleanCards,
    formLoadRequest, formLoadSuccess, formLoadFailture
} from "Src/redux/actions/clientContactsActions";

import {
    get, post, deleteById, put, getById
} from 'Src/services/clientContactsService';

import {
    get as getClients
} from 'Src/services/clientsService';

import {
    get as getContactTypes
} from 'Src/services/contactTypesService';


import { CLIENTCONTACTS_URI, CLIENTCONTACTS_FORM_URI } from "Src/routes";


const clientContactsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5

export const setCardsMount = () => {
    return async (dispatch, getState) => {
        const { clientContactsReducer } = getState();
        let filterText = clientContactsReducer.filter.text

        dispatch(setFilter(filterText));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText,
            clientContactsReducer.metadata.perPage,
            1));

    };
};

export const setCardsFilter = (filterText) => {
    return async (dispatch, getState) => {
        const { clientContactsReducer } = getState();

         if (filterText.length < 3
             || filterText == clientContactsReducer.filter.text) {
                dispatch(setFilter(filterText));
             return;
         }

         dispatch(setFilter(filterText));
        

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText,
            clientContactsReducer.metadata.perPage,
            1));

    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, clientContactsReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            clientContactsReducer.filter.text,
            clientContactsReducer.metadata.perPage,
            1));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, clientContactsReducer } = getState();

        if (!clientContactsReducer.metadata.hasMore) {
            return;
        }

        const nextPage = clientContactsReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            clientContactsReducer.filter.text,
            clientContactsReducer.metadata.perPage,
            nextPage));

    };
};

export const tryGetCardsAction = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {
        dispatch(getCardsRequest());

        let response = await get(accessToken, filter, pageSize, page);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...clientContactsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }

        dispatch(getCardsSuccess(response));
    }
};

export const tryAddClientContact = (clientContact) => {
    return async (dispatch, getState) => {
        dispatch(addRequest(clientContact));
        
        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await post(access_token, clientContact);

        if (response.code) {
            dispatch(addFailture(response));
            dispatch(error({
                ...clientContactsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear el Contacto del Cliente. Detalle: '${response.message}'`
            }));

            return;
        }

        if (response.hasOwnProperty("error")) {
            dispatch(addFailture(response));
            dispatch(error({
                ...clientContactsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear el Contacto del Cliente. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(addSuccess(response));

        clientContact.id = response.id;

        dispatch(success({
            ...clientContactsNotification,
            title: "Contacto de Cliente " + clientContact.id,
            message: `Creado exitosamente!`
        }));

        dispatch(push(CLIENTCONTACTS_URI));
    }
}

export const tryDeleteClientContact = (clientContactId) => {
    return async (dispatch, getState) => {
        let shouldDelete = confirm("Desea borrar el Contacto Id: " + clientContactId);
        if (!shouldDelete) {
            return;
        }

        dispatch(deleteRequest(clientContactId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await deleteById(access_token, clientContactId);
        if (response.hasOwnProperty("error")) {
            dispatch(deleteFailture(response));
            dispatch(error({
                ...clientContactsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Borrar el Contacto del Cliente. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(deleteSuccess(clientContactId));

        dispatch(success({
            ...clientContactsNotification,
            title: "Contacto de Cliente " + clientContactId,
            message: `Eliminado exitosamente!`
        }));
    }
}

export const tryGetClientContact = (clientContactId) => {
    return async (dispatch, getState) => {
        if(!clientContactId) return;

        dispatch(getRequest(clientContactId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await getById(access_token, clientContactId);
        if (response.hasOwnProperty("error")) {
            dispatch(getFailture(response));
            dispatch(error({
                ...clientContactsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar del Contacto del cliente. Detalle: '${response.error.message}'`
            }));
            return;
        }
        dispatch(getSuccess(response));

        dispatch(push(CLIENTCONTACTS_FORM_URI + "/" + clientContactId));
    }
}

export const tryEditClientContact = (clientContact) => {
    return async (dispatch, getState) => {
        dispatch(editRequest(clientContact));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await put(access_token, clientContact);
        if (response.hasOwnProperty("error") && response.error == true) {
            dispatch(editFailture(response));
            dispatch(error({
                ...clientContactsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar el Cliente. Detalle: '${response.message}'`
            }));

            return;
        }
        dispatch(editSuccess(response));

        clientContact.id = response.id;

        dispatch(success({
            ...clientContactsNotification,
            title: "Contacto de Cliente " + clientContact.id,
            message: `Actualizado exitosamente!`
        }));

        dispatch(push(CLIENTCONTACTS_URI));
        dispatch(cleanCards());
        dispatch(refreshCardsFilter());
    }
}

export const tryFormLoad = () => {
    return async (dispatch, getState) => {
        dispatch(formLoadRequest())

        const { access_token } = getState().loginReducer.authentication

        const clientsPromise = getClients(access_token, "", 1000, 1)

        const clientsResponse = await clientsPromise

        if(clientsResponse){
            const { error, code } = clientsResponse
            const hasError = error || Number.isInteger(code)

            if (hasError) {
                dispatch(formLoadFailture(clientsResponse))

                let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`

                showError(dispatch, ERROR_TITLE, message)

                return
            }
        }

        const contactTypesPromise = getContactTypes(access_token, "", 1000, 1)

        const contactTypesResponse = await contactTypesPromise

        if(contactTypesResponse){
            const { error, code } = contactTypesResponse
            const hasError = error || Number.isInteger(code)

            if (hasError) {
                dispatch(formLoadFailture(contactTypesResponse))

                let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`

                showError(dispatch, ERROR_TITLE, message)

                return
            }
        }

        dispatch(formLoadSuccess(clientsResponse.records,contactTypesResponse.records))
    }
}