import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';
import LoadingCard from 'Src/components/loadingCard';

import { ConnectedCreateUserForm } from 'Src/components/users/userForm'

import { tryAddUser,tryFormLoad } from 'Src/redux/thunk-creators/usersCardsActionCreator';
import {
    getProfilesOption
} from "Src/redux/reducers/usersCardsReducer";

class UsersAddPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }

    render() {
        const{
            profilesOptions,onUserAdd
        }=this.props;
        return (
            <Container className="Users-container">
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard/>} >
                            <ConnectedCreateUserForm 
                                title="Crear User"
                                submitText="Crear"
                                onSubmit={user => onUserAdd(user)} 
                                profilesOptions={profilesOptions}
                                onCancel={this.props.goBack}/>
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profilesOptions: getProfilesOption(state),
    };
}

const mapDispatchToProps = dispatch => ({
    onUserAdd: (user) => dispatch(tryAddUser(user)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersAddPage);