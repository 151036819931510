import {
  HISTORYS_CARDS_REQUEST,
  HISTORYS_CARDS_SUCCESS,
  HISTORYS_CARDS_FAILTURE,
  HISTORYS_CARDS_CLEAN,
  HISTORYS_FILTER_SET,
  HISTORYS_PAGE_SET,
} from "./historysActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const setFilter = (filter,type,client) => ({
  type: HISTORYS_FILTER_SET,
  payload: {
    filter,
    type,
    client
  }
});

export const getCardsRequest = () => ({
  type: HISTORYS_CARDS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getCardsSuccess = (response) => ({
  type: HISTORYS_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response.records,
    metadata: response.metadata,
    client:response.client,
    structureType:response.structureType,
  }
});

export const getCardsFailture = (response) => ({
  type: HISTORYS_CARDS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const setPage = (page) => ({
  type: HISTORYS_PAGE_SET,
  payload: {
    page
  }
});
