import {
    TASKSCONFIGURATIONS_CARDS_REQUEST,
    TASKSCONFIGURATIONS_CARDS_SUCCESS,
    TASKSCONFIGURATIONS_CARDS_FAILTURE,
    TASKSCONFIGURATIONS_CARDS_CLEAN,
    TASKSCONFIGURATIONS_FILTER_SET,
    TASKSCONFIGURATIONS_PAGE_SET,
    TASKSCONFIGURATIONS_FORM_DELETE_SUCCESS,
    TASKSCONFIGURATIONS_FORM_GET_SUCCESS,
    TASKSCONFIGURATIONS_FORM_EDIT_SUCCESS,
    TASKSCONFIGURATIONS_FORM_LOAD_SUCCESS,
    TASKSCONFIGURATIONS_CARDS_FILTER_SUCCESS
} from "Src/redux/actions/tasksConfigurationsActionTypes";

const initialMetadata = {
    perPage: 5000,
    page: 1
};

const initialOption = [{ key: -1, text: "Ninguna" }]

const initialState = {
    hasError: false,
    isFetching: false,
    filter:undefined,
    metadata: initialMetadata,
    cards: [],
    TaskConfigurationToEdit: undefined,
    structureTypeOption:initialOption,
    areasOptions:initialOption,
    entrustedTasksOptions:initialOption,
    operativeTasksOptions: initialOption
};

const tasksConfigurationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TASKSCONFIGURATIONS_FILTER_SET: {
            const { filter } = action.payload
            return {
                ...state,
                filter,
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case TASKSCONFIGURATIONS_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case TASKSCONFIGURATIONS_CARDS_SUCCESS: {
            const {filter} = state
            let cards = [];
            const cardsAll = action.payload.records.filter((ot)=>ot.structureTypeId == state.filter.structureTypeId);
                
            if(filter.EntrustedTaskId && filter.areaId){
                if(filter.EntrustedTaskId != "-1" && filter.areaId != "-1"){
                    if(filter.OperativeTaskId && filter.OperativeTaskId != "-1"){
                        cards = cardsAll.filter((tc)=> tc.operativeTaskTypeId == filter.OperativeTaskId && tc.entrustedTaskTypeId == filter.EntrustedTaskId && tc.areaId == filter.areaId)
                    }
                    else
                    {
                        cards = cardsAll.filter((tc)=> tc.entrustedTaskTypeId == filter.EntrustedTaskId && tc.areaId == filter.areaId)
                    }
                }
            }

            return {
                ...state,
                isFetching: false,
                cards,
                cardsAll,
                metadata: action.payload.metadata
            };
        }
        case TASKSCONFIGURATIONS_CARDS_FILTER_SUCCESS: {
            
            return {
                ...state,
                isFetching: false,
                cards: action.payload.records,
                
            };
        }
        case TASKSCONFIGURATIONS_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case TASKSCONFIGURATIONS_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        case TASKSCONFIGURATIONS_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        case TASKSCONFIGURATIONS_FORM_DELETE_SUCCESS: {
            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload.taskConfigurationId),
                metadata: {
                    ...state.metadata,
                    totalCount: state.metadata.totalCount - 1
                }
            };
        }
        case TASKSCONFIGURATIONS_FORM_GET_SUCCESS: {
            return {
                ...state,
                TaskConfigurationToEdit: action.payload.response
            };
        }        
        case TASKSCONFIGURATIONS_FORM_EDIT_SUCCESS: {
            return {
                ...state,
                TaskConfigurationToEdit: undefined
            };
        }
        case TASKSCONFIGURATIONS_FORM_LOAD_SUCCESS: {
            const structureTypeOption = action.payload.structureTypeOption.map(st => ({
                key: st.id,
                text: st.description
            }))

            const areasOptions = action.payload.areasOptions.map(a => ({
                key: a.id,
                text: a.name
            }))

            const entrustedTasksOptions = action.payload.entrustedTasksOptions.map(et => ({
                key: et.id,
                text: et.description
            }))

            const operativeTasksOptions = action.payload.operativeTasksOptions.map(ot => ({
                key: ot.id,
                text: ot.name
            }))

            return {
                ...state,
                structureTypeOption,
                areasOptions: initialOption.concat(areasOptions),
                entrustedTasksOptions: initialOption.concat(entrustedTasksOptions),
                operativeTasksOptions: initialOption.concat(operativeTasksOptions),
                TaskConfigurationToEdit: {structureTypeFK:structureTypeOption[0].key}
            }
        }
        default: {
            return state;
        }
    }
};

export default tasksConfigurationsReducer;

export const getTaskConfigurationsState = (state) => state.tasksConfigurationsReducer;

export const getFilter = (state) => getTaskConfigurationsState(state).filter;

export const getIsFetching = (state) => getTaskConfigurationsState(state).isFetching;

export const getCards = (state) => getTaskConfigurationsState(state).cards;

export const getMetadata = (state) => getTaskConfigurationsState(state).metadata;

export const getTaskConfigurationToEdit = (state) => getTaskConfigurationsState(state).TaskConfigurationToEdit;

export const getStructureTypeOption = (state) => getTaskConfigurationsState(state).structureTypeOption;

export const getAreasOptions = (state) => getTaskConfigurationsState(state).areasOptions;

export const getEntrustedTasksOptions = (state) => getTaskConfigurationsState(state).entrustedTasksOptions;

export const getOperativeTasksOptions = (state) => getTaskConfigurationsState(state).operativeTasksOptions;