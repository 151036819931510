import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditReportsForm } from 'Src/components/reports/reportsForm'

import { getClientsOption,getRegionsOption,getStructureTypeTemplate,getWorkOrderStateOptions, getClientsOptionTree, getEntrustedTasksOptionsTree , getCertificationOption, getInvoiceOption, getStateStructureOptions, getBaseDispatchOptions, getStructureDispatchOptions} from "Src/redux/reducers/reportsReducer";

import { tryCreateReport,tryFormLoad,tryGetClientChildren } from 'Src/redux/thunk-creators/reportsActionCreator';

class ReportsPage extends Component {
    constructor(props) {
        super(props);
        this.onChangeClient = this.onChangeClient.bind(this);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }

    onChangeClient(event) {
        if (event.target.value)
            this.props.tryGetClientChildren(event.target.value)
    }



    render() {
        const { 
            goBack,clientsOptions,regionsOptions,structureTypeTemplate,
            workOrderStateOptions, clientOptionsTree, entrustedTasksOptionsTree, certificationOptions,invoiceOptions, stateStructureOptions, baseDispatchOptions, structureDispatchOptions, tryCreateReport
        } = this.props;
      
        return (
            <Container className="StructureTypes-container">
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedEditReportsForm
                                title="Reportes"
                                submitText="Consultar"
                                onSubmit={report => tryCreateReport(report)}
                                onCancel={goBack}
                                clientsOptions={clientsOptions}
                                onChangeClient={this.onChangeClient}
                                regionsOptions={regionsOptions}
                                structureTypeOptions={structureTypeTemplate}
                                workOrderStateOptions={workOrderStateOptions}
                                clientOptionsTree={clientOptionsTree}
                                entrustedTasksOptionsTree = {entrustedTasksOptionsTree}
                                certificationOptions={certificationOptions}
                                invoiceOptions={invoiceOptions}
                                stateStructureOptions={stateStructureOptions}
                                baseDispatchOptions={baseDispatchOptions}
                                structureDispatchOptions={structureDispatchOptions}
                            />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        clientsOptions: getClientsOption(state),
        regionsOptions: getRegionsOption(state),
        structureTypeTemplate: getStructureTypeTemplate(state),
        workOrderStateOptions: getWorkOrderStateOptions(state),
        clientOptionsTree: getClientsOptionTree(state),
        entrustedTasksOptionsTree: getEntrustedTasksOptionsTree(state),
        certificationOptions: getCertificationOption(state),
        invoiceOptions: getInvoiceOption(state),
        stateStructureOptions: getStateStructureOptions(state),
        baseDispatchOptions: getBaseDispatchOptions(state),
        structureDispatchOptions:getStructureDispatchOptions(state),

    };
}

const mapDispatchToProps = dispatch => ({
    tryCreateReport: (report) => dispatch(tryCreateReport(report)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad()),
    tryGetClientChildren: (clientId) => dispatch(tryGetClientChildren(clientId)),
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportsPage);