import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import {
    Container, Row, Col,
    Card, Spinner, Button
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import LoadingCard from 'Src/components/loadingCard';
import { WORKORDERS_FORM_URI } from 'Src/routes';

import { getMetadata, getIsFetching, getFilter } from "Src/redux/reducers/workOrdersCardsReducer";
import { setCardsFilter, setShowCards } from 'Src/redux/thunk-creators/workOrdersCardsActionCreator';
import { getUserProfile, getUserPermissions } from "Src/redux/reducers/loginReducer";

const CardsFilter = React.lazy(() =>
    import('Src/components/cards/cardsFilter'));

const CardsMetadata = React.lazy(() =>
    import('Src/components/cards/cardsMetadata'));

const WorkOrdersCards = React.lazy(() =>
    import('Src/components/workOrders/workOrderCards'));

class WorkOrdersPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    handleFilterChange(event) {
        let filterText = event.target.value;
        //this.props.setCardsFilter(filterText);
        let statusWorkOrder = this.props.filter.statusWorkOrder;
        this.props.setCardsFilter(filterText,statusWorkOrder);
    }
    componentDidMount() {
        let filterText = this.props.filter.text;
        let statusWorkOrder = this.props.filter.statusWorkOrder;
        if(filterText || statusWorkOrder)
            this.props.setShowCards(filterText,statusWorkOrder);
    }

    render() {
        const { metadata, filter, userProfile, userPermissions }= this.props;
        let showCreateButtons = true;
        switch (userProfile) {
            case 'Administracion':
                if(typeof userPermissions ==  'object' && 'workOrders' in userPermissions) { 
                    showCreateButtons = userPermissions.workOrders.canCreate;
                }
              break;            
            case 'Adm Obras':
            case 'Ingenieria':
            case 'Proyectista Ingenieria':
            case 'Fabricacion':
            case 'Despacho':
            case 'Directivo':
                showCreateButtons= false;
              break;
            default:
              break;
          }
        return (
            <Container className="workorders-container">
                <Row>
                    <Col sm={12}>
                        <Card className="shadow">
                            <Card.Header><h5>Ordenes de Trabajo</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col sm={7}>
                                        <Suspense fallback={<Spinner animation="grow"/>}>
                                            <CardsFilter
                                                handleChange={this.handleFilterChange}
                                                filterText={filter.text} />
                                        </Suspense>
                                    </Col>
                                    
                                    <Col sm={3}>
                                        <Suspense fallback={<Spinner animation="grow"/>}>
                                            <CardsMetadata metadata={metadata} />
                                        </Suspense>
                                    </Col>
                                    
                                    <Col sm={1}>
                                        {showCreateButtons && 
                                        <Link to={WORKORDERS_FORM_URI}>
                                            <Button variant="primary">
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </Link>}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />}>
                            <WorkOrdersCards />
                        </Suspense>
                    </Col>
                </Row>
            </Container>
        );
    }
}


const mapStateToProps = state => {
    return {
        metadata: getMetadata(state),
        isFetching: getIsFetching(state),
        filter: getFilter(state),
        userProfile: getUserProfile(state),
        userPermissions: getUserPermissions(state)
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setCardsFilter: (filter) => dispatch(setCardsFilter(filter)),
        setShowCards:(filter,statusWorkOrder) => dispatch(setShowCards(filter,statusWorkOrder)),
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkOrdersPage);