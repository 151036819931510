export const WORKORDERS_CARDS_REQUEST = "WORKORDERS_CARDS_REQUEST";
export const WORKORDERS_CARDS_SUCCESS = "WORKORDERS_CARDS_SUCCESS";
export const WORKORDERS_CARDS_FAILTURE = "WORKORDERS_CARDS_FAILTURE";
export const WORKORDERS_CARDS_CLEAN = "WORKORDERS_CARDS_CLEAN";

export const WORKORDERS_OPTIONS_REQUEST = "WORKORDERS_OPTIONS_REQUEST";
export const WORKORDERS_OPTIONS_SUCCESS = "WORKORDERS_OPTIONS_SUCCESS";
export const WORKORDERS_OPTIONS_FAILTURE = "WORKORDERS_OPTIONS_FAILTURE";

export const WORKORDERS_FILTER_SET = "WORKORDERS_FILTER_SET";
export const WORKORDERS_PAGE_SET = "WORKORDERS_PAGE_SET";

//WORKORDERS_FORM
export const WORKORDERS_FORM_CREATE_REQUEST = "WORKORDERS_FORM_CREATE_REQUEST";
export const WORKORDERS_FORM_CREATE_SUCCESS = "WORKORDERS_FORM_CREATE_SUCCESS";
export const WORKORDERS_FORM_CREATE_FAILTURE = "WORKORDERS_FORM_CREATE_FAILTURE";

export const WORKORDERS_FORM_DELETE_REQUEST = "WORKORDERS_FORM_DELETE_REQUEST";
export const WORKORDERS_FORM_DELETE_SUCCESS = "WORKORDERS_FORM_DELETE_SUCCESS";
export const WORKORDERS_FORM_DELETE_FAILTURE = "WORKORDERS_FORM_DELETE_FAILTURE";

export const WORKORDERS_FORM_DELETE_DOCUMENT_REQUEST = "WORKORDERS_FORM_DELETE_DOCUMENT_REQUEST";
export const WORKORDERS_FORM_DELETE_DOCUMENT_SUCCESS = "WORKORDERS_FORM_DELETE_DOCUMENT_SUCCESS";
export const WORKORDERS_FORM_DELETE_DOCUMENT_FAILTURE = "WORKORDERS_FORM_DELETE_DOCUMENT_FAILTURE";

export const WORKORDERS_FORM_GET_REQUEST = "WORKORDERS_FORM_GET_REQUEST";
export const WORKORDERS_FORM_GET_SUCCESS = "WORKORDERS_FORM_GET_SUCCESS";
export const WORKORDERS_FORM_GET_FAILTURE = "WORKORDERS_FORM_GET_FAILTURE";

export const WORKORDERS_PREVIEW_GET_REQUEST = "WORKORDERS_PREVIEW_GET_REQUEST";
export const WORKORDERS_PREVIEW_GET_SUCCESS = "WORKORDERS_PREVIEW_GET_SUCCESS";
export const WORKORDERS_PREVIEW_GET_FAILTURE = "WORKORDERS_PREVIEW_GET_FAILTURE";

export const WORKORDERS_FORM_DUPLICATE_GET_REQUEST = "WORKORDERS_FORM_DUPLICATE_GET_REQUEST";
export const WORKORDERS_FORM_DUPLICATE_GET_SUCCESS = "WORKORDERS_FORM_DUPLICATE_GET_SUCCESS";
export const WORKORDERS_FORM_DUPLICATE_GET_FAILTURE = "WORKORDERS_FORM_DUPLICATE_GET_FAILTURE";

export const WORKORDERS_FORM_EDIT_REQUEST = "WORKORDERS_FORM_EDIT_REQUEST";
export const WORKORDERS_FORM_EDIT_SUCCESS = "WORKORDERS_FORM_EDIT_SUCCESS";
export const WORKORDERS_FORM_EDIT_FAILTURE = "WORKORDERS_FORM_EDIT_FAILTURE";

export const WORKORDERS_EDIT_STATES_REQUEST = "WORKORDERS_EDIT_STATES_REQUEST";
export const WORKORDERS_EDIT_STATES_SUCCESS = "WORKORDERS_EDIT_STATES_SUCCESS";
export const WORKORDERS_EDIT_STATES_FAILTURE = "WORKORDERS_EDIT_STATES_FAILTURE";

export const WORKORDERS_FORM_LOAD_REQUEST = "WORKORDERS_FORM_LOAD_REQUEST";
export const WORKORDERS_FORM_LOAD_SUCCESS = "WORKORDERS_FORM_LOAD_SUCCESS";
export const WORKORDERS_FORM_LOAD_FAILTURE = "WORKORDERS_FORM_LOAD_FAILTURE";

export const WORKORDERS_FORM_LOAD_CLIENT_CHILDRENS_REQUEST = "WORKORDERS_FORM_LOAD_CLIENT_CHILDRENS_REQUEST";
export const WORKORDERS_FORM_LOAD_CLIENT_CHILDRENS_SUCCESS = "WORKORDERS_FORM_LOAD_CLIENT_CHILDRENS_SUCCESS";
export const WORKORDERS_FORM_LOAD_CLIENT_CHILDRENS_FAILTURE = "WORKORDERS_FORM_LOAD_CLIENT_CHILDRENS_FAILTURE";

