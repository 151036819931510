
export const RESPONSIBLEFORAREA_CARDS_REQUEST = "RESPONSIBLEFORAREA_CARDS_REQUEST";
export const RESPONSIBLEFORAREA_CARDS_SUCCESS = "RESPONSIBLEFORAREA_CARDS_SUCCESS";
export const RESPONSIBLEFORAREA_CARDS_FAILTURE = "RESPONSIBLEFORAREA_CARDS_FAILTURE";
export const RESPONSIBLEFORAREA_CARDS_CLEAN = "RESPONSIBLEFORAREA_CARDS_CLEAN";

export const RESPONSIBLEFORAREA_FILTER_SET = "RESPONSIBLEFORAREA_FILTER_SET";
export const RESPONSIBLEFORAREA_PAGE_SET = "RESPONSIBLEFORAREA_PAGE_SET";

export const RESPONSIBLEFORAREA_FORM_ADD_REQUEST = "RESPONSIBLEFORAREA_FORM_ADD_REQUEST";
export const RESPONSIBLEFORAREA_FORM_ADD_SUCCESS = "RESPONSIBLEFORAREA_FORM_ADD_SUCCESS";
export const RESPONSIBLEFORAREA_FORM_ADD_FAILTURE = "RESPONSIBLEFORAREA_FORM_ADD_FAILTURE";

export const RESPONSIBLEFORAREA_FORM_DELETE_REQUEST = "RESPONSIBLEFORAREA_FORM_DELETE_REQUEST";
export const RESPONSIBLEFORAREA_FORM_DELETE_SUCCESS = "RESPONSIBLEFORAREA_FORM_DELETE_SUCCESS";
export const RESPONSIBLEFORAREA_FORM_DELETE_FAILTURE = "RESPONSIBLEFORAREA_FORM_DELETE_FAILTURE";

export const RESPONSIBLEFORAREA_FORM_GET_REQUEST = "RESPONSIBLEFORAREA_FORM_GET_REQUEST";
export const RESPONSIBLEFORAREA_FORM_GET_SUCCESS = "RESPONSIBLEFORAREA_FORM_GET_SUCCESS";
export const RESPONSIBLEFORAREA_FORM_GET_FAILTURE = "RESPONSIBLEFORAREA_FORM_GET_FAILTURE";

export const RESPONSIBLEFORAREA_FORM_EDIT_REQUEST = "RESPONSIBLEFORAREA_FORM_EDIT_REQUEST";
export const RESPONSIBLEFORAREA_FORM_EDIT_SUCCESS = "RESPONSIBLEFORAREA_FORM_EDIT_SUCCESS";
export const RESPONSIBLEFORAREA_FORM_EDIT_FAILTURE = "RESPONSIBLEFORAREA_FORM_EDIT_FAILTURE";

export const RESPONSIBLEFORAREA_FORM_LOAD_REQUEST = "RESPONSIBLEFORAREA_FORM_LOAD_REQUEST";
export const RESPONSIBLEFORAREA_FORM_LOAD_SUCCESS = "RESPONSIBLEFORAREA_FORM_LOAD_SUCCESS";
export const RESPONSIBLEFORAREA_FORM_LOAD_FAILTURE = "RESPONSIBLEFORAREA_FORM_LOAD_FAILTURE";