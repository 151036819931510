import config from 'Config';

import { buildDefaultOptions } from './authService';
import {
    httpGet, httpPost, httpPut,
    handleHttpError
} from './httpService';

import { 
    buildUriById,
    get as _get, 
    post as _post, 
    getById as _getById, 
    deleteById as _deleteById, 
    put as _put } from 'Src/services/entityService'

const API_ENDPOINT = `${config.apis.coreApi.URL}/api`
const ENTITY_URI = `${API_ENDPOINT}/clientcontacts`;

let get_Cancelation = undefined;
export const get = async (accessToken, filterText, pageSize, page) => 
                        _get(accessToken, ENTITY_URI, get_Cancelation, filterText, pageSize, page)

export const getById = async (accessToken, id) => 
                        _getById(accessToken, ENTITY_URI, id)

export const deleteById = async (accessToken, id) => 
                            _deleteById(accessToken, ENTITY_URI, id)

export const put = async (accessToken, id) => 
                        _put(accessToken, ENTITY_URI, id)

export const post = async (accessToken, clientContact) => {
    const params = {
        "Name": clientContact.name,
        "LastName": clientContact.lastName,
        "Email": clientContact.email,
        "Phone": clientContact.phone,
        "ContactTypeFK": clientContact.contactTypeFK,
        "ClientFK": clientContact.clientFK
    }

    return _post(accessToken, ENTITY_URI, params)
}


