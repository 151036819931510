import {
    DASHBOARD_MAP_FILTER_SET,
    DASHBOARD_MAP_FORM_LOAD_SUCCESS,
    DASHBOARD_MAP_SUCCESS,
    DASHBOARD_SHOW_CARD,
    DASHBOARD_PIECHART_SUCCESS,
    DASHBOARD_BARCHART_SUCCESS,
    DASHBOARD_PIECHART_FILTER_SET,
    DASHBOARD_BARCHART_FILTER_SET,
    DASHBOARD_WORKORDEN_AUTOCOMPLITE_SUCCESS,
    DASHBOARD_WORKORDEN_AUTOCOMPLITE_REQUEST,
    DASHBOARD_MAP_WEATHER_SUCCESS
  } from "Src/redux/actions/dashboardActionTypes";

import broadcastTowerSolidEnd from 'Src/assets/images/broadcast-tower-solid-end.png';
import broadcastTowerSolidCourse from 'Src/assets/images/broadcast-tower-solid-in-course.png';
import broadcastTowerSolidDelay from 'Src/assets/images/broadcast-tower-solid-delay.png';
import broadcastTowerSolidSuspened from 'Src/assets/images/broadcast-tower-solid-suspened.png';
import broadcastTowerSolidCanceled from 'Src/assets/images/broadcast-tower-solid-canceled.png';

import {
    inputformatDate
} from 'Src/services/formatService';

const setlocations = (mapData,icon) => {
    return mapData.filter(x => x.coordinates).map((l)=>{
        l.coordinates.structureType = l.structureType; 
        l.coordinates.workOrderId = l.workOrderId;
        l.coordinates.icon = icon;  
        l.coordinates.client = l.client;          
        l.coordinates.orderDate = inputformatDate(l.orderDate);
        l.coordinates.id=l.workOrderId;
        l.coordinates.requirement=l.requirement;
        return l.coordinates
    })
};

const initialMetadata = {
    perPage: 5000,
    page: 1
};

const initialOptionOfStructureType = [{ key: -1, text: "Todas las estructuras" }]
const initialOptionOfClient = [{ key: -1, text: "Todos los clientes" }]
const initialOptionOfState = [{ key: -1, text: "Todos los estados" }]
const stateStructure = [{ key: 1, text: "En Curso" },
                        { key: 2, text: "Con retraso" },
                        { key: 3, text: "Finalizadas" },
                        { key: 4, text: "Suspendidas" },
                        { key: 5, text: "Canceladas" }]

const initialState = {
    hasError: false,
    isFetching: false,
    filter: undefined,
    filterPieChart: undefined,
    filterBarChart: undefined,
    metadata: initialMetadata,
    dashboardToEdit: undefined,
    structureTypeOption:initialOptionOfStructureType,
    clientOptions:initialOptionOfClient,
    stateStructureOptions:initialOptionOfState,
    locations:[],
    isShownCard:"map",
    dataPie: undefined,
    dataBar: undefined,
    paiQuiantites: undefined,
    optionAutocomplite: [],
    locationData: undefined,
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_MAP_FILTER_SET: {
            const { filter } = action.payload
            return {
                ...state,
                optionAutocomplite: [],
                filter,
                metadata: { ...initialMetadata }
            }
        }
        case DASHBOARD_PIECHART_FILTER_SET: {
            const { filter } = action.payload
            return {
                ...state,
                optionAutocomplite: [],
                filterPieChart:filter,
                metadata: { ...initialMetadata }
            }
        }
        case DASHBOARD_BARCHART_FILTER_SET: {
            const { filter } = action.payload
            return {
                ...state,
                optionAutocomplite: [],
                filterBarChart:filter,
                metadata: { ...initialMetadata }
            }
        }
        case DASHBOARD_SHOW_CARD: {
            const { isShownCard } = action.payload
            return {
                ...state,
                locationData:undefined,
                isShownCard
            }
        } 
        case DASHBOARD_MAP_SUCCESS: {
            const { workOrderInCourse,
                    workOrderWithDelays,
                    workOrderFinished,
                    workOrderCanceled,
                    workOrderSuspended 
                } = action.payload.records 
            let locations = [];            

            const locationInCourse = setlocations(workOrderInCourse,broadcastTowerSolidCourse);

            const locationWithDelays = setlocations(workOrderWithDelays,broadcastTowerSolidDelay);

            const locationFinished = setlocations(workOrderFinished,broadcastTowerSolidEnd);
            
            const locationSuspended = setlocations(workOrderSuspended,broadcastTowerSolidSuspened);

            const locationCanceled = setlocations(workOrderCanceled,broadcastTowerSolidCanceled);

            locations=[ 
                    ...locationInCourse,
                    ...locationWithDelays,
                    ...locationFinished,
                    ...locationSuspended,
                    ...locationCanceled
                ];
            return {
                ...state,
                optionAutocomplite: [],
                locations
            }
        }  
        case DASHBOARD_PIECHART_SUCCESS: {
            const { records } = action.payload
            const { labels,datasets,incourse,withdelays,finished } = records

            const dataPie = {
                labels,
                datasets: [{
                    data: datasets,
                    backgroundColor: [
                    '#3B88CE',
                    '#FF3700',
                    '#019060'
                    ],
                    hoverBackgroundColor: [
                    '#3B88CE',
                    '#FF3700',
                    '#019060'
                    ]
                }]
            };

            
            return {
                ...state,
                optionAutocomplite: [],
                dataPie,
                paiQuiantites:{incourse,withdelays,finished} 
            }
        }
        case DASHBOARD_BARCHART_SUCCESS: {
            const { records } = action.payload
            const { labels,datasets} = records
            
            const dataBar = {
                labels,
                datasets: [
                  {
                    backgroundColor: [
                                    '#1E8250',
                                    '#28A969',
                                    '#3BC07E',
                                    '#44D78E',
                                    '#82EFB9',
                                    '#AAF7D1',
                                    '#E3FDF0',
                                    '#98CF98',
                                    '#B9EAB9'
                                    ],
                    borderWidth: 1,
                    data: datasets,        
                  }
                ]
              };

            
            return {
                ...state,
                optionAutocomplite: [],
                dataBar
            }
        }             
        case DASHBOARD_MAP_FORM_LOAD_SUCCESS: {
            const structureTypeOption = action.payload.structureTypeOption.map(st => ({
                key: st.id,
                text: st.description
            }))
            const clientOptions = action.payload.clientOptions.map(c => ({
                key: c.id,
                text: c.comertialName
            }))

            return {
                ...state,
                optionAutocomplite: [],
                structureTypeOption:initialOptionOfStructureType.concat(structureTypeOption),
                clientOptions:initialOptionOfClient.concat(clientOptions),
                stateStructureOptions:initialOptionOfState.concat(stateStructure)
            }
        }
        case DASHBOARD_WORKORDEN_AUTOCOMPLITE_REQUEST: {
            return {
                ...state,
                isFetching: true
            }
        }
        case DASHBOARD_WORKORDEN_AUTOCOMPLITE_SUCCESS: {
            const { workOrderInCourse,
                workOrderWithDelays,
                workOrderFinished,
                workOrderCanceled,
                workOrderSuspended 
            } = action.payload.records
        let locations = [];            

        const locationInCourse = setlocations(workOrderInCourse,broadcastTowerSolidCourse);

        const locationWithDelays = setlocations(workOrderWithDelays,broadcastTowerSolidDelay);

        const locationFinished = setlocations(workOrderFinished,broadcastTowerSolidEnd);
        
        const locationSuspended = setlocations(workOrderSuspended,broadcastTowerSolidSuspened);

        const locationCanceled = setlocations(workOrderCanceled,broadcastTowerSolidCanceled);

        locations=[ 
                ...locationInCourse,
                ...locationWithDelays,
                ...locationFinished,
                ...locationSuspended,
                ...locationCanceled
            ];
            return {
                ...state,
                isFetching: false,
                optionAutocomplite: locations
            };
        }
        case DASHBOARD_MAP_WEATHER_SUCCESS: {
            
            return {
                ...state,
                optionAutocomplite: [],
                locationData: action.payload.records[0]
            };
        }
        default: {
            return state;
        }
    }
};

export default dashboardReducer;

export const getDashboardsState = (state) => state.dashboardReducer;

export const getFilter = (state) => getDashboardsState(state).filter;

export const getFilterPieChart = (state) => getDashboardsState(state).filterPieChart;

export const getlocations = (state) => getDashboardsState(state).locations;

export const getStructureTypeOption = (state) => getDashboardsState(state).structureTypeOption;

export const getClientsOption = (state) => getDashboardsState(state).clientOptions;

export const getStateStructureOptions= (state) => getDashboardsState(state).stateStructureOptions;

export const getShowCard= (state) => getDashboardsState(state).isShownCard;

export const getDataForPieChart = (state) => getDashboardsState(state).dataPie;

export const getDataForBarChart = (state) => getDashboardsState(state).dataBar;    

export const getPaiQuantities = (state) => getDashboardsState(state).paiQuiantites;   

export const getAutocomplete = (state) => getDashboardsState(state).optionAutocomplite;

export const getLocationData = (state) => getDashboardsState(state).locationData;