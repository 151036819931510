import React,{Component} from 'react';
import {
    Button, Spinner,Col,Row
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { dropdownField } from 'Src/components/forms/dropdownField';
import { datePickerField } from 'Src/components/forms/dateField';

//TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')
//const requiredselect = value => (value ? undefined: 'Requerido!')

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}
    if(values.filterStartDate)
        errors.filterEndDate = required(values.filterEndDate)
    
    if(values.filterEndDate)
        errors.filterStartDate = required(values.filterStartDate)

    return errors
}

class filterPieChartForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, submitText,
            structureTypeOptions,clientsOptions,
            mode
        } = this.props;
        return (
                <form onSubmit={handleSubmit}>                        
                    <Row>
                        <Col>
                        <Field name="StructureTypeId" 
                                component={dropdownField}
                                options={structureTypeOptions} />
                        </Col>
                        <Col>
                        <Field name="ClientId"
                                component={dropdownField}
                                options={clientsOptions} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Field name="StartDate" placeholder="Fecha desde" component={datePickerField} />
                        </Col>
                        <Col>
                            <Field name="EndDate" placeholder="Fecha hasta" component={datePickerField} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant={"primary"} type="submit" disabled={pristine || submitting}>
                                {submitText}
                                {submitting ? <Spinner animation="grow" size="sm" /> : null}
                            </Button>                            
                        </Col>
                        <Col className="text-right">
                            <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                        </Col>
                    </Row>
                </form>
        );
    }
}


export const EDIT_FILTER_DASHBOARD_PIE_CHART = 'EDIT_FILTER_DASHBOARD_PIE_CHART';

  
const EditfilterPieChartForm = props =>
  <filterPieChartForm {...props} mode='edit' />;

const ConnectedEditfilterPieChartForm = reduxForm({
    form: EDIT_FILTER_DASHBOARD_PIE_CHART,
    validate
})(filterPieChartForm);

export {
    ConnectedEditfilterPieChartForm
}