import React from 'react'

import {
    Form, Col, Row, Alert
} from 'react-bootstrap'

import { TreeSelect } from 'antd';
// https://ant.design/components/tree-select/

function findPath(value, data) {
    const sel = [];
    function loop(selected, children) {
      for (let i = 0; i < children.length; i++) {
        const item = children[i];
        if (selected === item.value) {
          sel.push(item);
          return;
        }
        if (item.children) {
          loop(selected, item.children, item);
          if (sel.length) {
            sel.push(item);
            return;
          }
        }
      }
    }
    loop(value, data);
    return sel;
  }

export class treeSelectField extends React.Component {
    state = {
        value: undefined,
      };
    valueMap = {}
    loops(list, parent) {
        return (list || []).map(({ children, value }) => {
            const node = (this.valueMap[value] = {
                parent,
                value
            });
            node.children = this.loops(children, node);
            return node;
        });
    }

    getPath(value) {
        const path = [];
        let current = this.valueMap[value];
        
        while (current) {
            path.unshift(current.value);
            current = current.parent;
        }
        return path;
    }

    onChange = (value, input) => {

    
        if (value.length <= 0) {
          this.setState({ value });
          let path = null
          input.onChange({
            value,
            path
        })
          return;
        }

        if(value.length){
            value = value.map(element => {
                const path = findPath(element.value, this.props.treeData)
                    .map(i => i.title)
                    .reverse()
                    .join(' > ');
                element = { value: element.value, label: path };
                return element
            });
        }        
        
        let path = this.getPath(value)        
        
        this.setState({ value });
        input.onChange({
            value,
            path
        })
      };

    onSelect(value) {
        //console.log("Select:", this.getPath(value.value));
    }


    

    render() {
        const {
            name, input, label,
            treeData, isMultiple,
            meta
        } = this.props;
       
        if(input.value == '')     
        {
           this.state = {value:null};
        }
      
        if(input.value && treeData) {
        
            if(typeof input.value === 'string') {
                let inputEdit = JSON.parse(input.value);
                this.state = inputEdit;
                if(inputEdit.value.length > 0){
                    let valuesList = inputEdit.value.map(element => {
                        const path = findPath(element, this.props.treeData)
                            .map(i => i.title)
                            .reverse()
                            .join(' > ');
                        element = { value: element, label: path };
                        return element
                    });
                    this.state = {value:valuesList};
                }
            }
            if(input.name == 'entrustedTaskTypes'){

                const resultado = input.value.value.map( t => { 
                    
                    if(t.hasOwnProperty('label')) 
                        return t;
                        this.props.treeData.forEach(i => {
                            if(i.value == t){
                                t = { value: t, label: i.title };
                                return t;
                            }
                        }); 
                        return t;                       
                 } );
                this.state = {value:resultado};
            }
        }
        this.loops(treeData)
        
        return (
            <Form.Group as={Row} controlId={name}>
                <Form.Label column sm="3">{label}</Form.Label>
                <Col sm="9">
                    <TreeSelect
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        treeData={treeData}
                        placeholder={label}
                        multiple={isMultiple}
                        labelInValue         
                        value={this.state.value}               
                        // treeDefaultExpandAll
                        onChange={value => this.onChange(value, input)}
                        onSelect={value => this.onSelect(value, input)}
                    />

                    {(meta.touched && meta.error) ?
                        <Alert key="error" variant="danger">
                            {meta.error}
                        </Alert>
                    : null}

                </Col>
            </Form.Group >
        );
    }
}
