import React,{Component} from 'react';
import {
    Card, Button, Spinner,Col,Row
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { inputField } from 'Src/components/forms/inputField';
import { dropdownField } from 'Src/components/forms/dropdownField';

//TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')
//const requiredselect = value => (value ? undefined: 'Requerido!')

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}
    errors.description = required(values.description)
    return errors
}

class StructureTypeForm extends Component {
    constructor(props) {
        super(props);
        this.state = { DuplicationButtonShow: false };
        this.onChangeDuplicate = this.onChangeDuplicate.bind(this);
    }

    onChangeDuplicate(e){
        let selectVal = e.target.value
        let stateButton = true
        if(selectVal == "-1"){
            stateButton = false 
        }
        this.setState({
            DuplicationButtonShow:stateButton
        })
    }

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText,
            statusOption,duplicationOptions,
            mode
        } = this.props;
        let { DuplicationButtonShow } = this.state
        return (
            <Card className="shadow">
                <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Field name="description" label="Estructura" component={inputField} />
                            </Col>
                        </Row>
                        {statusOption &&
                        <Row>
                            <Col>
                            <Field name="active" label="Estado"
                                    component={dropdownField}
                                    options={statusOption} />
                            </Col>
                        </Row>}
                        {duplicationOptions &&
                        <Row>
                            <Col>
                            <Field name="structureDuplication" label="Opciones para duplicar"
                                    component={dropdownField}
                                    options={duplicationOptions}
                                    onChange={this.onChangeDuplicate} />
                            </Col>
                        </Row>}
                        <Row>
                            <Col>
                                <Button variant={!DuplicationButtonShow?"primary":"warning"} type="submit" disabled={pristine || submitting}>
                                    {!DuplicationButtonShow?submitText:"Duplicar"}
                                    {submitting ? <Spinner animation="grow" size="sm" /> : null}
                                </Button>                            
                            </Col>
                            <Col className="text-right">
                                <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                                &nbsp;                            
                                <Button variant="danger" onClick={onCancel}>Cancelar</Button>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_STRUCTURETYPE_NAME = 'CREATE_STRUCTURETYPE_NAME';
export const EDIT_STRUCTURETYPE_NAME = 'EDIT_STRUCTURETYPE_NAME';

const CreateStructureTypeForm = props =>
  <StructureTypeForm {...props} mode='create' />;
  
const EditStructureTypeForm = props =>
  <StructureTypeForm {...props} mode='edit' />;

const ConnectedCreateStructureTypeForm = reduxForm({
    form: CREATE_STRUCTURETYPE_NAME,
    validate,
    enableReinitialize:true,
     destroyOnUnmount:false
})(CreateStructureTypeForm);

const ConnectedEditStructureTypeForm = reduxForm({
    form: EDIT_STRUCTURETYPE_NAME,
    validate,
    enableReinitialize:true,
     destroyOnUnmount:false
})(EditStructureTypeForm);

export {
    ConnectedCreateStructureTypeForm,
    ConnectedEditStructureTypeForm
}