import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditAreaForm } from 'Src/components/areas/areaForm'

import { getAreaToEdit } from "Src/redux/reducers/areasReducer";

import { tryEditArea } from 'Src/redux/thunk-creators/areasActionCreator';

class AreasEditPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { 
            onAreaEdit, goBack, AreaToEdit
        } = this.props;
      
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedEditAreaForm
                                title="Editar Area"
                                submitText="Editar"
                                onSubmit={area => onAreaEdit(area)}
                                onCancel={goBack}
                                initialValues={AreaToEdit}
                            />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        AreaToEdit: getAreaToEdit(state),
    };
}

const mapDispatchToProps = dispatch => ({
    onAreaEdit: (area) => dispatch(tryEditArea(area)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AreasEditPage);
