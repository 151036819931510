import {
    ENTRUSTEDTASKTYPES_CARDS_REQUEST,
    ENTRUSTEDTASKTYPES_CARDS_SUCCESS,
    ENTRUSTEDTASKTYPES_CARDS_FAILTURE,
    ENTRUSTEDTASKTYPES_CARDS_CLEAN,
    ENTRUSTEDTASKTYPES_FILTER_SET,
    ENTRUSTEDTASKTYPES_PAGE_SET,
    ENTRUSTEDTASKTYPES_FORM_DELETE_SUCCESS,
    ENTRUSTEDTASKTYPES_FORM_GET_SUCCESS,
    ENTRUSTEDTASKTYPES_FORM_EDIT_SUCCESS
} from "Src/redux/actions/entrustedTaskTypesActionTypes";

const initialMetadata = {
    perPage: 6,
    page: 1
};

const statusOption = [{ key: true, text: "Activo" },{ key: false, text: "Inactivo" }]

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: ""
    },
    metadata: initialMetadata,
    cards: [],
    EntrustedTaskTypeToEdit: undefined,
    statusOption:statusOption
};

const entrustedTaskTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ENTRUSTEDTASKTYPES_FILTER_SET: {
            return {
                ...state,
                filter: {
                    text: action.payload.filter
                },
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case ENTRUSTEDTASKTYPES_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case ENTRUSTEDTASKTYPES_CARDS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                cards: state.cards.concat(action.payload.records),
                metadata: action.payload.metadata
            };
        }
        case ENTRUSTEDTASKTYPES_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case ENTRUSTEDTASKTYPES_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        case ENTRUSTEDTASKTYPES_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        case ENTRUSTEDTASKTYPES_FORM_DELETE_SUCCESS: {
            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload.entrustedTaskTypeId),
                metadata: {
                    ...state.metadata,
                    totalCount: state.metadata.totalCount - 1
                }
            };
        }
        case ENTRUSTEDTASKTYPES_FORM_GET_SUCCESS: {
            return {
                ...state,
                EntrustedTaskTypeToEdit: action.payload.response
            };
        }
        case ENTRUSTEDTASKTYPES_FORM_EDIT_SUCCESS: {
            return {
                ...state,
                EntrustedTaskTypeToEdit: undefined
            };
        }
        default: {
            return state;
        }
    }
};

export default entrustedTaskTypesReducer;

export const getEntrustedTaskTypesState = (state) => state.entrustedTaskTypesReducer;

export const getFilter = (state) => getEntrustedTaskTypesState(state).filter;

export const getIsFetching = (state) => getEntrustedTaskTypesState(state).isFetching;

export const getCards = (state) => getEntrustedTaskTypesState(state).cards;

export const getMetadata = (state) => getEntrustedTaskTypesState(state).metadata;

export const getEntrustedTaskTypeToEdit = (state) => getEntrustedTaskTypesState(state).EntrustedTaskTypeToEdit;

export const getStatusOption = (state) => getEntrustedTaskTypesState(state).statusOption;