import React, { Suspense } from 'react';
import {
    Card, Button, Spinner, Form, Row, Col
} from 'react-bootstrap';
import moment from 'moment';

import LoadingCard from 'Src/components/loadingCard';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { parseDate } from 'Src/services/formatService'

import { inputField } from 'Src/components/forms/inputField';
import { datePickerField } from 'Src/components/forms/dateField';
import { checkboxField } from 'Src/components/forms/checkboxField';
import { dropdownField } from 'Src/components/forms/dropdownField';
import { textAreaField } from 'Src/components/forms/textAreaField';
import { treeSelectField } from 'Src/components/forms/treeSelectField';
import { renderFiles } from 'Src/components/forms/renderFiles';
import { LinkFieldFile } from 'Src/components/forms/linkFieldFile';
import { locationField } from 'Src/components/forms/locationField';

import { ConnectedCreateClientContactForm } from 'Src/components/clientContacts/clientContactFormWorkOrder';


import Lightbox from 'react-lightbox-component';
import "react-lightbox-component/build/css/index.css";
import { loadState } from "../../redux/localStorage";



//TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')

const number = value =>
    value && isNaN(Number(value)) ? 'Debe ser un número!' : undefined

const minValue = min => value =>
    value && value < min ? `Mínimo ${min}` : undefined

const maxValue = max => value =>
    value && value > max ? `Máximo ${max}` : undefined

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}

    errors.clientFK = required(values.clientFK)
    errors.station = required(values.station)
    errors.location = required(values.location)
    errors.structureTypeFK = required(values.structureTypeFK)
    errors.orderDate = required(values.orderDate)
    errors.startDate = required(values.startDate)
    errors.deliveryDays = required(values.deliveryDays)

    if (values.deliveryDays) {
        const deliveryDays = parseInt(values.deliveryDays)
        errors.deliveryDays = number(deliveryDays)
            || minValue(1)(deliveryDays)
            || maxValue(200)(deliveryDays)
    }

    if (values.height) {
        errors.height = number(values.height)
        errors.height = minValue(1)(values.height)
        errors.height = maxValue(300)(values.height)
    }

    if (values.estimatedWeight) {
        errors.estimatedWeight = number(values.estimatedWeight)
        errors.estimatedWeight = minValue(1)(values.estimatedWeight)
    }

    if (values.realWeight) {
        errors.realWeight = number(values.realWeight)
        errors.realWeight = minValue(1)(values.realWeight)
        errors.realWeight = maxValue(100000)(values.realWeight)
    }

    if (values.releaseDate && values.startDate) {
        const releaseDateTimestamp = parseDate(values.releaseDate).valueOf()
        const startDateTimestamp = parseDate(values.startDate).valueOf()
        errors.releaseDate = releaseDateTimestamp < startDateTimestamp ? "Fecha de Liberación debe ser mayor o igual a Fecha de Inicio" : undefined
    }

    return errors
}


class WorkOrderForm extends React.Component {

    showContactFrom(ContactFrom) {
        this.setState({ ContactFrom });
    }
    constructor(props) {
      super(props);

      this.state = { ContactFrom: false };
      this.showContactFrom = this.showContactFrom.bind(this);
      this.hideContactFrom = this.hideContactFrom.bind(this);
      
    }

    hideContactFrom(ContactFrom) {
        this.setState({ ContactFrom });
    }

    render() {
        const {
            error,
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText,
            clientsOptions,
            regionsOptions,
            contactSupervisorOptions,
            contactOnSiteOptions,
            contactComertialOptions,
            contactContractorOptions,
            structureTypeTemplate,
            particularDetailsTemplate,
            entrustedTaskTypesTemplate,
            onChangeClient,
            certifications,
            facturations,
            endDate,
            listDocuments,
            onDeleteDocumentClick,
            contactTypesOptions,
            onSaveContact,
            onChangeAddress,
            listAttachedImages,
            withTaxReceipts,
            taxReceiptsList,
            listAttachedImagesOfTaxReceipts
        } = this.props;

        let isValidEndDate = moment(endDate,"DD/MM/YYYY").isValid();
        const { loginReducer } = loadState();
        const { isWeekend } = loginReducer.authentication;
        const withWeekend = isWeekend; // Fin de semanas!!!
        return (
            <Card className="shadow">
                <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        {!withTaxReceipts && 
                        <>
                        <Row>
                            <Col>
                                <Field name="requirement" label="Nombre de la Obra (RQ)" component={inputField} type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="clientFK" label="Cliente *"
                                    component={dropdownField}
                                    options={clientsOptions}
                                    onChange={onChangeClient} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="quotation" label="Cotización" component={checkboxField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="station" label="Estación *"
                                    component={inputField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="contractorContactFK" label="Contratista"
                                    component={dropdownField}
                                    options={contactContractorOptions} 
                                    onAdd={() => this.showContactFrom(true)} />
                            </Col>
                        </Row>
                        {this.state.ContactFrom ?
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Suspense fallback={<LoadingCard />} >
                                            <ConnectedCreateClientContactForm
                                                title="Crear Contacto de Cliente"
                                                submitText="Crear"
                                                clientsOptions={clientsOptions}
                                                contactTypesOptions={contactTypesOptions}
                                                onClose={() => this.hideContactFrom(false)}
                                                onSubmit={onSaveContact}
                                            />
                                        </Suspense>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        : null}
                        <Row>
                            <Col>
                                <Field name="contract" label="Contrato" component={inputField} type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="assignation" label="Asignación" component={inputField} type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="location" label="Ubicación *" component={locationField} type="text" onChange={onChangeAddress} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="regionFK" label="Region *"
                                    component={dropdownField}
                                    options={regionsOptions} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="supervisorContactFK" label="Contacto Supervisor"
                                    component={dropdownField}
                                    options={contactSupervisorOptions}
                                    onAdd={() => this.showContactFrom(true)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="onSiteContactFK" label="Contacto en Sitio"
                                    component={dropdownField}
                                    options={contactOnSiteOptions}
                                    onAdd={() => this.showContactFrom(true)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="comertialContactFK" label="Contacto Comercial"
                                    component={dropdownField}
                                    options={contactComertialOptions}
                                    onAdd={() => this.showContactFrom(true)} />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Field name="structureTypeFK" label="Tipo de Estructura *"
                                    component={dropdownField}
                                    options={structureTypeTemplate} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="height" label="Altura" component={inputField} type="number" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="estimatedWeight" label="Peso Estimado" component={inputField} type="number" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="realWeight" label="Peso Real" component={inputField} type="number" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="orderDate" label="Fecha de Pedido *" withWeekend={withWeekend} component={datePickerField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="deliveryDays" label="Plazo (en días) *" component={inputField} type="number" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="startDate" label="Fecha de Inicio *" withWeekend={withWeekend} component={datePickerField}
                                    onChange={this.startDateOnChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="3">Fecha de Fin</Form.Label>
                                    <Col sm="9">{isValidEndDate?endDate:""}</Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="entrustedTaskTypes"
                                    label="Tareas Encomendadas"
                                    component={treeSelectField}
                                    isMultiple={true}
                                    treeData={entrustedTaskTypesTemplate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="particularDetails"
                                    label="Detalle Particulares"
                                    component={treeSelectField}
                                    isMultiple={true}
                                    treeData={particularDetailsTemplate}
                                />
                            </Col>
                        </Row>
                        </>}
                        <Row>
                            <Col>
                                <Field name="releaseDate" label="Fecha de Liberación" withWeekend={withWeekend} component={datePickerField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="deliveryDate" label="Fecha de Entrega" withWeekend={withWeekend} component={datePickerField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="certification" label="Certificacion"
                                    component={dropdownField}
                                    options={certifications} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="certificationDate" label="Fecha de Certificación" withWeekend={withWeekend} component={datePickerField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="facturation" label="Facturacion"
                                    component={dropdownField}
                                    options={facturations} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="invoiceDate" label="Fecha de Facturación" withWeekend={withWeekend} component={datePickerField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="observations" label="Observaciones" component={textAreaField} />
                            </Col>
                        </Row>
                    {(listDocuments && listDocuments.length > 0) ?
                        <Row>
                            <Form.Label column sm="3">Listado de Documentos</Form.Label>

                            <Col sm="9">

                                {listDocuments.map((doc) => (
                                    //doc.idGeneration
                                    <Field name={doc.name} id={doc.id} woId={doc.workOrderFK}
                                        onDelete={onDeleteDocumentClick} path={doc.path}
                                        component={LinkFieldFile} />
                                ))}

                                <Lightbox images = {listAttachedImages} />

                            </Col>
                        </Row>
                    : ''}
                    <Row>
                            <Col sm="3">
                                Agregar Documentos
                            </Col>
                            <Col sm="9">
                                <FieldArray name="files" component={renderFiles} />
                            </Col>
                        </Row>
                        <br/><br/>
                    {(taxReceiptsList && taxReceiptsList.length > 0 && withTaxReceipts) ?
                        <Row>
                            <Form.Label column sm="3">Listado de Comprobantes</Form.Label>

                            <Col sm="9">
                                {taxReceiptsList.map((doc) => (
                                    //doc.idGeneration
                                    <Field name={doc.name} id={doc.id} woId={doc.workOrderFK}
                                        onDelete={onDeleteDocumentClick} path={doc.path}
                                        component={LinkFieldFile} />
                                ))}

                                <Lightbox images = {listAttachedImagesOfTaxReceipts} />

                            </Col>
                        </Row>
                    : ''}                        
                        { withTaxReceipts ?
                        <Row>
                            <Col sm="3">
                                Comprobantes fiscales
                            </Col>
                            <Col sm="9">
                                <FieldArray name="taxReceiptsFiles" component={renderFiles} />
                            </Col>
                        </Row>
                        : null}
                        <Row>
                            <Col>
                                {error && <strong>{error}</strong>}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit" disabled={pristine || submitting}>
                                    {submitText}
                                    {submitting ? <Spinner animation="grow" size="sm" /> : null}
                                </Button>
                            </Col>
                            <Col className="text-right">
                                <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                                &nbsp;
                                <Button variant="danger" onClick={onCancel}>Cancelar</Button>
                            </Col>

                        </Row>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_WORKORDER_FORM = 'CREATE_WORKORDER_FORM';
export const EDIT_WORKORDER_FORM = 'EDIT_WORKORDER_FORM';

const CreateWorkOrderForm = props =>
    <WorkOrderForm {...props} mode='create' />;

const EditWorkOrderForm = props =>
    <WorkOrderForm {...props} mode='edit' />;

const ConnectedCreateWorkOrderForm = reduxForm({
    form: CREATE_WORKORDER_FORM,
    validate
})(CreateWorkOrderForm);

const ConnectedEditWorkOrderForm = reduxForm({
    form: EDIT_WORKORDER_FORM,
    validate
})(EditWorkOrderForm);

export {
    ConnectedCreateWorkOrderForm,
    ConnectedEditWorkOrderForm
}
