import {
    REGIONS_CARDS_REQUEST,
    REGIONS_CARDS_SUCCESS,
    REGIONS_CARDS_FAILTURE,
    REGIONS_CARDS_CLEAN,
    REGIONS_FILTER_SET,
    REGIONS_PAGE_SET,
    REGIONS_FORM_DELETE_SUCCESS,
    REGIONS_FORM_GET_SUCCESS,
    REGIONS_FORM_EDIT_SUCCESS,
    REGIONS_FORM_LOAD_SUCCESS
} from "Src/redux/actions/regionsCardsActionTypes";

const initialMetadata = {
    perPage: 9,
    page: 1
};

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: ""
    },
    metadata: initialMetadata,
    cards: [],
    regionToEdit: undefined,
    clientsOptions: []
};

const regionCardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGIONS_FILTER_SET: {
            return {
                ...state,
                filter: {
                    text: action.payload.filter
                },
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case REGIONS_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case REGIONS_CARDS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                cards: state.cards.concat(action.payload.records),
                metadata: action.payload.metadata
            };
        }
        case REGIONS_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case REGIONS_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        case REGIONS_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        case REGIONS_FORM_DELETE_SUCCESS: {
            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload.regionId),
                metadata: {
                    ...state.metadata,
                    totalCount: state.metadata.totalCount - 1
                }
            };
        }
        case REGIONS_FORM_GET_SUCCESS: {
            return {
                ...state,
                regionToEdit: action.payload.response
            };
        }
        case REGIONS_FORM_EDIT_SUCCESS: {
            return {
                ...state,
                regionToEdit: undefined
            };
        }
        case REGIONS_FORM_LOAD_SUCCESS: {
            let newClientsOptions = [{ key: -1, text: "Ninguno" }]

            const clientsOptions = action.payload.clientsOptions.map(c => ({
                key: c.id,
                text: c.comertialName
            }))

            return {
                ...state,
                clientsOptions: newClientsOptions.concat(clientsOptions),
                structureTypeTemplate: action.payload.structureTypeTemplate
            }
        }
        default: {
            return state;
        }
    }
};

export default regionCardsReducer;

export const getregionsCardsState = (state) => state.regionsCardsReducer;

export const getFilter = (state) => getregionsCardsState(state).filter;

export const getIsFetching = (state) => getregionsCardsState(state).isFetching;

export const getCards = (state) => getregionsCardsState(state).cards;

export const getMetadata = (state) => getregionsCardsState(state).metadata;

export const getregionToEdit = (state) => getregionsCardsState(state).regionToEdit;

export const getClientsOption = (state) => getregionsCardsState(state).clientsOptions;