import config from 'Config';
import {
    httpPost,
    handleHttpError
} from './httpService';
import { buildDefaultOptions } from './authService';

import { 
    get as _get, 
    post as _post, 
    getById as _getById, 
    deleteById as _deleteById, 
    put as _put } from 'Src/services/entityService'

const API_ENDPOINT = `${config.apis.coreApi.URL}/api`
const ENTITY_URI = `${API_ENDPOINT}/structuretypes`;

const ENTITY_DUPLICATE_URI = structureType => 
    ENTITY_URI+'/'+structureType+'/duplicate'

let get_Cancelation = undefined;
export const get = async (accessToken, filterText, pageSize, page) => 
                        _get(accessToken, ENTITY_URI, get_Cancelation, filterText, pageSize, page)

export const getById = async (accessToken, id) => 
                            _getById(accessToken, ENTITY_URI, id)

export const deleteById = async (accessToken, id) => 
                            _deleteById(accessToken, ENTITY_URI, id)

export const put = async (accessToken, id) => 
                        _put(accessToken, ENTITY_URI, id)

export const duplicatePost = async (accessToken, structuretypes) => {

    const entity_uri = ENTITY_DUPLICATE_URI(structuretypes.structureDuplication)
    let options = buildDefaultOptions(accessToken)
    options.headers['Content-Type'] = 'application/json';

    const params = {
        "description": structuretypes.description
    }

    try {
        let response = await httpPost(entity_uri, params, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const post = async (accessToken, structuretypes) => {
    const params = {
        "description": structuretypes.description
    }

    return _post(accessToken, ENTITY_URI, params)
}
