export const TASKSCONFIGURATIONS_CARDS_REQUEST = "TASKSCONFIGURATIONS_CARDS_REQUEST";
export const TASKSCONFIGURATIONS_CARDS_SUCCESS = "TASKSCONFIGURATIONS_CARDS_SUCCESS";
export const TASKSCONFIGURATIONS_CARDS_FAILTURE = "TASKSCONFIGURATIONS_CARDS_FAILTURE";
export const TASKSCONFIGURATIONS_CARDS_CLEAN = "TASKSCONFIGURATIONS_CARDS_CLEAN";

export const TASKSCONFIGURATIONS_FILTER_SET = "TASKSCONFIGURATIONS_FILTER_SET";
export const TASKSCONFIGURATIONS_PAGE_SET = "TASKSCONFIGURATIONS_PAGE_SET";

export const TASKSCONFIGURATIONS_FORM_ADD_REQUEST = "TASKSCONFIGURATIONS_FORM_ADD_REQUEST";
export const TASKSCONFIGURATIONS_FORM_ADD_SUCCESS = "TASKSCONFIGURATIONS_FORM_ADD_SUCCESS";
export const TASKSCONFIGURATIONS_FORM_ADD_FAILTURE = "TASKSCONFIGURATIONS_FORM_ADD_FAILTURE";

export const TASKSCONFIGURATIONS_FORM_DELETE_REQUEST = "TASKSCONFIGURATIONS_FORM_DELETE_REQUEST";
export const TASKSCONFIGURATIONS_FORM_DELETE_SUCCESS = "TASKSCONFIGURATIONS_FORM_DELETE_SUCCESS";
export const TASKSCONFIGURATIONS_FORM_DELETE_FAILTURE = "TASKSCONFIGURATIONS_FORM_DELETE_FAILTURE";

export const TASKSCONFIGURATIONS_FORM_GET_REQUEST = "TASKSCONFIGURATIONS_FORM_GET_REQUEST";
export const TASKSCONFIGURATIONS_FORM_GET_SUCCESS = "TASKSCONFIGURATIONS_FORM_GET_SUCCESS";
export const TASKSCONFIGURATIONS_FORM_GET_FAILTURE = "TASKSCONFIGURATIONS_FORM_GET_FAILTURE";

export const TASKSCONFIGURATIONS_FORM_EDIT_REQUEST = "TASKSCONFIGURATIONS_FORM_EDIT_REQUEST";
export const TASKSCONFIGURATIONS_FORM_EDIT_SUCCESS = "TASKSCONFIGURATIONS_FORM_EDIT_SUCCESS";
export const TASKSCONFIGURATIONS_FORM_EDIT_FAILTURE = "TASKSCONFIGURATIONS_FORM_EDIT_FAILTURE";

export const TASKSCONFIGURATIONS_FORM_LOAD_REQUEST = "TASKSCONFIGURATIONS_FORM_LOAD_REQUEST";
export const TASKSCONFIGURATIONS_FORM_LOAD_SUCCESS = "TASKSCONFIGURATIONS_FORM_LOAD_SUCCESS";
export const TASKSCONFIGURATIONS_FORM_LOAD_FAILTURE = "TASKSCONFIGURATIONS_FORM_LOAD_FAILTURE";

export const TASKSCONFIGURATIONS_CARDS_FILTER_SUCCESS = "TASKSCONFIGURATIONS_CARDS_FILTER_SUCCESS";

