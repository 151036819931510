import moment from 'moment';

export const isWeekday = (date) => {
    date = moment(date);
    const day = date.isoWeekday();
    return day !== 6 && day !== 7;
  };

export  const isNumeric = (num) => {
    return !isNaN(num);
}

