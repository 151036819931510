import React, { Component } from 'react'
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Row } from 'react-bootstrap';

import { getIsLoggedIn, getUserDisplayName, getUserProfile } from "../redux/reducers/loginReducer";
import { LOGOUT } from '../redux/actions/loginActionTypes';

import { LOGIN_URI } from "../routes";

class Header extends Component {
    constructor(props) {
        super(props);

        this.onLogoutClick = this.onLogoutClick.bind(this);
    }

    onLogoutClick(e) {
        this.props.logout();
        this.props.goToLogin();
    }

    render() {
        if (!this.props.isLoggedIn) {
            return (null);
        }

        let greetings = (
            <p className='user-greeting'>
                <span class="badge badge-primary badge-username">{this.props.userDisplayName}</span>
                <span class="badge badge-secondary badge-role">{this.props.userProfile}</span>
            </p>
        );

        let flagIcon = (
            <div className='flag'></div>
        );

        let logoutButton = (
            <button type="button" class="btn btn-link btn-sm logout-color"
                onClick={this.onLogoutClick}>Cerrar Sesión</button>
        );

        return (
            <Container className="header">
                <Row>
                    <div class="col-md-12">
                        <div class="well inline-headers float-sm-right">
                            <div className='logo-container'>
                                <div className='logo'></div>
                            </div>
                            {greetings}
                            {flagIcon}
                            {logoutButton}
                        </div>
                    </div>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: getIsLoggedIn(state),
        userDisplayName: getUserDisplayName(state),
        userProfile: getUserProfile(state),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch({ type: LOGOUT }),
        goToLogin: () => dispatch(push(LOGIN_URI)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);