export const REPORTS_PAGE_SET = "REPORTS_PAGE_SET";

export const REPORTS_FORM_GET_REQUEST = "REPORTS_FORM_GET_REQUEST";
export const REPORTS_FORM_GET_SUCCESS = "REPORTS_FORM_GET_SUCCESS";
export const REPORTS_FORM_GET_FAILTURE = "REPORTS_FORM_GET_FAILTURE";

export const REPORTS_FORM_LOAD_REQUEST = "REPORTS_FORM_LOAD_REQUEST";
export const REPORTS_FORM_LOAD_SUCCESS = "REPORTS_FORM_LOAD_SUCCESS";
export const REPORTS_FORM_LOAD_FAILTURE = "REPORTS_FORM_LOAD_FAILTURE";

export const REPORTS_FORM_LOAD_CLIENT_CHILDRENS_REQUEST = "REPORTS_FORM_LOAD_CLIENT_CHILDRENS_REQUEST";
export const REPORTS_FORM_LOAD_CLIENT_CHILDRENS_SUCCESS = "REPORTS_FORM_LOAD_CLIENT_CHILDRENS_SUCCESS";
export const REPORTS_FORM_LOAD_CLIENT_CHILDRENS_FAILTURE = "REPORTS_FORM_LOAD_CLIENT_CHILDRENS_FAILTURE";

export const REPORTS_FORM_WORKORDERSTATE_LOAD_REQUEST = "REPORTS_FORM_WORKORDERSTATE_LOAD_REQUEST";
export const REPORTS_FORM_WORKORDERSTATE_LOAD_SUCCESS = "REPORTS_FORM_WORKORDERSTATE_LOAD_SUCCESS";
export const REPORTS_FORM_WORKORDERSTATE_LOAD_FAILTURE = "REPORTS_FORM_WORKORDERSTATE_LOAD_FAILTURE";