import React, {Component} from 'react'

import config from 'Config';

export default class Footer extends Component {
    render() {
        return (
            <footer class="row page-footer font-weight-light footer-container fixed-bottom">
                <div class="col-md-12">
                    <div class="footer-copyright text-right py-1">
                        © 2021 Copyright: {config.appSettings.name} {config.appSettings.version} empowered by Dirmod
                    </div>
                </div>
            </footer>
        );
    }
}