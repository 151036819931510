
import { error, success } from 'react-notification-system-redux';


import {
    setFilter,
    getRequest,getFailture,getSuccess,
    getPieChartRequest,getPieChartSuccess,getPieChartFailture,
    getBarChartRequest,getBarChartSuccess,getBarChartFailture,
    formLoadRequest,formLoadFailture,formLoadSuccess,
    setShowCard,setFilterPieChart,setFilterBarChart,
    getAutocompleteRequest, getAutocompleteFailture, getAutocompleteSuccess,
    getSuccessWeather
} from "Src/redux/actions/dashboardActions";


import {
    get,
    getChartPie,
    getChartBar
} from 'Src/services/dashboardService';

import {
    get as getStructureType
} from 'Src/services/structureTypesService';

import {
    get as getClients
} from 'Src/services/clientsService';


import { formatDate } from 'Src/services/formatService';




const dashboardCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5

export const trySetShowCard = (cardName) => {
    return async (dispatch) => {
        dispatch(setShowCard(cardName));
    };
};

const setFilterAll = (dataForFilter,filter) => {
    if(dataForFilter.StructureTypeId && dataForFilter.StructureTypeId == '-1')
            filter.StructureTypeId = null
        
        if(dataForFilter.ClientId && dataForFilter.ClientId == '-1')
            filter.ClientId = null

        if(dataForFilter.StartDate)
            filter.StartDate=formatDate(dataForFilter.StartDate)
        
        if(dataForFilter.EndDate)
            filter.EndDate=formatDate(dataForFilter.EndDate)

    return filter;
}


export const tryFilter = (dataForFilter) => {
    return async (dispatch, getState) => {
        const { dashboardReducer } = getState();

        let filter ={...dataForFilter}

        dispatch(setFilter(filter));

        setFilterAll(dataForFilter,filter);
       
        const { loginReducer } = getState();        

        dispatch(tryGetLocalitationsAction(
            loginReducer.authentication.access_token,
            filter,
            dashboardReducer.metadata.perPage,
            1));

    };
};

export const tryFilterPieChart = (dataForFilter) => {
    return async (dispatch, getState) => {
        const { dashboardReducer } = getState();

        let filter ={...dataForFilter}

        dispatch(setFilterPieChart(filter));

        setFilterAll(dataForFilter,filter);

        const { loginReducer } = getState();

        dispatch(tryGetPieChart(
            loginReducer.authentication.access_token,
            filter,
            dashboardReducer.metadata.perPage,
            1));

    };
};

export const tryFilterBarChart = (dataForFilter) => {
    return async (dispatch, getState) => {
        const { dashboardReducer } = getState();

        let filter ={...dataForFilter}

        dispatch(setFilterBarChart(filter));

        setFilterAll(dataForFilter,filter);

        const { loginReducer } = getState();

        dispatch(tryGetBarChart(
            loginReducer.authentication.access_token,
            filter,
            dashboardReducer.metadata.perPage,
            1));
    };
};


export const tryGetLocalitationsAction = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {
        dispatch(getRequest());

        let response = await get(accessToken, null, pageSize, page,filter);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getFailture(response));

            dispatch(error({
                ...dashboardCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        let setResul = {
            workOrderInCourse:[],
            workOrderWithDelays:[],
            workOrderFinished:[],
            workOrderCanceled:[],
            workOrderSuspended:[]
        };

        if(!filter){
            setResul = response
        } else {
            switch (filter.stateStructure) {
                case '1':
                    setResul.workOrderInCourse=response.workOrderInCourse                    
                    break;
                case '2':
                    setResul.workOrderWithDelays=response.workOrderWithDelays
                    break;
                case '3':
                    setResul.workOrderFinished=response.workOrderFinished
                    break;
                case '4':
                    setResul.workOrderSuspended=response.workOrderSuspended
                    break;
                case '5':
                    setResul.workOrderCanceled=response.workOrderCanceled
                    break;
                default:
                    setResul = response
                    break;
            }
        }

        dispatch(getSuccess(setResul));
    }
};

export const tryGetPieChart = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {
        dispatch(getPieChartRequest());

        let response = await getChartPie(accessToken, null, pageSize, page,filter);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getPieChartFailture(response));

            dispatch(error({
                ...dashboardCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las graficas. Detalle: '${response.error.message}'`
            }));

            return;
        }    
        dispatch(getPieChartSuccess(response));
    }
};

export const tryGetBarChart = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {
        dispatch(getBarChartRequest());

        let response = await getChartBar(accessToken, null, pageSize, page,filter);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getBarChartFailture(response));

            dispatch(error({
                ...dashboardCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las graficas. Detalle: '${response.error.message}'`
            }));

            return;
        }    
        dispatch(getBarChartSuccess(response));
    }
};


export const tryFormLoad = () => {
    return async (dispatch, getState) => {
        const { loginReducer,dashboardReducer } = getState();
        if(!loginReducer.isLoggedIn)
            return null;

        const { access_token } = loginReducer.authentication;        

        dispatch(formLoadRequest());

        const StructureTypesResponse = await getStructureType(access_token, '', 1000, 1)
        if (StructureTypesResponse.hasOwnProperty("error")) {
            dispatch(formLoadFailture(StructureTypesResponse));
            dispatch(error({
                ...dashboardCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar los tipos de estructuras. Detalle: '${StructureTypesResponse.error.message}'`
            }));

            return;
        }

        const ClientsResponse = await getClients(access_token, '', 1000, 1)
        if (ClientsResponse.hasOwnProperty("error")) {
            dispatch(formLoadFailture(ClientsResponse));
            dispatch(error({
                ...dashboardCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar los Clientes. Detalle: '${ClientsResponse.error.message}'`
            }));

            return;
        }

        dispatch(tryGetLocalitationsAction(
            loginReducer.authentication.access_token,
            null,
            dashboardReducer.metadata.perPage,
            dashboardReducer.metadata.page));
        
        dispatch(tryGetPieChart(
            loginReducer.authentication.access_token,
            null,
            dashboardReducer.metadata.perPage,
            dashboardReducer.metadata.page));
        
        dispatch(tryGetBarChart(
            loginReducer.authentication.access_token,
            null,
            dashboardReducer.metadata.perPage,
            dashboardReducer.metadata.page));

        dispatch(formLoadSuccess(StructureTypesResponse.records,ClientsResponse.records));
        
    }
}


export const setAutocompleteFilter = (filterText) => {
    return async (dispatch, getState) => {
        

        const { loginReducer } = getState();

        dispatch(tryGetOptionAutocompliteAction(
            loginReducer.authentication.access_token,
            filterText));

    };
};

export const tryGetOptionAutocompliteAction = (accessToken,fiedTexto) => {
    return async (dispatch) => {
        dispatch(getAutocompleteRequest());

        let response = await get(accessToken, fiedTexto, 1000, 1);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getAutocompleteFailture(response));

            dispatch(error({
                ...entrustedTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(getAutocompleteSuccess(response));
    }
};

export const setLocationData = (filterText) => {
    return async (dispatch, getState) => {
        
        const { dashboardReducer } = getState();
        const { optionAutocomplite } = dashboardReducer;

        const locationData = optionAutocomplite.filter(x=>x.workOrderId==filterText);

        dispatch(getSuccessWeather(locationData));

    };
};

