import React, { Component } from 'react'
import {
  Row, Col,
  Form, Alert, Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export class InputFieldFileMultiple extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    const { input: { onChange } } = this.props
    onChange(e.target.files[0])
  }

  render() {
    const { input: { value: omitValue, ...inputProps }, meta: omitMeta, ...props } = this.props
    const { input, label, required, meta ,onRemove, name } = this.props
    return (
      <Form.Group as={Row} controlId={name}>
        <Col sm="9">
          <input
            {...inputProps}
            {...props}
            type='file'
            accept='.jpg, .png, .jpeg, .pdf'
            onChange={this.onChange}
          />
          {(meta.touched && meta.error) ?
            <Alert key="error" variant="danger">
              {meta.error}
            </Alert>
            : null}
        </Col>
        <Col sm="1">
            <Button type="button" variant="danger" onClick={onRemove} >
                <FontAwesomeIcon icon={faTrash} />
            </Button>
        </Col>
      </Form.Group>
    );
  }
}
