import {
    ENTRUSTEDTASKS_CARDS_REQUEST,
    ENTRUSTEDTASKS_CARDS_SUCCESS,
    ENTRUSTEDTASKS_CARDS_FAILTURE,
    ENTRUSTEDTASKS_CARDS_CLEAN,
    ENTRUSTEDTASKS_FILTER_SET,
    ENTRUSTEDTASKS_CARDS_FILTER_SET,
    ENTRUSTEDTASKS_OPTIONS_SUCCESS,
    ENTRUSTEDTASKS_AUTOCOMPLITE_REQUEST,
    ENTRUSTEDTASKS_AUTOCOMPLITE_SUCCESS,
    ENTRUSTEDTASKS_AUTOCOMPLITE_FAILTURE
} from "Src/redux/actions/entrustedTasksCardsActionTypes";

import {
    inputformatDate
} from 'Src/services/formatService';

const initialMetadata = {
    perPage: 6,
    page: 1
};
const emptyWorkOrders = [{ key: -1, text: "Ninguna" }]

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: "",
        WorkOrderId:undefined
    },
    metadataTranckingTask:{
        client:undefined,
        structure:undefined,
        orderDate:undefined,
        WorkOrderId:undefined
    },
    metadata: initialMetadata,
    workOrderOptions: emptyWorkOrders,
    cards: [],
    optionAutocomplite: []
};

const entrustedTasksCardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ENTRUSTEDTASKS_CARDS_FILTER_SET: {
            const client = action.payload.cardData.workOrder.client.comertialName;
            const orderDate = action.payload.cardData.workOrder.orderDate;
            const id = action.payload.cardData.workOrder.id;
            const structureType = action.payload.cardData.workOrder.structureType.description;

            return {
                ...state,
                filter: {
                    WorkOrderId:id
                },
                metadataTranckingTask:{
                    client:client,
                    structure:structureType,
                    orderDate:inputformatDate(orderDate),
                    WorkOrderId:id
                },
                cards: [],
                optionAutocomplite: [],
                metadata: { ...initialMetadata }
            }
        }
        case ENTRUSTEDTASKS_FILTER_SET: {
            const {filter} = action.payload
            return {
                ...state,
                filter: {
                    text: filter,
                    WorkOrderId:filter
                },
                cards: [],
                optionAutocomplite: [],
                metadata: { ...initialMetadata }
            }
        }
        case ENTRUSTEDTASKS_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case ENTRUSTEDTASKS_CARDS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                cards: action.payload.records,
                metadata: action.payload.metadata
            };
        }
        case ENTRUSTEDTASKS_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case ENTRUSTEDTASKS_AUTOCOMPLITE_REQUEST: {
            return {
                ...state,
                isFetching: false
            };
        }
        case ENTRUSTEDTASKS_AUTOCOMPLITE_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                optionAutocomplite: action.payload.records
            };
        }
        case ENTRUSTEDTASKS_AUTOCOMPLITE_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case ENTRUSTEDTASKS_CARDS_CLEAN: {
            return {
                ...state,
                cards: [],
                optionAutocomplite: []
            };
        }
        case ENTRUSTEDTASKS_OPTIONS_SUCCESS: {
            const {response} = action.payload;
            let workOrderOptions = emptyWorkOrders.concat(response.map(wo => ({
                key: wo.id,
                text: wo.id
            })));
            return {
                ...state,
                isFetching: false,
                workOrderOptions
            };
        }
        default: {
            return state;
        }
    }
};

export default entrustedTasksCardsReducer;

export const getentrustedTasksCardsState = (state) => state.entrustedTasksCardsReducer;

export const getFilter = (state) => getentrustedTasksCardsState(state).filter;

export const getIsFetching = (state) => getentrustedTasksCardsState(state).isFetching;

export const getCards = (state) => getentrustedTasksCardsState(state).cards;

export const getMetadata = (state) => getentrustedTasksCardsState(state).metadata;

export const getWorkOrderOption = (state) => getentrustedTasksCardsState(state).workOrderOptions;

export const getMetadataTranckingTask = (state) => getentrustedTasksCardsState(state).metadataTranckingTask;

export const getAutocomplete = (state) => getentrustedTasksCardsState(state).optionAutocomplite;