import React from 'react'

import {
    Form, Col, Alert, Row
} from 'react-bootstrap'


export const inputField = ({
    name, input, label, type,
    meta: { touched, error }
}) => (
        <Form.Group as={Row} controlId={name}>
            <Form.Label column sm="3">{label}</Form.Label>
            <Col sm="9">
                <Form.Control 
                    autoComplete="off" 
                    size="sm"
                    type={type} 
                    placeholder={label} 
                    {...input} />
                
                {(touched && error) ?
                    <Alert key="error" variant="danger">
                        {error}
                    </Alert>
                : null}
            </Col>
        </Form.Group>
    )
