import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import {
    Container, Row, Col,
    Card, ButtonGroup,
    Button, ToggleButtonGroup
} from 'react-bootstrap';
import LoadingCard from 'Src/components/loadingCard';

import { getMetadata, getIsFetching,getFilter,getClient } from "Src/redux/reducers/historysReducer";
import {
    tryHistory
} from 'Src/redux/thunk-creators/historysActionCreator';

const HistorysCards = React.lazy(() =>
    import('Src/components/historys/historysCards'));


class HistorysPage extends Component {
    constructor(props) {
        super(props);
    }   

    render() {
        const { metadata,client,onHistory,filter } = this.props;
        return (
            <Container>
            <Row>
                <Col sm={12}>
                <Card className="shadow">
                <Card.Header><h5>Historico</h5></Card.Header>
                    <Card.Body>
                    <Row>
                        <Col >
                            N° de Orden de trabajo: {filter.workOrderId}
                        </Col>
                        <Col>
                            Cliente: {client}
                        </Col>
                    </Row> 
                    <Row>
                        <Col>
                        <br/>
                        <ButtonGroup>
                            <Button variant="primary" size="sm"
                                onClick={(e)=>onHistory(1)}>
                                Orden de trabajo
                            </Button>
                            <Button variant="primary" size="sm"
                                onClick={(e)=>onHistory(2)}>
                                Seguimiento de obras
                            </Button>
                        </ButtonGroup>                                           
                        </Col>
                        <Col>
                            <br/>
                            Encontrados: {metadata.totalCount}
                        </Col>
                    </Row>              
                    </Card.Body>   
                </Card>
                </Col>
            </Row>
            {metadata.totalCount ?
            <Row>
                <Col sm={12}>
                    <Suspense fallback={<LoadingCard />}>
                        <HistorysCards />
                    </Suspense>
                </Col>
            </Row>:null}
        </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        metadata: getMetadata(state),
        isFetching: getIsFetching(state),
        client: getClient(state),
        filter: getFilter(state)

    };
}

const mapDispatchToProps = dispatch => {
    return {
        onHistory: (typeHistory) => dispatch(tryHistory(typeHistory))
    };
}





export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HistorysPage);