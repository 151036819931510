export const OPERATIVETASKS_CARDS_REQUEST = "OPERATIVETASKS_CARDS_REQUEST";
export const OPERATIVETASKS_CARDS_SUCCESS = "OPERATIVETASKS_CARDS_SUCCESS";
export const OPERATIVETASKS_CARDS_FAILTURE = "OPERATIVETASKS_CARDS_FAILTURE";
export const OPERATIVETASKS_CARDS_CLEAN = "OPERATIVETASKS_CARDS_CLEAN";

export const OPERATIVETASKS_FILTER_SET = "OPERATIVETASKS_FILTER_SET";
export const OPERATIVETASKS_CARDS_FILTER_SET = "OPERATIVETASKS_CARDS_FILTER_SET";
export const OPERATIVETASKS_PAGE_SET = "OPERATIVETASKS_PAGE_SET";

export const OPERATIVETASKS_OPTIONS_REQUEST = "OPERATIVETASKS_OPTIONS_REQUEST";
export const OPERATIVETASKS_OPTIONS_SUCCESS = "OPERATIVETASKS_OPTIONS_SUCCESS";
export const OPERATIVETASKS_OPTIONS_FAILTURE = "OPERATIVETASKS_OPTIONS_FAILTURE";

export const OPERATIVETASKS_FORM_GET_REQUEST = "OPERATIVETASKS_FORM_GET_REQUEST";
export const OPERATIVETASKS_FORM_GET_SUCCESS = "OPERATIVETASKS_FORM_GET_SUCCESS";
export const OPERATIVETASKS_FORM_GET_FAILTURE = "OPERATIVETASKS_FORM_GET_FAILTURE";


export const OPERATIVETASKS_FORM_LOAD_REQUEST = "OPERATIVETASKS_FORM_LOAD_REQUEST";
export const OPERATIVETASKS_FORM_LOAD_SUCCESS = "OPERATIVETASKS_FORM_LOAD_SUCCESS";
export const OPERATIVETASKS_FORM_LOAD_FAILTURE = "OPERATIVETASKS_FORM_LOAD_FAILTURE";

export const OPERATIVETASKS_FORM_EDIT_REQUEST = "OPERATIVETASKS_FORM_EDIT_REQUEST";
export const OPERATIVETASKS_FORM_EDIT_SUCCESS = "OPERATIVETASKS_FORM_EDIT_SUCCESS";
export const OPERATIVETASKS_FORM_EDIT_FAILTURE = "OPERATIVETASKS_FORM_EDIT_FAILTURE";

export const OPERATIVETASKS_FORMS_EDIT_REQUEST = "OPERATIVETASKS_FORMS_EDIT_REQUEST";
export const OPERATIVETASKS_FORMS_EDIT_SUCCESS = "OPERATIVETASKS_FORMS_EDIT_SUCCESS";
export const OPERATIVETASKS_FORMS_EDIT_FAILTURE = "OPERATIVETASKS_FORMS_EDIT_FAILTURE";

export const OPERATIVETASKS_NEWDESING_REQUEST = "OPERATIVETASKS_NEWDESING_REQUEST";
export const OPERATIVETASKS_NEWDESING_SUCCESS = "OPERATIVETASKS_NEWDESING_SUCCESS";
export const OPERATIVETASKS_NEWDESING_FAILTURE = "OPERATIVETASKS_NEWDESING_FAILTURE";

export const OPERATIVETASKS_COMPLEMENTARYWORK_REQUEST = "OPERATIVETASKS_COMPLEMENTARYWORK_REQUEST";
export const OPERATIVETASKS_COMPLEMENTARYWORK_SUCCESS = "OPERATIVETASKS_COMPLEMENTARYWORK_SUCCESS";
export const OPERATIVETASKS_COMPLEMENTARYWORK_FAILTURE = "OPERATIVETASKS_COMPLEMENTARYWORK_FAILTURE";

export const OPERATIVETASKS_UPDATED_END_DATE_STATE = "OPERATIVETASKS_UPDATED_END_DATE_STATE";

export const OPERATIVETASKS_SELECTED_TO_SAVE = "OPERATIVETASKS_SELECTED_TO_SAVE";
