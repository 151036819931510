import React,{Component} from 'react';
import {
    Card, Button, Spinner,Col,Row
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { inputField } from 'Src/components/forms/inputField';
import { dropdownField } from 'Src/components/forms/dropdownField';

//TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')
//const requiredselect = value => (value ? undefined: 'Requerido!')

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}
    errors.description = required(values.description)
    return errors
}

class EntrustedTaskTypeForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText
        } = this.props;
        return (
            <Card className="shadow">
                <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Field name="description" label="Descripción" component={inputField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit" disabled={pristine || submitting}>
                                    {submitText}
                                    {submitting ? <Spinner animation="grow" size="sm" /> : null}
                                </Button>                            
                            </Col>
                            <Col className="text-right">
                                <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                                &nbsp;                            
                                <Button variant="danger" onClick={onCancel}>Cancelar</Button>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_ENTRUSTEDTASKTYPE_NAME = 'CREATE_ENTRUSTEDTASKTYPE_NAME';
export const EDIT_ENTRUSTEDTASKTYPE_NAME = 'EDIT_ENTRUSTEDTASKTYPE_NAME';

const CreateEntrustedTaskTypeForm = props =>
  <EntrustedTaskTypeForm {...props} mode='create' />;
  
const EditEntrustedTaskTypeForm = props =>
  <EntrustedTaskTypeForm {...props} mode='edit' />;

const ConnectedCreateEntrustedTaskTypeForm = reduxForm({
    form: CREATE_ENTRUSTEDTASKTYPE_NAME,
    validate
})(CreateEntrustedTaskTypeForm);

const ConnectedEditEntrustedTaskTypeForm = reduxForm({
    form: EDIT_ENTRUSTEDTASKTYPE_NAME,
    validate
})(EditEntrustedTaskTypeForm);

export {
    ConnectedCreateEntrustedTaskTypeForm,
    ConnectedEditEntrustedTaskTypeForm
}