import config from 'Config';
import * as actionTypes from "Src/redux/actions/workOrdersCardsActionTypes";
import {
    CLIENTCONTACTS_FORM_ADD_SUCCESS
  } from "Src/redux/actions/clientContactsActionTypes";

const initialMetadata = {
    perPage: 9,
    page: 1
};

const emptyRegion = [{ key: -1, text: "Ninguna" }]
const emptyContact = [{ key: -1, text: "Ninguno" }]
const emptyCertification = [{ key: -1, text: "Ninguno" }]
const emptyFaturation = [{ key: -1, text: "Ninguno" }]
const typeWorkOrderState = [{id:1,name:"Borrador"},
                            {id:2,name:"Liberacion Pendiente"},
                            {id:3,name:"En Curso"},
                            {id:4,name:"Certificada Parcial"},
                            {id:5,name:"Certificada"},
                            {id:6,name:"Facturada Parcial"},
                            {id:7,name:"Facturada"},
                            {id:8,name:"Cancelada"},
                            {id:9,name:"Suspendida"}];

const API_ENDPOINT = `${config.apis.coreApi.URL}/`

const setListDocuments= (listDocuments) =>  listDocuments.map(ld => ({
    ...ld,
    path:config.appSettings.Cloud?ld.path: API_ENDPOINT + ld.uri ,
})); 

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: "",
    },
    metadata: initialMetadata,
    cards: [],
    workOrderToEdit: undefined,
    clientsOptions: [],
    regionsOptions: emptyRegion,
    contactSupervisorOptions: emptyContact,
    contactOnSiteOptions: emptyContact,
    contactComertialOptions: emptyContact,
    contactContractorOptions: emptyContact,
    structureTypeTemplate: undefined,
    particularDetailsTemplate: undefined,
    entrustedTaskTypesTemplate: undefined,
    certificationsOptions:emptyCertification,
    facturationsOptions:emptyFaturation,
    listDocuments:[],
    listTasks:undefined,
    workOrderToDuplicate:undefined,
    contactTypesOptions:undefined,
    listAttachedImages:[],
    workOrderToPreview:undefined,
    taxReceiptsList:[],
    listAttachedImagesOfTaxReceipts:[]
};

const workOrdersCardsReducer = (state = initialState, action) => {
    //HINT SUPER SIMILAR THAN CARDS
    //TODO: CAN BE SHARE CODE ???
    switch (action.type) {
        case actionTypes.WORKORDERS_FILTER_SET: {
            return {
                ...state,
                filter: {
                    text: action.payload.filter,
                    statusWorkOrder: action.payload.statusWorkOrder
                },
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case actionTypes.WORKORDERS_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case actionTypes.WORKORDERS_CARDS_SUCCESS: {
            const { records }=action.payload;
            const cardsData = records.map((d)=>{
                const workOrderState = typeWorkOrderState.filter((twos)=>twos.id==d.workOrderStateFK)
                return {
                    ...d,
                    workOrderState: workOrderState[0].name
                }
            })
            return {
                ...state,
                isFetching: false,
                cards: state.cards.concat(cardsData),
                metadata: action.payload.metadata
            };
        }
        case actionTypes.WORKORDERS_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case actionTypes.WORKORDERS_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        case actionTypes.WORKORDERS_OPTIONS_SUCCESS: {
            const {response} = action.payload;
            let workOrderOptions = emptyRegion.concat(response.map(wo => ({
                key: wo.id,
                text: wo.id
            })));
            return {
                ...state,
                isFetching: false,
                workOrderOptions
            };
        }
        case actionTypes.WORKORDERS_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        case actionTypes.WORKORDERS_FORM_DELETE_SUCCESS: {

            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload.WorkOrderId),
                metadata: {
                    ...state.metadata,
                    totalCount: state.metadata.totalCount - 1
                }
            };
        }
        case actionTypes.WORKORDERS_FORM_DELETE_DOCUMENT_SUCCESS: {
            const {
                listDocuments,listAttachedImages,
                listAttachedImagesOfTaxReceipts,taxReceiptsList
            } =state;
            
            const newListDocument = listDocuments
                                        .filter(document => 
                                            document.id !== action.payload.WorkOrderId &&
                                            document.workOrderDocumentTypeFK==1)
                                            
            const newlistAttachedImages = listAttachedImages
                                            .filter(image => 
                                                image.idGeneration !== action.payload.WorkOrderId)
            

            const newTaxReceiptsList = taxReceiptsList
                                            .filter(document => 
                                                document.id !== action.payload.WorkOrderId &&
                                                document.workOrderDocumentTypeFK==2)

            const newListAttachedImagesOfTaxReceipts = listAttachedImagesOfTaxReceipts
                                                            .filter(imgen => 
                                                                imgen.idGeneration !== action.payload.WorkOrderId)
            //idGeneration
            return {
                ...state,
                listDocuments:newListDocument,
                listAttachedImages:newlistAttachedImages,
                taxReceiptsList:newTaxReceiptsList,
                listAttachedImagesOfTaxReceipts:newListAttachedImagesOfTaxReceipts
            };
        }
        case actionTypes.WORKORDERS_FORM_GET_SUCCESS: {
            const {response,listRegions,listDocuments,listContact} = action.payload;         

            const taxReceiptsList = setListDocuments(listDocuments).filter(x=> x.workOrderDocumentTypeFK==2);
            const documentGenealList = setListDocuments(listDocuments).filter(x=> x.workOrderDocumentTypeFK==1);

            const regionsOptions = emptyRegion.concat(listRegions.map(c => ({
                key: c.id,
                text: c.description
            })));

            const contactsOptions = listContact.map(c => ({
                key: c.id,
                text: c.name+', '+c.lastName,
                contactTypeFK: c.contactTypeFK
            }))

            let listAttachedImages = [];
            let listAttachedImagesOfTaxReceipts = [];
            const contactSupervisorOptions = emptyContact.concat(contactsOptions.filter(co => co.contactTypeFK == 1))
            const contactOnSiteOptions = emptyContact.concat(contactsOptions.filter(co => co.contactTypeFK == 2))
            const contactComertialOptions = emptyContact.concat(contactsOptions.filter(co => co.contactTypeFK == 3))
            const contactContractorOptions = emptyContact.concat(contactsOptions.filter(co => co.contactTypeFK == 7))

            
            listAttachedImages = documentGenealList.map(d => ({
                idGeneration: d.id,
                extension: d.name.substr(d.name.length - 3, 3),
                src: d.path,
                name: d.name,
                title: d.name,
                description: d.name
            })).filter(Image => Image.extension !== 'pdf')

            listAttachedImagesOfTaxReceipts = taxReceiptsList.map(d => ({
                idGeneration: d.id,
                extension: d.name.substr(d.name.length - 3, 3),
                src: d.path,
                name: d.name,
                title: d.name,
                description: d.name
            })).filter(Image => Image.extension !== 'pdf')

            return {
                ...state,
                workOrderToEdit: response,
                regionsOptions:regionsOptions,
                contactSupervisorOptions,
                contactOnSiteOptions,
                contactComertialOptions,
                contactContractorOptions,
                listDocuments:documentGenealList,
                listAttachedImages,
                taxReceiptsList,
                listAttachedImagesOfTaxReceipts
            };
        }
        case actionTypes.WORKORDERS_PREVIEW_GET_SUCCESS: {
            const {response,listDocuments} = action.payload;
            const newListDocuments = setListDocuments(listDocuments).filter(ld=> ld.workOrderDocumentTypeFK==1 || ld.workOrderDocumentTypeFK==2);
            const listAttachedImages = newListDocuments.map(d => ({
                idGeneration: d.id,
                extension: d.name.substr(d.name.length - 3, 3),
                src: d.path,
                name: d.name,
                title: d.name,
                description: d.name
            })).filter(Image => Image.extension !== 'pdf')

            return {
                ...state,
                workOrderToPreview: response,
                listDocuments:newListDocuments,
                listAttachedImages
            };
        }
        case actionTypes.WORKORDERS_FORM_DUPLICATE_GET_SUCCESS: {
            const {response,listRegions,listContact} = action.payload;
            const regionsOptions = emptyRegion.concat(listRegions.map(c => ({
                key: c.id,
                text: c.description
            })));

            const contactsOptions = listContact.map(c => ({
                key: c.id,
                text: c.name+', '+c.lastName,
                contactTypeFK: c.contactTypeFK
            }))

            const contactSupervisorOptions = emptyContact.concat(contactsOptions.filter(co => co.contactTypeFK == 1))
            const contactOnSiteOptions = emptyContact.concat(contactsOptions.filter(co => co.contactTypeFK == 2))
            const contactComertialOptions = emptyContact.concat(contactsOptions.filter(co => co.contactTypeFK == 3))
            const contactContractorOptions = emptyContact.concat(contactsOptions.filter(co => co.contactTypeFK == 7))

            return {
                ...state,
                workOrderToDuplicate: response,
                regionsOptions:regionsOptions,
                contactSupervisorOptions,
                contactOnSiteOptions,
                contactComertialOptions,
                contactContractorOptions
            };
        }
        case actionTypes.WORKORDERS_FORM_EDIT_SUCCESS: {
            return {
                ...state,
                workOrderToEdit: undefined
            };
        }
        case actionTypes.WORKORDERS_FORM_LOAD_SUCCESS: {
            let newClientsOptions = [{ key: -1, text: "Ninguno" }]
            let newContactTypesOptions = [{ key: -1, text: "Ninguno" }]
            let newStructureTypeOptions = [{ key: -1, text: "Ninguno" }]
            let emptyCertificationAndFacturation = [{ key: -1, text: "Ninguno" },{ key: 1, text: "Parcial" },{ key: 2, text: "Total" }]

            const structureTypeOptions = action.payload.structureTypeTemplate.filter((stp)=>stp.active != false).map(st => ({
                key: st.id,
                text: st.description
            }))

            const clientsOptions = action.payload.clientsOptions.map(c => ({
                key: c.id,
                text: c.comertialName
            }))
            
            const contactTypesOptions = action.payload.contactTypesOptions.map(ct => ({
                key: ct.id,
                text: ct.description
            }))

            const newEntrustedTaskTypesTemplate = action.payload.entrustedTaskTypes.records.map(te => ({
                                                title: te.description,
                                                value: te.id,
                                                key: te.id }))

            return {
                ...state,
                clientsOptions: newClientsOptions.concat(clientsOptions),
                structureTypeTemplate: newStructureTypeOptions.concat(structureTypeOptions),
                entrustedTaskTypesTemplate: newEntrustedTaskTypesTemplate,
                particularDetailsTemplate: action.payload.particularDetailsTemplate,
                certificationsOptions:emptyCertificationAndFacturation,
                facturationsOptions:emptyCertificationAndFacturation,
                contactTypesOptions:newContactTypesOptions.concat(contactTypesOptions)
            }
        }
        case actionTypes.WORKORDERS_FORM_LOAD_CLIENT_CHILDRENS_SUCCESS: {
            const { clientContacts, clientRegions } = action.payload;

            const regionsOptions = emptyRegion.concat(clientRegions.map(c => ({
                key: c.id,
                text: c.description
            })));

            const contactsOptions = clientContacts.map(c => ({
                key: c.id,
                text: c.name+', '+c.lastName,
                contactTypeFK: c.contactTypeFK
            }))

            const contactSupervisorOptions = emptyContact.concat(contactsOptions.filter(co => co.contactTypeFK == 1))
            const contactOnSiteOptions = emptyContact.concat(contactsOptions.filter(co => co.contactTypeFK == 2))
            const contactComertialOptions = emptyContact.concat(contactsOptions.filter(co => co.contactTypeFK == 3))
            const contactContractorOptions = emptyContact.concat(contactsOptions.filter(co => co.contactTypeFK == 7))

            return {
                ...state,
                regionsOptions,
                contactSupervisorOptions,
                contactOnSiteOptions,
                contactComertialOptions,
                contactContractorOptions
            }
        }
        case CLIENTCONTACTS_FORM_ADD_SUCCESS: {
            return {
                ...state
            };
        }
        default: {
            return state;
        }
    }
};

export default workOrdersCardsReducer;

export const getWorkOrdersCardsState = (state) => state.workOrdersCardsReducer;

export const getFilter = (state) => getWorkOrdersCardsState(state).filter;

export const getIsFetching = (state) => getWorkOrdersCardsState(state).isFetching;

export const getCards = (state) => getWorkOrdersCardsState(state).cards;

export const getMetadata = (state) => getWorkOrdersCardsState(state).metadata;

export const getWorkOrderToEdit = (state) => getWorkOrdersCardsState(state).workOrderToEdit;

export const getWorkOrderToDuplicate = (state) => getWorkOrdersCardsState(state).workOrderToDuplicate;

export const getClientsOption = (state) => getWorkOrdersCardsState(state).clientsOptions;

export const getMetadataTranckingTask = (state) => getWorkOrdersCardsState(state).metadataTranckingTask;

export const getRegionsOption = (state) => getWorkOrdersCardsState(state).regionsOptions;

export const getContactTypesOption = (state) => getWorkOrdersCardsState(state).contactTypesOptions;

export const getDocumentsList = (state) => getWorkOrdersCardsState(state).listDocuments;

export const getCertificationsOption = (state) => getWorkOrdersCardsState(state).certificationsOptions;

export const getFacturationsOption = (state) => getWorkOrdersCardsState(state).facturationsOptions;

export const getContactSupervisorOption = (state) => getWorkOrdersCardsState(state).contactSupervisorOptions;

export const getContactOnSiteOption = (state) => getWorkOrdersCardsState(state).contactOnSiteOptions;

export const getContactComertialOptions = (state) => getWorkOrdersCardsState(state).contactComertialOptions;

export const getContactContractorOption = (state) => getWorkOrdersCardsState(state).contactContractorOptions;

export const getStructureTypeTemplate = (state) => getWorkOrdersCardsState(state).structureTypeTemplate;

export const getParticularDetailsTemplate = (state) => {
    const { particularDetailsTemplate } = getWorkOrdersCardsState(state);
    if (particularDetailsTemplate)
        return JSON.parse(particularDetailsTemplate.scheme)
}

export const getEntrustedTaskTypesTemplate = (state) => getWorkOrdersCardsState(state).entrustedTaskTypesTemplate;

export const getAttachedImagesList = (state) => getWorkOrdersCardsState(state).listAttachedImages;

export const getTaxReceiptsList = (state) => getWorkOrdersCardsState(state).taxReceiptsList;

export const getListAttachedImagesOfTaxReceipts = (state) => getWorkOrdersCardsState(state).listAttachedImagesOfTaxReceipts;

export const getWorkOrderToShow = (state) => getWorkOrdersCardsState(state).workOrderToPreview;
