import {
  CLIENTS_CARDS_REQUEST,
  CLIENTS_CARDS_SUCCESS,
  CLIENTS_CARDS_FAILTURE,
  CLIENTS_CARDS_CLEAN,
  CLIENTS_FILTER_SET,
  CLIENTS_PAGE_SET,
  CLIENTS_FORM_ADD_REQUEST,
  CLIENTS_FORM_ADD_SUCCESS,
  CLIENTS_FORM_ADD_FAILTURE,
  CLIENTS_FORM_DELETE_REQUEST,
  CLIENTS_FORM_DELETE_SUCCESS,
  CLIENTS_FORM_DELETE_FAILTURE,
  CLIENTS_FORM_GET_REQUEST,
  CLIENTS_FORM_GET_SUCCESS,
  CLIENTS_FORM_GET_FAILTURE,
  CLIENTS_FORM_EDIT_REQUEST,
  CLIENTS_FORM_EDIT_SUCCESS,
  CLIENTS_FORM_EDIT_FAILTURE
} from "./clientsCardsActionTypes";

export const setFilter = (filter) => ({
  type: CLIENTS_FILTER_SET,
  payload: {
    filter
  }
});

export const getCardsRequest = () => ({
  type: CLIENTS_CARDS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getCardsSuccess = (response) => ({
  type: CLIENTS_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response.records,
    metadata: response.metadata
  }
});

export const getCardsFailture = (response) => ({
  type: CLIENTS_CARDS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const setPage = (page) => ({
  type: CLIENTS_PAGE_SET,
  payload: {
    page
  }
});

export const addRequest = (client) => ({
  type: CLIENTS_FORM_ADD_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    client
  }
});

export const addSuccess = (response) => ({
  type: CLIENTS_FORM_ADD_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const addFailture = (response) => ({
  type: CLIENTS_FORM_ADD_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const deleteRequest = (clientId) => ({
  type: CLIENTS_FORM_DELETE_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    clientId
  }
});

export const deleteSuccess = (clientId) => ({
  type: CLIENTS_FORM_DELETE_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    clientId
  }
});

export const deleteFailture = (response) => ({
  type: CLIENTS_FORM_DELETE_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const getRequest = (clientId) => ({
  type: CLIENTS_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    clientId
  }
});

export const getSuccess = (response,responseDocuments) => ({
  type: CLIENTS_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response,
    responseDocuments
  }
});

export const getFailture = (response) => ({
  type: CLIENTS_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const editRequest = (clientId) => ({
  type: CLIENTS_FORM_EDIT_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    clientId
  }
});

export const editSuccess = (response) => ({
  type: CLIENTS_FORM_EDIT_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const editFailture = (response) => ({
  type: CLIENTS_FORM_EDIT_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const cleanCards = () => ({
  type: CLIENTS_CARDS_CLEAN,
  payload: {
    isFetching: false,
    hasError: false
  }
});