import React from 'react';
import {
    Card, Button, Spinner, Row, Col
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { dropdownField } from 'Src/components/forms/dropdownField';
import { inputField } from 'Src/components/forms/inputField';


//TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}

    return errors
}

class ClientContactForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText,
            clientsOptions, contactTypesOptions
        } = this.props;

        return (
            <Card className="shadow">
                <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Field name="name" label="Nombre *" component={inputField} type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="lastName" label="Apellido *" component={inputField} type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="email" label="Email" component={inputField} type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="phone" label="Teléfono" component={inputField} type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="clientFK" label="Cliente *"
                                    component={dropdownField}
                                    options={clientsOptions} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="contactTypeFK" label="Tipo de Contacto *"
                                    component={dropdownField}
                                    options={contactTypesOptions} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit" disabled={pristine || submitting}>
                                    {submitText}
                                    {submitting ? <Spinner animation="grow" size="sm" /> : null}
                                </Button>                            
                            </Col>
                            <Col className="text-right">
                                <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                                &nbsp;                            
                                <Button variant="danger" onClick={onCancel}>Cancelar</Button>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_CLIENTCONTACT_NAME = 'CREATE_CLIENTCONTACT_NAME';
export const EDIT_CLIENTCONTACT_NAME = 'EDIT_CLIENTCONTACT_NAME';

const CreateClientContactForm = props =>
  <ClientContactForm {...props} mode='create' />;
  
const EditClientContactForm = props =>
  <ClientContactForm {...props} mode='edit' />;

const ConnectedCreateClientContactForm = reduxForm({
    form: CREATE_CLIENTCONTACT_NAME,
    validate
})(CreateClientContactForm);

const ConnectedEditClientContactForm = reduxForm({
    form: EDIT_CLIENTCONTACT_NAME,
    validate
})(EditClientContactForm);

export {
    ConnectedCreateClientContactForm,
    ConnectedEditClientContactForm
}