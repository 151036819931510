import React from 'react'
import { Route, Switch } from "react-router-dom";

import Navigation from './components/navigation';

import LoginPage from "Src/components/pages/LoginPage";
import DashboardPage from "Src/components/pages/dashboardPage";
import ForgotPasswordPage from "Src/components/pages/ForgotPasswordPage";
import ResetPasswordPage from "Src/components/pages/ResetPasswordPage";
import ClientsPage from "Src/components/pages/ClientsPage";
import ClientsAddPage from "Src/components/pages/ClientsAddPage";
import ClientsEditPage from "Src/components/pages/ClientsEditPage";

import RegionsPage from "Src/components/pages/regions";
import RegionsAddPage from "Src/components/pages/regions/Add";
import RegionsEditPage from "Src/components/pages/regions/Edit";

import UsersPage from "Src/components/pages/users";
import UsersAddPage from "./components/pages/users/add";
import UsersEditPage from "./components/pages/users/edit";

import StructureTypesPage from "Src/components/pages/structureTypes";
import StructureTypesAddPage from "Src/components/pages/structureTypes/add";
import StructureTypesEditPage from "Src/components/pages/structureTypes/edit";

import EntrustedTaskTypesPage from "Src/components/pages/entrustedTaskTypes";
import EntrustedTaskTypesAddPage from "Src/components/pages/entrustedTaskTypes/add";
import EntrustedTaskTypesEditPage from "Src/components/pages/entrustedTaskTypes/edit";

import AreasPage from "Src/components/pages/areas";
import AreasAddPage from "Src/components/pages/areas/add";
import AreasEditPage from "Src/components/pages/areas/edit";

import ResponsibleForAreaPage from "Src/components/pages/responsibleForArea";
import ResponsibleForAreaAddPage from "Src/components/pages/responsibleForArea/add";
import ResponsibleForAreaEditPage from "Src/components/pages/responsibleForArea/edit";

import OperativeTaskTypesPage from "Src/components/pages/operativeTaskTypes";
import OperativeTaskTypesAddPage from "Src/components/pages/operativeTaskTypes/add";
import OperativeTaskTypesEditPage from "Src/components/pages/operativeTaskTypes/edit";

import TasksConfigurationsPage from "Src/components/pages/tasksConfigurations";

import JustificationDelayAreasPage from "Src/components/pages/justificationDelayAreas";
import JustificationDelayAreasAddPage from "Src/components/pages/justificationDelayAreas/add";
import JustificationDelayAreasEditPage from "Src/components/pages/justificationDelayAreas/edit";

import ParticularDetailsEditPage from "Src/components/pages/particulardetails";


import WorkOrdersPage from 'Src/components/pages/workOrdersPage';
import WorkOrdersTrackingPage from 'Src/components/pages/workOrdersTrackingPage';
import TimeLinePage from 'Src/components/pages/TimeLinePage';
import EntrustedTasksPage from 'Src/components/pages/EntrustedTasksPage';
import OperativeTasksPage from 'Src/components/pages/OperativeTasksPage';
import WorkOrdersAddPage from 'Src/components/pages/workOrdersAddPage';
import WorkOrdersEditPage from 'Src/components/pages/workOrderEditPage';
import WorkOrdersDuplicatePage from 'Src/components/pages/workOrdersDuplicatePage';
import WorkOrderPreviewPage from 'Src/components/pages/workOrderPreviewPage';

import HistorysPage from 'Src/components/pages/historys';

import ClientContactsPage from "Src/components/pages/ClientContactsPage";
import ClientContactsAddPage from "Src/components/pages/ClientContactsAddPage";
import ClientContactsEditPage from "Src/components/pages/ClientContactsEditPage";

import ContactTypePage from 'Src/components/pages/ContactTypePage'
import ContactTypeAddPage from 'Src/components/pages/ContactTypeAddPage'
import ContactTypeEditPage from "Src/components/pages/ContactTypeEditPage"

import ReportsPage from "Src/components/pages/reports";


export const LOGIN_URI = '/login';
export const FORGOT_PASSWORD_URI = "/forgot-password";
export const RESET_PASSWORD_URI = "/reset-password";

export const CLIENTS_URI = "/clients";
export const CLIENTS_FORM_URI = "/clients/form";

export const WORKORDERS_URI = "/workorders";
export const WORKORDERSTRACKING_URI = "/workorderstracking";
export const TIMELINE_URI = "/timeline";
export const WORKORDERS_FORM_URI = "/workorders/form";
export const WORKORDERS_FORM_DUPLICATE_URI = "/workorders/duplicateform";
export const WORKORDERS_FORM_PREVIEW_URI = "/workorders/details";

export const HISTORYS_URI = "/historys";

export const ENTRUSTEDTASKS_URI = "/entrustedtasks";

export const ENTRUSTEDTASKS_URLID = WORKORDERSTRACKING_URI+"/:id"+ENTRUSTEDTASKS_URI;

export const OPERATIVETASKS_URI = "/operativetasks";

export const OPERATIVETASKS_URLI = ENTRUSTEDTASKS_URLID+"/:id"+OPERATIVETASKS_URI;

export const REGIONS_URI = "/regions";
export const REGIONS_FORM_URI = "/regions/form";

export const USERS_URI = "/users";
export const USERS_FORM_URI = "/users/form";

export const ENTRUSTEDTASKTYPES_URI = "/entrustedtasktypes";
export const ENTRUSTEDTASKTYPES_FORM_URI = "/entrustedtasktypes/form";

export const STRUCTURETYPES_URI = "/structuretypes";
export const STRUCTURETYPES_FORM_URI = "/structuretypes/form";

export const OPERATIVETASKTYPES_URI = "/operativetasktypes";
export const OPERATIVETASKTYPES_FORM_URI = "/operativetasktypes/form";

export const TASKSCONFIGURATIONS_FORM_URI = "/tasksconfigurations";

export const AREAS_URI = "/areas";
export const AREAS_FORM_URI = "/areas/form";

export const RESPONSIBLEFORAREA_URI = "/responsibleforarea";
export const RESPONSIBLEFORAREA_FORM_URI = "/responsibleforarea/form";

export const REPORTS_WORKORDERS_URI = "/reports";

export const PARTICULARDETAILS_FORM_URI = "/particulardetails/form";

export const CLIENTCONTACTS_URI = "/clientcontacts";
export const CLIENTCONTACTS_FORM_URI = "/clientcontacts/form";

export const CONTACTTYPES_URI = "/contacttypes";
export const CONTACTTYPES_FORM_URI = "/contacttypes/form";

export const JUSTIFICATIONDELAYAREAS_URI = "/justificationDelayAreas";
export const JUSTIFICATIONDELAYAREAS_FORM_URI = "/justificationDelayAreas/form";

export const DASHBOARD_URI = '/';

const routes = (
    <div>
        <Navigation></Navigation>
        <Switch>
            <Route path={LOGIN_URI} component={LoginPage} />
            <Route path={FORGOT_PASSWORD_URI} component={ForgotPasswordPage} />
            <Route path={RESET_PASSWORD_URI+"/:id"} component={ResetPasswordPage} />
            
            <Route path={CLIENTS_FORM_URI+"/:id"} component={ClientsEditPage} />
            <Route path={CLIENTS_FORM_URI} component={ClientsAddPage} />
            <Route path={CLIENTS_URI} component={ClientsPage} />

            <Route path={WORKORDERS_FORM_URI+"/:id"} component={WorkOrdersEditPage} />
            <Route path={WORKORDERS_FORM_DUPLICATE_URI} component={WorkOrdersDuplicatePage} />
            <Route path={WORKORDERS_FORM_PREVIEW_URI+"/:id"} component={WorkOrderPreviewPage} />
            <Route path={WORKORDERS_FORM_URI} component={WorkOrdersAddPage} />
            <Route path={WORKORDERS_URI} component={WorkOrdersPage} />
            <Route path={OPERATIVETASKS_URLI} component={OperativeTasksPage} />
            <Route path={ENTRUSTEDTASKS_URLID} component={EntrustedTasksPage} />
            <Route path={WORKORDERSTRACKING_URI} component={WorkOrdersTrackingPage} />
            <Route path={TIMELINE_URI+"/:id"} component={TimeLinePage} />

            <Route path={HISTORYS_URI+"/:id"} component={HistorysPage} />            

            <Route path={REGIONS_FORM_URI+"/:id"} component={RegionsEditPage} />
            <Route path={REGIONS_FORM_URI} component={RegionsAddPage} />
            <Route path={REGIONS_URI} component={RegionsPage} />

            <Route path={USERS_FORM_URI+"/:id"} component={UsersEditPage} />
            <Route path={USERS_FORM_URI} component={UsersAddPage} />
            <Route path={USERS_URI} component={UsersPage} />

            <Route path={STRUCTURETYPES_URI+"/:id"+TASKSCONFIGURATIONS_FORM_URI} component={TasksConfigurationsPage} />  

            <Route path={STRUCTURETYPES_FORM_URI+"/:id"} component={StructureTypesEditPage} />
            <Route path={STRUCTURETYPES_FORM_URI} component={StructureTypesAddPage} />
            <Route path={STRUCTURETYPES_URI} component={StructureTypesPage} />

            <Route path={ENTRUSTEDTASKTYPES_FORM_URI+"/:id"} component={EntrustedTaskTypesEditPage} />
            <Route path={ENTRUSTEDTASKTYPES_FORM_URI} component={EntrustedTaskTypesAddPage} />
            <Route path={ENTRUSTEDTASKTYPES_URI} component={EntrustedTaskTypesPage} />
            
            <Route path={OPERATIVETASKTYPES_FORM_URI+"/:id"} component={OperativeTaskTypesEditPage} />
            <Route path={OPERATIVETASKTYPES_FORM_URI} component={OperativeTaskTypesAddPage} />
            <Route path={OPERATIVETASKTYPES_URI} component={OperativeTaskTypesPage} />

            <Route path={AREAS_FORM_URI+"/:id"} component={AreasEditPage} />
            <Route path={AREAS_FORM_URI} component={AreasAddPage} />
            <Route path={AREAS_URI} component={AreasPage} />

            <Route path={RESPONSIBLEFORAREA_FORM_URI+"/:id"} component={ResponsibleForAreaEditPage} />
            <Route path={RESPONSIBLEFORAREA_FORM_URI} component={ResponsibleForAreaAddPage} />
            <Route path={RESPONSIBLEFORAREA_URI} component={ResponsibleForAreaPage} />

                      

            <Route path={PARTICULARDETAILS_FORM_URI} component={ParticularDetailsEditPage} />
            

            <Route path={CLIENTCONTACTS_FORM_URI+"/:id"} component={ClientContactsEditPage} />
            <Route path={CLIENTCONTACTS_FORM_URI} component={ClientContactsAddPage} />
            <Route path={CLIENTCONTACTS_URI} component={ClientContactsPage} />

            <Route path={CONTACTTYPES_FORM_URI+"/:id"} component={ContactTypeEditPage} />
            <Route path={CONTACTTYPES_FORM_URI} component={ContactTypeAddPage} />
            <Route path={CONTACTTYPES_URI} component={ContactTypePage} />   

            <Route path={JUSTIFICATIONDELAYAREAS_FORM_URI+"/:id"} component={JustificationDelayAreasEditPage} />
            <Route path={JUSTIFICATIONDELAYAREAS_FORM_URI} component={JustificationDelayAreasAddPage} />
            <Route path={JUSTIFICATIONDELAYAREAS_URI} component={JustificationDelayAreasPage} />

            <Route path={REPORTS_WORKORDERS_URI} component={ReportsPage} />         


            <Route path={DASHBOARD_URI} component={DashboardPage} />
            
            <Route render={() => (<div>Miss</div>)} />
        </Switch>
    </div>
)
  
export default routes;