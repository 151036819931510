import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditUserForm } from 'Src/components/users/userForm'

import { getuserToEdit, getProfilesOption } from "Src/redux/reducers/usersCardsReducer";

import { tryEditUser,tryFormLoad } from 'Src/redux/thunk-creators/usersCardsActionCreator';

class UsersEditPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }

    render() {
        const { 
            onUserEdit, goBack, UserToEdit,profilesOptions
        } = this.props;
      
        return (
            <Container className="Users-container">
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedEditUserForm
                                title="Editar Usuario"
                                submitText="Editar"
                                onSubmit={user => onUserEdit(user)}
                                profilesOptions={profilesOptions}
                                onCancel={goBack}
                                initialValues={UserToEdit}
                            />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        UserToEdit: getuserToEdit(state),
        profilesOptions: getProfilesOption(state),
    };
}

const mapDispatchToProps = dispatch => ({
    onUserEdit: (user) => dispatch(tryEditUser(user)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersEditPage);
