import React, { Component } from 'react'

import { Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { 
    DASHBOARD_URI, 
    CLIENTS_URI,
    WORKORDERS_URI,
    REGIONS_URI,
    CLIENTCONTACTS_URI,
    REPORTS_WORKORDERS_URI,
    WORKORDERSTRACKING_URI,
    USERS_URI,
    STRUCTURETYPES_URI,
    ENTRUSTEDTASKTYPES_URI,
    AREAS_URI,
    OPERATIVETASKTYPES_URI,
    JUSTIFICATIONDELAYAREAS_URI,
    RESPONSIBLEFORAREA_URI
} from 'Src/routes';

export class Menus extends Component {
  constructor(props) {
    super(props)

  }  

  render() {
    const { userProfile } = this.props
    let Navegation=null;
    switch (userProfile) {   
        case 'User':     
        case 'Supervisor':
        case 'Administrador':
        case 'Gerencia':
        Navegation= <Nav className="mr-auto">
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={DASHBOARD_URI}>Inicio</Link>
                    </Nav.Link>
                </Nav.Item>                            
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={WORKORDERS_URI}>Ordenes de trabajo</Link>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={WORKORDERSTRACKING_URI}>Seguimiento de obras</Link>
                    </Nav.Link>
                </Nav.Item> 
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={REPORTS_WORKORDERS_URI}>Reportes</Link>
                    </Nav.Link>
                </Nav.Item>
                {userProfile !== 'Gerencia' &&
                <NavDropdown title="Configuracion" id="basic-nav-dropdown">
                    <NavDropdown.Item><Link to={CLIENTCONTACTS_URI}>Contactos</Link></NavDropdown.Item>  
                    <NavDropdown.Divider />
                    <NavDropdown.Item><Link to={CLIENTS_URI}>Clientes</Link></NavDropdown.Item>  
                    <NavDropdown.Divider />
                    <NavDropdown.Item><Link to={REGIONS_URI}>Regiones</Link></NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item><Link to={USERS_URI}>Usuario</Link></NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item><Link to={STRUCTURETYPES_URI}>Tipos de estructuras</Link></NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item><Link to={AREAS_URI}>Areas</Link></NavDropdown.Item>    
                    <NavDropdown.Divider />
                    <NavDropdown.Item><Link to={ENTRUSTEDTASKTYPES_URI}>Tareas Encomendadas</Link></NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item><Link to={OPERATIVETASKTYPES_URI}>Tareas Operativas</Link></NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item><Link to={JUSTIFICATIONDELAYAREAS_URI}>Justificaciones de Retraso</Link></NavDropdown.Item>
                    <NavDropdown.Divider />    
                    <NavDropdown.Item><Link to={RESPONSIBLEFORAREA_URI}>Responsable del área</Link></NavDropdown.Item>                    
                </NavDropdown>}                                
            </Nav>
            break;
            case 'Obras':
            case 'Adm Obras':
            case 'Ingenieria':
            case 'Proyectista Ingenieria':
            case 'Fabricacion':
            case 'Despacho':
            case 'Directivo':
            Navegation=    <Nav className="mr-auto">
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={DASHBOARD_URI}>Inicio</Link>
                    </Nav.Link>
                </Nav.Item>                            
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={WORKORDERS_URI}>Ordenes de trabajo</Link>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={WORKORDERSTRACKING_URI}>Seguimiento de obras</Link>
                    </Nav.Link>
                </Nav.Item> 
                {userProfile !== 'Proyectista Ingenieria' &&   
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={REPORTS_WORKORDERS_URI}>Reportes</Link>
                    </Nav.Link>
                </Nav.Item>}                                   
            </Nav>
            break;
            case 'Comercial':
            Navegation=    <Nav className="mr-auto">
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={DASHBOARD_URI}>Inicio</Link>
                    </Nav.Link>
                </Nav.Item>                            
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={WORKORDERS_URI}>Ordenes de trabajo</Link>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={REPORTS_WORKORDERS_URI}>Reportes</Link>
                    </Nav.Link>
                </Nav.Item>  
            </Nav>
            break;
            case 'Administracion':
            Navegation=    <Nav className="mr-auto">
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={DASHBOARD_URI}>Inicio</Link>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={WORKORDERS_URI}>Ordenes de trabajo</Link>
                    </Nav.Link>
                </Nav.Item>                            
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={REPORTS_WORKORDERS_URI}>Reportes</Link>
                    </Nav.Link>
                </Nav.Item>
                <NavDropdown title="Configuracion" id="basic-nav-dropdown">
                    <NavDropdown.Item><Link to={CLIENTS_URI}>Clientes</Link></NavDropdown.Item>                  
                </NavDropdown>    
            </Nav>
            break;     
        default:
        Navegation=
            <Nav className="mr-auto">
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={DASHBOARD_URI}>Inicio</Link>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={WORKORDERSTRACKING_URI}>Seguimiento de obras</Link>
                    </Nav.Link>
                </Nav.Item>   
                <Nav.Item>
                    <Nav.Link className="nav-level-1">
                        <Link to={REPORTS_WORKORDERS_URI}>Reportes</Link>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            break;
    }
    return (
        Navegation
    );
  }
}
