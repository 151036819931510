import {
  ENTRUSTEDTASKTYPES_CARDS_REQUEST,
  ENTRUSTEDTASKTYPES_CARDS_SUCCESS,
  ENTRUSTEDTASKTYPES_CARDS_FAILTURE,
  ENTRUSTEDTASKTYPES_CARDS_CLEAN,
  ENTRUSTEDTASKTYPES_FILTER_SET,
  ENTRUSTEDTASKTYPES_PAGE_SET,
  ENTRUSTEDTASKTYPES_FORM_ADD_REQUEST,
  ENTRUSTEDTASKTYPES_FORM_ADD_SUCCESS,
  ENTRUSTEDTASKTYPES_FORM_ADD_FAILTURE,
  ENTRUSTEDTASKTYPES_FORM_DELETE_REQUEST,
  ENTRUSTEDTASKTYPES_FORM_DELETE_SUCCESS,
  ENTRUSTEDTASKTYPES_FORM_DELETE_FAILTURE,
  ENTRUSTEDTASKTYPES_FORM_GET_REQUEST,
  ENTRUSTEDTASKTYPES_FORM_GET_SUCCESS,
  ENTRUSTEDTASKTYPES_FORM_GET_FAILTURE,
  ENTRUSTEDTASKTYPES_FORM_EDIT_REQUEST,
  ENTRUSTEDTASKTYPES_FORM_EDIT_SUCCESS,
  ENTRUSTEDTASKTYPES_FORM_EDIT_FAILTURE,
} from "Src/redux/actions/entrustedTaskTypesActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const setFilter = (filter) => ({
  type: ENTRUSTEDTASKTYPES_FILTER_SET,
  payload: {
    filter
  }
});


export const getRequest = (structureTypeId) => ({
  type: ENTRUSTEDTASKTYPES_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    structureTypeId
  }
});

export const getSuccess = (response) => ({
  type: ENTRUSTEDTASKTYPES_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const getFailture = (response) => ({
  type: ENTRUSTEDTASKTYPES_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});


export const addRequest = (structureType) => ({
  type: ENTRUSTEDTASKTYPES_FORM_ADD_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    structureType
  }
});

export const addSuccess = (response) => ({
  type: ENTRUSTEDTASKTYPES_FORM_ADD_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const addFailture = (response) => ({
  type: ENTRUSTEDTASKTYPES_FORM_ADD_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const editRequest = (structureTypeId) => ({
  type: ENTRUSTEDTASKTYPES_FORM_EDIT_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    structureTypeId
  }
});

export const editSuccess = (response) => ({
  type: ENTRUSTEDTASKTYPES_FORM_EDIT_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const editFailture = (response) => ({
  type: ENTRUSTEDTASKTYPES_FORM_EDIT_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const deleteRequest = (entrustedTaskTypeId) => ({
  type: ENTRUSTEDTASKTYPES_FORM_DELETE_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    entrustedTaskTypeId
  }
});

export const deleteSuccess = (entrustedTaskTypeId) => ({
  type: ENTRUSTEDTASKTYPES_FORM_DELETE_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    entrustedTaskTypeId
  }
});

export const deleteFailture = (response) => ({
  type: ENTRUSTEDTASKTYPES_FORM_DELETE_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const getCardsRequest = () => ({
  type: ENTRUSTEDTASKTYPES_CARDS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getCardsSuccess = (response) => ({
  type: ENTRUSTEDTASKTYPES_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response.records,
    metadata: response.metadata
  }
});

export const getCardsFailture = (response) => ({
  type: ENTRUSTEDTASKTYPES_CARDS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const setPage = (page) => ({
  type: ENTRUSTEDTASKTYPES_PAGE_SET,
  payload: {
    page
  }
});