import React,{Component} from 'react';
import { Pie } from 'react-chartjs-2';
import {percentFormatter} from 'Src/services/formatService';


export default class PieChart extends Component {
    constructor(props) {
        super(props);
    }

  render() {
    const {data} = this.props;

    const dataOptions = {
      legend : { display:false },
      tooltips: {
          mode: 'label',
          callbacks: {
              label: function(tooltipItem, data) {
                let label = data.labels[tooltipItem.index] || ''; 
                if (label) {
                    label += ': ';
                }
                label += percentFormatter(data.datasets[0].data[tooltipItem.index],"0.00%");
                return label;
            }
          },
      }
  }
    return (
      <div>
        <Pie data={data} options={dataOptions} />
      </div>
    );
  }
};