import React, { Suspense,Component } from 'react';
import { connect } from 'react-redux';
import { goBack,push } from 'connected-react-router';
import {
    Container, Row, Col,
    Card, Spinner, Button,
    Form,Alert,Breadcrumb
} from 'react-bootstrap';

import LoadingCard from 'Src/components/loadingCard';

import {  
    getIsFetching, getFilter,getWorkOrderOption,
    getMetadataTranckingTask,getCards,getOperativeTaskSelectedToSave
    } from "Src/redux/reducers/operativeTasksCardsReducer";
import { 
    setCardsFilter,setShowCards,tryGetfilterEntrustedTask,
    tryEditOperativeTask,tryEditNewDesign,tryEditComplementaryWork,
    setSelectedToSaveItems, tryEditOperativeTasks
    } from 'Src/redux/thunk-creators/operativeTasksCardsActionCreator';

import { getUserProfile, getUserPermissions } from "Src/redux/reducers/loginReducer";

import { DropdownFilterField } from 'Src/components/forms/dropdownFilterField';

import { tryEndEntrustedTask } from "Src/redux/thunk-creators/entrustedTasksCardsActionCreator";

import { WORKORDERSTRACKING_URI,ENTRUSTEDTASKS_URI  } from "Src/routes";

const TasksOperativeCards = React.lazy(() =>
    import('Src/components/tasksOperative/tasksOperativeCards'));



class OperativeTasksPage extends Component {
    constructor(props) {
        super(props);
        
        this.handleFilterSelectChange = this.handleFilterSelectChange.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {            
        let filterText = this.props.filter.text;        
        if(filterText!=null)
                this.props.setShowCards(filterText); 
    }

    handleFilterSelectChange(event) {
        let filterSelectWorkOrderId = event.target.value;
        this.props.OperativesTaskCards(filterSelectWorkOrderId);
    }

    render() {
        const {  filter, workOrderOptions,metadataTranckingTask
                ,cards,goBack,onOperativeTaskEdit,
                endEntrustedTask,goBackToWorkTracking,
                goBackToEntrustedTasck,newDesign,complementaryWork,
                userPermissions,userProfile,
                editOperativeTasks,isFetching,
                selectedToSave
             }= this.props;
        const EntrustedTaskId = filter.text;
        let showButtons = true
        if(userProfile == 'Calidad' || userProfile == 'Directivo' || userProfile == 'Gerencia'){
            if(typeof userPermissions ==  'object' && 'workTracking' in userPermissions) { 
                showButtons = userPermissions.workTracking.canEdit;
            }
        }
        return (
            <Container className="workorders-container">
                <Row>
                    <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#" onClick={goBackToWorkTracking}>
                            Seguimiento de Ordenes de Trabajo
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={()=>goBackToEntrustedTasck(metadataTranckingTask.WorkOrderId)}>Tarea Encomendada - {EntrustedTaskId}</Breadcrumb.Item>
                        <Breadcrumb.Item active>Tareas Operativas </Breadcrumb.Item>
                    </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card className="shadow">
                            <Card.Header><h5>Tareas Operativas</h5></Card.Header>
                            <Card.Body>
                            <Row>
                                        <Form.Label column sm={2}>N° de OT: {metadataTranckingTask.WorkOrderId}</Form.Label> 
                                        <Form.Label title={metadataTranckingTask.client.comertialName} className="text-truncate" column sm={3}>Cliente: {metadataTranckingTask.client.comertialName}</Form.Label> 
                                        <Form.Label column sm={3}>Estructura: {metadataTranckingTask.structure}</Form.Label> 
                                        <Form.Label column sm={3}>Fecha de pedido: {metadataTranckingTask.orderDate}</Form.Label>                                         
                                    </Row>
                            <Row>  
                                <Col sm="6">
                                    <DropdownFilterField
                                        label="Tareas Encomendadas"
                                        name="filterEntrustedTask"
                                        handleChange={this.handleFilterSelectChange}
                                        options={workOrderOptions}
                                        value={filter.text}
                                        />
                                </Col>
                                {!metadataTranckingTask.finished ?
                                    <Col sm="1">
                                        {showButtons && 
                                        <Button variant="primary" type="button" onClick={() => endEntrustedTask(metadataTranckingTask.WorkOrderId,EntrustedTaskId)}>
                                            Finalizar
                                        </Button>}
                                    </Col>   
                                :
                                <Col sm="2">
                                    <Alert className="text-center" variant="success">
                                        <h6>Finalizada</h6>
                                    </Alert>
                                </Col>}
                                <Col sm="3">
                                    <Button variant="primary" disabled={isFetching || false} onClick={() => editOperativeTasks()}>
                                        Guardar Selección
                                        {isFetching ? <Spinner animation="grow" size="sm" /> : null}
                                    </Button>
                                </Col>
                                <Col>
                                { metadataTranckingTask.makeNewDesign && !metadataTranckingTask.finished ? 
                                    <Button variant={metadataTranckingTask.newDesign?"primary":"warning"} onClick={()=>newDesign(metadataTranckingTask.WorkOrderId)} >
                                        {metadataTranckingTask.newDesign?"Diseño estandar":"Nuevo diseño"}
                                    </Button> 
                                :null}
                                { metadataTranckingTask.makeComplementarywork && !metadataTranckingTask.finished ? 
                                    <Button className="complementaryWork" variant={metadataTranckingTask.complementaryWork?"primary":"info"} onClick={()=>complementaryWork(metadataTranckingTask.WorkOrderId)} >
                                        {metadataTranckingTask.complementaryWork?"Sin provisiones adicionales":"Con provisiones adicionales"}
                                    </Button>   
                                :null}
                                </Col>                                
                            </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />}>
                            <TasksOperativeCards />
                        </Suspense>
                        
                    </Col>
                </Row>
                <br/><br/><br/>
            </Container>
        );
    }
}


const mapStateToProps = state => {
    return {
        isFetching: getIsFetching(state),
        filter: getFilter(state),
        cards: getCards(state),
        workOrderOptions:getWorkOrderOption(state),
        metadataTranckingTask: getMetadataTranckingTask(state),
        userProfile: getUserProfile(state),
        userPermissions: getUserPermissions(state),
        selectedToSave :getOperativeTaskSelectedToSave(state)
    };
}

const mapDispatchToProps = dispatch => {
    return {
        endEntrustedTask: (workOrderId,entrustedTaskId) => dispatch(tryEndEntrustedTask(workOrderId,entrustedTaskId)),
        onOperativeTaskEdit: (operativeTask) => dispatch(tryEditOperativeTask(operativeTask)),
        setCardsFilter: (filterSelectWorkOrderId) => dispatch(setCardsFilter(filterSelectWorkOrderId)),
        setShowCards:(filterWorkOrderId) => dispatch(setShowCards(filterWorkOrderId)),
        OperativesTaskCards: (id) => dispatch(tryGetfilterEntrustedTask(id)),
        goBack: () => dispatch(goBack()),
        goBackToWorkTracking:() => dispatch(push(WORKORDERSTRACKING_URI)),
        goBackToEntrustedTasck:(workOrderId) => dispatch(push(WORKORDERSTRACKING_URI+"/"+workOrderId+ENTRUSTEDTASKS_URI)),
        newDesign:(id) => dispatch(tryEditNewDesign(id)),
        complementaryWork:(id) => dispatch(tryEditComplementaryWork(id)),
        setCardsSelectedToSave: (selectedToSave) => dispatch(setSelectedToSaveItems(selectedToSave)),
        editOperativeTasks:() => dispatch(tryEditOperativeTasks()),
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OperativeTasksPage);