import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditEntrustedTaskTypeForm } from 'Src/components/entrustedTaskTypes/entrustedTaskTypesForm'

import { getEntrustedTaskTypeToEdit } from "Src/redux/reducers/entrustedTaskTypesReducer";

import { tryEditEntrustedTaskType } from 'Src/redux/thunk-creators/entrustedTaskTypesActionCreator';

class EntrustedTaskTypesEditPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { 
            onEntrustedTaskTypeEdit, goBack, EntrustedTaskTypeToEdit
        } = this.props;
      
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedEditEntrustedTaskTypeForm
                                title="Editar tipo de tarea encomendada"
                                submitText="Editar"
                                onSubmit={entrustedTaskType => onEntrustedTaskTypeEdit(entrustedTaskType)}
                                onCancel={goBack}
                                initialValues={EntrustedTaskTypeToEdit}
                            />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        EntrustedTaskTypeToEdit: getEntrustedTaskTypeToEdit(state)
    };
}

const mapDispatchToProps = dispatch => ({
    onEntrustedTaskTypeEdit: (entrustedTaskType) => dispatch(tryEditEntrustedTaskType(entrustedTaskType)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EntrustedTaskTypesEditPage);
