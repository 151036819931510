import {
    CLIENTCONTACTS_CARDS_REQUEST,
    CLIENTCONTACTS_CARDS_SUCCESS,
    CLIENTCONTACTS_CARDS_FAILTURE,
    CLIENTCONTACTS_CARDS_CLEAN,
    CLIENTCONTACTS_FILTER_SET,
    CLIENTCONTACTS_PAGE_SET,
    CLIENTCONTACTS_FORM_ADD_REQUEST,
    CLIENTCONTACTS_FORM_ADD_SUCCESS,
    CLIENTCONTACTS_FORM_ADD_FAILTURE,
    CLIENTCONTACTS_FORM_DELETE_REQUEST,
    CLIENTCONTACTS_FORM_DELETE_SUCCESS,
    CLIENTCONTACTS_FORM_DELETE_FAILTURE,
    CLIENTCONTACTS_FORM_GET_REQUEST,
    CLIENTCONTACTS_FORM_GET_SUCCESS,
    CLIENTCONTACTS_FORM_GET_FAILTURE,
    CLIENTCONTACTS_FORM_EDIT_REQUEST,
    CLIENTCONTACTS_FORM_EDIT_SUCCESS,
    CLIENTCONTACTS_FORM_EDIT_FAILTURE,
    CLIENTCONTACTS_FORM_LOAD_REQUEST,
    CLIENTCONTACTS_FORM_LOAD_SUCCESS,
    CLIENTCONTACTS_FORM_LOAD_FAILTURE
  } from "./clientContactsActionTypes";
  
  export const buildAction = (type, payload) => ({ 
    type, 
    payload 
  })

  export const setFilter = (filter) => ({
    type: CLIENTCONTACTS_FILTER_SET,
    payload: {
      filter
    }
  });
  
  export const getCardsRequest = () => ({
    type: CLIENTCONTACTS_CARDS_REQUEST,
    payload: {
      isFetching: true,
      hasError: false
    }
  });
  
  export const getCardsSuccess = (response) => ({
    type: CLIENTCONTACTS_CARDS_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      records: response.records,
      metadata: response.metadata
    }
  });
  
  export const getCardsFailture = (response) => ({
    type: CLIENTCONTACTS_CARDS_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });
  
  export const setPage = (page) => ({
    type: CLIENTCONTACTS_PAGE_SET,
    payload: {
      page
    }
  });
  
  export const addRequest = (clientContact) => ({
    type: CLIENTCONTACTS_FORM_ADD_REQUEST,
    payload: {
      isFetching: true,
      hasError: false,
      clientContact
    }
  });
  
  export const addSuccess = (response) => ({
    type: CLIENTCONTACTS_FORM_ADD_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      response
    }
  });
  
  export const addFailture = (response) => ({
    type: CLIENTCONTACTS_FORM_ADD_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });
  
  export const deleteRequest = (clientContactId) => ({
    type: CLIENTCONTACTS_FORM_DELETE_REQUEST,
    payload: {
      isFetching: true,
      hasError: false,
      clientContactId
    }
  });
  
  export const deleteSuccess = (clientContactId) => ({
    type: CLIENTCONTACTS_FORM_DELETE_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      clientContactId
    }
  });
  
  export const deleteFailture = (response) => ({
    type: CLIENTCONTACTS_FORM_DELETE_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });
  
  export const getRequest = (clientContactId) => ({
    type: CLIENTCONTACTS_FORM_GET_REQUEST,
    payload: {
      isFetching: true,
      hasError: false,
      clientContactId
    }
  });
  
  export const getSuccess = (response) => ({
    type: CLIENTCONTACTS_FORM_GET_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      response
    }
  });
  
  export const getFailture = (response) => ({
    type: CLIENTCONTACTS_FORM_GET_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });
  
  export const editRequest = (clientContactId) => ({
    type: CLIENTCONTACTS_FORM_EDIT_REQUEST,
    payload: {
      isFetching: true,
      hasError: false,
      clientContactId
    }
  });
  
  export const editSuccess = (response) => ({
    type: CLIENTCONTACTS_FORM_EDIT_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      response
    }
  });
  
  export const editFailture = (response) => ({
    type: CLIENTCONTACTS_FORM_EDIT_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });
  
  export const cleanCards = () => ({
    type: CLIENTCONTACTS_CARDS_CLEAN,
    payload: {
      isFetching: false,
      hasError: false
    }
  });

  export const formLoadRequest = () =>
  buildAction(
    CLIENTCONTACTS_FORM_LOAD_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )
  
export const formLoadSuccess = (clientsOptions, contactTypesOptions) =>
  buildAction(
    CLIENTCONTACTS_FORM_LOAD_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      clientsOptions,
      contactTypesOptions
    }
  )

export const formLoadFailture = (response) =>
  buildAction(
    CLIENTCONTACTS_FORM_LOAD_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )