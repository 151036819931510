import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditRegionForm } from 'Src/components/regions/RegionForm'

import { getregionToEdit, getClientsOption } from "Src/redux/reducers/regionsCardsReducer";

import { tryEditRegion,tryFormLoad } from 'Src/redux/thunk-creators/regionsCardsActionCreator';

class RegionsEditPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }

    render() {
        const { 
            onRegionEdit, goBack, RegionToEdit,clientsOptions
        } = this.props;
      
        return (
            <Container className="Regions-container">
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedEditRegionForm
                                title="Editar Region"
                                submitText="Editar"
                                onSubmit={region => onRegionEdit(region)}
                                clientsOptions={clientsOptions}
                                onCancel={goBack}
                                initialValues={RegionToEdit}
                            />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        RegionToEdit: getregionToEdit(state),
        clientsOptions: getClientsOption(state),
    };
}

const mapDispatchToProps = dispatch => ({
    onRegionEdit: (region) => dispatch(tryEditRegion(region)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegionsEditPage);
