import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import {
    Container, Row, Col,
    Card, Button, Spinner
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import LoadingCard from 'Src/components/loadingCard';
import { CLIENTCONTACTS_FORM_URI } from 'Src/routes';

import { getMetadata, getIsFetching, getFilter } from "Src/redux/reducers/clientContactsReducer";
import { setCardsFilter } from 'Src/redux/thunk-creators/clientContactsActionCreator';

const ClientContactsCards = React.lazy(() =>
    import('Src/components/clientContacts/clientContactsCards'));

const CardsFilter = React.lazy(() =>
    import('Src/components/cards/cardsFilter'));

const CardsMetadata = React.lazy(() =>
    import('Src/components/cards/cardsMetadata'));

class ClientContactsPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    handleFilterChange(event) {
        let filterText = event.target.value;
        this.props.setCardsFilter(filterText);
    }

    render() {
        const { metadata, filter } = this.props;

        return (
            <Container className="clientContacts-container">
                <Row>
                    <Col sm={12}>
                        <Card className="shadow">
                            <Card.Header><h5>Contactos de Clientes</h5></Card.Header>
                            <Card.Body>
                                <Card.Body>
                                    <Row>
                                        <Col sm={7}>
                                            <Suspense fallback={<Spinner animation="grow" />}>
                                                <CardsFilter
                                                    handleChange={this.handleFilterChange}
                                                    filterText={filter.text} />
                                            </Suspense>
                                        </Col>

                                        <Col sm={3}>
                                            <Suspense fallback={<Spinner animation="grow" />}>
                                                <CardsMetadata metadata={metadata} />
                                            </Suspense>
                                        </Col>

                                        <Col sm={1}>
                                            <Link to={CLIENTCONTACTS_FORM_URI}>
                                                <Button variant="primary">
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card.Body>
                        </Card>
                        <br />
                    </Col>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />}>
                            <ClientContactsCards />
                        </Suspense>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        metadata: getMetadata(state),
        isFetching: getIsFetching(state),
        filter: getFilter(state)
    };
}


const mapDispatchToProps = dispatch => {
    return {
        setCardsFilter: (filter) => dispatch(setCardsFilter(filter))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientContactsPage);