export const TIMELINES_CARDS_REQUEST = "TIMELINES_CARDS_REQUEST";
export const TIMELINES_CARDS_SUCCESS = "TIMELINES_CARDS_SUCCESS";
export const TIMELINES_CARDS_FAILTURE = "TIMELINES_CARDS_FAILTURE";
export const TIMELINES_CARDS_CLEAN = "TIMELINES_CARDS_CLEAN";

export const TIMELINES_FILTER_SET = "TIMELINES_FILTER_SET";
export const TIMELINES_PAGE_SET = "TIMELINES_PAGE_SET";

export const TIMELINES_CARDS_HEADER_SET = "TIMELINES_CARDS_HEADER_SET";

export const TIMELINES_FORM_GET_REQUEST = "TIMELINES_FORM_GET_REQUEST";
export const TIMELINES_FORM_GET_SUCCESS = "TIMELINES_FORM_GET_SUCCESS";
export const TIMELINES_FORM_GET_FAILTURE = "TIMELINES_FORM_GET_FAILTURE";

export const TIMELINES_TASKXAREA_CARDS_SUCCESS = "TIMELINES_TASKXAREA_CARDS_SUCCESS";




