import {
  AREAS_CARDS_REQUEST,
  AREAS_CARDS_SUCCESS,
  AREAS_CARDS_FAILTURE,
  AREAS_CARDS_CLEAN,
  AREAS_FILTER_SET,
  AREAS_PAGE_SET,
  AREAS_FORM_ADD_REQUEST,
  AREAS_FORM_ADD_SUCCESS,
  AREAS_FORM_ADD_FAILTURE,
  AREAS_FORM_DELETE_REQUEST,
  AREAS_FORM_DELETE_SUCCESS,
  AREAS_FORM_DELETE_FAILTURE,
  AREAS_FORM_GET_REQUEST,
  AREAS_FORM_GET_SUCCESS,
  AREAS_FORM_GET_FAILTURE,
  AREAS_FORM_EDIT_REQUEST,
  AREAS_FORM_EDIT_SUCCESS,
  AREAS_FORM_EDIT_FAILTURE,
} from "./areasActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const setFilter = (filter) => ({
  type: AREAS_FILTER_SET,
  payload: {
    filter
  }
});


export const getRequest = (areaId) => ({
  type: AREAS_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    areaId
  }
});

export const getSuccess = (response) => ({
  type: AREAS_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const getFailture = (response) => ({
  type: AREAS_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});


export const addRequest = (area) => ({
  type: AREAS_FORM_ADD_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    area
  }
});

export const addSuccess = (response) => ({
  type: AREAS_FORM_ADD_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const addFailture = (response) => ({
  type: AREAS_FORM_ADD_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const editRequest = (areaId) => ({
  type: AREAS_FORM_EDIT_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    areaId
  }
});

export const editSuccess = (response) => ({
  type: AREAS_FORM_EDIT_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const editFailture = (response) => ({
  type: AREAS_FORM_EDIT_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const deleteRequest = (areaId) => ({
  type: AREAS_FORM_DELETE_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    areaId
  }
});

export const deleteSuccess = (areaId) => ({
  type: AREAS_FORM_DELETE_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    areaId
  }
});

export const deleteFailture = (response) => ({
  type: AREAS_FORM_DELETE_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const getCardsRequest = () => ({
  type: AREAS_CARDS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getCardsSuccess = (response) => ({
  type: AREAS_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response.records,
    metadata: response.metadata
  }
});

export const getCardsFailture = (response) => ({
  type: AREAS_CARDS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const setPage = (page) => ({
  type: AREAS_PAGE_SET,
  payload: {
    page
  }
});