import {
    USERS_CARDS_REQUEST,
    USERS_CARDS_SUCCESS,
    USERS_CARDS_FAILTURE,
    USERS_CARDS_CLEAN,
    USERS_FILTER_SET,
    USERS_PAGE_SET,
    USERS_FORM_DELETE_SUCCESS,
    USERS_FORM_GET_SUCCESS,
    USERS_FORM_EDIT_SUCCESS,
    USERS_FORM_LOAD_SUCCESS
} from "Src/redux/actions/usersCardsActionTypes";

const initialMetadata = {
    perPage: 6,
    page: 1
};

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: ""
    },
    metadata: initialMetadata,
    cards: [],
    userToEdit: undefined,
    profilesOptions: []
};

const userCardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case USERS_FILTER_SET: {
            return {
                ...state,
                filter: {
                    text: action.payload.filter
                },
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case USERS_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case USERS_CARDS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                cards: action.payload.records
            };
        }
        case USERS_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case USERS_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        case USERS_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        case USERS_FORM_DELETE_SUCCESS: {
            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload.userId),
                metadata: {
                    ...state.metadata,
                    totalCount: state.metadata.totalCount - 1
                }
            };
        }
        case USERS_FORM_GET_SUCCESS: {
            let newProfilesOptions = [{ key: -1, text: "Ninguno" }]

            const profilesOptions = action.payload.profiles.map(p => ({
                key: p.id,
                text: p.name
            }))
            return {
                ...state,
                userToEdit: action.payload.response,
                profilesOptions: newProfilesOptions.concat(profilesOptions),
            };
        }
        case USERS_FORM_EDIT_SUCCESS: {
            return {
                ...state,
                userToEdit: undefined
            };
        }
        case USERS_FORM_LOAD_SUCCESS: {
            let newProfilesOptions = [{ key: -1, text: "Ninguno" }]

            const profilesOptions = action.payload.profiles.map(p => ({
                key: p.id,
                text: p.name
            }))

            return {
                ...state,
                profilesOptions: newProfilesOptions.concat(profilesOptions),
                structureTypeTemplate: action.payload.structureTypeTemplate
            }
        }
        default: {
            return state;
        }
    }
};

export default userCardsReducer;

export const getusersCardsState = (state) => state.userCardsReducer;

export const getFilter = (state) => getusersCardsState(state).filter;

export const getIsFetching = (state) => getusersCardsState(state).isFetching;

export const getCards = (state) => getusersCardsState(state).cards;

export const getMetadata = (state) => getusersCardsState(state).metadata;

export const getuserToEdit = (state) => getusersCardsState(state).userToEdit;

export const getProfilesOption = (state) => getusersCardsState(state).profilesOptions;