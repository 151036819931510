import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditJustificationDelayAreaForm } from 'Src/components/justificationDelayAreas/justificationDelayAreaForm'

import { getJustificationDelayAreaToEdit, getAreasOptions } from "Src/redux/reducers/justificationDelayAreasReducer";

import { tryEditJustificationDelayArea, tryFormLoad } from 'Src/redux/thunk-creators/justificationDelayAreasActionCreator';

class JustificationDelayAreasEditPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }

    render() {
        const { 
            onJustificationDelayAreaEdit, goBack, JustificationDelayAreaToEdit, areasOptions
        } = this.props;
      
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedEditJustificationDelayAreaForm
                                title="Editar Justificación de Retraso"
                                submitText="Editar"
                                areasOptions = { areasOptions }
                                onSubmit={justificationDelayArea => onJustificationDelayAreaEdit(justificationDelayArea)}
                                onCancel={goBack}
                                initialValues={JustificationDelayAreaToEdit}
                            />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        JustificationDelayAreaToEdit: getJustificationDelayAreaToEdit(state),
        areasOptions: getAreasOptions(state)
    };
}

const mapDispatchToProps = dispatch => ({
    onJustificationDelayAreaEdit: (justificationDelayArea) => dispatch(tryEditJustificationDelayArea(justificationDelayArea)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JustificationDelayAreasEditPage);
