export const DASHBOARD_MAP_FILTER_SET = "DASHBOARD_MAP_FILTER_SET";

export const DASHBOARD_PIECHART_FILTER_SET = "DASHBOARD_PIECHART_FILTER_SET";

export const DASHBOARD_BARCHART_FILTER_SET = "DASHBOARD_BARCHART_FILTER_SET";

export const DASHBOARD_MAP_FORM_LOAD_REQUEST = "DASHBOARD_MAP_FORM_LOAD_REQUEST";
export const DASHBOARD_MAP_FORM_LOAD_SUCCESS = "DASHBOARD_MAP_FORM_LOAD_SUCCESS";
export const DASHBOARD_MAP_FORM_LOAD_FAILTURE = "DASHBOARD_MAP_FORM_LOAD_FAILTURE";

export const DASHBOARD_MAP_REQUEST = "DASHBOARD_MAP_REQUEST";
export const DASHBOARD_MAP_SUCCESS = "DASHBOARD_MAP_SUCCESS";
export const DASHBOARD_MAP_FAILTURE  = "DASHBOARD_MAP_FAILTURE";

export const DASHBOARD_PIECHART_REQUEST = "DASHBOARD_PIECHART_REQUEST";
export const DASHBOARD_PIECHART_SUCCESS = "DASHBOARD_PIECHART_SUCCESS";
export const DASHBOARD_PIECHART_FAILTURE  = "DASHBOARD_PIECHART_FAILTURE";

export const DASHBOARD_BARCHART_SUCCESS = "DASHBOARD_BARCHART_SUCCESS";
export const DASHBOARD_BARCHART_REQUEST = "DASHBOARD_BARCHART_REQUEST";
export const DASHBOARD_BARCHART_FAILTURE  = "DASHBOARD_BARCHART_FAILTURE";

export const DASHBOARD_SHOW_CARD  = "DASHBOARD_SHOW_CARD";

export const DASHBOARD_WORKORDEN_AUTOCOMPLITE_REQUEST = "DASHBOARD_WORKORDEN_AUTOCOMPLITE_REQUEST";
export const DASHBOARD_WORKORDEN_AUTOCOMPLITE_SUCCESS = "DASHBOARD_WORKORDEN_AUTOCOMPLITE_SUCCESS";
export const DASHBOARD_WORKORDEN_AUTOCOMPLITE_FAILTURE = "DASHBOARD_WORKORDEN_AUTOCOMPLITE_FAILTURE";

export const DASHBOARD_MAP_WEATHER_SUCCESS  = "DASHBOARD_MAP_WEATHER_SUCCESS";
