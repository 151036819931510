import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'

import loginReducer from "./loginReducer";
import clientsCardsReducer from "./clientsCardsReducer"
import regionsCardsReducer from "./regionsCardsReducer"
import workOrdersCardsReducer from "./workOrdersCardsReducer"
import structureTypesReducer from "./structureTypesReducer"
import ParticularDetailsReducer from "./particularDetailsReducer"
import clientContactsReducer from "./clientContactsReducer"
import contactTypesCardsReducer from "./contactTypesCardsReducer"
import reportsReducer from "./reportsReducer"
import entrustedTasksCardsReducer from "./entrustedTasksCardsReducer"
import workOrderTrackingCardsReducer from "./workOrderTrackingCardsReducer"
import operativeTasksCardsReducer from "./operativeTasksCardsReducer"
import userCardsReducer from "./usersCardsReducer"
import timelineCardsReducer from "./timelinesCardsReducer"
import entrustedTaskTypesReducer from "./entrustedTaskTypesReducer"
import areasReducer from "./areasReducer"
import operativeTaskTypesReducer from "./operativeTaskTypesReducer"
import tasksConfigurationsReducer from "./tasksConfigurationsReducer"
import dashboardReducer from "./dashboardReducer"
import historysReducer from "./historysReducer"
import justificationDelayAreasReducer from "./justificationDelayAreasReducer"
import responsibleForAreaReducer from "./responsibleForAreaReducer"




import {reducer as notifications} from 'react-notification-system-redux';

export default (history) => combineReducers({ 
    router: connectRouter(history),
    notifications,
    form: formReducer,

    loginReducer,
    clientsCardsReducer,
    regionsCardsReducer,
    workOrdersCardsReducer,
    structureTypesReducer,
    ParticularDetailsReducer,
    clientContactsReducer,
    contactTypesCardsReducer,
    reportsReducer,
    workOrderTrackingCardsReducer,
    entrustedTasksCardsReducer,
    operativeTasksCardsReducer,
    userCardsReducer,
    timelineCardsReducer,
    entrustedTaskTypesReducer,
    areasReducer,
    operativeTaskTypesReducer,
    tasksConfigurationsReducer,
    dashboardReducer,
    historysReducer,
    justificationDelayAreasReducer,
    responsibleForAreaReducer
});
