import config from 'Config';

import { buildDefaultOptions } from './authService';
import {
    httpGet, httpPost, httpPut,
    handleHttpError
} from './httpService';

import { 
    buildUriById,
    get as _get, 
    post as _post, 
    getById as _getById, 
    deleteById as _deleteById, 
    put as _put } from 'Src/services/entityService'

const API_ENDPOINT = `${config.apis.coreApi.URL}/api`
const ENTITY_URI = `${API_ENDPOINT}/clients`;

let get_Cancelation = undefined;
export const get = async (accessToken, filterText, pageSize, page) => 
                        _get(accessToken, ENTITY_URI, get_Cancelation, filterText, pageSize, page)

export const getById = async (accessToken, id) => 
                        _getById(accessToken, ENTITY_URI, id)

export const deleteById = async (accessToken, id) => 
                            _deleteById(accessToken, ENTITY_URI, id)

export const put = async (accessToken, id) => 
                        _put(accessToken, ENTITY_URI, id)

export const post = async (accessToken, client) => {
    const params = {
        "TangoCode": client.tangoCode,
        "BusinessName": client.businessName,
        "ComertialName": client.comertialName,
        "Address": client.address,
        "Cuit": client.cuit
    }

    return _post(accessToken, ENTITY_URI, params)
}

const DocumentTypes = {
    ConstanciaAfip: 1,
    ConstanciaIibb: 2,
    FormularioCm05: 3
}

export const addConstanciaAfip = async (accessToken, client) => addDocument(accessToken, client.id, DocumentTypes.ConstanciaAfip,client.constanciaAfip);
export const addConstanciaIibb = async (accessToken, client) => addDocument(accessToken, client.id, DocumentTypes.ConstanciaIibb,client.contanciaIibb);
export const addFormularioCm05 = async (accessToken, client) => addDocument(accessToken, client.id, DocumentTypes.FormularioCm05,client.formularioCM05);

export const addDocument = async (accessToken, clientId, documentFk,file) => {
    
    const URL = buildUriById(ENTITY_URI, clientId) + `/documents/${documentFk}`;

    let options = buildDefaultOptions(accessToken)
    options.headers['Content-Type'] = 'multipart/form-data';

    const formData = new FormData();
    formData.append("file", file);

    try {
        let response = await httpPost(URL, formData, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const editConstanciaAfip = async (accessToken, client) => editDocument(accessToken, client.id, DocumentTypes.ConstanciaAfip,client.constanciaAfip);
export const editConstanciaIibb = async (accessToken, client) => editDocument(accessToken, client.id, DocumentTypes.ConstanciaIibb,client.contanciaIibb);
export const editFormularioCm05 = async (accessToken, client) => editDocument(accessToken, client.id, DocumentTypes.FormularioCm05,client.formularioCM05);

export const editDocument = async (accessToken, clientId, documentFk,file) => {
    
    const URL = buildUriById(ENTITY_URI, clientId) + `/documents/${documentFk}`;

    let options = buildDefaultOptions(accessToken)
    options.headers['Content-Type'] = 'multipart/form-data';

    const formData = new FormData();
    formData.append("file", file);

    try {
        let response = await httpPost(URL, formData, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

// Specific of Clients
export const getContacts = async (accessToken, clientId) => {
    const URL = buildUriById(ENTITY_URI, clientId) + "/contacts";

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpGet(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const getRegions = async (accessToken, clientId) => {
    const URL = buildUriById(ENTITY_URI, clientId) + "/regions";

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpGet(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const getDocuments = async (accessToken, clientId) => {
    
    const URL = buildUriById(ENTITY_URI, clientId) + "/documents";

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpGet(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}
