import React,{Component} from 'react';
import {
    Card, Button, Spinner,Col,Row
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { dropdownField } from 'Src/components/forms/dropdownField';
import { datePickerField } from 'Src/components/forms/dateField';
import { checkboxField } from 'Src/components/forms/checkboxField';

import { treeSelectField } from 'Src/components/forms/treeSelectField';

//TOOD: Move to Generic Validations Functions

const required = value => (value ? undefined : 'Requerido!')

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}
    errors.scheme = required(values.scheme)
    return errors
}

class ReportsForm extends Component {
    constructor(props) {
        super(props);
    }


    

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText,clientsOptions,onChangeClient,regionsOptions,structureTypeOptions,startDate,
            workOrderStateOptions,
            clientOptionsTree,
            entrustedTasksOptionsTree,
            certificationOptions,
            invoiceOptions,
            stateStructureOptions,
            baseDispatchOptions,
            structureDispatchOptions,
            mode
        } = this.props;
        return (
            <Card className="shadow">
                <Card.Header><h5>{title}</h5></Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Field label="Descargar Plan de Obra?" withoutSize={true} name="reportType" component={checkboxField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="startDate" label="Fecha desde" 
                                component={datePickerField}
                                withWeekend={true} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="endDate" minDate={startDate} label="Fecha Hasta" 
                                component={datePickerField} 
                                withWeekend={true} />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                            <Field name="clientTree" label="Cliente"
                                    component={treeSelectField}
                                    isMultiple={true}
                                    treeData={clientOptionsTree}
                                   
                                   
                                   />
                            </Col>
                        </Row>


                        <Row>
                            <Col>
                            <Field name="structureTypeFK" label="Tipo de Estructura"
                                    component={dropdownField}
                                    options={structureTypeOptions} />  
                            </Col>
                        </Row>
   
                        <Row>
                            <Col>
                            <Field name="certificationOptionField" label="Certificación"
                                    component={dropdownField}
                                    options={certificationOptions} />  
                            </Col>
                        </Row>

                           
                        <Row>
                            <Col>
                            <Field name="invoiceOptionField" label="Facturación"
                                    component={dropdownField}
                                    options={invoiceOptions} />  
                            </Col>
                        </Row>
         
                        <Row>
                            <Col>
                            <Field name="entrustedTasksFieldNew" label="Descripción"
                                    component={treeSelectField}
                                    isMultiple={true}
                                    treeData={entrustedTasksOptionsTree} />  
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                            <Field name="workOrderStateNew" label="Estado"
                                    component={treeSelectField}
                                    isMultiple={true}
                                    treeData={stateStructureOptions} />  
                            </Col>
                        </Row>


                        <Row>
                            <Col>
                            <Field name="baseDispatchOptionsField" label="Despacho Base"
                                    component={dropdownField}
                                    options={baseDispatchOptions} />  
                            </Col>
                        </Row>
          
                       <Row>
                           <Col>
                            <Field name="structureDispatchOptionsField" label="Despacho Estructura"
                                    component={dropdownField}
                                    options={structureDispatchOptions} />
                           </Col>
                       </Row>


                        <Row>
                            <Col>
                                <Button variant="primary" type="submit" disabled={pristine || submitting}>
                                    {submitText}
                                    {submitting ? <Spinner animation="grow" size="sm" /> : null}
                                </Button>                            
                            </Col>
                            <Col className="text-right">
                                <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                                &nbsp;                            
                             
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_REPORTS_NAME = 'CREATE_REPORTS_NAME';
export const EDIT_REPORTS_NAME = 'EDIT_REPORTS_NAME';

const CreateReportsForm = props =>
  <ReportsForm {...props} mode='create' />;
  
const EditReportsForm = props =>
  <ReportsForm {...props} mode='edit' />;

const ConnectedCreateReportsForm = reduxForm({
    form: CREATE_REPORTS_NAME,
    validate
})(CreateReportsForm);

const ConnectedEditReportsForm = reduxForm({
    form: EDIT_REPORTS_NAME,
    validate
})(EditReportsForm);

export {
    ConnectedCreateReportsForm,
    ConnectedEditReportsForm
}