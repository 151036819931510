import config from 'Config';

import { buildDefaultOptions } from './authService';
import {
    httpGet, httpPost, httpPut,
    handleHttpError,buildURLQuery
} from './httpService';

import { 
    buildUriById,
    get as _get, 
    post as _post, 
    getById as _getById, 
    deleteById as _deleteById, 
    put as _put,
 } from 'Src/services/entityService'

const API_ENDPOINT = `${config.apis.coreApi.URL}/api`
const ENTITY_URI = `${API_ENDPOINT}/worktracking`;
const ENTITY_URI_ENTRUSTEDTASKS = `/entrustedtasks`;
const ENTITY_URI_OPERATIVETASKS = `/operativetasks`;
const ENTITY_URI_OPERATIVETASK = `/operativetask`;
const ENTITY_URI_FINISHED =`/finished`;
const ENTITY_URI_NEWDESING =`/newdesign`;
const ENTITY_URI_COMPLEMENTARYWORK =`/complementarywork`;
const ENTITY_URI_TIMELINE = `${API_ENDPOINT}/worktracking/timeline`;

let get_Cancelation = undefined;
export const get = async (accessToken, filterText, pageSize, page,options) => {
    return  _get(accessToken, ENTITY_URI, get_Cancelation, filterText, pageSize, page,options);
}
       

export const getEntrustedTasks = async (accessToken, id) => {
    const URLBase = buildUriById(ENTITY_URI, id);
    const URL = URLBase+ENTITY_URI_ENTRUSTEDTASKS

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpGet(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const getOperativeTasks = async (accessToken,WorkOrderId, EntrustedTaskId) => {
    const URLBase = buildUriById(ENTITY_URI, WorkOrderId);
    const URL = URLBase+'/'+EntrustedTaskId+ENTITY_URI_OPERATIVETASKS

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpGet(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}
                        

export const putOperativeTask = async (accessToken, form,EntrustedTaskFK,WorkOrderId) =>{

    const URLBase = buildUriById(ENTITY_URI, WorkOrderId);
    const URL = URLBase+'/'+EntrustedTaskFK+ENTITY_URI_OPERATIVETASK

    let options = buildDefaultOptions(accessToken)
    try {
        let response = await httpPut(URL, form, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const putOperativeTasks = async (accessToken, forms,EntrustedTaskFK,WorkOrderId) =>{

    const URLBase = buildUriById(ENTITY_URI, WorkOrderId);
    const URL = URLBase+'/'+EntrustedTaskFK+ENTITY_URI_OPERATIVETASKS

    let options = buildDefaultOptions(accessToken)
    try {
        let response = await httpPut(URL, forms, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
} 

export const putNewDesing = async (accessToken,WorkOrderId) =>{
    let form = null;
    const URLBase = buildUriById(ENTITY_URI, WorkOrderId);
    const URL = URLBase+ENTITY_URI_NEWDESING

    let options = buildDefaultOptions(accessToken)
    try {
        let response = await httpPut(URL, form, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
} 

export const putComplementaryWork = async (accessToken,WorkOrderId) =>{
    let form = null;
    const URLBase = buildUriById(ENTITY_URI, WorkOrderId);
    const URL = URLBase+ENTITY_URI_COMPLEMENTARYWORK

    let options = buildDefaultOptions(accessToken)
    try {
        let response = await httpPut(URL, form, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
} 

export const putEndEntrutedTask = async (accessToken,EntrustedTaskFK,WorkOrderId) =>{
    let form = null;
    const URLBase = buildUriById(ENTITY_URI, WorkOrderId);
    const URL = URLBase+'/'+EntrustedTaskFK+ENTITY_URI_FINISHED

    let options = buildDefaultOptions(accessToken)
    try {
        let response = await httpPut(URL, form, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
} 
     

export const getByIdOperativeTask = async (accessToken, id) => 
                        _getById(accessToken, ENTITY_URI_OPERATIVETASKS, id)

export const getByIdTimeLine = async (accessToken, id) => 
                        _getById(accessToken, ENTITY_URI_TIMELINE, id)

