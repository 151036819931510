import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LOGIN_URI } from 'Src/routes';
import { Alert,Spinner } from 'react-bootstrap'

import { trySendEmail } from 'Src/redux/thunk-creators/loginActionCreator';
import { getIsFetching } from "Src/redux/reducers/loginReducer";

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

class ForgotPasswordPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: false, isFetching:false };
        this.onSendEmail = this.onSendEmail.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onKeyPressed = this.onKeyPressed.bind(this);
    }

    onEmailChange(event) {
        this.setState({
            email: event.target.value
        });
    }

    onSendEmail(e) {          
        if(!validEmailRegex.test(this.state.email)){
            this.setState({
                error: 'Correo no valido',
                isFetching:false
            });
            return false;
        }  
        this.setState({
            error: false,
            isFetching:true
        });     
            this.props.trySendEmail(this.state.email);
    }

    onKeyPressed(event) {
        if (event.key === "Enter") {
            this.onSendEmail(event)
        }
    }

    render() {
        let loadingWheel;       
        if (this.props.isFetching) {
            loadingWheel = <Spinner animation="grow" size='sm' />;
        }
        return (
            <div className="container">
                <div className='row'>
                    <div className="card login-card shadow-lg">
                        <article className="card-body">
                            <div className="row h-100 justify-content-center align-items-center">
                                <div className="logo mb-4 mt-1"></div>
                            </div>

                            <h4 className="card-title text-center mb-4 mt-1">
                                Envio de email para recuperar contraseña
                            </h4>
                            <hr />
                            
                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="fa fa-user">@</i>
                                        </span>
                                    </div>
                                    <input className="form-control"
                                        placeholder="ejemplo@dominio.com" type="email"
                                        value={this.state && this.state.email}
                                        onChange={this.onEmailChange}
                                        onKeyPress={this.onKeyPressed} />
                                </div>
                                {this.state.error ?
                                            <Alert key="error" variant="danger">
                                                {this.state.error}
                                            </Alert>
                                        : null}
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary btn-block"
                                    onClick={this.onSendEmail}
                                    disabled={this.props.isFetching}>
                                    Enviar  {loadingWheel}
                                </button>

                            </div>
                            <div className="form-group">
                            <Link to={LOGIN_URI}>Volver al login</Link>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: getIsFetching(state),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        trySendEmail: (email) => dispatch(trySendEmail(email)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordPage);