import {
  RESETPASSWORD_FORM_GET_REQUEST,
  RESETPASSWORD_FORM_GET_SUCCESS,
  RESETPASSWORD_FORM_GET_FAILTURE,
  RESETPASSWORD_FORM_EDIT_REQUEST,
  RESETPASSWORD_FORM_EDIT_SUCCESS,
  RESETPASSWORD_FORM_EDIT_FAILTURE,
  RESETPASSWORD_FORM_LOAD_REQUEST,
  RESETPASSWORD_FORM_LOAD_SUCCESS,
  RESETPASSWORD_FORM_LOAD_FAILTURE
} from "./resetPasswordActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const sendEmailRequest = (email) => ({
  type: RESETPASSWORD_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    email
  }
});

export const sendEmailSuccess = (response) => ({
  type: RESETPASSWORD_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const sendEmailFailture = (response) => ({
  type: RESETPASSWORD_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const resetPasswordRequest = (password,token) => ({
  type: RESETPASSWORD_FORM_EDIT_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    password,
    token
  }
});

export const resetPasswordSuccess = (response) => ({
  type: RESETPASSWORD_FORM_EDIT_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const resetPasswordFailture = (response) => ({
  type: RESETPASSWORD_FORM_EDIT_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const cleanCards = () => ({
  type: RESETPASSWORD_CLEAN,
  payload: {
    isFetching: false,
    hasError: false
  }
});

export const formLoadRequest = () =>
  buildAction(
    RESETPASSWORD_FORM_LOAD_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )


export const formLoadSuccess = (clientsOptions, structureTypeTemplate, entrustedTaskTypes) =>
  buildAction(
    RESETPASSWORD_FORM_LOAD_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      clientsOptions,
      structureTypeTemplate,
      entrustedTaskTypes
    }
  )

export const formLoadFailture = (response) =>
  buildAction(
    RESETPASSWORD_FORM_LOAD_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )