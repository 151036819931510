import React,{Component} from 'react';
import { Bar } from 'react-chartjs-2';


export default class BarChart extends Component {
    constructor(props) {
        super(props);
    }

  render() {
    const {data} = this.props;

    const dataOptions = {
      maintainAspectRatio:false,
      legend : { display:false },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              display: true
            },
            ticks: {
              beginAtZero: true
            }
          }          
        ]
      },
      tooltips: {
          mode: 'label',
          callbacks: {
              label: function(tooltipItem) {
                let label = tooltipItem.label || ''; 
                if (label) {
                    label += ': ';
                }
                label += tooltipItem.value;
                return label;
            }
          },
      }
  }
    return (
      <div>
        <Bar height={250} data={data} options={dataOptions} />
      </div>
    );
  }
};