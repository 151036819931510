import {
    RESPONSIBLEFORAREA_CARDS_REQUEST,
    RESPONSIBLEFORAREA_CARDS_SUCCESS,
    RESPONSIBLEFORAREA_CARDS_FAILTURE,
    RESPONSIBLEFORAREA_CARDS_CLEAN,
    RESPONSIBLEFORAREA_FILTER_SET,
    RESPONSIBLEFORAREA_PAGE_SET,
    RESPONSIBLEFORAREA_FORM_DELETE_SUCCESS,
    RESPONSIBLEFORAREA_FORM_GET_SUCCESS,
    RESPONSIBLEFORAREA_FORM_EDIT_SUCCESS,
    RESPONSIBLEFORAREA_FORM_LOAD_SUCCESS
} from "Src/redux/actions/responsibleForAreaTypes";

const initialMetadata = {
    perPage: 6,
    page: 1
};


const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: ""
    },
    metadata: initialMetadata,
    cards: [],
    ResponsibleForAreaToEdit: undefined,
    areasOptions:undefined
};

const responsibleForAreaReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESPONSIBLEFORAREA_FILTER_SET: {
            return {
                ...state,
                filter: {
                    text: action.payload.filter
                },
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case RESPONSIBLEFORAREA_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case RESPONSIBLEFORAREA_CARDS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                cards: state.cards.concat(action.payload.records),
                metadata: action.payload.metadata
            };
        }
        case RESPONSIBLEFORAREA_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case RESPONSIBLEFORAREA_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        case RESPONSIBLEFORAREA_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        case RESPONSIBLEFORAREA_FORM_DELETE_SUCCESS: {
            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload.responsibleForAreaId),
                metadata: {
                    ...state.metadata,
                    totalCount: state.metadata.totalCount - 1
                }
            };
        }
        case RESPONSIBLEFORAREA_FORM_GET_SUCCESS: {
            return {
                ...state,
                ResponsibleForAreaToEdit: action.payload.response
            };
        }
        case RESPONSIBLEFORAREA_FORM_EDIT_SUCCESS: {
            return {
                ...state,
                ResponsibleForAreaToEdit: undefined
            };
        }
        case RESPONSIBLEFORAREA_FORM_LOAD_SUCCESS: {
            let newAreasOptions = [{ key: -1, text: "Ninguno" }]
            const areasFilter = action.payload.areasOptions.filter(x => x.id < 7 && x.id > 2)
            const areasOptions = areasFilter.map(c => ({
                key: c.id,
                text: c.name
            }))

            return {
                ...state,
                areasOptions: newAreasOptions.concat(areasOptions),
            }
        }
        default: {
            return state;
        }
    }
};

export default responsibleForAreaReducer;

export const getResponsibleForAreaState = (state) => state.responsibleForAreaReducer;

export const getFilter = (state) => getResponsibleForAreaState(state).filter;

export const getIsFetching = (state) => getResponsibleForAreaState(state).isFetching;

export const getCards = (state) => getResponsibleForAreaState(state).cards;

export const getMetadata = (state) => getResponsibleForAreaState(state).metadata;

export const getResponsibleForAreaToEdit = (state) => getResponsibleForAreaState(state).ResponsibleForAreaToEdit;

export const getAreasOptions = (state) => getResponsibleForAreaState(state).areasOptions;
