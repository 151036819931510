import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    setFilter, getCardsRequest, getCardsSuccess, getCardsFailture,
    setPage, addFailture, addRequest, addSuccess,
    deleteRequest, deleteSuccess, deleteFailture,
    getRequest, getSuccess, getFailture,
    editRequest, editSuccess, editFailture, cleanCards,
    formLoadRequest, formLoadSuccess, formLoadFailture,
} from "Src/redux/actions/regionsCardsActions";

import {
    get, post,
    deleteById, put, getById
} from 'Src/services/regionsService';

import {
    get as getClients,
} from 'Src/services/clientsService';

import { REGIONS_URI, REGIONS_FORM_URI } from "Src/routes";


const regionsCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5


export const setCardsMount = () => {
    return async (dispatch, getState) => {
        const { regionsCardsReducer } = getState();
        let filterText = regionsCardsReducer.filter.text

        dispatch(setFilter(filterText));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText,
            regionsCardsReducer.metadata.perPage,
            1));

    };
};

export const setCardsFilter = (filterText) => {
    return async (dispatch, getState) => {
        const { regionsCardsReducer } = getState();

        if (filterText.length < 3
            || filterText == regionsCardsReducer.filter.text) {
                dispatch(setFilter(filterText));
            return;
        }

        dispatch(setFilter(filterText));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText,
            regionsCardsReducer.metadata.perPage,
            1));

    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, regionsCardsReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            regionsCardsReducer.filter.text,
            regionsCardsReducer.metadata.perPage,
            1));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, regionsCardsReducer } = getState();

        if (!regionsCardsReducer.metadata.hasMore) {
            return;
        }

        const nextPage = regionsCardsReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            regionsCardsReducer.filter.text,
            regionsCardsReducer.metadata.perPage,
            nextPage));

    };
};

export const tryGetCardsAction = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {
        dispatch(getCardsRequest());

        let response = await get(accessToken, filter, pageSize, page);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...regionsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las regiones. Detalle: '${response.error.message}'`
            }));

            return;
        }

        dispatch(getCardsSuccess(response));
    }
};

export const tryFormLoad = () => {
    return async (dispatch, getState) => {
        dispatch(formLoadRequest())

        const { access_token } = getState().loginReducer.authentication

        const clientsPromise = getClients(access_token, '', 1000, 1)
        
        
        const clientsResponse = await clientsPromise
        if(clientsResponse){
            const { error, code } = clientsResponse
            const hasError = error || Number.isInteger(code)

            if (hasError) {
                dispatch(formLoadFailture(clientsResponse))

                let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`

                showError(dispatch, ERROR_TITLE, message)

                return
            }
        }
        
        dispatch(formLoadSuccess(clientsResponse.records))
    }
}

export const tryAddRegion = (region) => {
    return async (dispatch, getState) => {
        dispatch(addRequest(region));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await post(access_token, region);

        if (response.code && response.code == 4) {
            dispatch(addFailture(response));
            dispatch(error({
                ...regionsCardsNotification,
                title: "Oops!",
                message: `La region ya existe.`
            }));

            return;
        }        

        if (response.code) {
            dispatch(addFailture(response));
            dispatch(error({
                ...regionsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear la region. Detalle: '${response.message}'`
            }));

            return;
        }

        if (response.hasOwnProperty("error")) {
            dispatch(addFailture(response));
            dispatch(error({
                ...regionsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear la region. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(addSuccess(response));

        region.id = response.id;

        dispatch(success({
            ...regionsCardsNotification,
            title: "Region " + region.id,
            message: `Creado exitosamente!`
        }));

        dispatch(push(REGIONS_URI));
    }
}

export const tryDeleteregion = (regionId) => {
    return async (dispatch, getState) => {
        let shouldDelete = confirm("Desea borrar la region Id: " + regionId);
        if (!shouldDelete) {
            return;
        }

        dispatch(deleteRequest(regionId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await deleteById(access_token, regionId);
        if (response.hasOwnProperty("error")) {
            dispatch(deleteFailture(response));
            dispatch(error({
                ...regionsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Borrar la region. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(deleteSuccess(regionId));

        dispatch(success({
            ...regionsCardsNotification,
            title: "Region " + regionId,
            message: `Eliminado exitosamente!`
        }));
    }
}

export const tryGetregion = (regionId) => {
    return async (dispatch, getState) => {
        if(!regionId) return;

        dispatch(getRequest(regionId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await getById(access_token, regionId);
        if (response.hasOwnProperty("error")) {
            dispatch(getFailture(response));
            dispatch(error({
                ...regionsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar la region. Detalle: '${response.error.message}'`
            }));
            return;
        }
        dispatch(getSuccess(response));

        dispatch(push(REGIONS_FORM_URI + "/" + regionId));
    }
}

export const tryEditRegion = (region) => {
    return async (dispatch, getState) => {
        dispatch(editRequest(region));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await put(access_token, region);
        if (response.hasOwnProperty("error") && response.error == true) {
            dispatch(editFailture(response));
            dispatch(error({
                ...regionsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar la region. Detalle: '${response.message}'`
            }));

            return;
        }

        dispatch(editSuccess(response));

        region.id = response.id;

        dispatch(success({
            ...regionsCardsNotification,
            title: "Region " + region.id,
            message: `Actualizado exitosamente!`
        }));

        dispatch(push(REGIONS_URI));
        dispatch(cleanCards());
        dispatch(refreshCardsFilter());
    }
}
