export const CONTACTTYPES_CARDS_REQUEST = "CONTACTTYPES_CARDS_REQUEST";
export const CONTACTTYPES_CARDS_SUCCESS = "CONTACTTYPES_CARDS_SUCCESS";
export const CONTACTTYPES_CARDS_FAILTURE = "CONTACTTYPES_CARDS_FAILTURE";
export const CONTACTTYPES_CARDS_CLEAN = "CONTACTTYPES_CARDS_CLEAN";

export const CONTACTTYPES_FILTER_SET = "CONTACTTYPES_FILTER_SET";
export const CONTACTTYPES_PAGE_SET = "CONTACTTYPES_PAGE_SET";

export const CONTACTTYPES_FORM_ADD_REQUEST = "CONTACTTYPES_FORM_ADD_REQUEST";
export const CONTACTTYPES_FORM_ADD_SUCCESS = "CONTACTTYPES_FORM_ADD_SUCCESS";
export const CONTACTTYPES_FORM_ADD_FAILTURE = "CONTACTTYPES_FORM_ADD_FAILTURE";

export const CONTACTTYPES_FORM_DELETE_REQUEST = "CONTACTTYPES_FORM_DELETE_REQUEST";
export const CONTACTTYPES_FORM_DELETE_SUCCESS = "CONTACTTYPES_FORM_DELETE_SUCCESS";
export const CONTACTTYPES_FORM_DELETE_FAILTURE = "CONTACTTYPES_FORM_DELETE_FAILTURE";

export const CONTACTTYPES_FORM_GET_REQUEST = "CONTACTTYPES_FORM_GET_REQUEST";
export const CONTACTTYPES_FORM_GET_SUCCESS = "CONTACTTYPES_FORM_GET_SUCCESS";
export const CONTACTTYPES_FORM_GET_FAILTURE = "CONTACTTYPES_FORM_GET_FAILTURE";

export const CONTACTTYPES_FORM_EDIT_REQUEST = "CONTACTTYPES_FORM_EDIT_REQUEST";
export const CONTACTTYPES_FORM_EDIT_SUCCESS = "CONTACTTYPES_FORM_EDIT_SUCCESS";
export const CONTACTTYPES_FORM_EDIT_FAILTURE = "CONTACTTYPES_FORM_EDIT_FAILTURE";