import React,{Component} from 'react';
import {
    Card, Button, Spinner,Col,Row
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { inputField } from 'Src/components/forms/inputField';
import { dropdownField } from 'Src/components/forms/dropdownField';

//TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')
//const requiredselect = value => (value ? undefined: 'Requerido!')

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}
    errors.name = required(values.name)
    return errors
}

class OperativeTaskTypeForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText,
            mode
        } = this.props;
        return (
            <Card className="shadow">
                <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Field name="name" label="Nombre" component={inputField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit" disabled={pristine || submitting}>
                                    {submitText}
                                    {submitting ? <Spinner animation="grow" size="sm" /> : null}
                                </Button>                            
                            </Col>
                            <Col className="text-right">
                                <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                                &nbsp;                            
                                <Button variant="danger" onClick={onCancel}>Cancelar</Button>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_OPERATIVETASKTYPES_NAME = 'CREATE_OPERATIVETASKTYPES_NAME';
export const EDIT_OPERATIVETASKTYPES_NAME = 'EDIT_OPERATIVETASKTYPES_NAME';

const CreateOperativeTaskTypeForm = props =>
  <OperativeTaskTypeForm {...props} mode='create' />;
  
const EditOperativeTaskTypeForm = props =>
  <OperativeTaskTypeForm {...props} mode='edit' />;

const ConnectedCreateOperativeTaskTypeForm = reduxForm({
    form: CREATE_OPERATIVETASKTYPES_NAME,
    validate
})(CreateOperativeTaskTypeForm);

const ConnectedEditOperativeTaskTypeForm = reduxForm({
    form: EDIT_OPERATIVETASKTYPES_NAME,
    validate
})(EditOperativeTaskTypeForm);

export {
    ConnectedCreateOperativeTaskTypeForm,
    ConnectedEditOperativeTaskTypeForm
}