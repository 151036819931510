import { LOGIN_REQUEST, LOGIN_FAILTURE, LOGIN_SUCCESS } from './loginActionTypes';

export const loginRequest = (username, password) => ({
  type: LOGIN_REQUEST,
  payload:{
    username,
    password
  }
});

export const loginFailture = (response) => ({
  type: LOGIN_FAILTURE,
  payload: response
});

export const loginSuccess = (response) => ({
  type: LOGIN_SUCCESS,
  payload: response
});