import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditParticularDetailsForm } from 'Src/components/particulardetails/particulardetailsForm'

import { getParticularDetailToEdit } from "Src/redux/reducers/particularDetailsReducer";

import { tryEditparticularDetail,tryGetparticularDetail } from 'Src/redux/thunk-creators/particularDetailsActionCreator';

class ParticularDetailsEditPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.tryGetparticularDetail(1);
    }

    render() {
        const { 
            onParticularDetailEdit, goBack, ParticularDetailToEdit
        } = this.props;
      
        return (
            <Container className="ParticularDetails-container">
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedEditParticularDetailsForm
                                title="Editar Detalles Particulares"
                                submitText="Editar"
                                onSubmit={ParticularDetail => onParticularDetailEdit(ParticularDetail)}
                                onCancel={goBack}
                                initialValues={ParticularDetailToEdit}
                            />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ParticularDetailToEdit: getParticularDetailToEdit(state)
    };
}

const mapDispatchToProps = dispatch => ({
    onParticularDetailEdit: (ParticularDetail) => dispatch(tryEditparticularDetail(ParticularDetail)),
    goBack: () => dispatch(goBack()),
    tryGetparticularDetail: () => dispatch(tryGetparticularDetail(1))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ParticularDetailsEditPage);