import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILTURE, LOGOUT } from "Src/redux/actions/loginActionTypes";
import { RESETPASSWORD_FORM_GET_FAILTURE, RESETPASSWORD_FORM_GET_REQUEST, RESETPASSWORD_FORM_GET_SUCCESS } from "Src/redux/actions/resetPasswordActionTypes";

const initialState = {
  hasError: false,
  username: "test",
  password: "test",
  isFetching: false,
  isLoggedIn: false,
  authentication: undefined
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      const { username, password } = action.payload;
      return {
        ...state,
        isFetching: true,
        isLoggedIn: false,
        username,
        password
      };
    }
    case LOGIN_SUCCESS: {
      const isLoggedIn = (action && action.payload && !action.payload.hasOwnProperty("error"));
      return {
        ...state,
        isFetching: false,
        password: undefined,
        isLoggedIn: isLoggedIn,
        authentication: {...action.payload}
      };
    }
    case LOGIN_FAILTURE: {
      return { 
        ...state, 
        isFetching: false,
        isLoggedIn: false,
        password: undefined,
        authentication: {...action.payload}
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isFetching: false,
        isLoggedIn: false,
        password: false,
        authentication: undefined
      }
    }

    case RESETPASSWORD_FORM_GET_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case RESETPASSWORD_FORM_GET_SUCCESS:
    case RESETPASSWORD_FORM_GET_FAILTURE: {
      return { 
        ...state, 
        isFetching: false
      };
    }
    default: {
      return state;
    }
  }
};

export default loginReducer;

export const getLoginReducer = (state) => state.loginReducer;

export const getIsLoggedIn = (state) => getLoginReducer(state).isLoggedIn;

export const getIsFetching = (state) => getLoginReducer(state).isFetching;

export const getUsername = (state) => getLoginReducer(state).username;

export const getUserProfile = (state) => {
  if(getLoginReducer(state).authentication)
    return getLoginReducer(state).authentication.profile_name;
}

export const getUserDisplayName = (state) => {
  if(getLoginReducer(state).authentication)
    return getLoginReducer(state).authentication.username;

};

export const getUserPermissions = (state) => {
  if(getLoginReducer(state).authentication)
    return getLoginReducer(state).authentication.permissions;
}