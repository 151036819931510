export const OPERATIVETASKTYPES_CARDS_REQUEST = "OPERATIVETASKTYPES_CARDS_REQUEST";
export const OPERATIVETASKTYPES_CARDS_SUCCESS = "OPERATIVETASKTYPES_CARDS_SUCCESS";
export const OPERATIVETASKTYPES_CARDS_FAILTURE = "OPERATIVETASKTYPES_CARDS_FAILTURE";
export const OPERATIVETASKTYPES_CARDS_CLEAN = "OPERATIVETASKTYPES_CARDS_CLEAN";

export const OPERATIVETASKTYPES_FILTER_SET = "OPERATIVETASKTYPES_FILTER_SET";
export const OPERATIVETASKTYPES_PAGE_SET = "OPERATIVETASKTYPES_PAGE_SET";

export const OPERATIVETASKTYPES_FORM_ADD_REQUEST = "OPERATIVETASKTYPES_FORM_ADD_REQUEST";
export const OPERATIVETASKTYPES_FORM_ADD_SUCCESS = "OPERATIVETASKTYPES_FORM_ADD_SUCCESS";
export const OPERATIVETASKTYPES_FORM_ADD_FAILTURE = "OPERATIVETASKTYPES_FORM_ADD_FAILTURE";

export const OPERATIVETASKTYPES_FORM_DELETE_REQUEST = "OPERATIVETASKTYPES_FORM_DELETE_REQUEST";
export const OPERATIVETASKTYPES_FORM_DELETE_SUCCESS = "OPERATIVETASKTYPES_FORM_DELETE_SUCCESS";
export const OPERATIVETASKTYPES_FORM_DELETE_FAILTURE = "OPERATIVETASKTYPES_FORM_DELETE_FAILTURE";

export const OPERATIVETASKTYPES_FORM_GET_REQUEST = "OPERATIVETASKTYPES_FORM_GET_REQUEST";
export const OPERATIVETASKTYPES_FORM_GET_SUCCESS = "OPERATIVETASKTYPES_FORM_GET_SUCCESS";
export const OPERATIVETASKTYPES_FORM_GET_FAILTURE = "OPERATIVETASKTYPES_FORM_GET_FAILTURE";

export const OPERATIVETASKTYPES_FORM_EDIT_REQUEST = "OPERATIVETASKTYPES_FORM_EDIT_REQUEST";
export const OPERATIVETASKTYPES_FORM_EDIT_SUCCESS = "OPERATIVETASKTYPES_FORM_EDIT_SUCCESS";
export const OPERATIVETASKTYPES_FORM_EDIT_FAILTURE = "OPERATIVETASKTYPES_FORM_EDIT_FAILTURE";

export const OPERATIVETASKTYPES_FORM_LOAD_REQUEST = "OPERATIVETASKTYPES_FORM_LOAD_REQUEST";
export const OPERATIVETASKTYPES_FORM_LOAD_SUCCESS = "OPERATIVETASKTYPES_FORM_LOAD_SUCCESS";
export const OPERATIVETASKTYPES_FORM_LOAD_FAILTURE = "OPERATIVETASKTYPES_FORM_LOAD_FAILTURE";