import {
    CONTACTTYPES_CARDS_REQUEST,
    CONTACTTYPES_CARDS_SUCCESS,
    CONTACTTYPES_CARDS_FAILTURE,
    CONTACTTYPES_CARDS_CLEAN,
    CONTACTTYPES_FILTER_SET,
    CONTACTTYPES_PAGE_SET,
    CONTACTTYPES_FORM_DELETE_SUCCESS,
    CONTACTTYPES_FORM_GET_SUCCESS,
    CONTACTTYPES_FORM_EDIT_SUCCESS
} from "Src/redux/actions/contactTypesCardsActionTypes";

const initialMetadata = {
    perPage: 6,
    page: 1
};

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: ""
    },
    metadata: initialMetadata,
    cards: [],
    contactTypeToEdit: undefined
};

const contactTypeCardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTACTTYPES_FILTER_SET: {
            return {
                ...state,
                filter: {
                    text: action.payload.filter
                },
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case CONTACTTYPES_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case CONTACTTYPES_CARDS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                cards: state.cards.concat(action.payload.records),
                metadata: action.payload.metadata
            };
        }
        case CONTACTTYPES_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case CONTACTTYPES_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        case CONTACTTYPES_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        case CONTACTTYPES_FORM_DELETE_SUCCESS: {
            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload.response.id),
                metadata: {
                    ...state.metadata,
                    totalCount: state.metadata.totalCount - 1
                }
            };
        }
        case CONTACTTYPES_FORM_GET_SUCCESS: {
            return {
                ...state,
                contactTypeToEdit: action.payload.response
            };
        }
        case CONTACTTYPES_FORM_EDIT_SUCCESS: {
            return {
                ...state,
                contactTypeToEdit: undefined
            };
        }
        default: {
            return state;
        }
    }
};

export default contactTypeCardsReducer;

export const getContactTypesCardsState = (state) => state.contactTypesCardsReducer;

export const getFilter = (state) => getContactTypesCardsState(state).filter;

export const getIsFetching = (state) => getContactTypesCardsState(state).isFetching;

export const getCards = (state) => getContactTypesCardsState(state).cards;

export const getMetadata = (state) => getContactTypesCardsState(state).metadata;

export const getContactTypeToEdit = (state) => getContactTypesCardsState(state).contactTypeToEdit;