import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditStructureTypeForm } from 'Src/components/structureTypes/structureTypeForm'

import { getStructureTypeToEdit,getStatusOption } from "Src/redux/reducers/structureTypesReducer";

import { tryEditStructureType } from 'Src/redux/thunk-creators/structureTypesActionCreator';

class StructureTypesEditPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { 
            onStructureTypeEdit, goBack, StructureTypeToEdit,statusOption
        } = this.props;
      
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedEditStructureTypeForm
                                title="Editar tipo de estructura"
                                submitText="Editar"
                                onSubmit={structureType => onStructureTypeEdit(structureType)}
                                statusOption={statusOption}
                                onCancel={goBack}
                                initialValues={StructureTypeToEdit}
                            />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        StructureTypeToEdit: getStructureTypeToEdit(state),
        statusOption: getStatusOption(state),
    };
}

const mapDispatchToProps = dispatch => ({
    onStructureTypeEdit: (structureType) => dispatch(tryEditStructureType(structureType)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StructureTypesEditPage);
