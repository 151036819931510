import {
    CONTACTTYPES_CARDS_REQUEST,
    CONTACTTYPES_CARDS_SUCCESS,
    CONTACTTYPES_CARDS_FAILTURE,
    CONTACTTYPES_CARDS_CLEAN,
    CONTACTTYPES_FILTER_SET,
    CONTACTTYPES_PAGE_SET,
    CONTACTTYPES_FORM_ADD_REQUEST,
    CONTACTTYPES_FORM_ADD_SUCCESS,
    CONTACTTYPES_FORM_ADD_FAILTURE,
    CONTACTTYPES_FORM_DELETE_REQUEST,
    CONTACTTYPES_FORM_DELETE_SUCCESS,
    CONTACTTYPES_FORM_DELETE_FAILTURE,
    CONTACTTYPES_FORM_GET_REQUEST,
    CONTACTTYPES_FORM_GET_SUCCESS,
    CONTACTTYPES_FORM_GET_FAILTURE,
    CONTACTTYPES_FORM_EDIT_REQUEST,
    CONTACTTYPES_FORM_EDIT_SUCCESS,
    CONTACTTYPES_FORM_EDIT_FAILTURE
  } from "./contactTypesCardsActionTypes";
  
  export const setFilter = (filter) => ({
    type: CONTACTTYPES_FILTER_SET,
    payload: {
      filter
    }
  });
  
  export const getCardsRequest = () => ({
    type: CONTACTTYPES_CARDS_REQUEST,
    payload: {
      isFetching: true,
      hasError: false
    }
  });
  
  export const getCardsSuccess = (response) => ({
    type: CONTACTTYPES_CARDS_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      records: response.records,
      metadata: response.metadata
    }
  });
  
  export const getCardsFailture = (response) => ({
    type: CONTACTTYPES_CARDS_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });
  
  export const setPage = (page) => ({
    type: CONTACTTYPES_PAGE_SET,
    payload: {
      page
    }
  });
  
  export const addRequest = (contactType) => ({
    type: CONTACTTYPES_FORM_ADD_REQUEST,
    payload: {
      isFetching: true,
      hasError: false,
      contactType
    }
  });
  
  export const addSuccess = (response) => ({
    type: CONTACTTYPES_FORM_ADD_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      response
    }
  });
  
  export const addFailture = (response) => ({
    type: CONTACTTYPES_FORM_ADD_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });
  
  export const deleteRequest = (contactTypeId) => ({
    type: CONTACTTYPES_FORM_DELETE_REQUEST,
    payload: {
      isFetching: true,
      hasError: false,
      contactTypeId
    }
  });
  
  export const deleteSuccess = (response) => ({
    type: CONTACTTYPES_FORM_DELETE_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      response
    }
  });
  
  export const deleteFailture = (response) => ({
    type: CONTACTTYPES_FORM_DELETE_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });
  
  export const getRequest = (contactTypeId) => ({
    type: CONTACTTYPES_FORM_GET_REQUEST,
    payload: {
      isFetching: true,
      hasError: false,
      contactTypeId
    }
  });
  
  export const getSuccess = (response) => ({
    type: CONTACTTYPES_FORM_GET_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      response
    }
  });
  
  export const getFailture = (response) => ({
    type: CONTACTTYPES_FORM_GET_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });
  
  export const editRequest = (contactTypeId) => ({
    type: CONTACTTYPES_FORM_EDIT_REQUEST,
    payload: {
      isFetching: true,
      hasError: false,
      contactTypeId
    }
  });
  
  export const editSuccess = (response) => ({
    type: CONTACTTYPES_FORM_EDIT_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      response
    }
  });
  
  export const editFailture = (response) => ({
    type: CONTACTTYPES_FORM_EDIT_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });
  
  export const cleanCards = () => ({
    type: CONTACTTYPES_CARDS_CLEAN,
    payload: {
      isFetching: false,
      hasError: false
    }
  });