import * as actionTypes from "Src/redux/actions/workOrderTrackingCardsActionTypes";

const initialMetadata = {
    perPage: 12,
    page: 1
};

const optionsStatus = [
    { key: '0', text: "Todas" },
    { key: '1', text: "En Curso" },
    { key: '2', text: "Finalizadas" },
    { key: '3', text: "Suspendidas" },
    { key: '4', text: "Canceladas" }];

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: ""
    },
    metadata: initialMetadata,
    cards: [],
    optionsStatus:optionsStatus
};

const workOrderTrackingCardsReducer = (state = initialState, action) => {
    //HINT SUPER SIMILAR THAN CARDS
    //TODO: CAN BE SHARE CODE ???
    switch (action.type) {
        case actionTypes.WORKORDERSTRACKING_FILTER_SET: {
            return {
                ...state,
                filter: {
                    text: action.payload.filter,
                    statusWorkOrder: action.payload.statusWorkOrder
                },
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case actionTypes.WORKORDERSTRACKING_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case actionTypes.WORKORDERSTRACKING_CARDS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                cards: state.cards.concat(action.payload.records),
                metadata: action.payload.metadata
            };
        }
        case actionTypes.WORKORDERSTRACKING_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case actionTypes.WORKORDERSTRACKING_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        default: {
            return state;
        }
    }
};

export default workOrderTrackingCardsReducer;

export const getWorkOrdersTrackingCardsState = (state) => state.workOrderTrackingCardsReducer;

export const getFilter = (state) => getWorkOrdersTrackingCardsState(state).filter;

export const getIsFetching = (state) => getWorkOrdersTrackingCardsState(state).isFetching;

export const getCards = (state) => getWorkOrdersTrackingCardsState(state).cards;

export const getMetadata = (state) => getWorkOrdersTrackingCardsState(state).metadata;

export const getOptionsStatus = (state) => getWorkOrdersTrackingCardsState(state).optionsStatus;



