import config from 'Config';

import { buildDefaultOptions } from './authService';
import {
    httpGet, httpPost, httpPut, httpDelete,
    handleHttpError, createCancelation, isCancel, handleCancelation
} from './httpService';

const API_ENDPOINT = `${config.apis.coreApi.URL}/api`
const ENTITY_URI = `${API_ENDPOINT}/contacttypes`;
const buildUriById = (id) => `${ENTITY_URI}/${id}`;

let get_Cancelation = undefined;
export const get = async (accessToken, filterText, pageSize, page) => {
    if (get_Cancelation) get_Cancelation.cancel()
    get_Cancelation = createCancelation()

    let uri = `${ENTITY_URI}?page=${page}&pageSize=${pageSize}`;

    if (filterText.lenght > 3) {
        uri += `&textFilter=${filterText}`
    }

    let options = buildDefaultOptions(accessToken)
    options.cancelToken = get_Cancelation.token

    try {
        let response = await httpGet(uri, options);
        return response.data;
    }
    catch (error) {
        if (isCancel(error)) return handleCancelation(error)

        return handleHttpError(error);
    }
}

export const addContactType = async (accessToken, contactType) => {
    let options = buildDefaultOptions(accessToken)
    options.headers['Content-Type'] = 'application/json';

    const params = {
        "Description": contactType.description
    }

    try {
        let response = await httpPost(ENTITY_URI, params, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const deleteContactType = async (accessToken, contactTypeId) => {
    const URL = buildUriById(contactTypeId);

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpDelete(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const getContactType = async (accessToken, contactTypeId) => {
    const URL = buildUriById(contactTypeId);

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpGet(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const editContactType = async (accessToken, contactType) => {
    const URL = buildUriById(contactType.id);

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpPut(URL, contactType, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

