import {
  REGIONS_CARDS_REQUEST,
  REGIONS_CARDS_SUCCESS,
  REGIONS_CARDS_FAILTURE,
  REGIONS_CARDS_CLEAN,
  REGIONS_FILTER_SET,
  REGIONS_PAGE_SET,
  REGIONS_FORM_ADD_REQUEST,
  REGIONS_FORM_ADD_SUCCESS,
  REGIONS_FORM_ADD_FAILTURE,
  REGIONS_FORM_DELETE_REQUEST,
  REGIONS_FORM_DELETE_SUCCESS,
  REGIONS_FORM_DELETE_FAILTURE,
  REGIONS_FORM_GET_REQUEST,
  REGIONS_FORM_GET_SUCCESS,
  REGIONS_FORM_GET_FAILTURE,
  REGIONS_FORM_EDIT_REQUEST,
  REGIONS_FORM_EDIT_SUCCESS,
  REGIONS_FORM_EDIT_FAILTURE,
  REGIONS_FORM_LOAD_CLIENT_CHILDRENS_REQUEST, 
  REGIONS_FORM_LOAD_CLIENT_CHILDRENS_SUCCESS, 
  REGIONS_FORM_LOAD_CLIENT_CHILDRENS_FAILTURE,
  REGIONS_FORM_LOAD_REQUEST, 
  REGIONS_FORM_LOAD_SUCCESS, 
  REGIONS_FORM_LOAD_FAILTURE,
} from "./regionsCardsActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const setFilter = (filter) => ({
  type: REGIONS_FILTER_SET,
  payload: {
    filter
  }
});

export const getCardsRequest = () => ({
  type: REGIONS_CARDS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getCardsSuccess = (response) => ({
  type: REGIONS_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response.records,
    metadata: response.metadata
  }
});

export const getCardsFailture = (response) => ({
  type: REGIONS_CARDS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const setPage = (page) => ({
  type: REGIONS_PAGE_SET,
  payload: {
    page
  }
});

export const addRequest = (region) => ({
  type: REGIONS_FORM_ADD_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    region
  }
});

export const addSuccess = (response) => ({
  type: REGIONS_FORM_ADD_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const addFailture = (response) => ({
  type: REGIONS_FORM_ADD_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const deleteRequest = (regionId) => ({
  type: REGIONS_FORM_DELETE_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    regionId
  }
});

export const deleteSuccess = (regionId) => ({
  type: REGIONS_FORM_DELETE_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    regionId
  }
});

export const deleteFailture = (response) => ({
  type: REGIONS_FORM_DELETE_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const getRequest = (regionId) => ({
  type: REGIONS_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    regionId
  }
});

export const getSuccess = (response) => ({
  type: REGIONS_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const getFailture = (response) => ({
  type: REGIONS_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const editRequest = (regionId) => ({
  type: REGIONS_FORM_EDIT_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    regionId
  }
});

export const editSuccess = (response) => ({
  type: REGIONS_FORM_EDIT_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const editFailture = (response) => ({
  type: REGIONS_FORM_EDIT_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const cleanCards = () => ({
  type: REGIONS_CARDS_CLEAN,
  payload: {
    isFetching: false,
    hasError: false
  }
});

  export const formLoadRequest = () =>
  buildAction(
    REGIONS_FORM_LOAD_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )



export const formLoadSuccess = (clientsOptions, structureTypeTemplate, entrustedTaskTypes) =>
  buildAction(
    REGIONS_FORM_LOAD_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      clientsOptions,
      structureTypeTemplate,
      entrustedTaskTypes
    }
  )

export const formLoadFailture = (response) =>
  buildAction(
    REGIONS_FORM_LOAD_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )