import React, { Suspense,Component } from 'react';
import { connect } from 'react-redux';
import {
    Container, Row, Col,
    Card, Button, Spinner
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import LoadingCard from 'Src/components/loadingCard';
import { USERS_FORM_URI } from 'Src/routes';

import { getMetadata, getIsFetching, getFilter } from "Src/redux/reducers/usersCardsReducer";
import { setCardsFilter } from 'Src/redux/thunk-creators/usersCardsActionCreator';

const UsersCards = React.lazy(() =>
    import('Src/components/users/usersCards'));

const CardsFilter = React.lazy(() =>
    import('Src/components/cards/cardsFilter'));

const CardsMetadata = React.lazy(() =>
    import('Src/components/cards/cardsMetadata'));

class UsersPage extends Component {
    constructor(props) {
        super(props);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }    

    handleFilterChange(event) {
        let filterText = event.target.value;
        this.props.setCardsFilter(filterText);
    }

    render() {
        
        const { metadata, filter } = this.props;

        return (
            <Container className="users-container">
                <Row>
                    <Col sm={12}>
                    <Card className="shadow">
                    <Card.Header><h5>Usuarios</h5></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={1}>
                                    <Link to={USERS_FORM_URI}>
                                        <Button variant="primary">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Card.Body>   
                    </Card>
                    </Col>
                </Row>
                <Row>
                <Col sm={12}>
                    <Suspense fallback={<LoadingCard />}>
                        <UsersCards />
                    </Suspense>
                </Col>
                </Row>
            </Container>
        );
    }
}


const mapStateToProps = state => {
    return {
        metadata: getMetadata(state),
        isFetching: getIsFetching(state),
        filter: getFilter(state)
    };
}


const mapDispatchToProps = dispatch => {
    return {
        setCardsFilter: (filter) => dispatch(setCardsFilter(filter))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersPage);