export const AREAS_CARDS_REQUEST = "AREAS_CARDS_REQUEST";
export const AREAS_CARDS_SUCCESS = "AREAS_CARDS_SUCCESS";
export const AREAS_CARDS_FAILTURE = "AREAS_CARDS_FAILTURE";
export const AREAS_CARDS_CLEAN = "AREAS_CARDS_CLEAN";

export const AREAS_FILTER_SET = "AREAS_FILTER_SET";
export const AREAS_PAGE_SET = "AREAS_PAGE_SET";

export const AREAS_FORM_ADD_REQUEST = "AREAS_FORM_ADD_REQUEST";
export const AREAS_FORM_ADD_SUCCESS = "AREAS_FORM_ADD_SUCCESS";
export const AREAS_FORM_ADD_FAILTURE = "AREAS_FORM_ADD_FAILTURE";

export const AREAS_FORM_DELETE_REQUEST = "AREAS_FORM_DELETE_REQUEST";
export const AREAS_FORM_DELETE_SUCCESS = "AREAS_FORM_DELETE_SUCCESS";
export const AREAS_FORM_DELETE_FAILTURE = "AREAS_FORM_DELETE_FAILTURE";

export const AREAS_FORM_GET_REQUEST = "AREAS_FORM_GET_REQUEST";
export const AREAS_FORM_GET_SUCCESS = "AREAS_FORM_GET_SUCCESS";
export const AREAS_FORM_GET_FAILTURE = "AREAS_FORM_GET_FAILTURE";

export const AREAS_FORM_EDIT_REQUEST = "AREAS_FORM_EDIT_REQUEST";
export const AREAS_FORM_EDIT_SUCCESS = "AREAS_FORM_EDIT_SUCCESS";
export const AREAS_FORM_EDIT_FAILTURE = "AREAS_FORM_EDIT_FAILTURE";

export const AREAS_FORM_LOAD_REQUEST = "AREAS_FORM_LOAD_REQUEST";
export const AREAS_FORM_LOAD_SUCCESS = "AREAS_FORM_LOAD_SUCCESS";
export const AREAS_FORM_LOAD_FAILTURE = "AREAS_FORM_LOAD_FAILTURE";