import {
  STRUCTURETYPES_CARDS_REQUEST,
  STRUCTURETYPES_CARDS_SUCCESS,
  STRUCTURETYPES_CARDS_FAILTURE,
  STRUCTURETYPES_CARDS_CLEAN,
  STRUCTURETYPES_FILTER_SET,
  STRUCTURETYPES_PAGE_SET,
  STRUCTURETYPES_FORM_ADD_REQUEST,
  STRUCTURETYPES_FORM_ADD_SUCCESS,
  STRUCTURETYPES_FORM_ADD_FAILTURE,
  STRUCTURETYPES_FORM_DELETE_REQUEST,
  STRUCTURETYPES_FORM_DELETE_SUCCESS,
  STRUCTURETYPES_FORM_DELETE_FAILTURE,
  STRUCTURETYPES_FORM_GET_REQUEST,
  STRUCTURETYPES_FORM_GET_SUCCESS,
  STRUCTURETYPES_FORM_GET_FAILTURE,
  STRUCTURETYPES_FORM_EDIT_REQUEST,
  STRUCTURETYPES_FORM_EDIT_SUCCESS,
  STRUCTURETYPES_FORM_EDIT_FAILTURE,
  STRUCTURETYPES_FORM_LOAD_REQUEST, 
  STRUCTURETYPES_FORM_LOAD_SUCCESS, 
  STRUCTURETYPES_FORM_LOAD_FAILTURE
} from "./structureTypesActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const setFilter = (filter) => ({
  type: STRUCTURETYPES_FILTER_SET,
  payload: {
    filter
  }
});


export const getRequest = (structureTypeId) => ({
  type: STRUCTURETYPES_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    structureTypeId
  }
});

export const getSuccess = (response) => ({
  type: STRUCTURETYPES_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const getFailture = (response) => ({
  type: STRUCTURETYPES_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});


export const addRequest = (structureType) => ({
  type: STRUCTURETYPES_FORM_ADD_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    structureType
  }
});

export const addSuccess = (response) => ({
  type: STRUCTURETYPES_FORM_ADD_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const addFailture = (response) => ({
  type: STRUCTURETYPES_FORM_ADD_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const editRequest = (structureTypeId) => ({
  type: STRUCTURETYPES_FORM_EDIT_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    structureTypeId
  }
});

export const editSuccess = (response) => ({
  type: STRUCTURETYPES_FORM_EDIT_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const editFailture = (response) => ({
  type: STRUCTURETYPES_FORM_EDIT_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const deleteRequest = (structureTypeId) => ({
  type: STRUCTURETYPES_FORM_DELETE_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    structureTypeId
  }
});

export const deleteSuccess = (structureTypeId) => ({
  type: STRUCTURETYPES_FORM_DELETE_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    structureTypeId
  }
});

export const deleteFailture = (response) => ({
  type: STRUCTURETYPES_FORM_DELETE_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const getCardsRequest = () => ({
  type: STRUCTURETYPES_CARDS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getCardsSuccess = (response) => ({
  type: STRUCTURETYPES_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response.records,
    metadata: response.metadata
  }
});

export const getCardsFailture = (response) => ({
  type: STRUCTURETYPES_CARDS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const setPage = (page) => ({
  type: STRUCTURETYPES_PAGE_SET,
  payload: {
    page
  }
});


export const formLoadRequest = () =>({
  type: STRUCTURETYPES_FORM_LOAD_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
})



export const formLoadSuccess = (structureTypeOption) => ({
  type: STRUCTURETYPES_FORM_LOAD_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    structureTypeOption,
  }
})

export const formLoadFailture = (response) =>({
  type: STRUCTURETYPES_FORM_LOAD_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
})