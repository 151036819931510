import {
    TIMELINES_CARDS_REQUEST,
    TIMELINES_CARDS_SUCCESS,
    TIMELINES_CARDS_FAILTURE,
    TIMELINES_CARDS_CLEAN,
    TIMELINES_FILTER_SET,
    TIMELINES_PAGE_SET,
    TIMELINES_CARDS_HEADER_SET,
    TIMELINES_TASKXAREA_CARDS_SUCCESS 
} from "Src/redux/actions/timelinesCardsActionTypes";

import {
    inputformatDate
} from 'Src/services/formatService';

const initialMetadata = {
    perPage: 6,
    page: 1
};

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: ""
    },
    metadata: initialMetadata,
    metadataTranckingTask:{
        client:undefined,
        structure:undefined,
        orderDate:undefined,
        WorkOrderId:undefined,
        stages:undefined,
        totalDays:undefined,
    },
    cards: [],
    optionAutocomplite: [],
    selectedArea: 0
    
};

const timelineCardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TIMELINES_CARDS_HEADER_SET: {
            const {
                workOrderId,client,structureType,
                orderDate,advancePercentage,states,
                tasksXAreas,totalDays,startDateReal,
                endDateReal
            } = action.payload.cardData
            const startDateRealFormat = startDateReal?inputformatDate(startDateReal):null;
            const endDateRealFormat = endDateReal?inputformatDate(endDateReal):null;
            return {
                ...state,
                filter: {
                    WorkOrderId:workOrderId
                },
                metadataTranckingTask:{
                    client:client,
                    structure:structureType,
                    orderDate:inputformatDate(orderDate),
                    WorkOrderId:workOrderId,
                    advancePercentage,
                    stages:states,
                    tasksXAreas,
                    totalDays,
                    startDateReal:startDateRealFormat,
                    endDateReal:endDateRealFormat
                },                
                cards: [],
                optionAutocomplite: [],
                metadata: { ...initialMetadata },
                isFetching: false,
            }
        }
        case TIMELINES_FILTER_SET: {
            return {
                ...state,
                filter: {
                    text: action.payload.filter
                },
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case TIMELINES_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case TIMELINES_CARDS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                //cards: state.cards.concat(action.payload.records)                
            };
        }
        case TIMELINES_TASKXAREA_CARDS_SUCCESS: {
            const {records,AreaId}=action.payload;
            const {tasksXAreas}=records;
            const result = tasksXAreas.filter(tXa => tXa.areaId == AreaId);
            return {
                ...state,
                isFetching: false,
                cards: result[0].tasks
            };
        }
        case TIMELINES_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case TIMELINES_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        case TIMELINES_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        default: {
            return state;
        }
    }
};

export default timelineCardsReducer;

export const gettimelinesCardsState = (state) => state.timelineCardsReducer;

export const getFilter = (state) => gettimelinesCardsState(state).filter;

export const getIsFetching = (state) => gettimelinesCardsState(state).isFetching;

export const getCards = (state) => gettimelinesCardsState(state).cards;

export const getMetadata = (state) => gettimelinesCardsState(state).metadata;

export const getMetadataTranckingTask = (state) => gettimelinesCardsState(state).metadataTranckingTask;

export const getAutocomplete = (state) => gettimelinesCardsState(state).optionAutocomplite;

export const getSelectedArea = (state) => gettimelinesCardsState(state).selectedArea;