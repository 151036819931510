import React, { Suspense,Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    Container, Row, Col,
    Card, Spinner, Form,
    Breadcrumb
} from 'react-bootstrap';

import LoadingCard from 'Src/components/loadingCard';

import {  
        getIsFetching,getFilter,
        getMetadataTranckingTask,
        getMetadata,getAutocomplete 
    } from "Src/redux/reducers/entrustedTasksCardsReducer";
import { setAutocompleteFilter,setShowCards,sendToTaskEntrustedCards } from 'Src/redux/thunk-creators/entrustedTasksCardsActionCreator';

import { WORKORDERSTRACKING_URI  } from "Src/routes";

const CardsMetadata = React.lazy(() =>
    import('Src/components/cards/cardsMetadata'));

const TaskEntrustedCards = React.lazy(() =>
    import('Src/components/tasksEntrusted/taskEntrustedCards'));

const Autocomplete = React.lazy(() =>
    import('Src/components/autocomplete'));

class EntrustedTasksPage extends Component {
    constructor(props) {
        super(props);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);       
    }

    componentDidMount() {
        this.props.setShowCards(this.props.filter.WorkOrderId)
    }

    
    handleFilterChange(filterText) {
        this.props.setAutocompleteFilter(filterText);
    }

    render() {
        const {  filter,metadataTranckingTask,metadata,itemsAutocomplite,tasksEntrustedCards,goBackToWorkTracking }= this.props;
        
        return (
            <Container className="workorders-container">
                <Row>
                    <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#" onClick={goBackToWorkTracking}>
                            Seguimiento de Ordenes de Trabajo
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Tareas Encomendadas</Breadcrumb.Item>
                    </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card className="shadow">
                            <Card.Header><h5>Tareas Encomendadas</h5></Card.Header>
                            <Card.Body>
                                    <Row>
                                        <Form.Label  column sm={2}>Ordenes de trabajo </Form.Label>                                         
                                        <Col>
                                           <Suspense fallback={<Spinner animation="grow"/>}>
                                               <Autocomplete
                                                    handleChange={this.handleFilterChange}
                                                    SelectChange={tasksEntrustedCards}
                                                    filterText={filter.text}
                                                    items={itemsAutocomplite} />
                                            </Suspense>     
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={10}>
                                            <Row>
                                                <Form.Label column sm={2}>N° de OT: {metadataTranckingTask.WorkOrderId}</Form.Label> 
                                                <Form.Label title={metadataTranckingTask.client.comertialName} className="text-truncate" column sm={4}>Cliente: {metadataTranckingTask.client.comertialName}</Form.Label>
                                                <Form.Label column sm={3}>Estructura :{metadataTranckingTask.structure}</Form.Label> 
                                                <Form.Label column sm={3}>Fecha de pedido: {metadataTranckingTask.orderDate}</Form.Label>  
                                            </Row>  
                                        </Col>
                                        <Col sm={2}>
                                            <Suspense fallback={<Spinner animation="grow"/>}>
                                                <CardsMetadata metadata={metadata} />
                                            </Suspense>
                                        </Col>                                        
                                    </Row>
                                </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />}>
                            <TaskEntrustedCards onTasksEntrusted={true} />
                        </Suspense>
                    </Col>
                </Row>
            </Container>
        );
    }
}


const mapStateToProps = state => {
    return {
        isFetching: getIsFetching(state),
        filter: getFilter(state),
        metadataTranckingTask: getMetadataTranckingTask(state),
        metadata: getMetadata(state),
        itemsAutocomplite: getAutocomplete(state)
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setAutocompleteFilter: (filterText) => dispatch(setAutocompleteFilter(filterText)),
        setShowCards:(filterWorkOrderId) => dispatch(setShowCards(filterWorkOrderId)),
        tasksEntrustedCards: (id) => dispatch(sendToTaskEntrustedCards(id)),
        goBackToWorkTracking:() => dispatch(push(WORKORDERSTRACKING_URI)),
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EntrustedTasksPage);