import config from 'Config';

import { 
    get as _get, 
    post as _post, 
    getById as _getById } from 'Src/services/entityService'

const API_ENDPOINT = `${config.apis.analiticsApi.URL}/api/analytics`
const ENTITY_URI = `${API_ENDPOINT}/dashboardmap`;
const ENTITY_CHART_PIE_URI = `${API_ENDPOINT}/charts/operativestates`;
const ENTITY_CHART_BAR_URI = `${API_ENDPOINT}/charts/states`;

let get_Cancelation = undefined;
export const get = async (accessToken, filterText, pageSize, page,options) => 
                        _get(accessToken, ENTITY_URI, get_Cancelation, filterText, pageSize, page,options)

export const getById = async (accessToken, id) => 
                        _getById(accessToken, ENTITY_URI, id)

export const getChartPie = async (accessToken, filterText, pageSize, page,options) => 
                        _get(accessToken, ENTITY_CHART_PIE_URI, get_Cancelation, filterText, pageSize, page,options)

export const getChartBar = async (accessToken, filterText, pageSize, page,options) => 
                        _get(accessToken, ENTITY_CHART_BAR_URI, get_Cancelation, filterText, pageSize, page,options)
