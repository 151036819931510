export const REGIONS_CARDS_REQUEST = "REGIONS_CARDS_REQUEST";
export const REGIONS_CARDS_SUCCESS = "REGIONS_CARDS_SUCCESS";
export const REGIONS_CARDS_FAILTURE = "REGIONS_CARDS_FAILTURE";
export const REGIONS_CARDS_CLEAN = "REGIONS_CARDS_CLEAN";

export const REGIONS_FILTER_SET = "REGIONS_FILTER_SET";
export const REGIONS_PAGE_SET = "REGIONS_PAGE_SET";

export const REGIONS_FORM_ADD_REQUEST = "REGIONS_FORM_ADD_REQUEST";
export const REGIONS_FORM_ADD_SUCCESS = "REGIONS_FORM_ADD_SUCCESS";
export const REGIONS_FORM_ADD_FAILTURE = "REGIONS_FORM_ADD_FAILTURE";

export const REGIONS_FORM_DELETE_REQUEST = "REGIONS_FORM_DELETE_REQUEST";
export const REGIONS_FORM_DELETE_SUCCESS = "REGIONS_FORM_DELETE_SUCCESS";
export const REGIONS_FORM_DELETE_FAILTURE = "REGIONS_FORM_DELETE_FAILTURE";

export const REGIONS_FORM_GET_REQUEST = "REGIONS_FORM_GET_REQUEST";
export const REGIONS_FORM_GET_SUCCESS = "REGIONS_FORM_GET_SUCCESS";
export const REGIONS_FORM_GET_FAILTURE = "REGIONS_FORM_GET_FAILTURE";

export const REGIONS_FORM_EDIT_REQUEST = "REGIONS_FORM_EDIT_REQUEST";
export const REGIONS_FORM_EDIT_SUCCESS = "REGIONS_FORM_EDIT_SUCCESS";
export const REGIONS_FORM_EDIT_FAILTURE = "REGIONS_FORM_EDIT_FAILTURE";

export const REGIONS_FORM_LOAD_REQUEST = "REGIONS_FORM_LOAD_REQUEST";
export const REGIONS_FORM_LOAD_SUCCESS = "REGIONS_FORM_LOAD_SUCCESS";
export const REGIONS_FORM_LOAD_FAILTURE = "REGIONS_FORM_LOAD_FAILTURE";