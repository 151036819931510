import config from 'Config';

import {
    httpPut,
    handleHttpError
} from './httpService';

import { buildDefaultOptions } from './authService';

import { 
    get as _get, 
    post as _post, 
    getById as _getById, 
    deleteById as _deleteById, 
    } from 'Src/services/entityService'

const API_ENDPOINT = `${config.apis.authApi.URL}`
const APICORE_ENDPOINT = `${config.apis.coreApi.URL}/api`
const ENTITY_URI = `${API_ENDPOINT}/users`;
const CORE_ENTITY_URI = `${APICORE_ENDPOINT}/userNotifications`;
const adjustVersion = API_ENDPOINT.replace('v2','v1');
const ENTITY_URL_PROFILE = `${adjustVersion}/userxapplicationxprofiles`;
const ENTITY_URL_LIST_PROFILES = `${API_ENDPOINT}/profiles`;

let get_Cancelation = undefined;
export const get = async (accessToken, filterText, pageSize, page) => 
                        _get(accessToken, ENTITY_URI, get_Cancelation, filterText, pageSize, page)
                        
export const getProfiles = async (accessToken, filterText, pageSize, page) => 
                        _get(accessToken, ENTITY_URL_LIST_PROFILES, get_Cancelation, filterText, pageSize, page)

export const getUserXApplicationXProfiles = async (accessToken, filterText, pageSize, page) => 
                        _get(accessToken, ENTITY_URL_PROFILE, get_Cancelation, filterText, pageSize, page)

export const getUserNotifications = async (accessToken, filterText, pageSize, page) => 
                        _get(accessToken, CORE_ENTITY_URI, get_Cancelation, filterText, pageSize, page)

export const getById = async (accessToken, id) => 
                        _getById(accessToken, ENTITY_URI, id)

export const deleteById = async (accessToken, id) => 
                            _deleteById(accessToken, ENTITY_URI, id)

export const put = async (accessToken, id) => 
                        _put(accessToken, ENTITY_URI, id)

export const putUserXApplicationXProfiles = async (accessToken, users) => {
    const params = {
        "userId": users.id,
        "applicationId": 4,
        "profileId": users.profileId,
        "id": users.UserXApplicationXProfilesId
      }
      return _put(accessToken, ENTITY_URL_PROFILE, params)
}
                        

export const post = async (accessToken, users) => {
    const params = {
        "password": users.password,
        "name": users.name,
        "alias": users.name,
        "description": users.description,
        "email": users.email,
        "isWeekend": users.isWeekend // Se agrega fin de semana por usuario
    }

    return _post(accessToken, ENTITY_URI, params)
}

export const postProfile = async (accessToken, users) => {
    const params = {
        "userId": users.id,
        "applicationId": 4,
        "profileId": users.profileId
    }

    return _post(accessToken, ENTITY_URL_PROFILE, params)
}

export const postNotification = async (accessToken, users) => {
    const params = {
        "User": users.id,
        "MailNotifications": users.mailNotifications
    }

    return _post(accessToken, CORE_ENTITY_URI, params)
}

export const _put = async (accessToken, URL, entity) => {

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpPut(URL, entity, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}