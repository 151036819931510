export const JUSTIFICATIONDELAYAREAS_CARDS_REQUEST = "JUSTIFICATIONDELAYAREAS_CARDS_REQUEST";
export const JUSTIFICATIONDELAYAREAS_CARDS_SUCCESS = "JUSTIFICATIONDELAYAREAS_CARDS_SUCCESS";
export const JUSTIFICATIONDELAYAREAS_CARDS_FAILTURE = "JUSTIFICATIONDELAYAREAS_CARDS_FAILTURE";
export const JUSTIFICATIONDELAYAREAS_CARDS_CLEAN = "JUSTIFICATIONDELAYAREAS_CARDS_CLEAN";

export const JUSTIFICATIONDELAYAREAS_FILTER_SET = "JUSTIFICATIONDELAYAREAS_FILTER_SET";
export const JUSTIFICATIONDELAYAREAS_PAGE_SET = "JUSTIFICATIONDELAYAREAS_PAGE_SET";

export const JUSTIFICATIONDELAYAREAS_FORM_ADD_REQUEST = "JUSTIFICATIONDELAYAREAS_FORM_ADD_REQUEST";
export const JUSTIFICATIONDELAYAREAS_FORM_ADD_SUCCESS = "JUSTIFICATIONDELAYAREAS_FORM_ADD_SUCCESS";
export const JUSTIFICATIONDELAYAREAS_FORM_ADD_FAILTURE = "JUSTIFICATIONDELAYAREAS_FORM_ADD_FAILTURE";

export const JUSTIFICATIONDELAYAREAS_FORM_DELETE_REQUEST = "JUSTIFICATIONDELAYAREAS_FORM_DELETE_REQUEST";
export const JUSTIFICATIONDELAYAREAS_FORM_DELETE_SUCCESS = "JUSTIFICATIONDELAYAREAS_FORM_DELETE_SUCCESS";
export const JUSTIFICATIONDELAYAREAS_FORM_DELETE_FAILTURE = "JUSTIFICATIONDELAYAREAS_FORM_DELETE_FAILTURE";

export const JUSTIFICATIONDELAYAREAS_FORM_GET_REQUEST = "JUSTIFICATIONDELAYAREAS_FORM_GET_REQUEST";
export const JUSTIFICATIONDELAYAREAS_FORM_GET_SUCCESS = "JUSTIFICATIONDELAYAREAS_FORM_GET_SUCCESS";
export const JUSTIFICATIONDELAYAREAS_FORM_GET_FAILTURE = "JUSTIFICATIONDELAYAREAS_FORM_GET_FAILTURE";

export const JUSTIFICATIONDELAYAREAS_FORM_EDIT_REQUEST = "JUSTIFICATIONDELAYAREAS_FORM_EDIT_REQUEST";
export const JUSTIFICATIONDELAYAREAS_FORM_EDIT_SUCCESS = "JUSTIFICATIONDELAYAREAS_FORM_EDIT_SUCCESS";
export const JUSTIFICATIONDELAYAREAS_FORM_EDIT_FAILTURE = "JUSTIFICATIONDELAYAREAS_FORM_EDIT_FAILTURE";

export const JUSTIFICATIONDELAYAREAS_FORM_LOAD_REQUEST = "JUSTIFICATIONDELAYAREAS_FORM_LOAD_REQUEST";
export const JUSTIFICATIONDELAYAREAS_FORM_LOAD_SUCCESS = "JUSTIFICATIONDELAYAREAS_FORM_LOAD_SUCCESS";
export const JUSTIFICATIONDELAYAREAS_FORM_LOAD_FAILTURE = "JUSTIFICATIONDELAYAREAS_FORM_LOAD_FAILTURE";