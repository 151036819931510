import { push } from "connected-react-router";
import { error, success } from "react-notification-system-redux";

import {
  setFilter,
  getCardsRequest,
  getCardsSuccess,
  getCardsFailture,
  setPage,
} from "Src/redux/actions/workOrderTrackingCardsActions";

import { get } from "Src/services/workOrderTracking";

import { inputformatDate } from "Src/services/formatService";

import { ENTRUSTEDTASKS_URI } from "Src/routes";

const ENTITY_NAME = "Orden de Trabajo";

//TODO: THIS IS PRETTY GENERIC COULD BE SHARED
const ERROR_TITLE = "Oops!";
//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5
//
//TODO IT LOOKS PRETTY CARDS GENERIC

const WorkOrdersTrackingCardsNotification = {
  title: "Generic Title",
  message: "Generic Message",
  position: "tr",
};

const getVadationValue = (value) => {
  return value.length > 0 ? value : null;
};

const optionsStatus = [
  { key: "0", text: "Todas" },
  { key: "1", text: "En Curso" },
  { key: "2", text: "Finalizadas" },
  { key: "3", text: "Suspendidas" },
  { key: "4", text: "Canceladas" },
];

export const setCardsMount = () => {
  return async (dispatch, getState) => {
    const { areasReducer } = getState();

    dispatch(setFilter("", 0));

    const { loginReducer, workOrderTrackingCardsReducer } = getState();

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        "",
        workOrderTrackingCardsReducer.metadata.perPage,
        1,
        0
      )
    );
  };
};

export const setShowCards = (filterText, statusWorkOrder) => {
  return async (dispatch, getState) => {
    const { workOrderTrackingCardsReducer } = getState();
    dispatch(setFilter(filterText, statusWorkOrder));

    const { loginReducer } = getState();

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        filterText,
        workOrderTrackingCardsReducer.metadata.perPage,
        1,
        statusWorkOrder
      )
    );
  };
};

export const setCardsFilter = (filterText, statusWorkOrder = 0) => {
  return async (dispatch, getState) => {
    const { workOrderTrackingCardsReducer } = getState();

    dispatch(setFilter(filterText, statusWorkOrder));

    const { loginReducer } = getState();

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        filterText,
        workOrderTrackingCardsReducer.metadata.perPage,
        1,
        statusWorkOrder
      )
    );
  };
};

export const refreshCardsFilter = () => {
  return async (dispatch, getState) => {
    const { loginReducer, workOrderTrackingCardsReducer } = getState();

    const statusWorkOrder =
      workOrderTrackingCardsReducer.filter.statusWorkOrder;
    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        workOrderTrackingCardsReducer.filter.text,
        workOrderTrackingCardsReducer.metadata.perPage,
        1,
        statusWorkOrder
      )
    );
  };
};

export const setCardsNextPage = () => {
  return async (dispatch, getState) => {
    const { loginReducer, workOrderTrackingCardsReducer } = getState();

    if (!workOrderTrackingCardsReducer.metadata.hasMore) return;

    const nextPage = workOrderTrackingCardsReducer.metadata.page + 1;
    const statusWorkOrder =
      workOrderTrackingCardsReducer.filter.statusWorkOrder;
    dispatch(setPage(nextPage));

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        workOrderTrackingCardsReducer.filter.text,
        workOrderTrackingCardsReducer.metadata.perPage,
        nextPage,
        statusWorkOrder
      )
    );
  };
};

export const tryGetCardsAction = (
  accessToken,
  filter,
  pageSize,
  page,
  active
) => {
  return async (dispatch) => {
    dispatch(getCardsRequest());

    let response = await get(accessToken, filter, pageSize, page, {sortOrder:1,sortColumn:"id", filterState: active});

    if (response && response.cancelled) return;

    const { error } = response;

    if (error) {
      dispatch(getCardsFailture(response));

      showError(
        dispatch,
        ERROR_TITLE,
        `Ha ocurrido un error al cosultar las Cards. Detalle: '${error.message}'`
      );

      return;
    }

    dispatch(getCardsSuccess(response));
  };
};

const NOTIFICATION = {
  title: "Generic Title",
  message: "Generic Message",
  position: "tr",
};

const showSuccess = (dispatch, title, message) =>
  dispathNotification(dispatch, success, title, message);

const showError = (dispatch, title, message) =>
  dispathNotification(dispatch, error, title, message);

const dispathNotification = (dispatch, typeFunc, title, message) =>
  dispatch(
    typeFunc({
      ...NOTIFICATION,
      title,
      message,
    })
  );
