import React from 'react';
import { Card } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

export default class LoadingCard extends React.Component {
    render() {
        return (
            <Card className="loading-card card shadow-lg text-justify">
                <Card.Body>
                    <Spinner animation="grow" />
                </Card.Body>
            </Card>
        );
    }
}