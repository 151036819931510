import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';
import LoadingCard from 'Src/components/loadingCard';

import { ConnectedCreateStructureTypeForm } from 'Src/components/structureTypes/structureTypeForm';

import { getStructureTypeOption } from "Src/redux/reducers/structureTypesReducer";

import { tryAddStructureType,tryFormLoad } from 'Src/redux/thunk-creators/structureTypesActionCreator';


class StructureTypesAddPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }


    render() {
        const{
            onStructureTypeAdd,structureTypeToDuplicate
        }=this.props;
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard/>} >
                            <ConnectedCreateStructureTypeForm 
                                title="Crear tipo de estructura"
                                submitText="Crear"
                                duplicationOptions={structureTypeToDuplicate}
                                onSubmit={structureType => onStructureTypeAdd(structureType)} 
                                onCancel={this.props.goBack}/>
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        structureTypeToDuplicate: getStructureTypeOption(state),
    };
}

const mapDispatchToProps = dispatch => ({
    onStructureTypeAdd: (structureType) => dispatch(tryAddStructureType(structureType)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StructureTypesAddPage);