import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    getRequest, getSuccess, getFailture,
    editRequest, editSuccess, editFailture
} from "Src/redux/actions/particularDetailsActions";

import {
    getById , put
} from 'Src/services/particularDetailsService';


import { PARTICULARDETAILS_FORM_URI } from "Src/routes";


const particularDetailsCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5




export const tryGetparticularDetail = (particularDetailId) => {
    return async (dispatch, getState) => {
        if(!particularDetailId) return;
        
        dispatch(getRequest(particularDetailId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await getById(access_token, particularDetailId);
        if (response.hasOwnProperty("error")) {
            dispatch(getFailture(response));
            dispatch(error({
                ...particularDetailsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el particularDetaile. Detalle: '${response.error.message}'`
            }));
            return;
        }
        dispatch(getSuccess(response));

        dispatch(push(PARTICULARDETAILS_FORM_URI + "/" + particularDetailId));
    }
}

export const tryEditparticularDetail = (particularDetail) => {
    return async (dispatch, getState) => {
        dispatch(editRequest(particularDetail));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await put(access_token, particularDetail);
        if (response.hasOwnProperty("error")) {
            dispatch(editFailture(response));
            dispatch(error({
                ...particularDetailsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar el particularDetaile. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(editSuccess(response));

        particularDetail.id = response.id;

        dispatch(success({
            ...particularDetailsCardsNotification,
            title: "particularDetaile " + particularDetail.id,
            message: `Actualizado exitosamente!`
        }));

        dispatch(push(PARTICULARDETAILS_FORM_URI));
    }
}
