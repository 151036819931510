import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';

import { getIsFetching, getIsLoggedIn,getUserProfile } from "Src/redux/reducers/loginReducer";
import { 
    LOGIN_URI, 
    FORGOT_PASSWORD_URI, 
    RESET_PASSWORD_URI,
} from 'Src/routes';

import {Menus} from './menus'

class Navigation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        if (!this.props.isLoggedIn
            && (this.props.pathname.includes(RESET_PASSWORD_URI) || this.props.pathname.includes(FORGOT_PASSWORD_URI) )) {
            return (null);
        }

        if (!this.props.isLoggedIn
            && this.props.pathname != FORGOT_PASSWORD_URI
            && this.props.pathname != LOGIN_URI) {
            this.props.goToLogin();
        }

        if (!this.props.isLoggedIn) {
            this.props.goToLogin();
            return (null);
        }

        return (
            <Container className="nav-menu">
                <Row>
                    <Navbar expand="lg">
                        <Navbar.Brand>
                            <div className="logo"></div>
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="basic-navbar-nav" />

                        <Navbar.Collapse id="basic-navbar-nav">

                            <Menus userProfile={this.props.userProfile} />
                            
                        </Navbar.Collapse>
                    </Navbar>
                </Row>
            </Container>);
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: getIsLoggedIn(state),
        isFetching: getIsFetching(state),
        userProfile: getUserProfile(state),
        pathname: state.router.location.pathname,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        goToLogin: () => dispatch(push(LOGIN_URI)),
        goToResetPassword: () => dispatch(push(RESET_PASSWORD_URI))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation);