import React, { Component } from "react";
import { Map, LayersControl, TileLayer, Marker, Popup } from "react-windy-leaflet";

import config from 'Config';


const { BaseLayer } = LayersControl;

// key = W6owAESUOPv9RkCjTsS2ujNfnrqCybYn
/*
user: laynerker o lay.guerrero@dirmod.com
pass: Dirmod2020
*/
export default class WeatherMap extends Component {

    constructor(props) {
      super(props);
      this.state = {
          lat: config.apis.geolocationApi.DEFAULT_POSITION.lat,
          lng: config.apis.geolocationApi.DEFAULT_POSITION.lng,
          zoom: config.apis.geolocationApi.DEFAULT_ZOOM_DASHBOARD,      
          overlay: "wind"
      }
      
    }

  render() {
    const { lat, lng,structureType,client,workOrderId }  = this.props.locationData;

    const position = [lat, lng];
    
    return (
      <Map
        className="leaflet-container"
        windyKey={config.apis.geolocationApi.API_KEY_WINDY}
        windyLabels={false}
        windyControls={true}
        overlay={this.state.overlay}
        overlayOpacity={0.5}
        particlesAnim={false}
        zoom={this.state.zoom}
        center={[this.state.lat, this.state.lng]}
        removeWindyLayers
        onWindyMapReady={() => {
          console.log("Windy Map Loaded!");
        }}
        
        mapElements={
          <React.Fragment>
            <LayersControl>
              <BaseLayer checked name="OSM">
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </BaseLayer>
            </LayersControl>
            
            <Marker position={position}>
              <Popup>
                    N° O.T.: {workOrderId}<br/>
                    Estructura: {structureType} <br/>
                    Cliente: {client} <br/>
                    <a target="_blank" href={`https://www.windy.com/?${position[0]},${position[1]},8`}>Ver mas</a>
              </Popup>
            </Marker>
          </React.Fragment>
        }
      />
    );
  }
}