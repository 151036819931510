import React,{Component} from 'react';
import {
    Card, Button, Spinner,Col,Row
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { inputField } from 'Src/components/forms/inputField';
import { dropdownField } from 'Src/components/forms/dropdownField';

//TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')
//const requiredselect = value => (value ? undefined: 'Requerido!')

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}
    errors.name = required(values.name)
    errors.areaFK = required(values.areaFK=='-1'?null:values.areaFK)
    return errors
}

class ResponsibleForAreaForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText,
            mode,areasOptions
        } = this.props;
        return (
            <Card className="shadow">
                <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Field name="name" label="Nombre *" component={inputField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Field name="areaFK" label="Área *"
                                    component={dropdownField}
                                    options={areasOptions}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit" disabled={pristine || submitting}>
                                    {submitText}
                                    {submitting ? <Spinner animation="grow" size="sm" /> : null}
                                </Button>                            
                            </Col>
                            <Col className="text-right">
                                <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                                &nbsp;                            
                                <Button variant="danger" onClick={onCancel}>Cancelar</Button>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_RESPONSIBLEFORAREA_NAME = 'CREATE_RESPONSIBLEFORAREA_NAME';
export const EDIT_RESPONSIBLEFORAREA_NAME = 'EDIT_RESPONSIBLEFORAREA_NAME';

const CreateResponsibleForAreaForm = props =>
  <ResponsibleForAreaForm {...props} mode='create' />;
  
const EditResponsibleForAreaForm = props =>
  <ResponsibleForAreaForm {...props} mode='edit' />;

const ConnectedCreateResponsibleForAreaForm = reduxForm({
    form: CREATE_RESPONSIBLEFORAREA_NAME,
    validate
})(CreateResponsibleForAreaForm);

const ConnectedEditResponsibleForAreaForm = reduxForm({
    form: EDIT_RESPONSIBLEFORAREA_NAME,
    validate
})(EditResponsibleForAreaForm);

export {
    ConnectedCreateResponsibleForAreaForm,
    ConnectedEditResponsibleForAreaForm
}