import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    setFilter, getCardsRequest, getCardsSuccess, getCardsFailture,
    setPage,setFiltertaskEntrusted,getAutocompleteRequest,getAutocompleteFailture,getAutocompleteSuccess,
    getEndRequest,getEndSuccess,getEndFailture
} from "Src/redux/actions/entrustedTasksActions";

import {
    get as getWorkOrderTracking,
    getEntrustedTasks,
    putEndEntrutedTask
} from 'Src/services/workOrderTracking';

import { ENTRUSTEDTASKS_URI,WORKORDERSTRACKING_URI  } from "Src/routes";


const entrustedTaskCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5

export const setShowCards = (filterWorkOrderId) => {
    return async (dispatch, getState) => {
        const { entrustedTasksCardsReducer } = getState();
        const filterCard=filterWorkOrderId?filterWorkOrderId:entrustedTasksCardsReducer.filter.WorkOrderId
        
        
        dispatch(setFilter(filterCard));
        
        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterCard));

    };
};


export const setAutocompleteFilter = (filterText) => {
    return async (dispatch, getState) => {
        

        const { loginReducer } = getState();

        dispatch(tryGetOptionAutocompliteAction(
            loginReducer.authentication.access_token,
            filterText));

    };
};

export const tryGetOptionAutocompliteAction = (accessToken,fiedTexto) => {
    return async (dispatch) => {
        dispatch(getAutocompleteRequest());

        let response = await getWorkOrderTracking(accessToken, fiedTexto, 20, 1, {filterState:0});

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getAutocompleteFailture(response));

            dispatch(error({
                ...entrustedTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(getAutocompleteSuccess(response));
    }
};


export const setCardsFilter = (filterText) => {
    return async (dispatch, getState) => {
        const { entrustedTasksCardsReducer } = getState();
        
        dispatch(setFilter(filterText));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText));

    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, entrustedTasksCardsReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            entrustedTasksCardsReducer.filter.text));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, entrustedTasksCardsReducer } = getState();

        if (!entrustedTasksCardsReducer.metadata.hasMore) {
            return;
        }

        const nextPage = entrustedTasksCardsReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            entrustedTasksCardsReducer.filter.text));

    };
};

export const tryGetCardsAction = (accessToken,id) => {
    return async (dispatch) => {
        dispatch(getCardsRequest());

        let response = await getEntrustedTasks(accessToken,id);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...entrustedTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(getCardsSuccess(response));
    }
};

export const sendToTaskEntrustedCards = (WorkOrderId) => {
    return async (dispatch, getState) => {
        const { workOrderTrackingCardsReducer } = getState();
        
        const selectedWorkOrder = workOrderTrackingCardsReducer.cards.find(card => card.workOrder.id == WorkOrderId);

        dispatch(setFiltertaskEntrusted(selectedWorkOrder));
        
        dispatch(setShowCards(WorkOrderId));
        
        dispatch(push(WORKORDERSTRACKING_URI+"/"+WorkOrderId+ENTRUSTEDTASKS_URI))

    };
};

export const tryEndEntrustedTask = (WorkOrderId,entrustedTaskId) => {
    return async (dispatch, getState) => {
        const { loginReducer } = getState();
        dispatch(getEndRequest(entrustedTaskId));

        let response = await putEndEntrutedTask(loginReducer.authentication.access_token, entrustedTaskId, WorkOrderId);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getEndFailture(response));

            dispatch(error({
                ...entrustedTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Finalizar. Detalle: '${response.error.message}'`
            }));

            return;
        }

        if (response.hasOwnProperty("code")) {
            dispatch(getEndFailture(response));

            dispatch(error({
                ...entrustedTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Finalizar. Detalle: '${response.message}'`
            }));

            return;
        }

        dispatch(getEndSuccess(response));

        dispatch(success({
            ...entrustedTaskCardsNotification,
            title: "Tarea Encomendada "+entrustedTaskId,
            message: `Actualizado exitosamente!`
        }));
        
        dispatch(sendToTaskEntrustedCards(WorkOrderId));

    };
};





