export default {
    "appSettings": {
        "name": "Sistema de Gestion de Obras",
        "version": "v1.15.1",
        "state": {
            "SAVING_PERIOD": 1000
        },
        "DATE_FORMAT": "DD/MM/YYYY",
        "Cloud": false
    },
    "apis": {
        "authApi": {
            "URL": "https://authapi.pgo.guzman-nacich.com.ar/v2",
            "CLIENT_ID": 4,
            "APPLICATION_ID": 4,
            "AUTHENTICATION_ENDPOINT": "/authentication",
            "RESETPASSWORD_ENDPOINT": "/users/password",
            "HEADERS": {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }
        },
        "coreApi": {
            "URL": "https://coreapi.pgo.guzman-nacich.com.ar"
        },
        "analiticsApi": {
            "URL": "https://analyticsapi.pgo.guzman-nacich.com.ar"
        },
        "geolocationApi": {
            "API_KEY": 'AIzaSyDydxu-s75vkcxjuoWn619fh7cCGnQ7pH4',
            "API_KEY_WINDY": 'J1D6V9mXtSvlqGh6XOhDBwD6IRhoo8a7',
            "DEFAULT_ZOOM": 16,
            "DEFAULT_ZOOM_DASHBOARD": 4,
            "DEFAULT_POSITION": {
                "lat": -34.7079207,
                "lng": -58.34591130000001
            }
        }
    }
}
