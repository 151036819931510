import React,{Component} from 'react';
import {
    Card, Button, Spinner,Col,Row
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { inputField } from 'Src/components/forms/inputField';
import { dropdownField } from 'Src/components/forms/dropdownField';

//TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')
//const requiredselect = value => (value ? undefined: 'Requerido!')

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}
    errors.description = required(values.description)
    errors.clientFk = required(values.clientFk)    
    return errors
}

class RegionForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText,clientsOptions,
            mode
        } = this.props;

        return (
            <Card className="shadow">
                <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                            <Field name="description" label="Nombre de la Region" component={inputField} type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="clientFK" label="Clientes"
                                component={dropdownField}
                                options={clientsOptions} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit" disabled={pristine || submitting}>
                                    {submitText}
                                    {submitting ? <Spinner animation="grow" size="sm" /> : null}
                                </Button>                            
                            </Col>
                            <Col className="text-right">
                                <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                                &nbsp;                            
                                <Button variant="danger" onClick={onCancel}>Cancelar</Button>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_REGION_NAME = 'CREATE_REGION_NAME';
export const EDIT_REGION_NAME = 'EDIT_REGION_NAME';

const CreateRegionForm = props =>
  <RegionForm {...props} mode='create' />;
  
const EditRegionForm = props =>
  <RegionForm {...props} mode='edit' />;

const ConnectedCreateRegionForm = reduxForm({
    form: CREATE_REGION_NAME,
    validate
})(CreateRegionForm);

const ConnectedEditRegionForm = reduxForm({
    form: EDIT_REGION_NAME,
    validate
})(EditRegionForm);

export {
    ConnectedCreateRegionForm,
    ConnectedEditRegionForm
}