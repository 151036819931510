import config from 'Config';

import { buildDefaultOptions } from './authService';
import {
    httpGet,
    handleHttpError,
    buildURLQuery
} from './httpService';

import { 
    buildUriById,
    get as _get, 
    post as _post, 
    getById as _getById, 
    deleteById as _deleteById, 
    put as _put } from 'Src/services/entityService'

const API_ENDPOINT = `${config.apis.analiticsApi.URL}/api/analytics`
const ENTITY_HISTORYS_WORKORDER_URI = `/history`;
const ENTITY_HISTORYS_TASKS_URI = `${ENTITY_HISTORYS_WORKORDER_URI}/tasks`;

let get_Cancelation = undefined;
export const getHistorysWorkOrder = async (accessToken, id,pageSize,page) => {
    let setbuildURLQuery = {
        pageSize,
        page
    }    
    const queryString = buildURLQuery(setbuildURLQuery);
    const URL = buildUriById(API_ENDPOINT, id) + ENTITY_HISTORYS_WORKORDER_URI+`?${queryString}`;

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpGet(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const getHistorysTasks = async (accessToken, id,pageSize,page) => {
    let setbuildURLQuery = {
        pageSize,
        page
    }    
    const queryString = buildURLQuery(setbuildURLQuery);
    const URL = buildUriById(API_ENDPOINT, id) + ENTITY_HISTORYS_TASKS_URI+`?${queryString}`;

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpGet(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}
