import { push } from "connected-react-router";
import { error, success } from "react-notification-system-redux";

import {
  getRequest,
  getSuccess,
  getFailture,
  addRequest,
  addSuccess,
  addFailture,
  editRequest,
  editSuccess,
  editFailture,
  getCardsRequest,
  getCardsFailture,
  getCardsSuccess,
  setFilter,
  setPage,
  deleteRequest,
  deleteFailture,
  deleteSuccess,
} from "Src/redux/actions/areasActions";

import { get, getById, put, post, deleteById } from "Src/services/areasService";

import { AREAS_FORM_URI, AREAS_URI } from "Src/routes";

const areasCardsNotification = {
  title: "Generic Title",
  message: "Generic Message",
  position: "tr",
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5

export const setCardsMount = () => {
  return async (dispatch, getState) => {
    const { areasReducer } = getState();

    dispatch(setFilter());

    const { loginReducer } = getState();

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        "",
        areasReducer.metadata.perPage,
        1
      )
    );
  };
};

export const setCardsFilter = (filterText) => {
  return async (dispatch, getState) => {
    const { areasReducer } = getState();

    dispatch(setFilter(filterText));

    const { loginReducer } = getState();

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        filterText,
        areasReducer.metadata.perPage,
        1
      )
    );
  };
};

export const refreshCardsFilter = () => {
  return async (dispatch, getState) => {
    const { loginReducer, areasReducer } = getState();

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        areasReducer.filter.text,
        areasReducer.metadata.perPage,
        1
      )
    );
  };
};

export const setCardsNextPage = () => {
  return async (dispatch, getState) => {
    const { loginReducer, areasReducer } = getState();

    if (!areasReducer.metadata.hasMore) {
      return;
    }

    const nextPage = areasReducer.metadata.page + 1;

    dispatch(setPage(nextPage));

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        areasReducer.filter.text,
        areasReducer.metadata.perPage,
        nextPage
      )
    );
  };
};

export const tryGetCardsAction = (accessToken, filter, pageSize, page) => {
  return async (dispatch) => {
    dispatch(getCardsRequest());

    let response = await get(accessToken, filter, pageSize, page);

    if (response.cancelled) {
      return;
    }

    if (response.hasOwnProperty("error")) {
      dispatch(getCardsFailture(response));

      dispatch(
        error({
          ...AreasCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`,
        })
      );

      return;
    }

    dispatch(getCardsSuccess(response));
  };
};

export const tryGetarea = (areaId) => {
  return async (dispatch, getState) => {
    if (!areaId) return;

    dispatch(getRequest(areaId));

    const { loginReducer } = getState();
    const { access_token } = loginReducer.authentication;

    let response = await getById(access_token, areaId);
    if (response.hasOwnProperty("error")) {
      dispatch(getFailture(response));
      dispatch(
        error({
          ...areasCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar la área. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }
    dispatch(getSuccess(response));

    dispatch(push(AREAS_FORM_URI + "/" + areaId));
  };
};

export const tryDeleteArea = (areaId) => {
  return async (dispatch, getState) => {
    let shouldDelete = confirm("Desea borrar la área Id: " + areaId);
    if (!shouldDelete) {
      return;
    }

    dispatch(deleteRequest(areaId));

    const { loginReducer } = getState();
    const { access_token } = loginReducer.authentication;

    let response = await deleteById(access_token, areaId);
    if (response.hasOwnProperty("error")) {
      dispatch(deleteFailture(response));
      dispatch(
        error({
          ...areasCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al Borrar la área. Detalle: '${response.error.message}'`,
        })
      );

      return;
    }
    dispatch(deleteSuccess(areaId));

    dispatch(
      success({
        ...areasCardsNotification,
        title: "Area " + areaId,
        message: `Eliminado exitosamente!`,
      })
    );
  };
};

export const tryEditArea = (area) => {
  return async (dispatch, getState) => {
    dispatch(editRequest(area));

    const { loginReducer } = getState();
    const { access_token } = loginReducer.authentication;

    let response = await put(access_token, area);
    if (response.hasOwnProperty("error")) {
      dispatch(editFailture(response));
      dispatch(
        error({
          ...areasCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al Actualizar la área. Detalle: '${response.error.message}'`,
        })
      );

      return;
    }
    dispatch(editSuccess(response));

    area.id = response.id;

    dispatch(
      success({
        ...areasCardsNotification,
        title: "Área " + area.id,
        message: `Actualizado exitosamente!`,
      })
    );

    dispatch(push(AREAS_URI));
  };
};

export const tryAddArea = (area) => {
  return async (dispatch, getState) => {
    dispatch(addRequest(area));

    const { loginReducer } = getState();
    const { access_token } = loginReducer.authentication;

    let response = await post(access_token, area);

    if (response.code && response.code == 4) {
      dispatch(addFailture(response));
      dispatch(
        error({
          ...areasCardsNotification,
          title: "Oops!",
          message: `La área ya existe.`,
        })
      );

      return;
    }

    if (response.code) {
      dispatch(addFailture(response));
      dispatch(
        error({
          ...areasCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al Crear una área. Detalle: '${response.message}'`,
        })
      );

      return;
    }

    if (response.hasOwnProperty("error")) {
      dispatch(addFailture(response));
      dispatch(
        error({
          ...areasCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al Crear una área. Detalle: '${response.error.message}'`,
        })
      );

      return;
    }

    dispatch(addSuccess(response));

    area.id = response.id;

    dispatch(
      success({
        ...areasCardsNotification,
        title: "Area " + area.id,
        message: `Creado exitosamente!`,
      })
    );

    dispatch(push(AREAS_URI));
  };
};
