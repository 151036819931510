import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';

import { 
    Container, Row, Col,
    Card, Spinner, ToggleButton, 
    ToggleButtonGroup, ButtonToolbar,
    Alert 
} from 'react-bootstrap';
import LoadingCard from 'Src/components/loadingCard';

import { 
        getFilter,getStructureTypeOption,
        getClientsOption,getlocations,
        getStateStructureOptions, getShowCard,
        getDataForPieChart,getDataForBarChart,
        getPaiQuantities,getAutocomplete,getLocationData
    } from "Src/redux/reducers/dashboardReducer";

import { 
        tryFormLoad,tryFilter,trySetShowCard,
        tryFilterPieChart,tryFilterBarChart,
        setAutocompleteFilter,setLocationData
    } from 'Src/redux/thunk-creators/dashboardActionCreator';
import { sendToTimeLineCards } from "Src/redux/thunk-creators/timelinesCardsActionCreator";
import { ConnectedEditfilterMapForm } from 'Src/components/dashboard/filterMapForm'
import { ConnectedEditfilterPieChartForm } from 'Src/components/dashboard/filterPieChartForm'
import { ConnectedEditfilterBarChartForm } from 'Src/components/dashboard/filterBarChartForm'


import config from 'Config';
const GmapComponent = React.lazy(() => 
    import('Src/components/geocoding/gmap'));

const Autocomplete = React.lazy(() =>
    import('Src/components/autocomplete'));

import PieChart from 'Src/components/analytics/pie';
import BarChart from 'Src/components/analytics/bar';
import WeatherMap from "Src/components/geocoding/weatherMap";


class DashboardPage extends Component {
    constructor(props) {
        super(props);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }
    handleFilterChange(filterText) {
        this.props.setAutocompleteFilter(filterText);
    }

    render() {
        const {
                structureTypeOption,clientsOption,locations,
                onFilter,stateStructureOptions,isShownCard,
                dataForPieChart,onDashboard,onFilterMap,
                onFilterBarChart,dataForBarChart,paiQuiantites,
                onFilterPieChart,timeLineCards,itemsAutocomplite,
                onLocationData,locationData
            } = this.props
        
        return (
            <Container className="dashboard-container">
                <Row>
                    <Col>
                    <Card className="shadow">
                        <Card.Header><h5>Dashboard</h5></Card.Header>
                        <Card.Body>
                            <ButtonToolbar>
                                <ToggleButtonGroup type="radio" name="options" defaultValue={isShownCard} className="timeline-buttongroup mt-3">                                                                                  
                                    <ToggleButton value="map"  onClick={() => onDashboard('map')}>
                                        Mapa
                                    </ToggleButton> 
                                    <ToggleButton value="chart" onClick={() => onDashboard('chart')}>
                                        Graficos
                                    </ToggleButton>
                                    <ToggleButton value="weather" onClick={() => onDashboard('weather')}>
                                        Clima
                                    </ToggleButton> 
                                </ToggleButtonGroup>                                            
                            </ButtonToolbar>       
                        </Card.Body>
                    </Card>                        
                    </Col>
                </Row>
                {isShownCard == "map"?
                <Row>
                    <Col >
                        <Card className="shadow">
                            <Card.Header><h5>Mapa</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Suspense fallback={<LoadingCard />} >
                                    <ConnectedEditfilterMapForm
                                            submitText="Filtrar"
                                            onSubmit={filter => onFilterMap(filter)}
                                            clientsOptions={clientsOption}
                                            structureTypeOptions={structureTypeOption}
                                            stateStructureOptions={stateStructureOptions}                                   
                                        />
                                    </Suspense>
                                    <br/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={2}>
                                        <div className="inProgress"></div><strong>En Curso</strong>
                                    </Col>
                                    <Col sm={2}>
                                        <div className="inDelay"></div><strong>Con Retraso</strong>
                                    </Col>
                                    <Col sm={2}>
                                        <div className="finish"></div><strong>Finalizada</strong>
                                    </Col>
                                    <Col sm={2}>
                                        <div className="suspended"></div><strong>Suspendidas</strong>
                                    </Col>
                                    <Col sm={2}>
                                        <div className="canceled"></div><strong>Canceladas</strong>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col>
                                    <Suspense fallback={<Spinner animation="grow" />}>
                                <GmapComponent
                                    isMarkerClustererShown
                                    zoom={config.apis.geolocationApi.DEFAULT_ZOOM_DASHBOARD}
                                    position={config.apis.geolocationApi.DEFAULT_POSITION}
                                    markers={locations}
                                    onTimeLine={timeLineCards}/>
                                </Suspense></Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>:null}
                {isShownCard == "chart"?
                <Row>
                    <Col >
                        <Card className="shadow">
                            <Card.Header><h5>Reporte de seguimiento de obra</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Suspense fallback={<LoadingCard />} >
                                        <ConnectedEditfilterPieChartForm
                                                submitText="Filtrar"
                                                onSubmit={filter => onFilterPieChart(filter)}
                                                clientsOptions={clientsOption}
                                                structureTypeOptions={structureTypeOption}
                                                stateStructureOptions={stateStructureOptions}                                   
                                            />
                                    </Suspense>
                                    <br/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3} className="text-center colum-num-chart">
                                        <div>
                                        <div className="num-chart withDelay">
                                            {paiQuiantites.withdelays}
                                        </div>
                                        Con retraso
                                        </div>
                                        <div>
                                        <div className="num-chart inProgress">
                                            {paiQuiantites.incourse}
                                        </div>
                                        En curso
                                        </div>
                                        <div>
                                        <div className="num-chart finished">
                                            {paiQuiantites.finished}
                                        </div>
                                        Finalizada
                                        </div>
                                    </Col>
                                    <Col>
                                        <Suspense fallback={<Spinner animation="grow" />}>
                                        <PieChart data={dataForPieChart} />
                                    </Suspense>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col >
                        <Card className="shadow">
                            <Card.Header><h5>Reporte de ordenes de trabajo</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                    <Suspense fallback={<LoadingCard />} >
                                        <ConnectedEditfilterBarChartForm
                                                submitText="Filtrar"
                                                onSubmit={filter => onFilterBarChart(filter)}
                                                clientsOptions={clientsOption}
                                                structureTypeOptions={structureTypeOption}
                                                stateStructureOptions={stateStructureOptions}                                   
                                            />
                                    </Suspense>
                                    <br/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <Suspense fallback={<Spinner animation="grow" />}>
                                        <BarChart data={dataForBarChart} />
                                    </Suspense>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>:null}
                {isShownCard == "weather"?
                <Row>
                    <Col >
                        <Card className="shadow">
                            <Card.Header><h5>Clima</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Suspense fallback={<Spinner animation="grow"/>}>
                                            <Autocomplete
                                                handleChange={this.handleFilterChange}
                                                SelectChange={onLocationData}
                                                items={itemsAutocomplite} />
                                        </Suspense>     
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    {locationData != null?
                                    <Suspense fallback={<Spinner animation="grow" />}>
                                        <WeatherMap locationData={locationData}  />
                                    </Suspense>
                                    :null}              
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>:null}
                <br/><br/>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        filter: getFilter(state),
        structureTypeOption: getStructureTypeOption(state),
        clientsOption: getClientsOption(state),
        locations : getlocations(state),
        stateStructureOptions: getStateStructureOptions(state),
        isShownCard: getShowCard(state),
        dataForPieChart: getDataForPieChart(state),
        dataForBarChart: getDataForBarChart(state),
        paiQuiantites: getPaiQuantities(state),
        itemsAutocomplite: getAutocomplete(state),
        locationData: getLocationData(state)
    };
}

const mapDispatchToProps = dispatch => {
    return {
        tryFormLoad:() => dispatch(tryFormLoad()),
        onFilterMap:(filter) => dispatch(tryFilter(filter)),
        onFilterPieChart:(filter) => dispatch(tryFilterPieChart(filter)),
        onFilterBarChart:(filter) => dispatch(tryFilterBarChart(filter)),
        onDashboard:(cardName) => dispatch(trySetShowCard(cardName)),
        timeLineCards: (id) => dispatch(sendToTimeLineCards(id)), 
        setAutocompleteFilter: (filterText) => dispatch(setAutocompleteFilter(filterText)),
        onLocationData: (filterText) => dispatch(setLocationData(filterText)),
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardPage);