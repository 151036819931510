import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    setFilter, getCardsRequest, getCardsSuccess, getCardsFailture,
    setPage,setFilterTimeLine,getTaskXAreaCardsSuccess
} from "Src/redux/actions/timelinesCardsActions";

import {
    getByIdTimeLine
} from 'Src/services/workOrderTracking';

import {
    inputformatDate
} from 'Src/services/formatService';

import { TIMELINE_URI } from "Src/routes";

const ERROR_TITLE = "Oops!";

export const setShowCards = (filterText) => {
    return async (dispatch, getState) => {
        const { timelineCardsReducer } = getState();
        
        dispatch(setFilter(filterText));
        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText));

    };
};

export const setShowTaskXAreaCards = (filterText) => {
    return async (dispatch, getState) => {     
        const { timelineCardsReducer,loginReducer } = getState();          

        dispatch(getCardsRequest())

        let response = await getByIdTimeLine(loginReducer.authentication.access_token, timelineCardsReducer.filter.text)

        if (response
            && response.cancelled) return

        const { error } = response

        if (error) {
            dispatch(getCardsFailture(response));

            showError(dispatch, ERROR_TITLE,
                `Ha ocurrido un error al cosultar las Cards. Detalle: '${error.message}'`);

            return
        }
        dispatch(getTaskXAreaCardsSuccess(response,filterText))

    };
};


export const setCardsFilter = (filterText) => {
    return async (dispatch, getState) => {
        const { timelineCardsReducer } = getState();
        
        
        
        dispatch(setFilter(filterText));

        
        const { loginReducer } = getState();

        
        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText));

    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, timelineCardsReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            timelineCardsReducer.filter.text));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, timelineCardsReducer } = getState()

        if (!timelineCardsReducer.metadata.hasMore) return


        const nextPage = timelineCardsReducer.metadata.page + 1
        
        dispatch(setPage(nextPage))

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            timelineCardsReducer.filter.text,
            timelineCardsReducer.metadata.perPage,
            nextPage))

    }
}

export const tryGetCardsAction = (accessToken, workOrderId) => {
    return async (dispatch) => {
        dispatch(getCardsRequest())

        let response = await getByIdTimeLine(accessToken, workOrderId)

        if (response
            && response.cancelled) return

        const { error } = response

        if (error) {
            dispatch(getCardsFailture(response));

            showError(dispatch, ERROR_TITLE,
                `Ha ocurrido un error al cosultar las Cards. Detalle: '${error.message}'`);

            return
        }
        
        dispatch(getCardsSuccess(response))
    }
}

export const sendToTimeLineCards = (WorkOrderId) => {
    return async (dispatch, getState) => {
        const { loginReducer } = getState();
        
        dispatch(getCardsRequest());

        let response = await getByIdTimeLine(loginReducer.authentication.access_token, WorkOrderId);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...entrustedTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(setFilterTimeLine(response));
        dispatch(setFilter(WorkOrderId));
            
        dispatch(push(TIMELINE_URI+"/"+WorkOrderId))

    };
};

const NOTIFICATION = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr'
}

const showError = (dispatch, title, message) =>
    dispathNotification(dispatch, error, title, message)

const dispathNotification = (dispatch, typeFunc, title, message) =>
    dispatch(typeFunc({
        ...NOTIFICATION,
        title,
        message
    }))
