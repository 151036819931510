import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditClientForm } from 'Src/components/clients/ClientForm'

import { getClientToEdit,getConstanciaAfip,getConstanciaIibb,getFormularioCm05 } from "Src/redux/reducers/clientsCardsReducer";

import { tryEditClient } from 'Src/redux/thunk-creators/clientsCardsActionCreator';

class ClientsEditPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { 
            onClientEdit, goBack, clientToEdit,constanciaAfip,constanciaIibb,formularioCm05
        } = this.props;
        return (
            <Container className="clients-container">
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedEditClientForm
                                title="Editar Cliente"
                                submitText="Editar"
                                onSubmit={values => onClientEdit(values)}
                                onCancel={goBack}
                                initialValues={clientToEdit}
                                DocumentConstanciaAfip ={constanciaAfip}
                                DocumentConstanciaIibb ={constanciaIibb}
                                DocumentFormularioCm05 ={formularioCm05} 
                            />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        clientToEdit: getClientToEdit(state),
        constanciaAfip: getConstanciaAfip(state),
        constanciaIibb: getConstanciaIibb(state),
        formularioCm05: getFormularioCm05(state)
    };
}

const mapDispatchToProps = dispatch => ({
    onClientEdit: (client) => dispatch(tryEditClient(client)),
    goBack: () => dispatch(goBack())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientsEditPage);
