import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    setFilter, getCardsRequest, getCardsSuccess, getCardsFailture,
    setPage, addFailture, addRequest, addSuccess,
    deleteRequest, deleteSuccess, deleteFailture,
    getRequest, getSuccess, getFailture,
    editRequest, editSuccess, editFailture, cleanCards
} from "Src/redux/actions/clientsCardsActions";

import {
    get, post,
    addConstanciaAfip, addConstanciaIibb, addFormularioCm05,
    editConstanciaAfip, editConstanciaIibb, editFormularioCm05,
    deleteById, put, getById, getDocuments
} from 'Src/services/clientsService';

import { CLIENTS_URI, CLIENTS_FORM_URI } from "Src/routes";


const clientsCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5

export const setCardsMount = () => {
    return async (dispatch, getState) => {
        const { clientsCardsReducer } = getState();
        let filterText = clientsCardsReducer.filter.text

        dispatch(setFilter(filterText));


        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText,
            clientsCardsReducer.metadata.perPage,
            1));

    };
};

export const setCardsFilter = (filterText) => {
    return async (dispatch, getState) => {
        const { clientsCardsReducer } = getState();

        if (filterText.length < 3
            || filterText == clientsCardsReducer.filter.text) {
                dispatch(setFilter(filterText));
            return;
        }

        dispatch(setFilter(filterText));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText,
            clientsCardsReducer.metadata.perPage,
            1));

    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, clientsCardsReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            clientsCardsReducer.filter.text,
            clientsCardsReducer.metadata.perPage,
            1));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, clientsCardsReducer } = getState();

        if (!clientsCardsReducer.metadata.hasMore) {
            return;
        }

        const nextPage = clientsCardsReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            clientsCardsReducer.filter.text,
            clientsCardsReducer.metadata.perPage,
            nextPage));

    };
};

export const tryGetCardsAction = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {
        dispatch(getCardsRequest());

        let response = await get(accessToken, filter, pageSize, page);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...clientsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }

        dispatch(getCardsSuccess(response));
    }
};

export const tryAddClient = (client) => {
    return async (dispatch, getState) => {
        dispatch(addRequest(client));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await post(access_token, client);

        if (response.code && response.code == 4) {
            dispatch(addFailture(response));
            dispatch(error({
                ...clientsCardsNotification,
                title: "Oops!",
                message: `El Cliente ya existe.`
            }));

            return;
        }

        if (response.code && response.code == 1) {
            dispatch(addFailture(response));
            dispatch(error({
                ...clientsCardsNotification,
                title: "Oops!",
                message: `El Cuit es invalido.`
            }));

            return;
        }

        if (response.code) {
            dispatch(addFailture(response));
            dispatch(error({
                ...clientsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear el Cliente. Detalle: '${response.message}'`
            }));

            return;
        }

        if (response.hasOwnProperty("error")) {
            dispatch(addFailture(response));
            dispatch(error({
                ...clientsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear el Cliente. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(addSuccess(response));

        client.id = response.id;
        if (client.constanciaAfip) {
            let addDocumentResponse = await addConstanciaAfip(access_token, client);

            if (addDocumentResponse.hasOwnProperty("error")) {
                dispatch(addFailture(addDocumentResponse));
                dispatch(error({
                    ...clientsCardsNotification,
                    title: "Oops!",
                    message: `Ha ocurrido un error al Subir la Constancia de AFIP. Detalle: '${addDocumentResponse.error.message}'`
                }));

                return;
            }
        }
        if (client.contanciaIibb) {
            let addDocumentResponse = await addConstanciaIibb(access_token, client);

            if (addDocumentResponse.hasOwnProperty("error")) {
                dispatch(addFailture(addDocumentResponse));
                dispatch(error({
                    ...clientsCardsNotification,
                    title: "Oops!",
                    message: `Ha ocurrido un error al Subir la Constancia de Iibb. Detalle: '${addDocumentResponse.error.message}'`
                }));

                return;
            }
        }
        if (client.formularioCM05) {
            let addDocumentResponse = await addFormularioCm05(access_token, client);

            if (addDocumentResponse.hasOwnProperty("error")) {
                dispatch(addFailture(addDocumentResponse));
                dispatch(error({
                    ...clientsCardsNotification,
                    title: "Oops!",
                    message: `Ha ocurrido un error al Subir la formularioCM05. Detalle: '${addDocumentResponse.error.message}'`
                }));

                return;
            }
        }

        dispatch(success({
            ...clientsCardsNotification,
            title: "Cliente " + client.id,
            message: `Creado exitosamente!`
        }));

        dispatch(push(CLIENTS_URI));
    }
}

export const tryDeleteClient = (clientId) => {
    return async (dispatch, getState) => {
        let shouldDelete = confirm("Desea borrar el Cliente Id: " + clientId);
        if (!shouldDelete) {
            return;
        }

        dispatch(deleteRequest(clientId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await deleteById(access_token, clientId);
        if (response.hasOwnProperty("error")) {
            dispatch(deleteFailture(response));
            dispatch(error({
                ...clientsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Borrar el Cliente. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(deleteSuccess(clientId));

        dispatch(success({
            ...clientsCardsNotification,
            title: "Cliente " + clientId,
            message: `Eliminado exitosamente!`
        }));
    }
}

export const tryGetClient = (clientId) => {
    return async (dispatch, getState) => {
        if(!clientId) return;

        dispatch(getRequest(clientId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await getById(access_token, clientId);
        if (response.hasOwnProperty("error")) {
            dispatch(getFailture(response));
            dispatch(error({
                ...clientsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el Cliente. Detalle: '${response.error.message}'`
            }));
            return;
        }

        let responseDocuments = await getDocuments(access_token, clientId);

        if (responseDocuments.hasOwnProperty("error")) {
            dispatch(getFailture(responseDocuments));
            dispatch(error({
                ...clientsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar los documentos. Detalle: '${response.error.message}'`
            }));
            return;
        }

        dispatch(getSuccess(response,responseDocuments));
        
        dispatch(push(CLIENTS_FORM_URI + "/" + clientId));
    }
}

export const tryEditClient = (client) => {
    return async (dispatch, getState) => {
        dispatch(editRequest(client));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await put(access_token, client);
        if (response.hasOwnProperty("error")) {
            dispatch(editFailture(response));
            dispatch(error({
                ...clientsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar el Cliente. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(editSuccess(response));

        client.id = response.id;
        if (client.constanciaAfip) {
            let documentResponse = await editConstanciaAfip(access_token, client);

            if (documentResponse.hasOwnProperty("error")) {
                dispatch(editFailture(documentResponse));
                dispatch(error({
                    ...clientsCardsNotification,
                    title: "Oops!",
                    message: `Ha ocurrido un error al Subir la Constancia de AFIP. Detalle: '${documentResponse.error.message}'`
                }));

                return;
            }
        }
        if (client.contanciaIibb) {
            let documentResponse = await editConstanciaIibb(access_token, client);

            if (documentResponse.hasOwnProperty("error")) {
                dispatch(editFailture(documentResponse));
                dispatch(error({
                    ...clientsCardsNotification,
                    title: "Oops!",
                    message: `Ha ocurrido un error al Subir la Constancia de Iibb. Detalle: '${documentResponse.error.message}'`
                }));

                return;
            }
        }
        if (client.formularioCM05) {
            let documentResponse = await editFormularioCm05(access_token, client);

            if (documentResponse.hasOwnProperty("error")) {
                dispatch(editFailture(documentResponse));
                dispatch(error({
                    ...clientsCardsNotification,
                    title: "Oops!",
                    message: `Ha ocurrido un error al Subir la formularioCM05. Detalle: '${documentResponse.error.message}'`
                }));

                return;
            }
        }

        dispatch(success({
            ...clientsCardsNotification,
            title: "Cliente " + client.id,
            message: `Actualizado exitosamente!`
        }));

        dispatch(push(CLIENTS_URI));
        dispatch(cleanCards());
        dispatch(refreshCardsFilter());
    }
}