import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    getCardsRequest,getCardsFailture,getCardsSuccess,
    setFilter,setPage,    
} from "Src/redux/actions/historysActions";

import {
    get as getUsers
} from 'Src/services/usersService';

import {
    getHistorysWorkOrder, getHistorysTasks
} from 'Src/services/historysService';

import {
    inputformatDate
} from 'Src/services/formatService';


import { HISTORYS_URI } from "Src/routes";


const historysCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5


export const tryHistory = (typeHistory) => {
    return async (dispatch, getState) => {
        const { historysReducer,loginReducer } = getState();

        dispatch(setFilter(historysReducer.filter.workOrderId,typeHistory,historysReducer.client));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            historysReducer.filter.workOrderId,
            typeHistory,
            historysReducer.metadata.perPage,
            1));
    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, historysReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            historysReducer.filter.workOrderId,
            historysReducer.filter.typeHistory,
            historysReducer.metadata.perPage,
            historysReducer.metadata.page));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, historysReducer } = getState();

        if (!historysReducer.metadata.hasMore) {
            return;
        }

        const nextPage = historysReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            historysReducer.filter.workOrderId,
            historysReducer.filter.typeHistory,
            historysReducer.metadata.perPage,
            nextPage));

    };
};

export const tryGetCardsAction = (accessToken, id,typeHistory,pageSize, page) => {
    return async (dispatch) => {
        dispatch(getCardsRequest());
        let response=undefined;
        
        if(typeHistory==1)
            response = await getHistorysWorkOrder(accessToken, id, pageSize, page);     

        if(typeHistory==2)
            response = await getHistorysTasks(accessToken, id, pageSize, page);     
        

        let responseUser = await getUsers(accessToken, '', 1000, 1);
        

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...historysCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }
       

        if (responseUser.hasOwnProperty("error")) {
            dispatch(getCardsFailture(responseUser));
            dispatch(error({
                ...historysCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${responseUser.error.message}'`
            }));
            return;            
        }

        response.records = response.records.map((h)=>{
            let userName = undefined;
            if(h.userId){
                const user = responseUser.filter(u => u.id==h.userId);
                userName = user[0].name;
            }
            const date = inputformatDate(h.date);
            return {
                ...h,
                user:userName,
                date
            }
        })

        dispatch(getCardsSuccess(response));
    }
};



export const sendToHistory = (workOrderId,client) => {
    return async (dispatch, getState) => {
        if(!workOrderId) return;

        dispatch(setFilter(workOrderId,null,client));


        dispatch(push(HISTORYS_URI + "/" + workOrderId));
    }
}

