import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditTaskConfigurationForm } from 'Src/components/tasksConfigurations/taskConfigurationForm'

import { 
    getTaskConfigurationToEdit,getStructureTypeOption,
    getAreasOptions,getEntrustedTasksOptions,
    getOperativeTasksOptions,getFilter,getCards
} from "Src/redux/reducers/tasksConfigurationsReducer";

import { 
        tryEditTaskConfiguration,tryFormLoad,tryGetCardsChildrens,
        trySelectClearChildrens
     } from 'Src/redux/thunk-creators/tasksConfigurationsActionCreator';

const TasksConfigurationsCards = React.lazy(() =>
    import('Src/components/tasksConfigurations/tasksConfigurationsCards'));

class TasksConfigurationsPage extends Component {
    constructor(props) {
        super(props);

        this.onChangeArea = this.onChangeArea.bind(this);
        this.onChangeEntrustedTasks = this.onChangeEntrustedTasks.bind(this);
        this.onChangeOperativeTasks = this.onChangeOperativeTasks.bind(this);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }

    onChangeArea(event) {
        if (event.target.value){
            const filter = { areaId : event.target.value }
            this.props.tryGetCardsChildrens(filter)           
        }
            
    }

    onChangeEntrustedTasks(event) {
        if (event.target.value){
            const filter = { EntrustedTaskId : event.target.value }
            this.props.tryGetCardsChildrens(filter)
        }
    }

    onChangeOperativeTasks(event) {
        if (event.target.value){
            const filter = { OperativeTaskId : event.target.value }
            this.props.tryGetCardsChildrens(filter)
        }
    }

    render() {
        const { 
            onTaskConfigurationEdit, goBack, 
            TaskConfigurationToEdit, structureTypeOption,
            areasOptions,entrustedTasksOptions,
            operativeTasksOptions,filter
        } = this.props;
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedEditTaskConfigurationForm
                                title="Configuración de relación"
                                submitText="Guardar"
                                onSubmit={taskConfiguration => onTaskConfigurationEdit(taskConfiguration)}
                                structureTypeOption={structureTypeOption}
                                areasOptions = {areasOptions}
                                onChangeArea = {this.onChangeArea}
                                entrustedTasksOptions = {entrustedTasksOptions}
                                onChangeEntrustedTasks = {this.onChangeEntrustedTasks}
                                operativeTasksOptions = {operativeTasksOptions}
                                onChangeOperativeTasks = {this.onChangeOperativeTasks}
                                valueClear = {filter}
                                onCancel={goBack}
                                initialValues={TaskConfigurationToEdit}
                            />
                        </Suspense>
                        
                    </Col>
                </Row>
                <Row>
            <Col sm={12}>
                <Suspense fallback={<LoadingCard />}>
                    <TasksConfigurationsCards />
                </Suspense>
            </Col>
            </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        TaskConfigurationToEdit: getTaskConfigurationToEdit(state),
        structureTypeOption: getStructureTypeOption(state),
        areasOptions: getAreasOptions(state),
        entrustedTasksOptions: getEntrustedTasksOptions(state),
        operativeTasksOptions:getOperativeTasksOptions(state),
        filter:getFilter(state),
        cards: getCards(state)
    };
}

const mapDispatchToProps = dispatch => ({
    onTaskConfigurationEdit: (taskConfiguration) => dispatch(tryEditTaskConfiguration(taskConfiguration)),
    trySelectClearChildrens: (filter) => dispatch(trySelectClearChildrens(filter)),
    tryGetCardsChildrens: (filter) => dispatch(tryGetCardsChildrens(filter)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TasksConfigurationsPage);
