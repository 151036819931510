import React, { Component } from 'react';
import {
    Card, Button, Form, Row,
    Col,Table, Nav
} from 'react-bootstrap';

import Lightbox from 'react-lightbox-component';
import "react-lightbox-component/build/css/index.css";

import config from 'Config';

const displayIfExist = (text) => text?text:null;

export default class WorkOrderPreview extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            title,
            dataWorkOrder,
            listDocuments,
            listAttachedImages,
            onCancel,
            showField       
        } = this.props;
        return (
            <Card className="shadow">
                <Card.Header><h5>{title}</h5></Card.Header>
                <Card.Body className="preview">
                <Card.Text>
                    <Table responsive hover size="sm" className="card-table">
                        <tbody>
                            <tr>
                                <td>Nombre de la Obra (RQ )</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.requirement)}</td>
                            </tr>
                            <tr>
                                <td className="td-title">Cliente</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.comertialName)}</td>
                            </tr> 
                            <tr>
                                <td>Cotización</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.quotation)}</td>
                            </tr>
                            <tr>
                                <td>Estación</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.station)}</td>
                            </tr>  
                            <tr>
                                <td>Contrato</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.contract)}</td>
                            </tr>  
                            <tr>
                                <td>Contratista</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.contractorContact)}</td>
                            </tr>
                            <tr>
                                <td>Asignación</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.assignation)}</td>
                            </tr>  
                            <tr>
                                <td>Region</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.region)}</td>
                            </tr>  
                            <tr>
                                <td>Contacto Supervisor</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.supervisorContact)}</td>
                            </tr>  
                            <tr>
                                <td>Contacto en Sitio</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.onsiteContact)}</td>
                            </tr>  
                            <tr>
                                <td>Contacto Comercial</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.comertialContact)}</td>
                            </tr>  
                            <tr>
                                <td>Tipo de Estructura</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.structureType)}</td>
                            </tr>  
                            <tr>
                                <td>Altura</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.height)}</td>
                            </tr>  
                            <tr>
                                <td>Peso Estimado</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.estimatedWeight)}</td>
                            </tr>  
                            <tr>
                                <td>Peso Real</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.realWeight)}</td>
                            </tr>  
                            <tr>
                                <td>Fecha de Pedido</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.orderDate)}</td>
                            </tr>  
                            <tr>
                                <td>Plazo (en días)</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.deliveryDays)}</td>
                            </tr>  
                            <tr>
                                <td>Fecha de Inicio</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.startDate)}</td>
                            </tr>  
                            <tr>
                                <td>Fecha de Finalización</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.endDate)}</td>
                            </tr>  
                            <tr>
                                <td>Tareas Encomendadas</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.tasks)}</td>
                            </tr>
                            <tr>
                                <td>Detalle Particulares</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.particularDetails)}</td>
                            </tr>
                            <tr>
                                <td>Fecha de Liberación</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.releaseDate)}</td>
                            </tr>
                            <tr>
                                <td>Fecha de Entrega</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.deliveryDate)}</td>
                            </tr>
                            <tr>
                                <td>Certificacion</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.certification)}</td>
                            </tr>
                            <tr>
                                <td>Fecha de Certificación</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.certificationDate)}</td>
                            </tr>
                            <tr>
                                <td>Facturacion</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.facturation)}</td>
                            </tr>
                            <tr>
                                <td>Fecha de Facturación</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.invoiceDate)}</td>
                            </tr>
                            <tr>
                                <td>Observaciones</td>
                                <td className="text-let font-weight-bold">{displayIfExist(dataWorkOrder.observations)}</td>
                            </tr>
                            {(listDocuments && listDocuments.length > 0) ?
                            <tr>
                                <td>Listado de Documentos</td>
                                <td>
                                    <Nav>
                                    {listDocuments.map((doc) => {
                                        if(doc.workOrderDocumentTypeFK == 2){
                                            if(showField.canViewDoc == true ){
                                                return (
                                                    <Nav.Item>
                                                        <Nav.Link target='_blank' key={doc.id} href={doc.path}>{doc.name}</Nav.Link>
                                                    </Nav.Item>                                            
                                                    );   
                                            }
                                        }else {
                                            return (
                                                <Nav.Item>
                                                    <Nav.Link target='_blank' key={doc.id} href={doc.path}>{doc.name}</Nav.Link>
                                                </Nav.Item>                                            
                                                );
                                        }
                                        
                                    }
                                    )}
                                    </Nav>   
                                </td>
                            </tr>
                            : null}
                            {(listAttachedImages && listAttachedImages.length > 0) ?
                            <tr>
                                <td colspan="2">
                                    <Lightbox images = {listAttachedImages} />
                                </td>
                            </tr>
                            : null}
                        </tbody>
                    </Table>
                </Card.Text>                           
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col className="text-right">
                            <Button variant="danger" onClick={onCancel}>Cancelar</Button>
                        </Col>
                    </Row> 
                </Card.Footer>
            </Card>
        );
    }
}

