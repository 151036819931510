import React, { Suspense,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';
import  WorkOrderPreview  from 'Src/components/workOrders/workOrderPreview'
import {
     getWorkOrderToShow,getDocumentsList,
     getAttachedImagesList
} from "Src/redux/reducers/workOrdersCardsReducer";
import { getUserProfile } from "Src/redux/reducers/loginReducer";


class WorkOrderPreviewPage extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const {
            WorkOrderToShow,goBack,listDocuments,
            listAttachedImages,
            userProfile
        } = this.props
        let showField = {
            canViewDoc: false
          }
          if(userProfile == 'Administracion' || userProfile == 'Administrador'){
            showField.canViewDoc = true
          }
        return (
            <Container className="page-container">
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <WorkOrderPreview
                                title="Orden de trabajo"
                                dataWorkOrder={WorkOrderToShow}
                                listDocuments={listDocuments}
                                onCancel={goBack}
                                listAttachedImages={listAttachedImages}
                                showField={showField}
                                 />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        WorkOrderToShow: getWorkOrderToShow(state),
        listDocuments: getDocumentsList(state),
        listAttachedImages: getAttachedImagesList(state),
        userProfile: getUserProfile(state),
    };
}

const mapDispatchToProps = dispatch => ({
    goBack: () => dispatch(goBack()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkOrderPreviewPage);