import numeral from 'numeral';
import moment from 'moment';
import { isNumeric } from './validationService'

import config from 'Config';

export const currencyFormatter = (value) => numeral(value).format('$0,0[.]00a')

export const mapToHttpBody = (data) => {
  return Object.keys(data)
    .map(key =>
      encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const parseDate = (argDate) => moment(argDate, config.appSettings.DATE_FORMAT)

export const parseDateField = (date) => moment(date).format(config.appSettings.DATE_FORMAT)

export const addWeekdays = (date, days, withoutformat) => {
  date = moment(date);
  while (days > 1) {
    date = date.add(1, 'days');
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1;
    }
  }
  if (withoutformat)
    return date;

  return date.format(config.appSettings.DATE_FORMAT);
}


// Agrega los días con fin de semana
export const addWeekenddays = (date, days, withoutformat) => {
  date = moment(date);
  while (days > 1) {
    date = date.add(1, 'days');
  }
  if (withoutformat)
    return date;

  return date.format(config.appSettings.DATE_FORMAT);
}

export const formatDate = (argDate) => parseDate(argDate).toISOString()

export const toStringDate = (date) => date.format(config.appSettings.DATE_FORMAT)

export const inputformatDate = (date) => {
  let dateString = date.split('T');
  return dateString[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1');
}

export const getformatDate = (date) => {
  return date.replace(/^(\d{2})\/(\d{2})\/(\d{4})$/g, '$3-$2-$1');
}



const formatParse = (value, format) => {

  let numberValid = isNumeric(value) ? true : false;

  if (!numberValid)
    return value;

  if (format) {
    if (format.includes('%'))
      return percentFormatter(parseFloat(value), format);
    if (format.includes('p1'))
      return percentFormatter(parseFloat(value), '0.0%');
  }

  return numeral(value).format();

}

const percentFormatter = (value, format) => numeral(value).format(format)

export {
  percentFormatter,
  formatParse
};