import React, { Component } from 'react'
import config from 'Config';
import {
  Row, Col,
  Form, Alert
} from 'react-bootstrap';

export class FileInputField extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    const { input: { onChange } } = this.props
    onChange(e.target.files[0])
  }

  render() {
    const { input: { value } } = this.props
    const { input, label, required, meta, document } = this.props
    
    return (
      <Form.Group as={Row} controlId={name}>
        <Form.Label column sm="3">{label}</Form.Label>
        <Col sm="4">
          <input
            type='file'
            accept='.jpg, .png, .jpeg, .pdf'
            onChange={this.onChange}
          />
          {(meta.touched && meta.error) ?
            <Alert key="error" variant="danger">
              {meta.error}
            </Alert>
            : null}
        </Col>
        <Col sm="5">
        {document?
            document.map((doc) => (
              <a target='_blank'  href={config.apis.coreApi.URL+'/'+doc.uri} name={doc.name}>{doc.name}</a>
            )):''    
       
          }
        </Col>
      </Form.Group>
    );
  }
}
