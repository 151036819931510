import config from 'Config';

import { buildDefaultOptions } from './authService';
import {
    httpGet, httpPost, httpPut,httpDelete,
    handleHttpError
} from './httpService';
import { 
    buildUriById,
    get as _get , 
    getById as _getById,
    post as _post,
    deleteById as _deleteById,
    put as _put
} from 'Src/services/entityService'

import { formatDate } from './formatService';

const API_ENDPOINT = `${config.apis.coreApi.URL}/api`
const ENTITY_URI = `${API_ENDPOINT}/workorders`;

let get_Cancelation = undefined;
export const get = async (accessToken, filterText, pageSize, page, options) => 
                        _get(accessToken, ENTITY_URI, get_Cancelation, filterText, pageSize, page, options)

export const getById = async (accessToken, id) => 
                        _getById(accessToken, ENTITY_URI, id)

export const deleteById = async (accessToken, id) => 
                        _deleteById(accessToken, ENTITY_URI, id)

export const put = async (accessToken, entity) => {

    const params = {
        "id":entity.id,
        "clientFK": entity.clientFK,
        "station": entity.station,
        "contract": entity.contract,
        "requirement": entity.requirement,
        "assignation": entity.assignation,
        "quotation": entity.quotation,
        "address": entity.location.address,
        "coordinates": entity.location.coordinatesString,
        "regionFK": entity.regionFK,
        "supervisorContactFK": entity.supervisorContactFK,
        "onsiteContactFK": entity.onSiteContactFK,
        "comertialContactFK": entity.comertialContactFK,
        "contractorContactFK": entity.contractorContactFK,
        "structureTypeFK":entity.structureTypeFK,
        "height": entity.height,
        "estimatedWeight": entity.estimatedWeight,
        "realWeight": entity.realWeight,
        "deliveryDays": entity.deliveryDays,
        "partialCertification": false,
        "totalCertification": false,
        "partialInvoice": false,
        "totalInvoice": false,
        "invoiceDate": entity.invoiceDate,
        "observations": entity.observations
    }

    if (entity.facturation == "1") params.partialInvoice = true
    if (entity.facturation == "2") params.totalInvoice = true
    if (entity.certification == "1") params.partialCertification = true
    if (entity.certification == "2") params.totalCertification = true
    if (entity.particularDetails) params.particularDetails = JSON.stringify(entity.particularDetails)
    
    if (entity.entrustedTaskTypes) params.entrustedTaskTypeIds = entity.entrustedTaskTypes.value

    if (entity.startDate) params.startDate = formatDate(entity.startDate)
    if (entity.orderDate) params.orderDate = formatDate(entity.orderDate)
    if (entity.releaseDate) params.releaseDate = formatDate(entity.releaseDate)
    if (entity.deliveryDate) params.deliveryDate = formatDate(entity.deliveryDate)
    if (entity.certificationDate) params.certificationDate = formatDate(entity.certificationDate)
    if (entity.invoiceDate) params.invoiceDate = formatDate(entity.invoiceDate)

    return _put(accessToken, ENTITY_URI, params)
}
                        

export const post = async (accessToken, entity) => {
    
    const params = {
        "clientFK": entity.clientFK,
        "station": entity.station,
        "contract": entity.contract,
        "requirement": entity.requirement,
        "assignation": entity.assignation,
        "quotation": entity.quotation,
        "address": entity.location.address,
        "coordinates": entity.location.coordinatesString,
        "regionFK": entity.regionFK,
        "supervisorContactFK": entity.supervisorContactFK,
        "onsiteContactFK": entity.onSiteContactFK,
        "comertialContactFK": entity.comertialContactFK,
        "contractorContactFK": entity.contractorContactFK,
        "structureTypeFK":entity.structureTypeFK,
        "height": entity.height,
        "estimatedWeight": entity.estimatedWeight,
        "realWeight": entity.realWeight,
        "deliveryDays": entity.deliveryDays,
        "partialCertification": false,
        "totalCertification": false,
        "partialInvoice": false,
        "totalInvoice": false,
        "invoiceDate": entity.invoiceDate,
        "observations": entity.observations
    }

    if (entity.facturation == "1") params.partialInvoice = true
    if (entity.facturation == "2") params.totalInvoice = true
    if (entity.certification == "1") params.partialCertification = true
    if (entity.certification == "2") params.totalCertification = true
    if (entity.particularDetails) params.particularDetails = JSON.stringify(entity.particularDetails)

    if (entity.entrustedTaskTypes) params.entrustedTaskTypeIds = entity.entrustedTaskTypes.value

    if (entity.startDate) params.startDate = formatDate(entity.startDate)
    if (entity.orderDate) params.orderDate = formatDate(entity.orderDate)
    if (entity.releaseDate) params.releaseDate = formatDate(entity.releaseDate)
    if (entity.deliveryDate) params.deliveryDate = formatDate(entity.deliveryDate)
    if (entity.certificationDate) params.certificationDate = formatDate(entity.certificationDate)
    if (entity.invoiceDate) params.invoiceDate = formatDate(entity.invoiceDate)
    
    return _post(accessToken, ENTITY_URI, params)
}


export const addListFail = async (accessToken, workOrder,typefile) => {
    let resul = [];
    let files = [];
    if(typefile == 1)
        files= workOrder.files;

    if(typefile == 2)
        files= workOrder.taxReceiptsFiles;
        
        files.forEach(file => {
        if (file) {
            let addDocumentResponse = addDocument(accessToken, workOrder.id,file._file[0],typefile);
            resul.push(addDocumentResponse);
        }
    })
    return resul;
}

//  document post call ***
export const addDocument = async (accessToken, id,file,typefile) => {
    const URL = buildUriById(ENTITY_URI, id) + `/documents/`+typefile;

    let options = buildDefaultOptions(accessToken)
    options.headers['Content-Type'] = 'multipart/form-data';

    const formData = new FormData();
    formData.append("file", file);

    try {
        let response = await httpPost(URL, formData, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const getDocument = async (accessToken, id) => {
    const URL = buildUriById(ENTITY_URI, id) + `/documents`;

    let options = buildDefaultOptions(accessToken)
    try {
        let response = await httpGet(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const getTasks = async (accessToken, id) => {
    const URL = buildUriById(ENTITY_URI, id) + `/entrustedtasktype`;

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpGet(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const deleteDocument = async (accessToken, workOrderId, fileId) => {
    const URL = buildUriById(ENTITY_URI, workOrderId) + `/documents/`+fileId;

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpDelete(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const getPreview = async (accessToken, id) => {
    const URL = buildUriById(ENTITY_URI, id) + `/preview`;

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpGet(URL, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}

export const editState = async (accessToken, workOrderId,state) => {
    const URL = buildUriById(ENTITY_URI, workOrderId)+`/${state}`;

    let options = buildDefaultOptions(accessToken)

    try {
        let response = await httpPut(URL, null, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error);
    }
}