import {
  PARTICULARDETAILS_FORM_GET_REQUEST,
  PARTICULARDETAILS_FORM_GET_SUCCESS,
  PARTICULARDETAILS_FORM_GET_FAILTURE,
  PARTICULARDETAILS_FORM_EDIT_REQUEST,
  PARTICULARDETAILS_FORM_EDIT_SUCCESS,
  PARTICULARDETAILS_FORM_EDIT_FAILTURE,
  PARTICULARDETAILS_FORM_LOAD_REQUEST, 
  PARTICULARDETAILS_FORM_LOAD_SUCCESS, 
  PARTICULARDETAILS_FORM_LOAD_FAILTURE,
} from "./particularDetailsActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})


export const getRequest = (particularDetailId) => ({
  type: PARTICULARDETAILS_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    particularDetailId
  }
});

export const getSuccess = (response) => ({
  type: PARTICULARDETAILS_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const getFailture = (response) => ({
  type: PARTICULARDETAILS_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const editRequest = (particularDetailId) => ({
  type: PARTICULARDETAILS_FORM_EDIT_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    particularDetailId
  }
});

export const editSuccess = (response) => ({
  type: PARTICULARDETAILS_FORM_EDIT_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const editFailture = (response) => ({
  type: PARTICULARDETAILS_FORM_EDIT_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const formLoadRequest = () =>
  buildAction(
    PARTICULARDETAILS_FORM_LOAD_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )



export const formLoadSuccess = (clientsOptions, particularDetailTemplate, entrustedTaskTypes) =>
  buildAction(
    PARTICULARDETAILS_FORM_LOAD_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      clientsOptions,
      particularDetailTemplate,
      entrustedTaskTypes
    }
  )

export const formLoadFailture = (response) =>
  buildAction(
    PARTICULARDETAILS_FORM_LOAD_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )