import React from 'react'

import {
    Form, Col, Row
} from 'react-bootstrap'


export class checkboxField extends React.Component {
    render() {
    const {name, input, label,dato,withoutSize} = this.props;
    return (
        <Form.Group as={Row} controlId={name}>
            {label?
                <Form.Label column sm={withoutSize?null:3}>{label}</Form.Label>
            :null}            
            <Col sm={withoutSize?null:9}>
                <Form.Check  checked={input.value}  type="checkbox" {...input} />
            </Col>
        </Form.Group>
    )
    }
}