import * as actionTypes from "./workOrderTrackingCardsActionTypes";


// actionsService.js
export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const setFilter = (filter,statusWorkOrder) =>
  buildAction(
    actionTypes.WORKORDERSTRACKING_FILTER_SET,
    {
      filter,
      statusWorkOrder
    }
  )

export const getCardsRequest = () =>
  buildAction(
    actionTypes.WORKORDERSTRACKING_CARDS_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )

export const getCardsSuccess = (response) =>
  buildAction(
    actionTypes.WORKORDERSTRACKING_CARDS_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      records: response.records,
      metadata: response.metadata
    }
  )

export const getCardsFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERSTRACKING_CARDS_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )



export const getOptionsRequest = () =>
  buildAction(
    actionTypes.WORKORDERSTRACKING_OPTIONS_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )

export const getOptionsSuccess = (response) =>
  buildAction(
    actionTypes.WORKORDERSTRACKING_OPTIONS_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      response
    }
  )

export const getOptionsFailture = (response) =>
  buildAction(
    actionTypes.WORKORDERSTRACKING_OPTIONS_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )

export const setPage = (page) =>
  buildAction(
    actionTypes.WORKORDERSTRACKING_PAGE_SET,
    {
      page
    }
  )


export const cleanCards = () =>
  buildAction(
    actionTypes.WORKORDERSTRACKING_CARDS_CLEAN,
    {
      isFetching: false,
      hasError: false
    }
  )

