import { push } from "connected-react-router";
import { error, success } from "react-notification-system-redux";

import {
  setFilter,
  getCardsRequest,
  getCardsSuccess,
  getCardsFailture,
  setPage,
  createRequest,
  createFailture,
  createSuccess,
  deleteRequest,
  deleteSuccess,
  deleteFailture,
  formLoadRequest,
  formLoadSuccess,
  formLoadFailture,
  clientChildrenRequest,
  clientChildrenSuccess,
  clientChildrenFailture,
  getRequest,
  getSuccess,
  getFailture,
  getPreviewRequest,
  getPreviewSuccess,
  getPreviewFailture,
  editRequest,
  editSuccess,
  editFailture,
  cleanCards,
  deleteDocumentRequest,
  deleteDocumentSuccess,
  deleteDocumentFailture,
  formDuplicateLoadRequest,
  formDuplicateLoadSuccess,
  formDuplicateLoadFailture,
  addClientRequest,
  addClientSuccess,
  addClientFailture,
  getOptionsRequest,
  getOptionsSuccess,
  getOptionsFailture,
  editStateRequest,
  editStateSuccess,
  editStateFailture,
} from "Src/redux/actions/workOrdersCardsActions";

import {
  get,
  post,
  deleteById,
  addListFail,
  getById,
  put,
  getDocument,
  deleteDocument,
  getTasks,
  getPreview,
  editState,
} from "Src/services/workOrdersService";

import { post as postClientContact } from "Src/services/clientContactsService";

import {
  get as getClients,
  getContacts,
  getRegions,
} from "Src/services/clientsService";

import {
  getById as getStructureTypeById,
  get as getStructureType,
} from "Src/services/structureTypesService";

import { getById as getParticulaDetailsById } from "Src/services/particularDetailsService";

import { get as getEntrustedTaskType } from "Src/services/taskEntrustedService";

import { inputformatDate } from "Src/services/formatService";

import { get as getContactTypes } from "Src/services/contactTypesService";

import {
  WORKORDERS_URI,
  WORKORDERS_FORM_URI,
  WORKORDERS_FORM_DUPLICATE_URI,
  WORKORDERS_FORM_PREVIEW_URI,
} from "Src/routes";

const ENTITY_NAME = "Orden de Trabajo";

//TODO: THIS IS PRETTY GENERIC COULD BE SHARED
const ERROR_TITLE = "Oops!";
//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5
//
//TODO IT LOOKS PRETTY CARDS GENERIC

const workOrdersCardsNotification = {
  title: "Generic Title",
  message: "Generic Message",
  position: "tr",
};

const getVadationValue = (value) => {
  return value.length > 0 ? value : null;
};

export const setCardsMount = () => {
  return async (dispatch, getState) => {
    dispatch(setFilter("", 0));
    const { loginReducer, workOrdersCardsReducer } = getState();

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        "",
        workOrdersCardsReducer.metadata.perPage,
        1
      )
    );
  };
};

export const setShowCards = (filterText, statusWorkOrder) => {
  return async (dispatch, getState) => {
    const { workOrdersCardsReducer } = getState();

    dispatch(setFilter(filterText, statusWorkOrder));

    const { loginReducer } = getState();

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        filterText,
        workOrdersCardsReducer.metadata.perPage,
        1
      )
    );
  };
};

export const setCardsFilter = (filterText, statusWorkOrder) => {
  return async (dispatch, getState) => {
    const { workOrdersCardsReducer } = getState();

    dispatch(setFilter("", 0));

    const { loginReducer } = getState();

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        filterText,
        workOrdersCardsReducer.metadata.perPage,
        1
      )
    );
  };
};

export const refreshCardsFilter = () => {
  return async (dispatch, getState) => {
    const { loginReducer, workOrdersCardsReducer } = getState();

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        workOrdersCardsReducer.filter.text,
        workOrdersCardsReducer.metadata.perPage,
        1
      )
    );
  };
};

export const setCardsNextPage = () => {
  return async (dispatch, getState) => {
    const { loginReducer, workOrdersCardsReducer } = getState();

    if (!workOrdersCardsReducer.metadata.hasMore) return;

    const nextPage = workOrdersCardsReducer.metadata.page + 1;

    dispatch(setPage(nextPage));

    dispatch(
      tryGetCardsAction(
        loginReducer.authentication.access_token,
        workOrdersCardsReducer.filter.text,
        workOrdersCardsReducer.metadata.perPage,
        nextPage
      )
    );
  };
};

export const tryGetOptionsToList = (accessToken, filter, pageSize, page) => {
  return async (dispatch) => {
    dispatch(getOptionsRequest());

    let response = await get(accessToken, filter, pageSize, page);

    if (response && response.cancelled) return;

    const { error } = response;

    if (error) {
      dispatch(getOptionsFailture(response));

      showError(
        dispatch,
        ERROR_TITLE,
        `Ha ocurrido un error al cosultar las Cards. Detalle: '${error.message}'`
      );

      return;
    }

    dispatch(getOptionsSuccess(response.records));
  };
};

export const tryGetCardsAction = (accessToken, filter, pageSize, page) => {
  return async (dispatch) => {
    dispatch(getCardsRequest());

    let response = await get(accessToken, filter, pageSize, page,{sortOrder:1,sortColumn:"id"});

    if (response && response.cancelled) return;

    const { error } = response;

    if (error) {
      dispatch(getCardsFailture(response));

      showError(
        dispatch,
        ERROR_TITLE,
        `Ha ocurrido un error al cosultar las Cards. Detalle: '${error.message}'`
      );

      return;
    }

    dispatch(getCardsSuccess(response));
  };
};

//TODO IT LOOKS PRETTY CARDS GENERIC
export const tryCreate = (workOrder) => {
  return async (dispatch, getState) => {
    dispatch(createRequest(workOrder));

    const { access_token } = getState().loginReducer.authentication;

    if (
      workOrder.particularDetails &&
      typeof workOrder.particularDetails !== "string"
    ) {
      let particularDetailsInput = workOrder.particularDetails.value;
      const particularDetailsSelect = [];

      particularDetailsInput.forEach((element) => {
        particularDetailsSelect.unshift(element.value);
      });

      workOrder.particularDetails.value = particularDetailsSelect;
    }

    if (typeof workOrder.particularDetails === "string") {
      workOrder.particularDetails = JSON.parse(workOrder.particularDetails);
    }

    if (
      workOrder.entrustedTaskTypes &&
      typeof workOrder.entrustedTaskTypes !== "string"
    ) {
      let entrustedTaskTypesInput = getVadationValue(
        workOrder.entrustedTaskTypes.value
      );
      if (entrustedTaskTypesInput !== null) {
        if (entrustedTaskTypesInput[0].hasOwnProperty("value")) {
          const entrustedTaskTypesSelect = [];

          entrustedTaskTypesInput.forEach((element) => {
            entrustedTaskTypesSelect.unshift(element.value);
          });

          workOrder.entrustedTaskTypes.value = entrustedTaskTypesSelect;
        }
      }
    }

    if (typeof workOrder.entrustedTaskTypes === "string") {
      workOrder.entrustedTaskTypes = JSON.parse(workOrder.entrustedTaskTypes);
    }

    let response = await post(access_token, workOrder);

    const { error, code } = response;
    const hasError = error || Number.isInteger(code);

    if (hasError) {
      dispatch(createFailture(response));

      let message = `Ha ocurrido un error al Crear la ${ENTITY_NAME}.`;

      if (code == 4) message += ` La entidad ya existe.`;
      if (code == 6) message += ` Las validaciones fallaron.`;

      showError(dispatch, ERROR_TITLE, message);

      return;
    }
    dispatch(createSuccess(response));

    workOrder.id = response.id;

    if (workOrder.files) {
      let addDocumentResponse = await addListFail(access_token, workOrder, 1);
      if (addDocumentResponse.hasOwnProperty("error")) {
        dispatch(createFailture(addDocumentResponse));
        dispatch(
          error({
            ...workOrdersCardsNotification,
            title: "Oops!",
            message: `Ha ocurrido un error al agregar un nuevo archivo. Detalle: '${addDocumentResponse.error.message}'`,
          })
        );
      }
    }

    if (workOrder.taxReceiptsFiles) {
      let addDocumentResponse = await addListFail(access_token, workOrder, 2);
      if (addDocumentResponse.hasOwnProperty("error")) {
        dispatch(createFailture(addDocumentResponse));
        dispatch(
          error({
            ...workOrdersCardsNotification,
            title: "Oops!",
            message: `Ha ocurrido un error al agregar un nuevo archivo. Detalle: '${addDocumentResponse.error.message}'`,
          })
        );
      }
    }

    showSuccess(
      dispatch,
      `${ENTITY_NAME} ${workOrder.id}`,
      `${ENTITY_NAME} exitosamente!`
    );

    dispatch(push(WORKORDERS_URI));
  };
};

export const tryDeleteWorkOrder = (WorkOrderId) => {
  return async (dispatch, getState) => {
    let shouldDelete = confirm(
      "Desea borrar la orden de trabajo Id: " + WorkOrderId
    );
    if (!shouldDelete) {
      return;
    }

    dispatch(deleteRequest(WorkOrderId));

    const { loginReducer } = getState();
    const { access_token } = loginReducer.authentication;

    let response = await deleteById(access_token, WorkOrderId);
    if (response.hasOwnProperty("error")) {
      dispatch(deleteFailture(response));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al Borrar la orden de trabajo. Detalle: '${response.error.message}'`,
        })
      );

      return;
    }
    dispatch(deleteSuccess(WorkOrderId));

    dispatch(
      success({
        ...workOrdersCardsNotification,
        title: "WorkOrdere " + WorkOrderId,
        message: `Eliminado exitosamente!`,
      })
    );
  };
};

export const tryFormLoad = () => {
  return async (dispatch, getState) => {
    dispatch(formLoadRequest());

    const { access_token } = getState().loginReducer.authentication;

    const clientsPromise = getClients(access_token, null, 1000, 1);

    const structureTypePromise = getStructureType(access_token, null, 1000, 1);

    const PARTICULARDETAIL_TYPE_DEFAULT = 1;
    const particularDetailsPromise = getParticulaDetailsById(
      access_token,
      PARTICULARDETAIL_TYPE_DEFAULT
    );

    const entrustedTaskTypesPromise = getEntrustedTaskType(
      access_token,
      false,
      1000,
      1
    );

    const clientsResponse = await clientsPromise;
    if (clientsResponse) {
      const { error, code } = clientsResponse;
      const hasError = error || Number.isInteger(code);

      if (hasError) {
        dispatch(formLoadFailture(clientsResponse));

        let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`;

        showError(dispatch, ERROR_TITLE, message);

        return;
      }
    }

    const structureTypeResponse = await structureTypePromise;
    if (structureTypeResponse) {
      const { error, code } = structureTypeResponse;
      const hasError = error || Number.isInteger(code);

      if (hasError) {
        dispatch(formLoadFailture(structureTypeResponse));

        let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`;

        showError(dispatch, ERROR_TITLE, message);

        return;
      }
    }

    const particularDetailsResponse = await particularDetailsPromise;
    if (particularDetailsResponse) {
      const { error, code } = particularDetailsResponse;
      const hasError = error || Number.isInteger(code);

      if (hasError) {
        dispatch(formLoadFailture(particularDetailsResponse));

        let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`;

        showError(dispatch, ERROR_TITLE, message);

        return;
      }
    }

    const entrustedTaskTypesResponse = await entrustedTaskTypesPromise;
    if (entrustedTaskTypesResponse) {
      const { error, code } = entrustedTaskTypesResponse;
      const hasError = error || Number.isInteger(code);

      if (hasError) {
        dispatch(formLoadFailture(entrustedTaskTypesResponse));

        let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`;

        showError(dispatch, ERROR_TITLE, message);

        return;
      }
    }
    const contactTypesPromise = getContactTypes(access_token, "", 1000, 1);

    const contactTypesResponse = await contactTypesPromise;

    if (contactTypesResponse) {
      const { error, code } = contactTypesResponse;
      const hasError = error || Number.isInteger(code);

      if (hasError) {
        dispatch(formLoadFailture(contactTypesResponse));

        let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`;

        showError(dispatch, ERROR_TITLE, message);

        return;
      }
    }

    dispatch(
      formLoadSuccess(
        clientsResponse.records,
        structureTypeResponse.records,
        entrustedTaskTypesResponse,
        particularDetailsResponse,
        contactTypesResponse.records
      )
    );
  };
};

export const tryGetClientChildren = (clientId) => {
  return async (dispatch, getState) => {
    dispatch(clientChildrenRequest());

    const { access_token } = getState().loginReducer.authentication;

    let getContactRequest = getContacts(access_token, clientId);
    let getWorkOrdersRequest = getRegions(access_token, clientId);

    const contactsResponse = await getContactRequest;
    const { contactsError, contactsCode } = contactsResponse;
    const contactsHasError = contactsError || Number.isInteger(contactsCode);

    if (contactsHasError) {
      dispatch(clientChildrenFailture(contactsResponse));

      let message = `Ha ocurrido un error al cargar los Contactos.`;

      showError(dispatch, ERROR_TITLE, message);

      return;
    }

    const WorkOrderResponse = await getWorkOrdersRequest;

    const { WorkOrderError, WorkOrderCode } = WorkOrderResponse;
    const WorkOrderHasError = WorkOrderError || Number.isInteger(WorkOrderCode);

    if (WorkOrderHasError) {
      dispatch(clientChildrenFailture(WorkOrderResponse));

      let message = `Ha ocurrido un error al cargar las WorkOrderes.`;

      showError(dispatch, ERROR_TITLE, message);

      return;
    }

    dispatch(clientChildrenSuccess(contactsResponse, WorkOrderResponse));
  };
};

export const tryEditWorkOrder = (WorkOrder) => {
  return async (dispatch, getState) => {
    dispatch(editRequest(WorkOrder));

    const { loginReducer } = getState();
    const { access_token } = loginReducer.authentication;

    if (
      WorkOrder.particularDetails &&
      typeof WorkOrder.particularDetails !== "string"
    ) {
      let particularDetailsInput = WorkOrder.particularDetails.value;
      const particularDetailsSelect = [];

      particularDetailsInput.forEach((element) => {
        particularDetailsSelect.unshift(element.value);
      });

      WorkOrder.particularDetails.value = particularDetailsSelect;
    }

    if (typeof WorkOrder.particularDetails === "string") {
      WorkOrder.particularDetails = JSON.parse(WorkOrder.particularDetails);
    }

    if (
      WorkOrder.entrustedTaskTypes &&
      typeof WorkOrder.entrustedTaskTypes !== "string"
    ) {
      let entrustedTaskTypesInput = getVadationValue(
        WorkOrder.entrustedTaskTypes.value
      );
      if (entrustedTaskTypesInput !== null) {
        if (entrustedTaskTypesInput[0].hasOwnProperty("value")) {
          const entrustedTaskTypesSelect = [];

          entrustedTaskTypesInput.forEach((element) => {
            entrustedTaskTypesSelect.unshift(element.value);
          });

          WorkOrder.entrustedTaskTypes.value = entrustedTaskTypesSelect;
        }
      }
    }

    if (typeof WorkOrder.entrustedTaskTypes === "string") {
      WorkOrder.entrustedTaskTypes = JSON.parse(WorkOrder.entrustedTaskTypes);
    }

    let response = await put(access_token, WorkOrder);
    if (response.hasOwnProperty("error")) {
      dispatch(editFailture(response));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al actualizar la orden de trabajo. Detalle: '${response.error.message}'`,
        })
      );

      return;
    }
    dispatch(editSuccess(response));

    if (WorkOrder.files) {
      let addDocumentResponse = await addListFail(access_token, WorkOrder, 1);
      if (addDocumentResponse.hasOwnProperty("error")) {
        dispatch(createFailture(addDocumentResponse));
        dispatch(
          error({
            ...workOrdersCardsNotification,
            title: "Oops!",
            message: `Ha ocurrido un error al agregar un nuevo archivo. Detalle: '${addDocumentResponse.error.message}'`,
          })
        );
      }
    }

    if (WorkOrder.taxReceiptsFiles) {
      let addDocumentResponse = await addListFail(access_token, WorkOrder, 2);
      if (addDocumentResponse.hasOwnProperty("error")) {
        dispatch(createFailture(addDocumentResponse));
        dispatch(
          error({
            ...workOrdersCardsNotification,
            title: "Oops!",
            message: `Ha ocurrido un error al agregar un nuevo archivo. Detalle: '${addDocumentResponse.error.message}'`,
          })
        );
      }
    }

    dispatch(
      success({
        ...workOrdersCardsNotification,
        title: "WorkOrder " + response.id,
        message: `Actualizado exitosamente!`,
      })
    );

    dispatch(push(WORKORDERS_URI));
    dispatch(cleanCards());
    dispatch(refreshCardsFilter());
  };
};

export const tryGetWorkOrderPreview = (workOrderId) => {
  return async (dispatch, getState) => {
    if (!workOrderId) return;

    dispatch(getPreviewRequest(workOrderId));

    const { loginReducer } = getState();
    const { access_token } = loginReducer.authentication;

    let response = await getPreview(access_token, workOrderId);

    if (response.hasOwnProperty("error")) {
      dispatch(getPreviewFailture(response));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar la Orden de trabajo. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }

    if (response.startDate) {
      response.startDate = inputformatDate(response.startDate);
    }
    if (response.orderDate) {
      response.orderDate = inputformatDate(response.orderDate);
    }
    if (response.endDate) {
      response.endDate = inputformatDate(response.endDate);
    }
    if (response.releaseDate) {
      response.releaseDate = inputformatDate(response.releaseDate);
    }
    if (response.deliveryDate) {
      response.deliveryDate = inputformatDate(response.deliveryDate);
    }
    if (response.certificationDate) {
      response.certificationDate = inputformatDate(response.certificationDate);
    }
    if (response.invoiceDate) {
      response.invoiceDate = inputformatDate(response.invoiceDate);
    }

    if (response.onsiteContactFK)
      response.onSiteContactFK = response.onsiteContactFK;

    if (response.partialInvoice == true) response.facturation = "Parcial";
    if (response.totalInvoice == true) response.facturation = "Total";

    if (response.partialCertification == true)
      response.certification = "Parcial";
    if (response.totalCertification == true) response.certification = "Total";

    response.location = {
      address: response.address,
      coordinatesString: response.coordinates,
    };

    let regionsWordOrder = await getRegions(access_token, response.clientFK);
    if (regionsWordOrder.hasOwnProperty("error")) {
      dispatch(getPreviewFailture(regionsWordOrder));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar las regiones. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }

    let contactRequest = await getContacts(access_token, response.clientFK);
    if (contactRequest.hasOwnProperty("error")) {
      dispatch(getPreviewFailture(contactRequest));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar los contactos. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }

    let documentsWordOrder = await getDocument(access_token, workOrderId);
    if (documentsWordOrder.hasOwnProperty("error")) {
      dispatch(getPreviewFailture(documentsWordOrder));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar el listado de documentos. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }

    let tasksWordOrder = await getTasks(access_token, response.id);
    if (tasksWordOrder.hasOwnProperty("error")) {
      dispatch(getPreviewFailture(tasksWordOrder));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar las tareas. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }
    let entrustedTaskTypes = tasksWordOrder.map((t) => t.id);
    response.entrustedTaskTypes = { value: entrustedTaskTypes };

    dispatch(
      getPreviewSuccess(
        response,
        regionsWordOrder,
        documentsWordOrder,
        contactRequest
      )
    );

    dispatch(push(WORKORDERS_FORM_PREVIEW_URI + "/" + workOrderId));
  };
};

export const tryGetWorkOrder = (workOrderId) => {
  return async (dispatch, getState) => {
    if (!workOrderId) return;

    dispatch(getRequest(workOrderId));

    const { loginReducer } = getState();
    const { access_token } = loginReducer.authentication;

    let response = await getById(access_token, workOrderId);

    if (response.hasOwnProperty("error")) {
      dispatch(getFailture(response));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar la Orden de trabajo. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }

    if (response.startDate) {
      let startDate = inputformatDate(response.startDate);
      response.startDate = startDate;
    }
    if (response.orderDate) {
      let orderDate = inputformatDate(response.orderDate);
      response.orderDate = orderDate;
    }
    if (response.releaseDate) {
      let releaseDate = inputformatDate(response.releaseDate);
      response.releaseDate = releaseDate;
    }
    if (response.deliveryDate) {
      let deliveryDate = inputformatDate(response.deliveryDate);
      response.deliveryDate = deliveryDate;
    }
    if (response.certificationDate) {
      let certificationDate = inputformatDate(response.certificationDate);
      response.certificationDate = certificationDate;
    }
    if (response.invoiceDate) {
      let invoiceDate = inputformatDate(response.invoiceDate);
      response.invoiceDate = invoiceDate;
    }

    if (response.onsiteContactFK)
      response.onSiteContactFK = response.onsiteContactFK;

    if (response.partialInvoice == true) response.facturation = "1";
    if (response.totalInvoice == true) response.facturation = "2";

    if (response.partialCertification == true) response.certification = "1";
    if (response.totalCertification == true) response.certification = "2";

    response.location = {
      address: response.address,
      coordinatesString: response.coordinates,
    };

    let regionsWordOrder = await getRegions(access_token, response.clientFK);
    if (regionsWordOrder.hasOwnProperty("error")) {
      dispatch(getFailture(regionsWordOrder));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar las regiones. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }

    let contactRequest = await getContacts(access_token, response.clientFK);
    if (contactRequest.hasOwnProperty("error")) {
      dispatch(getFailture(contactRequest));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar los contactos. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }

    let documentsWordOrder = await getDocument(access_token, response.id);
    if (documentsWordOrder.hasOwnProperty("error")) {
      dispatch(getFailture(documentsWordOrder));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar el listado de documentos. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }

    let tasksWordOrder = await getTasks(access_token, response.id);
    if (tasksWordOrder.hasOwnProperty("error")) {
      dispatch(getFailture(tasksWordOrder));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar las tareas. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }
    let arrayTasks = tasksWordOrder.map((t) => t.id);
    response.entrustedTaskTypes = { value: arrayTasks };

    dispatch(
      getSuccess(response, regionsWordOrder, documentsWordOrder, contactRequest)
    );

    dispatch(push(WORKORDERS_FORM_URI + "/" + workOrderId));
  };
};

const dataTypeForm = (form, type) => {
  let dataForm = null;
  if (type == "add") {
    const { CREATE_WORKORDER_FORM } = form;
    dataForm = CREATE_WORKORDER_FORM;
  }

  if (type == "edit") {
    const { EDIT_WORKORDER_FORM } = form;
    dataForm = EDIT_WORKORDER_FORM;
  }

  return dataForm;
};

export const tryAddClientContactInWorkOrder = (clientContact, typeForm) => {
  return async (dispatch, getState) => {
    dispatch(addClientRequest(clientContact));

    const { loginReducer, form } = getState();
    const { access_token } = loginReducer.authentication;

    const dataForm = dataTypeForm(form, typeForm);

    if (!dataForm.hasOwnProperty("values")) {
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al Crear el Contacto del Cliente. Detalle: Seleccione tipo de Cliente`,
        })
      );

      return;
    }

    const { values } = dataForm;

    if (!values.clientFK) {
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al Crear el Contacto del Cliente. Detalle: Seleccione tipo de Cliente`,
        })
      );

      return;
    }

    clientContact.clientFK = values.clientFK;

    let response = await postClientContact(access_token, clientContact);

    if (response.code) {
      dispatch(addClientFailture(response));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al Crear el Contacto del Cliente. Detalle: '${response.message}'`,
        })
      );

      return;
    }

    if (response.hasOwnProperty("error")) {
      dispatch(addClientFailture(response));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al Crear el Contacto del Cliente. Detalle: '${response.error.message}'`,
        })
      );

      return;
    }

    dispatch(addClientSuccess(response));

    clientContact.id = response.id;

    dispatch(
      success({
        ...workOrdersCardsNotification,
        title: "Contacto de Cliente " + clientContact.id,
        message: `Creado exitosamente!`,
      })
    );
    dispatch(tryGetClientChildren(response.clientFK));
  };
};

export const tryGetDuplicateWorkOrder = (workOrderId) => {
  return async (dispatch, getState) => {
    if (!workOrderId) return;

    dispatch(formDuplicateLoadRequest(workOrderId));

    const { loginReducer } = getState();
    const { access_token } = loginReducer.authentication;

    let response = await getById(access_token, workOrderId);

    if (response.hasOwnProperty("error")) {
      dispatch(formDuplicateLoadFailture(response));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar la Orden de trabajo. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }

    let responseWorkOrderSucced = {
      clientFK: response.clientFK,
      quotation: response.quotation,
      station: response.station,
      contract: response.contract,
      requirement: response.requirement,
      assignation: response.assignation,
      regionFK: response.regionFK,
      supervisorContactFK: response.supervisorContactFK,
      onSiteContactFK: response.onsiteContactFK,
      comertialContactFK: response.comertialContactFK,
      structureTypeFK: response.structureTypeFK,
      height: response.height,
      estimatedWeight: response.estimatedWeight,
      realWeight: response.realWeight,
      deliveryDays: response.deliveryDays,
      particularDetails: response.particularDetails,
    };

    responseWorkOrderSucced.location = {
      address: response.address,
      coordinatesString: response.coordinates,
    };

    let regionsWordOrder = await getRegions(access_token, response.clientFK);
    if (regionsWordOrder.hasOwnProperty("error")) {
      dispatch(formDuplicateLoadFailture(regionsWordOrder));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar las regiones. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }

    let contactRequest = await getContacts(access_token, response.clientFK);
    if (contactRequest.hasOwnProperty("error")) {
      dispatch(formDuplicateLoadFailture(contactRequest));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar los contactos. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }

    let tasksWordOrder = await getTasks(access_token, response.id);
    if (tasksWordOrder.hasOwnProperty("error")) {
      dispatch(formDuplicateLoadFailture(tasksWordOrder));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido al cargar las tareas. Detalle: '${response.error.message}'`,
        })
      );
      return;
    }
    let arrayTasks = tasksWordOrder.map((t) => t.id);
    responseWorkOrderSucced.entrustedTaskTypes = { value: arrayTasks };

    dispatch(
      formDuplicateLoadSuccess(
        responseWorkOrderSucced,
        regionsWordOrder,
        contactRequest
      )
    );

    dispatch(push(WORKORDERS_FORM_DUPLICATE_URI));
  };
};

export const tryDeleteDocumentWorkOrder = (WorkOrderDocument) => {
  return async (dispatch, getState) => {
    let shouldDelete = confirm(
      "Desea borrar el documento : " + WorkOrderDocument.name
    );
    if (!shouldDelete) {
      return;
    }
    let WorkOrderDocId = WorkOrderDocument.fileId;
    let workOrderId = WorkOrderDocument.workOrderId;

    dispatch(deleteDocumentRequest(WorkOrderDocId));

    const { loginReducer } = getState();
    const { access_token } = loginReducer.authentication;

    let response = await deleteDocument(
      access_token,
      workOrderId,
      WorkOrderDocId
    );
    if (response.hasOwnProperty("error")) {
      dispatch(deleteDocumentFailture(response));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al borrar el archivo. Detalle: '${response.error.message}'`,
        })
      );

      return;
    }
    dispatch(deleteDocumentSuccess(WorkOrderDocId));

    dispatch(
      success({
        ...workOrdersCardsNotification,
        title: "WorkOrdereDocument " + WorkOrderDocId,
        message: `Eliminado exitosamente!`,
      })
    );
  };
};

export const tryEditStateWorkOrder = (workOrderId, state) => {
  return async (dispatch, getState) => {
    const { loginReducer } = getState();
    const { access_token } = loginReducer.authentication;

    dispatch(editStateRequest(workOrderId, state));

    let editStateResponse = await editState(access_token, workOrderId, state);

    if (editStateResponse.hasOwnProperty("error")) {
      dispatch(editStateFailture(editStateResponse));
      dispatch(
        error({
          ...workOrdersCardsNotification,
          title: "Oops!",
          message: `Ha ocurrido un error al cambiar el estado de la orden de trabajo. Detalle: '${editStateResponse.error.message}'`,
        })
      );
      return;
    }

    dispatch(
      success({
        ...workOrdersCardsNotification,
        title: "Orden de trabajo " + workOrderId,
        message: `Actualizado exitosamente!`,
      })
    );

    dispatch(editStateSuccess(editStateResponse));
    dispatch(cleanCards());
    dispatch(refreshCardsFilter());
  };
};

const NOTIFICATION = {
  title: "Generic Title",
  message: "Generic Message",
  position: "tr",
};

const showSuccess = (dispatch, title, message) =>
  dispathNotification(dispatch, success, title, message);

const showError = (dispatch, title, message) =>
  dispathNotification(dispatch, error, title, message);

const dispathNotification = (dispatch, typeFunc, title, message) =>
  dispatch(
    typeFunc({
      ...NOTIFICATION,
      title,
      message,
    })
  );
