import { success, error } from 'react-notification-system-redux';
import { push } from 'connected-react-router';
import { loginSuccess, loginRequest, loginFailture } from 'Src/redux/actions/loginActions';
import { sendEmailRequest,sendEmailSuccess,sendEmailFailture,
    resetPasswordRequest,resetPasswordSuccess,resetPasswordFailture
 } from 'Src/redux/actions/resetPasswordActions';
import { LOGIN_URI } from 'Src/routes';

import config from 'Config';

import { authenticate,askPasswordChange,resetPassword } from 'Src/services/authService';

const loginNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

export const tryLogInAction = (email, password) => {
    return async (dispatch) => {
        dispatch(loginRequest(email, password));

        let response = await authenticate(email, password)

        if (response.hasOwnProperty("error")) {

            dispatch(loginFailture(response));

            dispatch(error({
                ...loginNotification,
                title: "Oops!",
                message: `Se produjo un error al querer iniciar sesion: Usuario y Contraseña no validos`
            }));

            return;
        }

        dispatch(loginSuccess(response));

        dispatch(success({
            ...loginNotification,
            title: "Bienvenido!",
            message: `Ingresaste en ${config.appSettings.name} ${config.appSettings.version}`
        }));

    }
}

export const trySendEmail = (email) => {
    return async (dispatch) => {
        dispatch(sendEmailRequest(email));

        let response = await askPasswordChange(email)

        if (response.hasOwnProperty("error")) {
            dispatch(sendEmailFailture(response));

            if(response.response.response.data.error.code == 1){
                dispatch(error({
                    ...loginNotification,
                    title: "Oops!",
                    message: `Se produjo un error al querer reiniciar la password: El correo ingresado no existe`
                }));

                return;
            }

            dispatch(error({
                ...loginNotification,
                title: "Oops!",
                message: `Se produjo un error al querer reiniciar la password: '${response.error.message}'`
            }));

            return;
        }

        dispatch(sendEmailSuccess(response));

        dispatch(success({
            ...loginNotification,
            title: "Email Enviado!",
            message: `Verifique su correo`
        }));

        dispatch(push(LOGIN_URI));

    }
}

export const tryResetPassword = (password,token) => {
    return async (dispatch) => {
        dispatch(resetPasswordRequest(password,token));

        let response = await resetPassword(password,token)

        if (response.hasOwnProperty("error")) {
            dispatch(resetPasswordFailture(response));

            dispatch(error({
                ...loginNotification,
                title: "Oops!",
                message: `Se produjo un error al querer reiniciar la password: '${response.error.message}'`
            }));

            return;
        }

        dispatch(resetPasswordSuccess(response));

        dispatch(success({
            ...loginNotification,
            title: "Email Enviado!",
            message: `Verifique su correo`
        }));

        dispatch(push(LOGIN_URI));

    }
}