import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';
import { change } from 'redux-form';

import {
    getRequest, getSuccess, getFailture,getCardsFilterSuccess,
    formLoadRequest,formLoadSuccess,formLoadFailture,
    addRequest, addSuccess, addFailture,
    editRequest, editSuccess, editFailture,
    getCardsRequest,getCardsFailture,getCardsSuccess,
    setFilter,setPage,
    deleteRequest, deleteFailture, deleteSuccess
} from "Src/redux/actions/tasksConfigurationsActions";

import {
    get, getById, put, post, deleteById
} from 'Src/services/tasksConfigurationsService';

import {
    get as getArea, getById as getByIdArea
} from 'Src/services/areasService';

import {
    get as getEntrustedTask, getById as getByIdEntrustedTask
} from 'Src/services/taskEntrustedService';

import {
    get as getOperativeTask, getById as getByIdOperativeTask
} from 'Src/services/operativeTaskTypesService';


import { TASKSCONFIGURATIONS_FORM_URI,STRUCTURETYPES_URI } from "Src/routes";


const tasksConfigurationsCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5


export const setCardsFilter = () => {
    return async (dispatch, getState) => {
        const { tasksConfigurationsReducer } = getState();
        let { filter,cardsAll } = tasksConfigurationsReducer

        let days = undefined;
        let daysComplementaryWork = undefined;
        let daysNewWork = undefined;
        let order = undefined;
        
        let response = [];
            
        if(filter.EntrustedTaskId && filter.areaId){
            if(filter.EntrustedTaskId != "-1" && filter.areaId != "-1"){
                response = cardsAll.filter((tc)=> tc.entrustedTaskTypeId == filter.EntrustedTaskId && tc.areaId == filter.areaId)
            }
        }

        if(filter.EntrustedTaskId && filter.areaId && filter.OperativeTaskId){
            if(filter.EntrustedTaskId != "-1" && filter.areaId != "-1" && filter.OperativeTaskId != "-1"){
                response = response.filter((tc)=> tc.operativeTaskTypeId == filter.OperativeTaskId)
                if(response.length > 0){
                    days = response[0].days;
                    daysComplementaryWork = response[0].daysComplementaryWork;                    
                    daysNewWork = response[0].daysNewWork;                    
                    order = response[0].order;
                }
            }
        }
        
        if(filter.areaId && !filter.EntrustedTaskId &&  !filter.OperativeTaskId){
            dispatch(change('EDIT_TASKCONFIGURATION_NAME', 'entrustedTaskTypeFK', '-1'))
            dispatch(change('EDIT_TASKCONFIGURATION_NAME', 'operativeTaskTypeFK', '-1'))
        }

        if(filter.areaId && filter.EntrustedTaskId &&  !filter.OperativeTaskId){
            dispatch(change('EDIT_TASKCONFIGURATION_NAME', 'operativeTaskTypeFK', '-1'))
        }
        
        dispatch(change('EDIT_TASKCONFIGURATION_NAME', 'days', days))
        dispatch(change('EDIT_TASKCONFIGURATION_NAME', 'daysComplementaryWork', daysComplementaryWork));
        dispatch(change('EDIT_TASKCONFIGURATION_NAME', 'daysNewWork', daysNewWork));
        dispatch(change('EDIT_TASKCONFIGURATION_NAME', 'order', order));

        
        dispatch(getCardsFilterSuccess(response));
    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, tasksConfigurationsReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            tasksConfigurationsReducer.filter.text,
            tasksConfigurationsReducer.metadata.perPage,
            1));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, tasksConfigurationsReducer } = getState();

        if (!tasksConfigurationsReducer.metadata.hasMore) {
            return;
        }

        const nextPage = tasksConfigurationsReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            tasksConfigurationsReducer.filter.text,
            tasksConfigurationsReducer.metadata.perPage,
            nextPage));

    };
};

export const tryGetCardsAction = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {

        dispatch(getCardsRequest());

        let response = await get(accessToken, filter, pageSize, page);
        
        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...TaskConfigurationsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }

        dispatch(getCardsSuccess(response));
    }
};



export const tryGettaskConfiguration = (taskConfigurationId) => {
    return async (dispatch, getState) => {
        if(!taskConfigurationId) return;
        
        dispatch(getRequest(taskConfigurationId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await getById(access_token, taskConfigurationId);
        if (response.hasOwnProperty("error")) {
            dispatch(getFailture(response));
            dispatch(error({
                ...tasksConfigurationsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar la configuracion de tareas. Detalle: '${response.error.message}'`
            }));
            return;
        }
        dispatch(getSuccess(response));

        dispatch(push(TASKSCONFIGURATIONS_FORM_URI + "/" + taskConfigurationId));
    }
}

export const tryDeleteTaskConfiguration = (taskConfigurationId) => {
    return async (dispatch, getState) => {
        let shouldDelete = confirm("Desea borrar la configuración de relacion Id: " + taskConfigurationId);
        if (!shouldDelete) {
            return;
        }

        dispatch(deleteRequest(taskConfigurationId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await deleteById(access_token, taskConfigurationId);
        if (response.hasOwnProperty("error")) {
            dispatch(deleteFailture(response));
            dispatch(error({
                ...tasksConfigurationsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Borrar la configuración de relación. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(deleteSuccess(taskConfigurationId));

        dispatch(success({
            ...tasksConfigurationsCardsNotification,
            title: "Configuración de relación " + taskConfigurationId,
            message: `Eliminado exitosamente!`
        }));
        
        dispatch(tryFormLoad());
    }
}

export const tryEditTaskConfiguration = (taskConfiguration) => {
    return async (dispatch, getState) => {
        dispatch(editRequest(taskConfiguration));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await post(access_token, taskConfiguration);
        if (response.hasOwnProperty("error")) {
            dispatch(editFailture(response));
            dispatch(error({
                ...tasksConfigurationsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar la configuración de relación. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(editSuccess(response));

        taskConfiguration.id = response.id;

        dispatch(success({
            ...tasksConfigurationsCardsNotification,
            title: "Configuración de relación " + taskConfiguration.id,
            message: `Actualizado exitosamente!`
        }));

        dispatch(tryFormLoad());

    }
}


export const tryAddTaskConfiguration = (taskConfiguration) => {
    return async (dispatch, getState) => {
        dispatch(addRequest(taskConfiguration));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await post(access_token, taskConfiguration);

        if (response.code && response.code == 4) {
            dispatch(addFailture(response));
            dispatch(error({
                ...tasksConfigurationsCardsNotification,
                title: "Oops!",
                message: `la configuración de relación ya existe.`
            }));

            return;
        }        

        if (response.code) {
            dispatch(addFailture(response));
            dispatch(error({
                ...tasksConfigurationsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear una configuración de relación. Detalle: '${response.message}'`
            }));

            return;
        }

        if (response.hasOwnProperty("error")) {
            dispatch(addFailture(response));
            dispatch(error({
                ...tasksConfigurationsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear una configuración de relación. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(addSuccess(response));

        taskConfiguration.id = response.id;

        dispatch(success({
            ...tasksConfigurationsCardsNotification,
            title: "Configuración de relación " + taskConfiguration.id,
            message: `Creado exitosamente!`
        }));

        dispatch(push(STRUCTURETYPES_URI));
    }
}

export const tryFormLoad = () => {
    return async (dispatch, getState) => {
        dispatch(formLoadRequest())

        const { loginReducer,structureTypesReducer,tasksConfigurationsReducer } = getState()

        const { cards } = structureTypesReducer
        const { filter,metadata } = tasksConfigurationsReducer

        const StructureTypeOption = cards.filter((st)=> st.id == filter.structureTypeId);

        const { access_token } = loginReducer.authentication        
        
        const AreasResponse = await getArea(access_token, '', 1000, 1)
        if (AreasResponse.hasOwnProperty("error")) {
            dispatch(formLoadFailture(AreasResponse));
            dispatch(error({
                ...tasksConfigurationsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el area. Detalle: '${AreasResponse.error.message}'`
            }));

            return;
        }

        const EntrustedTaskResponse = await getEntrustedTask(access_token, '', 1000, 1)
        if (EntrustedTaskResponse.hasOwnProperty("error")) {
            dispatch(formLoadFailture(EntrustedTaskResponse));
            dispatch(error({
                ...tasksConfigurationsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar las tareas encomendadas. Detalle: '${EntrustedTaskResponse.error.message}'`
            }));

            return;
        }

        const OperativeTasksResponse = await getOperativeTask(access_token, '', 1000, 1)
        if (OperativeTasksResponse.hasOwnProperty("error")) {
            dispatch(formLoadFailture(OperativeTasksResponse));
            dispatch(error({
                ...tasksConfigurationsCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar las tareas operativas. Detalle: '${OperativeTasksResponse.error.message}'`
            }));

            return;
        }

        dispatch(tryGetCardsAction(
            access_token,
            '',
            metadata.perPage,
            metadata.page));
              
        
        dispatch(formLoadSuccess(StructureTypeOption,
                                AreasResponse.records,
                                EntrustedTaskResponse.records,
                                OperativeTasksResponse.records))
    }
}

export const goToTaskConfiguration = (structureTypeId) => {
    return async (dispatch) => {
        const filter = { structureTypeId } 
        dispatch(setFilter(filter));

        dispatch(push(STRUCTURETYPES_URI+'/'+structureTypeId+TASKSCONFIGURATIONS_FORM_URI))
    }
}

export const trySelectClearChildrens = (filterform) => {
    return async (dispatch,getState) => {
        const { tasksConfigurationsReducer } = getState()
        let { filter } = tasksConfigurationsReducer

        if(filterform.hasOwnProperty("areaId")){
            filter ={...filter.structureTypeId,filterform}
        }
        dispatch(setFilter(filter));

        dispatch(setCardsFilter());
        
    }
}

export const tryGetCardsChildrens = (filterform) => {
    return async (dispatch,getState) => {
        const { tasksConfigurationsReducer } = getState()
        let { filter } = tasksConfigurationsReducer

        if(filterform.hasOwnProperty("areaId")){
            filter = {
                structureTypeId:filter.structureTypeId,
                areaId:filterform.areaId
            }
            dispatch(setFilter(filter));
            dispatch(setCardsFilter());
            return;
        }

        if(filterform.hasOwnProperty("EntrustedTaskId")){
            filter = {
                structureTypeId:filter.structureTypeId,
                areaId:filter.areaId,
                EntrustedTaskId: filterform.EntrustedTaskId
            }
            dispatch(setFilter(filter));
            dispatch(setCardsFilter());
            return;
        }


        filter ={...filter,...filterform}

        dispatch(setFilter(filter));

        dispatch(setCardsFilter());
        
    }
}
