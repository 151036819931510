import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';
import LoadingCard from 'Src/components/loadingCard';

import { ConnectedCreateEntrustedTaskTypeForm } from 'Src/components/entrustedTaskTypes/entrustedTaskTypesForm';

import { tryAddEntrustedTaskType } from 'Src/redux/thunk-creators/entrustedTaskTypesActionCreator';


class EntrustedTaskTypesAddPage extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const{
            onEntrustedTaskTypeAdd
        }=this.props;
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard/>} >
                            <ConnectedCreateEntrustedTaskTypeForm 
                                title="Crear tipo de tarea encomendada"
                                submitText="Crear"
                                onSubmit={entrustedTaskType => onEntrustedTaskTypeAdd(entrustedTaskType)} 
                                onCancel={this.props.goBack}/>
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    onEntrustedTaskTypeAdd: (entrustedTaskType) => dispatch(tryAddEntrustedTaskType(entrustedTaskType)),
    goBack: () => dispatch(goBack())
})


export default connect(
    null,
    mapDispatchToProps
)(EntrustedTaskTypesAddPage);