import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditResponsibleForAreaForm } from 'Src/components/responsibleForArea/responsibleForAreaForm'

import { getResponsibleForAreaToEdit,getAreasOptions } from "Src/redux/reducers/responsibleForAreaReducer";

import { tryEditResponsibleForArea,tryFormLoad } from 'Src/redux/thunk-creators/responsibleForAreaActionCreator';

class ResponsibleForAreaEditPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }

    render() {
        const { 
            onResponsibleForAreaEdit, goBack, responsibleForAreaToEdit,areasOptions
        } = this.props;
      
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedEditResponsibleForAreaForm
                                title="Editar responsable de área"
                                submitText="Editar"
                                areasOptions={areasOptions}
                                onSubmit={responsibleForArea => onResponsibleForAreaEdit(responsibleForArea)}
                                onCancel={goBack}
                                initialValues={responsibleForAreaToEdit}
                            />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        responsibleForAreaToEdit: getResponsibleForAreaToEdit(state),
        areasOptions: getAreasOptions(state),
    };
}

const mapDispatchToProps = dispatch => ({
    onResponsibleForAreaEdit: (responsibleForArea) => dispatch(tryEditResponsibleForArea(responsibleForArea)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResponsibleForAreaEditPage);
