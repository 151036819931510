import {
  AREAS_CARDS_REQUEST,
  AREAS_CARDS_SUCCESS,
  AREAS_CARDS_FAILTURE,
  AREAS_CARDS_CLEAN,
  AREAS_FILTER_SET,
  AREAS_PAGE_SET,
  AREAS_FORM_DELETE_SUCCESS,
  AREAS_FORM_GET_SUCCESS,
  AREAS_FORM_EDIT_SUCCESS,
} from "Src/redux/actions/areasActionTypes";

const initialMetadata = {
  perPage: 6,
  page: 1,
};

const initialState = {
  hasError: false,
  isFetching: false,
  filter: {
    text: "",
  },
  metadata: initialMetadata,
  cards: [],
  AreaToEdit: undefined,
};

const areasReducer = (state = initialState, action) => {
  switch (action.type) {
    case AREAS_FILTER_SET: {
      return {
        ...state,
        filter: {
          text: action.payload.filter,
        },
        cards: [],
        metadata: { ...initialMetadata },
      };
    }
    case AREAS_CARDS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case AREAS_CARDS_SUCCESS: {
      let cards = [];
      if (action.payload.metadata.page == 1)
        cards = cards.concat(action.payload.records);
      else cards = state.cards.concat(action.payload.records);

      return {
        ...state,
        isFetching: false,
        cards: cards,
        metadata: action.payload.metadata,
      };
    }
    case AREAS_CARDS_FAILTURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case AREAS_CARDS_CLEAN: {
      return {
        ...state,
        cards: [],
      };
    }
    case AREAS_PAGE_SET: {
      const newMetadata = {
        ...state.metadata,
        page: action.payload.page,
      };
      return {
        ...state,
        metadata: newMetadata,
      };
    }
    case AREAS_FORM_DELETE_SUCCESS: {
      return {
        ...state,
        cards: state.cards.filter((card) => card.id !== action.payload.areaId),
        metadata: {
          ...state.metadata,
          totalCount: state.metadata.totalCount - 1,
        },
      };
    }
    case AREAS_FORM_GET_SUCCESS: {
      return {
        ...state,
        AreaToEdit: action.payload.response,
      };
    }
    case AREAS_FORM_EDIT_SUCCESS: {
      return {
        ...state,
        AreaToEdit: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export default areasReducer;

export const getAreasState = (state) => state.areasReducer;

export const getFilter = (state) => getAreasState(state).filter;

export const getIsFetching = (state) => getAreasState(state).isFetching;

export const getCards = (state) => getAreasState(state).cards;

export const getMetadata = (state) => getAreasState(state).metadata;

export const getAreaToEdit = (state) => getAreasState(state).AreaToEdit;
