import React, { Suspense,Component } from 'react';
import { connect } from 'react-redux';
import {
    Container, Row, Col,
    Card, Spinner,Form,
    ProgressBar,ButtonToolbar,
    ToggleButton , ToggleButtonGroup,
    Badge,OverlayTrigger,Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import LoadingCard from 'Src/components/loadingCard';

import { getIsFetching, getFilter, getMetadataTranckingTask, getSelectedArea } from "Src/redux/reducers/timelinesCardsReducer";
import { setShowCards, sendToTimeLineCards, setShowTaskXAreaCards } from 'Src/redux/thunk-creators/timelinesCardsActionCreator';

import { getAutocomplete } from "Src/redux/reducers/entrustedTasksCardsReducer";
import { setAutocompleteFilter } from 'Src/redux/thunk-creators/entrustedTasksCardsActionCreator';


const TimeLineCards = React.lazy(() =>
    import('Src/components/timeLine/timeLineCards'));

const Autocomplete = React.lazy(() =>
    import('Src/components/autocomplete'));

class TimeLinePage extends Component {
    constructor(props) {
        super(props);

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        
    }

    componentDidMount() {
        let filterText = this.props.filter.text;
        if(filterText){
            this.props.setShowCards(filterText);
        }
    }

    handleFilterChange(event) {
        this.props.setAutocompleteFilter(event);
    }

    handleClickAreaButton(areaId){
        this.props.showTaskXAreaCards(areaId)
    }


    render() {
        const { filter, metadataTranckingTask, itemsAutocomplite, WorkOrderTrackingCards } = this.props;
        const { stages, client, structure, 
            orderDate, WorkOrderId, 
            advancePercentage, tasksXAreas, 
            totalDays,startDateReal,endDateReal
        } = metadataTranckingTask 
        let dateIntervalText = "Faltan tareas por finalizar";
        if(startDateReal && endDateReal){
            dateIntervalText = "Inició "+startDateReal+" y finalizo "+endDateReal;
        }
        return (
            <Container className="workorders-container">
                <Row>
                    <Col sm={12}>
                        <Card className="shadow">
                            <Card.Header><h5>Estado de avance</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Form.Label  column sm={2}>Ordenes de trabajo </Form.Label>                                         
                                    <Col>
                                        <Suspense fallback={<Spinner animation="grow"/>}>
                                            <Autocomplete
                                                handleChange={this.handleFilterChange}
                                                SelectChange={WorkOrderTrackingCards}
                                                filterText={filter.text}
                                                items={itemsAutocomplite} />
                                        </Suspense>     
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card className="shadow">
                            <Card.Header>
                                <Row className="text-center">
                                <Form.Label title={client} className="text-truncate" column ><b>Cliente: </b>{client}</Form.Label> 
                                            <Form.Label column ><b>Orden de Trabajo n°:</b> {WorkOrderId}</Form.Label> 
                                            <Form.Label column ><b>Estructura:</b> {structure}</Form.Label> 
                                            <Form.Label column ><b>Fecha de pedido:</b> {orderDate}</Form.Label>
                                            <Form.Label column ><b>Fecha de finalización:</b> {endDateReal}</Form.Label>
                                            <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                {dateIntervalText}
                                                </Tooltip>
                                            }>
                                            <Form.Label column ><b>Total de dias</b> {totalDays}</Form.Label> 
                                            </OverlayTrigger>                                         
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                    {stages &&
                                    <Row>
                                        {stages.map((s) => {
                                            return (                                                
                                                <Col className="text-center" >
                                                    {s.success ?  //revisar colos de los iconos
                                                        <FontAwesomeIcon size="4x" color="#05743C" icon={faAngleDoubleRight} />
                                                        : <FontAwesomeIcon size="4x" color="#D3D3D3" icon={faAngleDoubleRight} />
                                                    }
                                                    <br/>
                                                    <h6>{s.state}</h6>
                                                </Col>                                                    
                                            )
                                        })} 
                                    </Row>} 
                                                                              
                                </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card className="shadow">
                            <Card.Header>
                                <h6>Tareas Encomendadas</h6>
                            </Card.Header>
                            <Card.Body>
                                    <Row>
                                        <Form.Label title={WorkOrderId} column sm="2">
                                            <b>Estado de avance: </b>
                                        </Form.Label> 
                                        <Form.Label title={WorkOrderId} column>
                                            <ProgressBar 
                                                variant="success" 
                                                now={advancePercentage} 
                                                label={`${advancePercentage}%`} />
                                        </Form.Label> 
                                    </Row> 
                                    
                                {tasksXAreas &&
                                <Row>
                                    <Col>
                                    <ButtonToolbar>
                                        <ToggleButtonGroup type="radio" name="options" defaultValue={3} className="timeline-buttongroup mt-3">
                                            {tasksXAreas.map((tXa) => {
                                                return (         
                                                    tXa.tasks.length > 0 ?                                        
                                                    <ToggleButton value={tXa.areaId}  onClick={() => this.handleClickAreaButton(tXa.areaId)}>
                                                        {tXa.area} <Badge variant="light">{tXa.completedTask}/{tXa.pendingTask+tXa.completedTask}</Badge>
                                                    </ToggleButton>   
                                                    : null                                                                                                
                                                )
                                            })}     
                                        </ToggleButtonGroup>                                            
                                        </ButtonToolbar>                                            
                                    </Col>
                                </Row>}   
                                <br></br>   
                                <Row>
                                    <Col sm={12}>
                                        <Suspense fallback={<LoadingCard />}>
                                            <TimeLineCards />
                                        </Suspense>
                                    </Col>
                                </Row>                                       
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
        );
    }
}


const mapStateToProps = state => {
    return {
        isFetching: getIsFetching(state),
        filter: getFilter(state),
        selectedArea: getSelectedArea(state),
        metadataTranckingTask: getMetadataTranckingTask(state),
        itemsAutocomplite: getAutocomplete(state),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setAutocompleteFilter: (filterText) => dispatch(setAutocompleteFilter(filterText)),
        setShowCards:(filter) => dispatch(setShowCards(filter)),
        WorkOrderTrackingCards: (id) => dispatch(sendToTimeLineCards(id)),
        showTaskXAreaCards:(id) => dispatch(setShowTaskXAreaCards(id)),
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeLinePage);