export const CLIENTS_CARDS_REQUEST = "CLIENTS_CARDS_REQUEST";
export const CLIENTS_CARDS_SUCCESS = "CLIENTS_CARDS_SUCCESS";
export const CLIENTS_CARDS_FAILTURE = "CLIENTS_CARDS_FAILTURE";
export const CLIENTS_CARDS_CLEAN = "CLIENTS_CARDS_CLEAN";

export const CLIENTS_FILTER_SET = "CLIENTS_FILTER_SET";
export const CLIENTS_PAGE_SET = "CLIENTS_PAGE_SET";

export const CLIENTS_FORM_ADD_REQUEST = "CLIENTS_FORM_ADD_REQUEST";
export const CLIENTS_FORM_ADD_SUCCESS = "CLIENTS_FORM_ADD_SUCCESS";
export const CLIENTS_FORM_ADD_FAILTURE = "CLIENTS_FORM_ADD_FAILTURE";

export const CLIENTS_FORM_DELETE_REQUEST = "CLIENTS_FORM_DELETE_REQUEST";
export const CLIENTS_FORM_DELETE_SUCCESS = "CLIENTS_FORM_DELETE_SUCCESS";
export const CLIENTS_FORM_DELETE_FAILTURE = "CLIENTS_FORM_DELETE_FAILTURE";

export const CLIENTS_FORM_GET_REQUEST = "CLIENTS_FORM_GET_REQUEST";
export const CLIENTS_FORM_GET_SUCCESS = "CLIENTS_FORM_GET_SUCCESS";
export const CLIENTS_FORM_GET_FAILTURE = "CLIENTS_FORM_GET_FAILTURE";

export const CLIENTS_FORM_EDIT_REQUEST = "CLIENTS_FORM_EDIT_REQUEST";
export const CLIENTS_FORM_EDIT_SUCCESS = "CLIENTS_FORM_EDIT_SUCCESS";
export const CLIENTS_FORM_EDIT_FAILTURE = "CLIENTS_FORM_EDIT_FAILTURE";