import React, { Component, Suspense } from 'react'
import {
    Row, Col,
    Form, ListGroup,
    Spinner, Alert
} from 'react-bootstrap';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import config from 'Config';
const GmapComponent = React.lazy(() => import('Src/components/geocoding/gmap'));

export class locationField extends Component {
    constructor(props) {
        super(props)

        let address = '';

        const { input } = this.props
        if (input.value.address) {
            address = input.value.address;
        }

        this.state = { address };

        this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this);
    }

    handleChange = address => {
        this.setState({ address });
    }

    onMarkerDragEnd(event) {
        const coordinatesString = event.latLng.lat() + ", " + event.latLng.lng()

        this.setState({ address: coordinatesString });

        const { input: { onChange } } = this.props
        onChange({
            address: coordinatesString,
            coordinates: event.latLng,
            coordinatesString
        })
    }

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                console.log('geocodeByAddress.latLng Success', latLng)

                const { input: { onChange } } = this.props

                this.setState({ address });

                onChange({
                    address,
                    coordinates: latLng,
                    coordinatesString: latLng.lat.toString() + ", " + latLng.lng.toString()
                })
            })
            .catch(error => console.error('Error', error));
    }

    render() {
        const {
            input: {
                value: omitValue,
                ...inputProps
            },
            meta: omitMeta,
            ...props
        } = this.props

        const { input, label, meta, name } = this.props

        let storedPosition = input.value.coordinates || undefined;
        if (!storedPosition && input && input.value && input.value.coordinatesString) {
            const coordintesParts = input.value.coordinatesString.split(", ");
            storedPosition = {
                lat: parseFloat(coordintesParts[0]),
                lng: parseFloat(coordintesParts[1])
            }
        }
        const position = storedPosition || config.apis.geolocationApi.DEFAULT_POSITION

        return (
            <div>
                <Form.Group as={Row} controlId={name}>
                    <Form.Label column sm="3">{label}</Form.Label>
                    <Col sm="9">
                        <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <Form.Control
                                        size="sm"
                                        autoComplete="off"
                                        type="text"
                                        placeholder={label}
                                        {...input}

                                        {...getInputProps({
                                            placeholder: label,
                                            className: 'location-search-input',
                                        })}
                                    />
                                    <ListGroup className="autocomplete-dropdown-container">
                                        {loading && <ListGroup.Item>Cargando...</ListGroup.Item>}

                                        {suggestions.map(suggestion => {

                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';

                                            return (
                                                <ListGroup.Item
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className
                                                    })}>
                                                    {suggestion.description}
                                                </ListGroup.Item>
                                            );
                                        })}
                                    </ListGroup>
                                </div>
                            )}
                        </PlacesAutocomplete>
                        {(meta.touched && meta.error) ?
                            <Alert key="error" variant="danger">
                                {meta.error}
                            </Alert>
                            : null}

                        <Suspense fallback={<Spinner animation="grow" />}>
                            <GmapComponent
                                isMarkerShown
                                zoom={config.apis.geolocationApi.DEFAULT_ZOOM}
                                position={position}
                                onMarkerDragEnd={this.onMarkerDragEnd} />
                        </Suspense>

                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="coordenadas">
                    <Form.Label column sm="3">Coordenadas</Form.Label>
                    <Col sm="9">
                        {input.value.coordinatesString}
                    </Col>
                </Form.Group>
            </div>
        );
    }
}
