import {
  USERS_CARDS_REQUEST,
  USERS_CARDS_SUCCESS,
  USERS_CARDS_FAILTURE,
  USERS_CARDS_CLEAN,
  USERS_FILTER_SET,
  USERS_PAGE_SET,
  USERS_FORM_ADD_REQUEST,
  USERS_FORM_ADD_SUCCESS,
  USERS_FORM_ADD_FAILTURE,
  USERS_FORM_DELETE_REQUEST,
  USERS_FORM_DELETE_SUCCESS,
  USERS_FORM_DELETE_FAILTURE,
  USERS_FORM_GET_REQUEST,
  USERS_FORM_GET_SUCCESS,
  USERS_FORM_GET_FAILTURE,
  USERS_FORM_EDIT_REQUEST,
  USERS_FORM_EDIT_SUCCESS,
  USERS_FORM_EDIT_FAILTURE,
  USERS_FORM_LOAD_REQUEST, 
  USERS_FORM_LOAD_SUCCESS, 
  USERS_FORM_LOAD_FAILTURE,
  USERS_FORM_ADD_PROFILE_REQUEST, 
  USERS_FORM_ADD_PROFILE_SUCCESS, 
  USERS_FORM_ADD_PROFILE_FAILTURE
} from "./usersCardsActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const setFilter = (filter) => ({
  type: USERS_FILTER_SET,
  payload: {
    filter
  }
});

export const getCardsRequest = () => ({
  type: USERS_CARDS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getCardsSuccess = (response) => ({
  type: USERS_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response
  }
});

export const getCardsFailture = (response) => ({
  type: USERS_CARDS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const setPage = (page) => ({
  type: USERS_PAGE_SET,
  payload: {
    page
  }
});

export const addRequest = (user) => ({
  type: USERS_FORM_ADD_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    user
  }
});

export const addSuccess = (response) => ({
  type: USERS_FORM_ADD_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const addFailture = (response) => ({
  type: USERS_FORM_ADD_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});


export const addProfileRequest = (user) => ({
  type: USERS_FORM_ADD_PROFILE_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    user
  }
});

export const addProfileSuccess = (response) => ({
  type: USERS_FORM_ADD_PROFILE_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const addProfileFailture = (response) => ({
  type: USERS_FORM_ADD_PROFILE_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});




export const deleteRequest = (regionId) => ({
  type: USERS_FORM_DELETE_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    regionId
  }
});

export const deleteSuccess = (regionId) => ({
  type: USERS_FORM_DELETE_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    regionId
  }
});

export const deleteFailture = (response) => ({
  type: USERS_FORM_DELETE_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const getRequest = (regionId) => ({
  type: USERS_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    regionId
  }
});

export const getSuccess = (response,profiles) => ({
  type: USERS_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response,
    profiles
  }
});

export const getFailture = (response) => ({
  type: USERS_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const editRequest = (regionId) => ({
  type: USERS_FORM_EDIT_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    regionId
  }
});

export const editSuccess = (response) => ({
  type: USERS_FORM_EDIT_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const editFailture = (response) => ({
  type: USERS_FORM_EDIT_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const cleanCards = () => ({
  type: USERS_CARDS_CLEAN,
  payload: {
    isFetching: false,
    hasError: false
  }
});

  export const formLoadRequest = () =>
  buildAction(
    USERS_FORM_LOAD_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )



export const formLoadSuccess = (profiles) =>
  buildAction(
    USERS_FORM_LOAD_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      profiles
    }
  )

export const formLoadFailture = (response) =>
  buildAction(
    USERS_FORM_LOAD_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )