import {
  ENTRUSTEDTASKS_CARDS_REQUEST,
  ENTRUSTEDTASKS_CARDS_SUCCESS,
  ENTRUSTEDTASKS_CARDS_FAILTURE,
  ENTRUSTEDTASKS_CARDS_CLEAN,
  ENTRUSTEDTASKS_FILTER_SET,
  ENTRUSTEDTASKS_PAGE_SET,
  ENTRUSTEDTASKS_FORM_GET_REQUEST,
  ENTRUSTEDTASKS_FORM_GET_SUCCESS,
  ENTRUSTEDTASKS_FORM_GET_FAILTURE,
  ENTRUSTEDTASKS_FORM_LOAD_REQUEST, 
  ENTRUSTEDTASKS_FORM_LOAD_SUCCESS, 
  ENTRUSTEDTASKS_FORM_LOAD_FAILTURE,
  ENTRUSTEDTASKS_CARDS_FILTER_SET,
  ENTRUSTEDTASKS_END_REQUEST,
  ENTRUSTEDTASKS_END_SUCCESS,
  ENTRUSTEDTASKS_END_FAILTURE,
  ENTRUSTEDTASKS_AUTOCOMPLITE_REQUEST, 
  ENTRUSTEDTASKS_AUTOCOMPLITE_SUCCESS,
  ENTRUSTEDTASKS_AUTOCOMPLITE_FAILTURE  
} from "./entrustedTasksCardsActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const setFilter = (filter) => ({
  type: ENTRUSTEDTASKS_FILTER_SET,
  payload: {
    filter
  }
});

export const getCardsRequest = () => ({
  type: ENTRUSTEDTASKS_CARDS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getCardsSuccess = (response) => ({
  type: ENTRUSTEDTASKS_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response.records,
    metadata: response.metadata
  }
});

export const getCardsFailture = (response) => ({
  type: ENTRUSTEDTASKS_CARDS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const getAutocompleteRequest = () => ({
  type: ENTRUSTEDTASKS_AUTOCOMPLITE_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getAutocompleteSuccess = (response) => ({
  type: ENTRUSTEDTASKS_AUTOCOMPLITE_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response.records
  }
});

export const getAutocompleteFailture = (response) => ({
  type: ENTRUSTEDTASKS_AUTOCOMPLITE_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const setPage = (page) => ({
  type: ENTRUSTEDTASKS_PAGE_SET,
  payload: {
    page
  }
});

export const setFiltertaskEntrusted = (cardData) => ({
  type: ENTRUSTEDTASKS_CARDS_FILTER_SET,
  payload: {
      isFetching: false,
      hasError: false,
      cardData
    }
  });

export const getRequest = (entrutedTaskId) => ({
  type: ENTRUSTEDTASKS_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    entrutedTaskId
  }
});

export const getSuccess = (response) => ({
  type: ENTRUSTEDTASKS_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const getFailture = (response) => ({
  type: ENTRUSTEDTASKS_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const getEndRequest = (entrutedTaskId) => ({
  type: ENTRUSTEDTASKS_END_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    entrutedTaskId
  }
});

export const getEndSuccess = (response) => ({
  type: ENTRUSTEDTASKS_END_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const getEndFailture = (response) => ({
  type: ENTRUSTEDTASKS_END_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});



export const cleanCards = () => ({
  type: ENTRUSTEDTASKS_CARDS_CLEAN,
  payload: {
    isFetching: false,
    hasError: false
  }
});

  export const formLoadRequest = () =>
  buildAction(
    ENTRUSTEDTASKS_FORM_LOAD_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )



export const formLoadSuccess = (clientsOptions, structureTypeTemplate, tasksEntrusted) =>
  buildAction(
    ENTRUSTEDTASKS_FORM_LOAD_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      clientsOptions,
      structureTypeTemplate,
      tasksEntrusted
    }
  )

export const formLoadFailture = (response) =>
  buildAction(
    ENTRUSTEDTASKS_FORM_LOAD_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )