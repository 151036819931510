import {
  TIMELINES_CARDS_REQUEST,
  TIMELINES_CARDS_SUCCESS,
  TIMELINES_CARDS_FAILTURE,
  TIMELINES_CARDS_CLEAN,
  TIMELINES_FILTER_SET,
  TIMELINES_PAGE_SET,
  TIMELINES_FORM_GET_REQUEST,
  TIMELINES_FORM_GET_SUCCESS,
  TIMELINES_FORM_GET_FAILTURE,
  TIMELINES_CARDS_HEADER_SET,
  TIMELINES_TASKXAREA_CARDS_SUCCESS
} from "./timelinesCardsActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const setFilter = (filter) => ({
  type: TIMELINES_FILTER_SET,
  payload: {
    filter
  }
});

export const getCardsRequest = () => ({
  type: TIMELINES_CARDS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});
getTaskXAreaCardsSuccess

export const getTaskXAreaCardsSuccess = (response,AreaId) => ({
  type: TIMELINES_TASKXAREA_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response,
    AreaId
  }
});

export const getCardsSuccess = (response) => ({
  type: TIMELINES_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response
  }
});

export const getCardsFailture = (response) => ({
  type: TIMELINES_CARDS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const setPage = (page) => ({
  type: TIMELINES_PAGE_SET,
  payload: {
    page
  }
});

export const setFilterTimeLine = (cardData) => ({
  type: TIMELINES_CARDS_HEADER_SET,
  payload: {
      isFetching: false,
      hasError: false,
      cardData
    }
  });

export const getRequest = (regionId) => ({
  type: TIMELINES_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    regionId
  }
});

export const getSuccess = (response) => ({
  type: TIMELINES_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const getFailture = (response) => ({
  type: TIMELINES_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});


export const cleanCards = () => ({
  type: TIMELINES_CARDS_CLEAN,
  payload: {
    isFetching: false,
    hasError: false
  }
});
