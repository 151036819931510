export const WORKORDERSTRACKING_CARDS_REQUEST = "WORKORDERSTRACKING_CARDS_REQUEST";
export const WORKORDERSTRACKING_CARDS_SUCCESS = "WORKORDERSTRACKING_CARDS_SUCCESS";
export const WORKORDERSTRACKING_CARDS_FAILTURE = "WORKORDERSTRACKING_CARDS_FAILTURE";
export const WORKORDERSTRACKING_CARDS_CLEAN = "WORKORDERSTRACKING_CARDS_CLEAN";

export const WORKORDERSTRACKING_OPTIONS_REQUEST = "WORKORDERSTRACKING_OPTIONS_REQUEST";
export const WORKORDERSTRACKING_OPTIONS_SUCCESS = "WORKORDERSTRACKING_OPTIONS_SUCCESS";
export const WORKORDERSTRACKING_OPTIONS_FAILTURE = "WORKORDERSTRACKING_OPTIONS_FAILTURE";

export const WORKORDERSTRACKING_FILTER_SET = "WORKORDERSTRACKING_FILTER_SET";
export const WORKORDERSTRACKING_PAGE_SET = "WORKORDERSTRACKING_PAGE_SET";

export const WORKORDERSTASKENTRUSTED_FILTER_SET = "WORKORDERSTASKENTRUSTED_FILTER_SET";
