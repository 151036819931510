import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';
import LoadingCard from 'Src/components/loadingCard';

import { ConnectedCreateJustificationDelayAreaForm } from 'Src/components/justificationDelayAreas/justificationDelayAreaForm';

import { tryAddJustificationDelayArea, tryFormLoad } from 'Src/redux/thunk-creators/justificationDelayAreasActionCreator';

import { getAreasOptions} from "Src/redux/reducers/justificationDelayAreasReducer";

class JustificationDelayAreasAddPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }

    render() {
        const{
            onJustificationDelayAreaAdd, areasOptions
        }=this.props;
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard/>} >
                            <ConnectedCreateJustificationDelayAreaForm 
                                title="Crear Justificación de Retraso"
                                submitText="Crear"
                                areasOptions = { areasOptions }
                                onSubmit={justificationDelayArea => onJustificationDelayAreaAdd(justificationDelayArea)} 
                                onCancel={this.props.goBack}/>
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        areasOptions: getAreasOptions(state),
    };
}

const mapDispatchToProps = dispatch => ({
    onJustificationDelayAreaAdd: (justificationDelayArea) => dispatch(tryAddJustificationDelayArea(justificationDelayArea)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JustificationDelayAreasAddPage);