import React, { Suspense,Component } from 'react';
import { connect } from 'react-redux';
import {
    Container, Row, Col,
    Card, Spinner
} from 'react-bootstrap';

import LoadingCard from 'Src/components/loadingCard';

import { getMetadata, getIsFetching, getFilter,getOptionsStatus } from "Src/redux/reducers/workOrderTrackingCardsReducer";
import { setCardsFilter,setShowCards } from 'Src/redux/thunk-creators/workOrdersTrackingCardsActionCreator';

import { DropdownFilterField } from 'Src/components/forms/dropdownFilterField';

const CardsFilter = React.lazy(() =>
    import('Src/components/cards/cardsFilter'));

const CardsMetadata = React.lazy(() =>
    import('Src/components/cards/cardsMetadata'));

const WorkOrdersTrackingCards = React.lazy(() =>
    import('Src/components/workOrdersTracking/workOrdersTrackingCards'));

class WorkOrdersTrackingPage extends Component {
    constructor(props) {
        super(props);

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleFilterSelectChange = this.handleFilterSelectChange.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        let filterText = this.props.filter.text;
        let statusWorkOrder = this.props.filter.statusWorkOrder;
        if(filterText || statusWorkOrder)
        this.props.setShowCards(filterText,statusWorkOrder);

    }

    handleFilterChange(event) {
        let filterText = event.target.value;
        let statusWorkOrder = this.props.filter.statusWorkOrder;
        this.props.setCardsFilter(filterText,statusWorkOrder);
    }

    handleFilterSelectChange(event) {
        let statusWorkOrder = event.target.value;
        let filterText = this.props.filter.text;
        this.props.setCardsFilter(filterText,statusWorkOrder);
    }

    render() {
        const { metadata, filter,optionsStatus }= this.props;
        return (
            <Container className="workorders-tracking-container">
                <Row>
                    <Col sm={12}>
                        <Card className="shadow">
                            <Card.Header><h5>Seguimiento de Ordenes de Trabajo</h5></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col sm={3}>
                                        <DropdownFilterField
                                        label="Estado"
                                        name="filtro"
                                        handleChange={this.handleFilterSelectChange}
                                        options={optionsStatus}
                                        value={filter.statusWorkOrder}/>
                                    </Col>
                                    <Col sm={7}>
                                        <Suspense fallback={<Spinner animation="grow"/>}>
                                            <CardsFilter
                                                handleChange={this.handleFilterChange}
                                                filterText={filter.text} />
                                        </Suspense>
                                    </Col>
                                    <Col sm={2}>
                                        <Suspense fallback={<Spinner animation="grow"/>}>
                                            <CardsMetadata metadata={metadata} />
                                        </Suspense>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />}>
                            <WorkOrdersTrackingCards onTasksEntrusted={true} />
                        </Suspense>
                    </Col>
                </Row>
            </Container>
        );
    }
}


const mapStateToProps = state => {
    return {
        metadata: getMetadata(state),
        isFetching: getIsFetching(state),
        filter: getFilter(state),
        optionsStatus: getOptionsStatus(state)
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setCardsFilter: (filter,statusWorkOrder) => dispatch(setCardsFilter(filter,statusWorkOrder)),
        setShowCards:(filter,statusWorkOrder) => dispatch(setShowCards(filter,statusWorkOrder)),
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkOrdersTrackingPage);