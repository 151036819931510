import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    getRequest, getSuccess, getFailture,
    addRequest, addSuccess, addFailture,
    editRequest, editSuccess, editFailture,
    getCardsRequest,getCardsFailture,getCardsSuccess,
    setFilter,setPage,
    deleteRequest, deleteFailture, deleteSuccess,
    formLoadRequest,formLoadSuccess,formLoadFailture
} from "Src/redux/actions/responsibleForAreaActions";

import {
    get, getById, put, post, deleteById
} from 'Src/services/responsibleForAreaService';

import {
    get as getAreas
} from 'Src/services/areasService';


import { RESPONSIBLEFORAREA_FORM_URI,RESPONSIBLEFORAREA_URI } from "Src/routes";


const responsibleForAreaCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5

export const setCardsMount = () => {
    return async (dispatch, getState) => {
        const { responsibleForAreaReducer } = getState();

        dispatch(setFilter());

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            '',
            responsibleForAreaReducer.metadata.perPage,
            1));

    };
};


export const setCardsFilter = (filterText) => {
    return async (dispatch, getState) => {
        const { responsibleForAreaReducer } = getState();

        if (filterText.length < 1
            || filterText == responsibleForAreaReducer.filter.text) {
                dispatch(setFilter(filterText));
            return;
        }

        dispatch(setFilter(filterText));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText,
            responsibleForAreaReducer.metadata.perPage,
            1));

    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, responsibleForAreaReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            responsibleForAreaReducer.filter.text,
            responsibleForAreaReducer.metadata.perPage,
            1));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, responsibleForAreaReducer } = getState();

        if (!responsibleForAreaReducer.metadata.hasMore) {
            return;
        }

        const nextPage = responsibleForAreaReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            responsibleForAreaReducer.filter.text,
            responsibleForAreaReducer.metadata.perPage,
            nextPage));

    };
};

export const tryGetCardsAction = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {
        dispatch(getCardsRequest());

        let response = await get(accessToken, filter, pageSize, page,0);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...ResponsibleForAreaCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }

        dispatch(getCardsSuccess(response));
    }
};



export const tryGetResponsibleForArea = (responsibleForAreaId) => {
    return async (dispatch, getState) => {
        if(!responsibleForAreaId) return;
        
        dispatch(getRequest(responsibleForAreaId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await getById(access_token, responsibleForAreaId);
        if (response.hasOwnProperty("error")) {
            dispatch(getFailture(response));
            dispatch(error({
                ...responsibleForAreaCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar al responsable de área. Detalle: '${response.error.message}'`
            }));
            return;
        }
        dispatch(getSuccess(response));

        dispatch(push(RESPONSIBLEFORAREA_FORM_URI + "/" + responsibleForAreaId));
    }
}

export const tryDeleteResponsibleForArea = (responsibleForAreaId) => {
    return async (dispatch, getState) => {
        let shouldDelete = confirm("Desea borrar al responsable de área Id: " + responsibleForAreaId);
        if (!shouldDelete) {
            return;
        }

        dispatch(deleteRequest(responsibleForAreaId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await deleteById(access_token, responsibleForAreaId);
        if (response.hasOwnProperty("error")) {
            dispatch(deleteFailture(response));
            dispatch(error({
                ...responsibleForAreaCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Borrar al responsable de área. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(deleteSuccess(responsibleForAreaId));

        dispatch(success({
            ...responsibleForAreaCardsNotification,
            title: "Responsable de área " + responsibleForAreaId,
            message: `Eliminado exitosamente!`
        }));
    }
}

export const tryEditResponsibleForArea = (responsibleForArea) => {
    return async (dispatch, getState) => {
        dispatch(editRequest(responsibleForArea));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await put(access_token, responsibleForArea);
        if (response.hasOwnProperty("error")) {
            dispatch(editFailture(response));
            dispatch(error({
                ...responsibleForAreaCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al actualizar al responsable de área. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(editSuccess(response));

        responsibleForArea.id = response.id;

        dispatch(success({
            ...responsibleForAreaCardsNotification,
            title: "Responsable de área " + responsibleForArea.id,
            message: `Actualizado exitosamente!`
        }));

        dispatch(push(RESPONSIBLEFORAREA_URI));
    }
}


export const tryAddResponsibleForArea = (responsibleForArea) => {
    return async (dispatch, getState) => {
        dispatch(addRequest(responsibleForArea));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await post(access_token, responsibleForArea);

        if (response.code && response.code == 4) {
            dispatch(addFailture(response));
            dispatch(error({
                ...responsibleForAreaCardsNotification,
                title: "Oops!",
                message: `El responsable de área ya existe.`
            }));

            return;
        }        

        if (response.code) {
            dispatch(addFailture(response));
            dispatch(error({
                ...responsibleForAreaCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear un responsable de área. Detalle: '${response.message}'`
            }));

            return;
        }

        if (response.hasOwnProperty("error")) {
            dispatch(addFailture(response));
            dispatch(error({
                ...responsibleForAreaCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear un responsable de área. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(addSuccess(response));

        responsibleForArea.id = response.id;

        dispatch(success({
            ...responsibleForAreaCardsNotification,
            title: "Responsable de área " + responsibleForArea.id,
            message: `Creado exitosamente!`
        }));

        dispatch(push(RESPONSIBLEFORAREA_URI));
    }
}

export const tryFormLoad = () => {
    return async (dispatch, getState) => {
        dispatch(formLoadRequest())

        const { access_token } = getState().loginReducer.authentication

        const areasPromise = getAreas(access_token, '', 1000, 1)
        
        
        const areasResponse = await areasPromise
        if(areasResponse){
            const { error, code } = areasResponse
            const hasError = error || Number.isInteger(code)

            if (hasError) {
                dispatch(formLoadFailture(areasResponse))

                let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`

                showError(dispatch, ERROR_TITLE, message)

                return
            }
        }
        
        dispatch(formLoadSuccess(areasResponse.records))
    }
}
