import { mapToHttpBody } from './formatService'
import { httpPost,httpPut,handleHttpError } from './httpService'
import config from 'Config';

export const buildAuthorization = (accessToken) => `Bearer ${accessToken}`

export const buildDefaultOptions = (accessToken) => {
    return {
        headers: {
            Authorization: buildAuthorization(accessToken)
        }
    };
};

export const authenticate = async (email, password) => {
    const URL = config.apis.authApi.URL + config.apis.authApi.AUTHENTICATION_ENDPOINT;

    const details = {
        mode: 'no-cors',
        grant_type: "password",
        email: email,
        password: password,
        client_id: config.apis.authApi.CLIENT_ID,
        app_id: config.apis.authApi.APPLICATION_ID
    };

    const body = mapToHttpBody(details);

    var request = {
        headers: config.apis.authApi.HEADERS
    };

    try {
        let response = await httpPost(URL, body, request);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error)
    }
}

export const askPasswordChange = async (email) => {
    const URL = config.apis.authApi.URL + config.apis.authApi.RESETPASSWORD_ENDPOINT;    
    let callbackUrlEmail = window.location.href.replace('forgot-password','reset-password'); 

    const params = {
        email: email,
        callbackUrl: callbackUrlEmail
    }

    var options = {
        headers: {
            ...config.apis.authApi.HEADERS,
            'Content-Type': 'application/json'
        }
    };

    try {
        let response = await httpPost(URL, params, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error)
    }
}

export const resetPassword = async (password,token) => {
    const URL = config.apis.authApi.URL + config.apis.authApi.RESETPASSWORD_ENDPOINT;    

    const params = {        
        identifier: token,
        password: password
    }

    var options = {
        headers: {
            ...config.apis.authApi.HEADERS,
            'Content-Type': 'application/json'
        }
    };

    try {
        let response = await httpPut(URL, params, options);
        return response.data;
    }
    catch (error) {
        return handleHttpError(error)
    }
}