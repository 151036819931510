import {
    HISTORYS_CARDS_REQUEST,
    HISTORYS_CARDS_SUCCESS,
    HISTORYS_CARDS_FAILTURE,
    HISTORYS_CARDS_CLEAN,
    HISTORYS_FILTER_SET,
    HISTORYS_PAGE_SET,
} from "Src/redux/actions/historysActionTypes";

const initialMetadata = {
    perPage: 10,
    page: 1
};

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: "",
        typeHistory:undefined
    },
    metadata: initialMetadata,
    cards: [],
    client:undefined
};

const historysReducer = (state = initialState, action) => {
    switch (action.type) {
        case HISTORYS_FILTER_SET: {
            return {
                ...state,
                filter: {
                    workOrderId: action.payload.filter,
                    typeHistory: action.payload.type
                },
                cards: [],
                metadata: { ...initialMetadata },
                client:action.payload.client
            }
        }
        case HISTORYS_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case HISTORYS_CARDS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                cards: state.cards.concat(action.payload.records),
                metadata: {
                    ...action.payload.metadata
                },
                client:action.payload.client
            };
        }
        case HISTORYS_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case HISTORYS_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        case HISTORYS_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        default: {
            return state;
        }
    }
};

export default historysReducer;

export const getHistorysReducerState = (state) => state.historysReducer;

export const getFilter = (state) => getHistorysReducerState(state).filter;

export const getIsFetching = (state) => getHistorysReducerState(state).isFetching;

export const getCards = (state) => getHistorysReducerState(state).cards;

export const getMetadata = (state) => getHistorysReducerState(state).metadata;

export const getClient = (state) => getHistorysReducerState(state).client;