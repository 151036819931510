import {
    STRUCTURETYPES_CARDS_REQUEST,
    STRUCTURETYPES_CARDS_SUCCESS,
    STRUCTURETYPES_CARDS_FAILTURE,
    STRUCTURETYPES_CARDS_CLEAN,
    STRUCTURETYPES_FILTER_SET,
    STRUCTURETYPES_PAGE_SET,
    STRUCTURETYPES_FORM_DELETE_SUCCESS,
    STRUCTURETYPES_FORM_GET_SUCCESS,
    STRUCTURETYPES_FORM_EDIT_SUCCESS,
    STRUCTURETYPES_FORM_LOAD_SUCCESS
} from "Src/redux/actions/structureTypesActionTypes";

const initialMetadata = {
    perPage: 9,
    page: 1
};

const statusOption = [{ key: true, text: "Activo" },{ key: false, text: "Inactivo" }]
const initialOption = [{ key: -1, text: "Ninguna" }]

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: ""
    },
    metadata: initialMetadata,
    cards: [],
    StructureTypeToEdit: undefined,
    statusOption:statusOption,
    structureTypeOption:initialOption
};

const structureTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case STRUCTURETYPES_FILTER_SET: {
            return {
                ...state,
                filter: {
                    text: action.payload.filter
                },
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case STRUCTURETYPES_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case STRUCTURETYPES_CARDS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                cards: state.cards.concat(action.payload.records),
                metadata: action.payload.metadata
            };
        }
        case STRUCTURETYPES_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case STRUCTURETYPES_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        case STRUCTURETYPES_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        case STRUCTURETYPES_FORM_DELETE_SUCCESS: {
            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload.structureTypeId),
                metadata: {
                    ...state.metadata,
                    totalCount: state.metadata.totalCount - 1
                }
            };
        }
        case STRUCTURETYPES_FORM_GET_SUCCESS: {
            return {
                ...state,
                StructureTypeToEdit: action.payload.response
            };
        }
        case STRUCTURETYPES_FORM_EDIT_SUCCESS: {
            return {
                ...state,
                StructureTypeToEdit: undefined
            };
        }
        case STRUCTURETYPES_FORM_LOAD_SUCCESS: {
            const structureTypeOption = action.payload.structureTypeOption.map(st => ({
                key: st.id,
                text: st.description
            }))

            return {
                ...state,
                structureTypeOption:initialOption.concat(structureTypeOption)
            }
        }
        default: {
            return state;
        }
    }
};

export default structureTypesReducer;

export const getStructureTypesState = (state) => state.structureTypesReducer;

export const getFilter = (state) => getStructureTypesState(state).filter;

export const getIsFetching = (state) => getStructureTypesState(state).isFetching;

export const getCards = (state) => getStructureTypesState(state).cards;

export const getMetadata = (state) => getStructureTypesState(state).metadata;

export const getStructureTypeToEdit = (state) => getStructureTypesState(state).StructureTypeToEdit;

export const getStatusOption = (state) => getStructureTypesState(state).statusOption;

export const getStructureTypeOption = (state) => getStructureTypesState(state).structureTypeOption;