import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    setFilter, getCardsRequest, getCardsSuccess, getCardsFailture,
    setPage, addFailture, addRequest, addSuccess,
    deleteRequest, deleteSuccess, deleteFailture,
    getRequest, getSuccess, getFailture,
    editRequest, editSuccess, editFailture, cleanCards,
    formLoadRequest, formLoadSuccess, formLoadFailture,
    addProfileRequest,addProfileSuccess,addProfileFailture
} from "Src/redux/actions/usersCardsActions";

import {
    get, 
    post,
    postProfile,
    deleteById, 
    put, 
    getById,
    getProfiles,
    getUserXApplicationXProfiles,
    putUserXApplicationXProfiles,
    getUserNotifications,
    postNotification
} from 'Src/services/usersService';

import {
    get as getClients,
} from 'Src/services/clientsService';

import { USERS_URI, USERS_FORM_URI } from "Src/routes";


const usersCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5

export const setCardsMount = () => {
    return async (dispatch, getState) => {
        const { userCardsReducer } = getState();

        dispatch(setFilter());

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            '',
            userCardsReducer.metadata.perPage,
            1));

    };
};


export const setCardsFilter = (filterText) => {
    return async (dispatch, getState) => {
        const { userCardsReducer } = getState();

        if (filterText.length < 1
            || filterText == userCardsReducer.filter.text) {
            return;
        }

        dispatch(setFilter(filterText));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText,
            userCardsReducer.metadata.perPage,
            1));

    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, userCardsReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            userCardsReducer.filter.text,
            userCardsReducer.metadata.perPage,
            1));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, userCardsReducer } = getState();

        if (!userCardsReducer.metadata.hasMore) {
            return;
        }

        const nextPage = userCardsReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            userCardsReducer.filter.text,
            userCardsReducer.metadata.perPage,
            nextPage));

    };
};

export const tryGetCardsAction = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {
        dispatch(getCardsRequest());

        let response = await get(accessToken, filter, pageSize, page);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }

        const responseUserProfiles = await getUserXApplicationXProfiles(accessToken, '', 1000, 1);
        if (responseUserProfiles.hasOwnProperty("error")) {
            dispatch(getCardsFailture(responseUserProfiles));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el usuario. Detalle: '${responseUserProfiles.error.message}'`
            }));
            return;            
        }

        const responseProfiles = await getProfiles(accessToken, '', 1000, 1);
        if (responseProfiles.hasOwnProperty("error")) {
            dispatch(getCardsFailture(responseProfiles));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el usuario. Detalle: '${responseProfiles.error.message}'`
            }));
            return;            
        }

       
        const responseUserNotifications = await getUserNotifications(accessToken, '', 1000, 1);
        if (responseUserNotifications.hasOwnProperty("error")) {
            dispatch(getFailture(responseUserNotifications));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el usuario. Detalle: '${responseUserNotifications.error.message}'`
            }));
            return;            
        }

        response = responseUserProfiles.map((up)=>{
            const user = response.filter(u=> u.id==up.userId) 
            const profile = responseProfiles.filter(p=>p.id==up.profileId)

            const userNotifications = responseUserNotifications.filter(p=>p.user==up.userId)

            return {
                ...user[0],
                profile: profile[0].name,
                mailNotifications: (userNotifications.length > 0)?userNotifications[0].mailNotifications:false
            }
        }) ;

        dispatch(getCardsSuccess(response));
    }
};

export const tryFormLoad = () => {
    return async (dispatch, getState) => {
        dispatch(formLoadRequest())

        const { access_token } = getState().loginReducer.authentication
        
        
        const response = await getProfiles(access_token, '', 1000, 1);
        if(response){
            const { error, code } = response
            const hasError = error || Number.isInteger(code)

            if (hasError) {
                dispatch(formLoadFailture(response))

                let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`

                showError(dispatch, ERROR_TITLE, message)

                return
            }
        }
        
        dispatch(formLoadSuccess(response))
    }
}

export const tryAddUser = (user) => {
    return async (dispatch, getState) => {
        dispatch(addRequest(user));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await post(access_token, user);

        if (response.code && response.code == 4) {
            dispatch(addFailture(response));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `El usuario ya existe.`
            }));

            return;
        }        

        if (response.code) {
            dispatch(addFailture(response));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear el usuario. Detalle: '${response.message}'`
            }));

            return;
        }

        if (response.hasOwnProperty("error")) {
            dispatch(addFailture(response));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear el usuario. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(addSuccess(response));

        user.id = response.id;

        dispatch(addProfileRequest(user.id));

        let responseProfile = await postProfile(access_token, user);     

        if (responseProfile.code) {
            dispatch(addProfileFailture(responseProfile));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear el usuario. Detalle: '${responseProfile.message}'`
            }));

            return;
        }

        if (responseProfile.hasOwnProperty("error")) {
            dispatch(addProfileFailture(responseProfile));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear el usuario. Detalle: '${responseProfile.error.message}'`
            }));

            return;
        }

        let responseNotification = await postNotification(access_token, user);
        if (responseNotification.hasOwnProperty("error")) {
            dispatch(editFailture(responseNotification));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear el usuario. Detalle: '${responseNotification.error.message}'`
            }));

            return;
        }

        
        dispatch(addProfileSuccess(responseProfile));


        dispatch(success({
            ...usersCardsNotification,
            title: "usuario " + user.id,
            message: `Creado exitosamente!`
        }));

        dispatch(push(USERS_URI));
    }
}

export const tryDeleteUser = (userId) => {
    return async (dispatch, getState) => {
        let shouldDelete = confirm("Desea borrar el usuario Id: " + userId);
        if (!shouldDelete) {
            return;
        }

        dispatch(deleteRequest(userId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await deleteById(access_token, userId);
        if (response.hasOwnProperty("error")) {
            dispatch(deleteFailture(response));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Borrar el usuario. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(deleteSuccess(userId));

        dispatch(success({
            ...usersCardsNotification,
            title: "usuario " + userId,
            message: `Eliminado exitosamente!`
        }));
    }
}

export const tryGetUser = (userId) => {
    return async (dispatch, getState) => {
        if(!userId) return;

        dispatch(getRequest(userId));

        const { loginReducer , userCardsReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = userCardsReducer.cards.filter(u => u.id == userId);
        if (response.hasOwnProperty("error")) {
            dispatch(getFailture(response));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el usuario. Detalle: '${response.error.message}'`
            }));
            return;
        }

        const responseOptionProfiles = await getProfiles(access_token, '', 1000, 1);
        if (responseOptionProfiles.hasOwnProperty("error")) {
            dispatch(getFailture(responseOptionProfiles));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el usuario. Detalle: '${responseOptionProfiles.error.message}'`
            }));
            return;            
        }

        const responseUserProfiles = await getUserXApplicationXProfiles(access_token, '', 1000, 1);
        if (responseUserProfiles.hasOwnProperty("error")) {
            dispatch(getFailture(responseUserProfiles));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el usuario. Detalle: '${responseUserProfiles.error.message}'`
            }));
            return;            
        }

        const ProfileUser = responseUserProfiles.filter(UserProfile => UserProfile.userId == userId);

        const responseUserNotifications = await getUserNotifications(access_token, '', 1000, 1);
        if (responseUserNotifications.hasOwnProperty("error")) {
            dispatch(getFailture(responseUserNotifications));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el usuario. Detalle: '${responseUserNotifications.error.message}'`
            }));
            return;            
        }

        const NotificationUser = responseUserNotifications.filter(notificationUser => notificationUser.user == userId);

        response[0].profileId = ProfileUser[0].profileId
        response[0].UserXApplicationXProfilesId = ProfileUser[0].id
        response[0].mailNotifications = false

        if (NotificationUser.length > 0)
            response[0].mailNotifications = NotificationUser[0].mailNotifications

        dispatch(getSuccess(response[0],responseOptionProfiles));

        dispatch(push(USERS_FORM_URI + "/" + userId));
    }
}

export const tryEditUser = (user) => {
    return async (dispatch, getState) => {
        dispatch(editRequest(user));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;
        

        let response = await put(access_token, user);
        if (response.hasOwnProperty("error")) {
            dispatch(editFailture(response));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar el usuario. Detalle: '${response.error.message}'`
            }));

            return;
        }

        let responseProfile = await putUserXApplicationXProfiles(access_token, user);
        if (responseProfile.hasOwnProperty("error")) {
            dispatch(editFailture(responseProfile));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar el usuario. Detalle: '${responseProfile.error.message}'`
            }));

            return;
        }

        let responseNotification = await postNotification(access_token, user);
        if (responseNotification.hasOwnProperty("error")) {
            dispatch(editFailture(responseNotification));
            dispatch(error({
                ...usersCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar el usuario. Detalle: '${responseNotification.error.message}'`
            }));

            return;
        }

        dispatch(editSuccess(response));

        user.id = response.id;

        dispatch(success({
            ...usersCardsNotification,
            title: "usuario " + user.id,
            message: `Actualizado exitosamente!`
        }));

        dispatch(push(USERS_URI));
        dispatch(cleanCards());
        dispatch(refreshCardsFilter());
    }
}
