export const USERS_CARDS_REQUEST = "USERS_CARDS_REQUEST";
export const USERS_CARDS_SUCCESS = "USERS_CARDS_SUCCESS";
export const USERS_CARDS_FAILTURE = "USERS_CARDS_FAILTURE";
export const USERS_CARDS_CLEAN = "USERS_CARDS_CLEAN";

export const USERS_FILTER_SET = "USERS_FILTER_SET";
export const USERS_PAGE_SET = "USERS_PAGE_SET";

export const USERS_FORM_ADD_REQUEST = "USERS_FORM_ADD_REQUEST";
export const USERS_FORM_ADD_SUCCESS = "USERS_FORM_ADD_SUCCESS";
export const USERS_FORM_ADD_FAILTURE = "USERS_FORM_ADD_FAILTURE";

export const USERS_FORM_ADD_PROFILE_REQUEST = "USERS_FORM_ADD_PROFILE_REQUEST";
export const USERS_FORM_ADD_PROFILE_SUCCESS = "USERS_FORM_ADD_PROFILE_SUCCESS";
export const USERS_FORM_ADD_PROFILE_FAILTURE = "USERS_FORM_ADD_PROFILE_FAILTURE";

export const USERS_FORM_DELETE_REQUEST = "USERS_FORM_DELETE_REQUEST";
export const USERS_FORM_DELETE_SUCCESS = "USERS_FORM_DELETE_SUCCESS";
export const USERS_FORM_DELETE_FAILTURE = "USERS_FORM_DELETE_FAILTURE";

export const USERS_FORM_GET_REQUEST = "USERS_FORM_GET_REQUEST";
export const USERS_FORM_GET_SUCCESS = "USERS_FORM_GET_SUCCESS";
export const USERS_FORM_GET_FAILTURE = "USERS_FORM_GET_FAILTURE";

export const USERS_FORM_EDIT_REQUEST = "USERS_FORM_EDIT_REQUEST";
export const USERS_FORM_EDIT_SUCCESS = "USERS_FORM_EDIT_SUCCESS";
export const USERS_FORM_EDIT_FAILTURE = "USERS_FORM_EDIT_FAILTURE";

export const USERS_FORM_LOAD_REQUEST = "USERS_FORM_LOAD_REQUEST";
export const USERS_FORM_LOAD_SUCCESS = "USERS_FORM_LOAD_SUCCESS";
export const USERS_FORM_LOAD_FAILTURE = "USERS_FORM_LOAD_FAILTURE";