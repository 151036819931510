import React,{Component} from 'react';
import {
    Card, Button, Spinner,Col,Row
} from 'react-bootstrap';
import { Field, reduxForm,change } from 'redux-form';
import { inputField } from 'Src/components/forms/inputField';
import { dropdownField } from 'Src/components/forms/dropdownField';

//TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')
const requiredSelect = (value) => {
    if(!value){
        return 'Requerido!';
    }
    if(value=='-1'){
        return 'Requerido!';
    }
    return undefined;
}
const number = value =>
    value && isNaN(Number(value)) ? 'Debe ser un número!' : undefined

const minValue = (min,value) =>
    value && value < min ? `Mínimo ${min}` : undefined

const maxValue = (max,value) =>
    value && value > max ? `Máximo ${max}` : undefined
//const requiredselect = value => (value ? undefined: 'Requerido!')

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}
    errors.areaFK = requiredSelect(values.areaFK)
    errors.entrustedTaskTypeFK = requiredSelect(values.entrustedTaskTypeFK)
    errors.operativeTaskTypeFK = requiredSelect(values.operativeTaskTypeFK)
    errors.days = required(values.days)
    if(!errors.days){
        const num = parseInt(values.days)
        errors.days = minValue(1,num)
        errors.days = maxValue(365,num)
    }  
    return errors
}

class TaskConfigurationForm extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText,
            structureTypeOption,areasOptions,
            entrustedTasksOptions,operativeTasksOptions,
            onChangeArea,onChangeEntrustedTasks,
            onChangeOperativeTasks,
            mode
        } = this.props;
        
        return (
            <Card className="shadow">
                <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                            <Field name="structureTypeFK" label="Tipos de estructuras"
                                    component={dropdownField}
                                    options={structureTypeOption} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Field name="areaFK" label="Areas"
                                    component={dropdownField}
                                    options={areasOptions}
                                    onChange={onChangeArea}
                                     />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Field name="entrustedTaskTypeFK" label="Tareas Encomendadas"
                                    component={dropdownField}
                                    options={entrustedTasksOptions}
                                    onChange={onChangeEntrustedTasks}
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Field name="operativeTaskTypeFK" label="Tareas Operativas"
                                    component={dropdownField}
                                    options={operativeTasksOptions}
                                    onChange={onChangeOperativeTasks}
                                     />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="days" label="Días" type="number" component={inputField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="daysComplementaryWork" label="Días provisiones adicionales" type="number" component={inputField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="daysNewWork" label="Días nuevo diseño" type="number" component={inputField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field name="ordered" label="Orden" type="number" component={inputField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit" disabled={pristine || submitting}>
                                    {submitText}
                                    {submitting ? <Spinner animation="grow" size="sm" /> : null}
                                </Button>                            
                            </Col>
                            <Col className="text-right">
                                <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                                &nbsp;                            
                                <Button variant="danger" onClick={onCancel}>Cancelar</Button>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_TASKCONFIGURATION_NAME = 'CREATE_TASKCONFIGURATION_NAME';
export const EDIT_TASKCONFIGURATION_NAME = 'EDIT_TASKCONFIGURATION_NAME';

const CreateTaskConfigurationForm = props =>
  <TaskConfigurationForm {...props} mode='create' />;
  
const EditTaskConfigurationForm = props =>
  <TaskConfigurationForm {...props} mode='edit' />;

const ConnectedCreateTaskConfigurationForm = reduxForm({
    form: CREATE_TASKCONFIGURATION_NAME,
    validate,
    enableReinitialize:true,
     destroyOnUnmount:false
})(CreateTaskConfigurationForm);

const ConnectedEditTaskConfigurationForm = reduxForm({
    form: EDIT_TASKCONFIGURATION_NAME,
    validate,
    enableReinitialize:true,
    destroyOnUnmount:false
})(EditTaskConfigurationForm);

export {
    ConnectedCreateTaskConfigurationForm,
    ConnectedEditTaskConfigurationForm
}