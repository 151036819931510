import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import config from 'Config';

import { tryLogInAction } from 'Src/redux/thunk-creators/loginActionCreator';
import { getIsFetching, getIsLoggedIn } from "Src/redux/reducers/loginReducer";

import { DASHBOARD_URI, FORGOT_PASSWORD_URI } from "Src/routes";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.onUsernameChange = this.onUsernameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onLoginClick = this.onLoginClick.bind(this);
        this.onForgotPasswordClick = this.onForgotPasswordClick.bind(this);
        this.onKeyPressed = this.onKeyPressed.bind(this);
    }

    onUsernameChange(event) {
        this.setState({
            username: event.target.value
        });
    }

    onPasswordChange(event) {
        this.setState({
            password: event.target.value
        });
    }

    onLoginClick(e) {
        this.props.tryLogin(this.state.username, this.state.password);
    }

    onForgotPasswordClick(e) {
        this.props.goToForgotPassword();
    }

    onKeyPressed(event) {
        if (event.key === "Enter") {
            this.props.tryLogin(this.state.username, this.state.password);
        }
    }

    render() {
        if (this.state && this.state.hasError) {
            return <h1>Oops! Something went wrong.</h1>;
        }

        if (this.props.isLoggedIn) {
            this.props.goToDashboard();
        }

        let loadingWheel;
        if (this.props.isFetching) {
            loadingWheel = <Spinner animation="grow" size='sm' />;
        }

        return (
            <div className="container">
                <div className='row'>
                    <div className="card login-card shadow-lg">
                        <article className="card-body">
                            <div className="row justify-content-center align-items-center">
                                <div className="logo mb-4 mt-1"></div>
                            </div>

                            <h4 className="card-title text-center mb-4 mt-1">
                            {config.appSettings.name} {config.appSettings.version}
                            </h4>
                            <hr />
                            
                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="fa fa-user">@</i>
                                        </span>
                                    </div>
                                    <input className="form-control"
                                        placeholder="Email" type="username"
                                        value={this.state && this.state.username}
                                        onChange={this.onUsernameChange}
                                        onKeyPress={this.onKeyPressed} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="fa fa-lock">**</i>
                                        </span>
                                    </div>
                                    <input className="form-control" placeholder="******" type="password"
                                        value={this.state && this.state.password}
                                        onChange={this.onPasswordChange}
                                        onKeyPress={this.onKeyPressed} />
                                </div>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary btn-block"
                                    onClick={this.onLoginClick}
                                    disabled={this.props.isFetching}>
                                    Ingresar  {loadingWheel}
                                </button>

                            </div>
                            <div className="form-group text-center">
                                <Link onClick={this.onForgotPasswordClick}>¿Olvido su contraseña?</Link>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: getIsLoggedIn(state),
        isFetching: getIsFetching(state)
    };
}

const mapDispatchToProps = dispatch => {
    return {
        tryLogin: (username, password) => dispatch(tryLogInAction(username, password)),
        goToDashboard: () => dispatch(push(DASHBOARD_URI)),
        goToForgotPassword: () => dispatch(push(FORGOT_PASSWORD_URI))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);