import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import numeral from 'numeral';

import "core-js/stable";
import "regenerator-runtime/runtime";

import routes from 'Src/routes';
import Layout from './components/layout';
import Notifications from './components/globalNotifications';

import es_locale from 'Configs/es_locale';
numeral.register('locale', 'es', es_locale);
numeral.locale('es');

import moment from 'moment';
moment.locale('es');

import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);

import 'antd/es/tree-select/style/css';

const App = ({ history }) => {
  return (
    <ConnectedRouter history={history}>
        <Layout>
          <Notifications></Notifications>
          { routes }
        </Layout>
    </ConnectedRouter>
  )
}

export default App