import React from 'react'

import {
    Form, Col, Row, Alert, Button
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


export const dropdownField = ({
    name, input, label, options,
    onAdd,withoutSize,disabled,
    meta: { touched, error }
}) => (
    <Form.Group as={Row} controlId={name}>
        {label ? 
            <Form.Label column sm={withoutSize?null:3}  >{label}</Form.Label>
        :null}        
        <Col sm={onAdd ? 8 : null}>
            <Form.Control size="sm" as="select" disabled={disabled?disabled:false}  {...input}>
                {options ?  
                    options.map((o) => (
                        <option key={o.key} value={o.key}>{o.text}</option>
                    ))
                :null}
            </Form.Control>

            {(touched && error) ?
                <Alert key="error" variant="danger">
                    {error}
                </Alert>
            : null}
        </Col>
        {onAdd?
            <Col sm="1" className="text-left">
                <Button type="button" variant="primary" onClick={onAdd} >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </Col>
        :null}
        
    </Form.Group>
)
