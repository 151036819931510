import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    formLoadSuccess,formLoadRequest,formLoadFailture,
    clientChildrenRequest,clientChildrenFailture,clientChildrenSuccess
} from "Src/redux/actions/reportsActions";

import {
    get as getStructureType
} from 'Src/services/structureTypesService';


import {
    get as getEntrustedTaskTypes
} from 'Src/services/taskEntrustedService';

import {
    get as getClients,getRegions
} from 'Src/services/clientsService';

import {
    get as getWorkOrderState,
    getDownloadFile
} from 'Src/services/workOrderState';

import config from 'Config';

import { formatDate,getformatDate } from 'Src/services/formatService';
import { getEntrustedTasksOptionsTree } from '../reducers/reportsReducer';
import { loadState } from "../../redux/localStorage";


const reportsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5



export const tryFormLoad = () => {
    return async (dispatch, getState) => {
        dispatch(formLoadRequest())

        const { access_token } = getState().loginReducer.authentication

        const clientsPromise = getClients(access_token, null, 1000, 1)

        const entrustedTaskTypesPromise = getEntrustedTaskTypes(access_token, false, 1000, 1)
        
        const structureTypePromise = getStructureType(access_token, null, 1000, 1)

        const workOrderStatePromise = getWorkOrderState(access_token, null, 1000, 1)

        const clientsResponse = await clientsPromise
        if(clientsResponse){
            const { error, code } = clientsResponse
            const hasError = error || Number.isInteger(code)

            if (hasError) {
                dispatch(formLoadFailture(clientsResponse))

                let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`

                showError(dispatch, ERROR_TITLE, message)

                return
            }
        }
        

        const entrustedTaskTypesResponse = await entrustedTaskTypesPromise
        if(entrustedTaskTypesResponse){
            const { error, code } = entrustedTaskTypesResponse
            const hasError = error || Number.isInteger(code)

            if (hasError) {
                dispatch(formLoadFailture(entrustedTaskTypesResponse))

                let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`

                showError(dispatch, ERROR_TITLE, message)

                return
            }
        }


        const structureTypeResponse = await structureTypePromise
        if(structureTypeResponse){
            const { error, code } = structureTypeResponse
            const hasError = error || Number.isInteger(code)

            if (hasError) {
                dispatch(formLoadFailture(structureTypeResponse))

                let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`

                showError(dispatch, ERROR_TITLE, message)

                return
            }
        }

        const workOrderStateResponse = await workOrderStatePromise
        if(workOrderStateResponse){
            const { error, code } = workOrderStateResponse
            const hasError = error || Number.isInteger(code)

            if (hasError) {
                dispatch(formLoadFailture(workOrderStateResponse))

                let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`

                showError(dispatch, ERROR_TITLE, message)

                return
            }
        }


        dispatch(formLoadSuccess(clientsResponse.records, structureTypeResponse.records,workOrderStateResponse.records, entrustedTaskTypesResponse.records))
    }
}

export const tryGetClientChildren = (clientId) => {
    return async (dispatch, getState) => {
        dispatch(clientChildrenRequest())

        const { access_token } = getState().loginReducer.authentication

        let getWorkOrdersRequest = getRegions(access_token, clientId)

        const WorkOrderResponse = await getWorkOrdersRequest;
        
        const { WorkOrderError, WorkOrderCode } = WorkOrderResponse
        const WorkOrderHasError = WorkOrderError || Number.isInteger(WorkOrderCode)

        if (WorkOrderHasError) {
            dispatch(clientChildrenFailture(WorkOrderResponse))

            let message = `Ha ocurrido un error al cargar las Regiones.`

            showError(dispatch, ERROR_TITLE, message)

            return
        }
        
        dispatch(clientChildrenSuccess(WorkOrderResponse))
    }
}


export const tryCreateReport = (form) =>{
    return async (dispatch, getState) => {
        const { access_token } = getState().loginReducer.authentication
        let urlConstruc='';
        let _reportType
        if (form.reportType == undefined) {
            _reportType = false;
        }
        else {
            _reportType = form.reportType;
        }
        urlConstruc += 'ReportType=' + _reportType +'&';
         let clientIdList = ''; 
         let clientStrList = '';        
        if(form.clientTree && form.clientTree.value.length > 0 && form.clientTree.value[0].value != '-1')
        {         
            clientIdList =  form.clientTree.value.map(function(item) {
                return item['value'];
              });
            clientStrList = clientIdList.join("-");
            urlConstruc += 'ClientId='+ clientStrList +'&';
        }

        let entrustedTasksIdList = '';   
        let entrustedTasksStrList = '';  
       if(form.entrustedTasksFieldNew && form.entrustedTasksFieldNew.value.length > 0 && form.entrustedTasksFieldNew.value[0].value != '-1')
       {
           entrustedTasksIdList = form.entrustedTasksFieldNew.value.map(function(item){
                return item['value'];
           });
           
           entrustedTasksStrList = entrustedTasksIdList.join("-");
           urlConstruc += 'EntrustedTasksId='+ entrustedTasksStrList +'&';
       }

        // Certification
        if(form.certificationOptionField && form.certificationOptionField != '-1')
        {
            urlConstruc+='CertificationOption='+ form.certificationOptionField +'&'

        }

        // Invoice
        if(form.invoiceOptionField && form.invoiceOptionField != '-1')
        {
            urlConstruc+='InvoiceOption='+ form.invoiceOptionField +'&'
        }

           // Base Dispatch
        if(form.baseDispatchOptionsField && form.baseDispatchOptionsField != '-1')
        {
            urlConstruc+='BaseDispatchOption='+ form.baseDispatchOptionsField +'&'
        }

        // Structure Dispatch
        if(form.structureDispatchOptionsField && form.structureDispatchOptionsField != '-1')
        {
            urlConstruc+='StructureDispatchOption='+ form.structureDispatchOptionsField + '&'
        }

        if(form.startDate && form.startDate != '-1')
            urlConstruc+='OrderDateStart='+getformatDate(form.startDate)+'&'

        if(form.endDate && form.endDate != '-1')
            urlConstruc+='OrderDateEnd='+getformatDate(form.endDate)+'&'
        

        if(form.structureTypeFK && form.structureTypeFK != '-1')
            urlConstruc+='StructureTypeId='+form.structureTypeFK+'&'
   

        let woStateIdList = '';      
        let woStateStrList = ''; 
       if(form.workOrderStateNew && form.workOrderStateNew.value.length > 0 && form.workOrderStateNew.value[0].value != '-1')
       {

            woStateIdList = form.workOrderStateNew.value.map(function(item){
                return item['value'];
            });

            woStateStrList = woStateIdList.join("-");
            urlConstruc += 'StateWorkOrderId='+ woStateStrList +'&';
       }

       
        const downloadUrl = config.apis.coreApi.URL+'/api/WorkOrders/export?'+urlConstruc.slice(0, -1);         
        
        const  response = await getDownloadFile(access_token,downloadUrl);

        if (response.hasOwnProperty("error")) {
            dispatch(error({
                ...reportsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al generar el reporte. Detalle: '${response.error.message}'`
            }));

            return;
        }
            
        let classDate = new Date();
        let month = classDate.getMonth()+1;
        let hoy = classDate.getDate()+''+month+''+classDate.getFullYear()+''+classDate.getHours()+''+classDate.getMinutes()+''+classDate.getSeconds();
        let url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        const { loginReducer } = loadState();
        const { profile_name } = loginReducer.authentication;
        if (profile_name == "Administrador" || profile_name == "Obras" || profile_name == "Fabricacion" || profile_name == "Fabricación" || profile_name == "Despacho" || profile_name == "Ingenieria" || profile_name == "Ingeniería") {
            if (_reportType) {
                link.setAttribute('download', 'Plan de Obra '+hoy+'.xlsx');
                document.body.appendChild(link);
                link.click();
    
                dispatch(success({
                    ...reportsNotification,
                    title: "Plan de Obra",
                    message: `Descargado exitosamente!`
                }));
            } else {
                link.setAttribute('download', 'Reporte '+ profile_name + ' ' +hoy+'.xlsx');
                document.body.appendChild(link);
                link.click();
    
                dispatch(success({
                    ...reportsNotification,
                    title: "Reporte",
                    message: `Descargado exitosamente!`
                }));
            }
        }
        else {
            if (_reportType) {
                link.setAttribute('download', 'Plan de Obra '+hoy+'.xlsx');
                document.body.appendChild(link);
                link.click();
    
                dispatch(success({
                    ...reportsNotification,
                    title: "Plan de Obra",
                    message: `Descargado exitosamente!`
                }));
            } else {
                link.setAttribute('download', 'Reporte '+hoy+'.xlsx');
                document.body.appendChild(link);
                link.click();
    
                dispatch(success({
                    ...reportsNotification,
                    title: "Reporte",
                    message: `Descargado exitosamente!`
                }));
            }
        }
        
    }
}


