export const ENTRUSTEDTASKS_CARDS_REQUEST = "ENTRUSTEDTASKS_CARDS_REQUEST";
export const ENTRUSTEDTASKS_CARDS_SUCCESS = "ENTRUSTEDTASKS_CARDS_SUCCESS";
export const ENTRUSTEDTASKS_CARDS_FAILTURE = "ENTRUSTEDTASKS_CARDS_FAILTURE";
export const ENTRUSTEDTASKS_CARDS_CLEAN = "ENTRUSTEDTASKS_CARDS_CLEAN";

export const ENTRUSTEDTASKS_FILTER_SET = "ENTRUSTEDTASKS_FILTER_SET";
export const ENTRUSTEDTASKS_CARDS_FILTER_SET = "ENTRUSTEDTASKS_CARDS_FILTER_SET";
export const ENTRUSTEDTASKS_PAGE_SET = "ENTRUSTEDTASKS_PAGE_SET";

export const ENTRUSTEDTASKS_OPTIONS_REQUEST = "ENTRUSTEDTASKS_OPTIONS_REQUEST";
export const ENTRUSTEDTASKS_OPTIONS_SUCCESS = "ENTRUSTEDTASKS_OPTIONS_SUCCESS";
export const ENTRUSTEDTASKS_OPTIONS_FAILTURE = "ENTRUSTEDTASKS_OPTIONS_FAILTURE";

export const ENTRUSTEDTASKS_FORM_GET_REQUEST = "ENTRUSTEDTASKS_FORM_GET_REQUEST";
export const ENTRUSTEDTASKS_FORM_GET_SUCCESS = "ENTRUSTEDTASKS_FORM_GET_SUCCESS";
export const ENTRUSTEDTASKS_FORM_GET_FAILTURE = "ENTRUSTEDTASKS_FORM_GET_FAILTURE";


export const ENTRUSTEDTASKS_FORM_LOAD_REQUEST = "ENTRUSTEDTASKS_FORM_LOAD_REQUEST";
export const ENTRUSTEDTASKS_FORM_LOAD_SUCCESS = "ENTRUSTEDTASKS_FORM_LOAD_SUCCESS";
export const ENTRUSTEDTASKS_FORM_LOAD_FAILTURE = "ENTRUSTEDTASKS_FORM_LOAD_FAILTURE";

export const ENTRUSTEDTASKS_AUTOCOMPLITE_REQUEST = "ENTRUSTEDTASKS_AUTOCOMPLITE_REQUEST";
export const ENTRUSTEDTASKS_AUTOCOMPLITE_SUCCESS = "ENTRUSTEDTASKS_AUTOCOMPLITE_SUCCESS";
export const ENTRUSTEDTASKS_AUTOCOMPLITE_FAILTURE = "ENTRUSTEDTASKS_AUTOCOMPLITE_FAILTURE";

export const ENTRUSTEDTASKS_END_REQUEST = "ENTRUSTEDTASKS_END_REQUEST";
export const ENTRUSTEDTASKS_END_SUCCESS = "ENTRUSTEDTASKS_END_SUCCESS";
export const ENTRUSTEDTASKS_END_FAILTURE = "ENTRUSTEDTASKS_END_FAILTURE";