export const RESETPASSWORD_REQUEST = "RESETPASSWORD_REQUEST";
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAILTURE = "RESETPASSWORD_FAILTURE";
export const RESETPASSWORD_CLEAN = "RESETPASSWORD_CLEAN";


export const RESETPASSWORD_FORM_GET_REQUEST = "RESETPASSWORD_FORM_GET_REQUEST";
export const RESETPASSWORD_FORM_GET_SUCCESS = "RESETPASSWORD_FORM_GET_SUCCESS";
export const RESETPASSWORD_FORM_GET_FAILTURE = "RESETPASSWORD_FORM_GET_FAILTURE";

export const RESETPASSWORD_FORM_EDIT_REQUEST = "RESETPASSWORD_FORM_EDIT_REQUEST";
export const RESETPASSWORD_FORM_EDIT_SUCCESS = "RESETPASSWORD_FORM_EDIT_SUCCESS";
export const RESETPASSWORD_FORM_EDIT_FAILTURE = "RESETPASSWORD_FORM_EDIT_FAILTURE";

export const RESETPASSWORD_FORM_LOAD_REQUEST = "RESETPASSWORD_FORM_LOAD_REQUEST";
export const RESETPASSWORD_FORM_LOAD_SUCCESS = "RESETPASSWORD_FORM_LOAD_SUCCESS";
export const RESETPASSWORD_FORM_LOAD_FAILTURE = "RESETPASSWORD_FORM_LOAD_FAILTURE";