import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';
import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditClientContactForm } from 'Src/components/clientContacts/clientContactForm'

import {
    getClientsOption,
    getContactTypesOption,
    getClientContactToEdit
} from "Src/redux/reducers/clientContactsReducer"

import { tryEditClientContact, tryFormLoad } from 'Src/redux/thunk-creators/clientContactsActionCreator';

class ClientContactsEditPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }

    render() {
        return (
            <Container className="clientContact-container">
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard/>} >
                            <ConnectedEditClientContactForm 
                                title="Editar Contacto de Cliente"
                                submitText="Editar"
                                onSubmit={values => this.props.onClientContactEdit(values)} 
                                onCancel={this.props.goBack}
                                initialValues={this.props.clientContactToEdit}
                                clientsOptions={this.props.clientsOptions}
                                contactTypesOptions={this.props.contactTypesOptions}/>
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        clientsOptions: getClientsOption(state),
        contactTypesOptions: getContactTypesOption(state),
        clientContactToEdit: getClientContactToEdit(state)
    };
}

const mapDispatchToProps = dispatch => ({
    onClientContactEdit: (clientContact) => dispatch(tryEditClientContact(clientContact)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientContactsEditPage);