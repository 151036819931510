import {
  DASHBOARD_MAP_FILTER_SET,
  DASHBOARD_MAP_FORM_LOAD_REQUEST, 
  DASHBOARD_MAP_FORM_LOAD_SUCCESS, 
  DASHBOARD_MAP_FORM_LOAD_FAILTURE,
  DASHBOARD_MAP_REQUEST,
  DASHBOARD_MAP_SUCCESS,
  DASHBOARD_MAP_FAILTURE,
  DASHBOARD_PIECHART_REQUEST, 
  DASHBOARD_PIECHART_SUCCESS,
  DASHBOARD_PIECHART_FAILTURE,
  DASHBOARD_PIECHART_FILTER_SET,
  DASHBOARD_BARCHART_FILTER_SET,
  DASHBOARD_BARCHART_REQUEST,
  DASHBOARD_BARCHART_SUCCESS, 
  DASHBOARD_BARCHART_FAILTURE,
  DASHBOARD_SHOW_CARD,
  DASHBOARD_WORKORDEN_AUTOCOMPLITE_REQUEST,
  DASHBOARD_WORKORDEN_AUTOCOMPLITE_SUCCESS,
  DASHBOARD_WORKORDEN_AUTOCOMPLITE_FAILTURE,
  DASHBOARD_MAP_WEATHER_SUCCESS
} from "./dashboardActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const setFilter = (filter) => ({
  type: DASHBOARD_MAP_FILTER_SET,
  payload: {
    filter
  }
});

export const setFilterPieChart = (filter) => ({
  type: DASHBOARD_PIECHART_FILTER_SET,
  payload: {
    filter
  }
});

export const setFilterBarChart = (filter) => ({
  type: DASHBOARD_BARCHART_FILTER_SET,
  payload: {
    filter
  }
});

export const getSuccessWeather = (response) => ({
  type: DASHBOARD_MAP_WEATHER_SUCCESS,
  payload: {
    records: response
  }
});

export const getRequest = () => ({
  type: DASHBOARD_MAP_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getSuccess = (response) => ({
  type: DASHBOARD_MAP_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response
  }
});

export const getFailture = (response) => ({
  type: DASHBOARD_MAP_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const getPieChartRequest = () => ({
  type: DASHBOARD_PIECHART_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getPieChartSuccess = (response) => ({
  type: DASHBOARD_PIECHART_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response
  }
});

export const getPieChartFailture = (response) => ({
  type: DASHBOARD_PIECHART_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const getBarChartRequest = () => ({
  type: DASHBOARD_BARCHART_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getBarChartSuccess = (response) => ({
  type: DASHBOARD_BARCHART_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response
  }
});

export const getBarChartFailture = (response) => ({
  type: DASHBOARD_BARCHART_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});


export const formLoadRequest = () =>({
  type: DASHBOARD_MAP_FORM_LOAD_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
})



export const formLoadSuccess = (structureTypeOption,clientOptions) => ({
  type: DASHBOARD_MAP_FORM_LOAD_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    structureTypeOption,
    clientOptions
  }
})

export const formLoadFailture = (response) =>({
  type: DASHBOARD_MAP_FORM_LOAD_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
})

export const setShowCard = (isShownCard) =>({
  type: DASHBOARD_SHOW_CARD,
  payload: {
    isShownCard
  }
})

export const getAutocompleteRequest = () => ({
  type: DASHBOARD_WORKORDEN_AUTOCOMPLITE_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getAutocompleteSuccess = (response) => ({
  type: DASHBOARD_WORKORDEN_AUTOCOMPLITE_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response
  }
});

export const getAutocompleteFailture = (response) => ({
  type: DASHBOARD_WORKORDEN_AUTOCOMPLITE_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});