import React from 'react';
import {
    Card, Button, Spinner
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { inputField } from 'Src/components/forms/inputField';

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}

    if (!values.description) {
        errors.description = 'Requerido!'
    }
    
    return errors
}

class ContactTypeForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText,
            mode
        } = this.props;

        return (
            <Card className="shadow">
                <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <Field name="description" label="Descripción" component={inputField} type="text" />
                        </div>
                        <div>
                            <Button variant="primary" type="submit" disabled={pristine || submitting}>
                                {submitText}
                                {submitting ? <Spinner animation="grow" size="sm" /> : null}
                            </Button>
                            {' '}
                            <Button variant="alternative" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                            {' '}
                            <Button variant="link" onClick={onCancel}>Cancelar</Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_CONTACTTYPE_NAME = 'CREATE_CONTACTTYPE_NAME';
export const EDIT_CONTACTTYPE_NAME = 'EDIT_CONTACTTYPE_NAME';

const CreateContactTypeForm = props =>
  <ContactTypeForm {...props} mode='create' />;
  
const EditContactTypeForm = props =>
  <ContactTypeForm {...props} mode='edit' />;

const ConnectedCreateContactTypeForm = reduxForm({
    form: CREATE_CONTACTTYPE_NAME,
    validate
})(CreateContactTypeForm);

const ConnectedEditContactTypeForm = reduxForm({
    form: EDIT_CONTACTTYPE_NAME,
    validate
})(EditContactTypeForm);

export {
    ConnectedCreateContactTypeForm,
    ConnectedEditContactTypeForm
}