import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';

import LoadingCard from 'Src/components/loadingCard';
import { ConnectedCreateWorkOrderForm, CREATE_WORKORDER_FORM } from 'Src/components/workOrders/workOrderForm'
import { tryCreate, tryFormLoad, tryGetClientChildren, tryAddClientContactInWorkOrder } from 'Src/redux/thunk-creators/workOrdersCardsActionCreator';


import {
    getClientsOption, getRegionsOption, getContactSupervisorOption,
    getContactOnSiteOption, getContactComertialOptions,
    getStructureTypeTemplate, getParticularDetailsTemplate,
    getEntrustedTaskTypesTemplate, getCertificationsOption, getFacturationsOption,
    getContactTypesOption, getContactContractorOption
} from "Src/redux/reducers/workOrdersCardsReducer";

import { formValueSelector } from 'redux-form';
import { parseDate, toStringDate } from 'Src/services/formatService';
import { getUserProfile } from "Src/redux/reducers/loginReducer";

class WorkOrdersAddPage extends React.Component {
    constructor(props) {
        super(props);

        this.onChangeClient = this.onChangeClient.bind(this);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }

    onChangeClient(event) {
        if (event.target.value)
            this.props.tryGetClientChildren(event.target.value)
    }

    onChangeAddress(event) {
        console.time("onChangeAddress")
        console.info("onChangeAddress ", event);
        console.timeEnd("onChangeAddress")
    }

    render() {
        const {
            clientsOptions, regionsOptions,
            contactSupervisorOptions, contactOnSiteOptions, contactComertialOptions,
            structureTypeTemplate, particularDetailsTemplate, endDate,
            entrustedTaskTypesTemplate, certificationsOptions, 
            facturationsOption, contactTypesOptions, contactContractorOptions,
            userProfile
        } = this.props
        const withTaxReceipts=userProfile=='Administracion'? true:false;
        return (
            <Container className="page-container">
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedCreateWorkOrderForm
                                title="Crear Orden de Trabajo"
                                submitText="Crear"
                                onSubmit={wo => this.props.onCreate(wo)}
                                onCancel={this.props.goBack}
                                // initialValues={MOCK_WORKORDER}
                                clientsOptions={clientsOptions}
                                regionsOptions={regionsOptions}
                                contactTypesOptions={contactTypesOptions}
                                certifications={certificationsOptions}
                                facturations={facturationsOption}
                                contactSupervisorOptions={contactSupervisorOptions}
                                contactOnSiteOptions={contactOnSiteOptions}
                                contactComertialOptions={contactComertialOptions}
                                contactContractorOptions={contactContractorOptions}
                                structureTypeTemplate={structureTypeTemplate}
                                particularDetailsTemplate={particularDetailsTemplate}
                                entrustedTaskTypesTemplate={entrustedTaskTypesTemplate}
                                onChangeClient={this.onChangeClient}
                                onChangeAddress={this.onChangeAddress}
                                endDate={endDate}
                                withTaxReceipts={withTaxReceipts}
                                onSaveContact={values => this.props.onClientContactAdd(values)}
                            />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const cwoSelector = formValueSelector(CREATE_WORKORDER_FORM)

const mapStateToProps = (state) => {
    const startDate = parseDate(cwoSelector(state, 'startDate'))
    let endDate = startDate;

    const deliveryDays = cwoSelector(state, 'deliveryDays')
    if (deliveryDays)
        endDate = startDate.add(deliveryDays, 'days');

    return {
        clientsOptions: getClientsOption(state),
        regionsOptions: getRegionsOption(state),
        contactTypesOptions: getContactTypesOption(state),
        certificationsOptions: getCertificationsOption(state),
        facturationsOption: getFacturationsOption(state),
        contactSupervisorOptions: getContactSupervisorOption(state),
        contactOnSiteOptions: getContactOnSiteOption(state),
        contactComertialOptions: getContactComertialOptions(state),
        contactContractorOptions: getContactContractorOption(state),
        endDate: toStringDate(endDate),
        structureTypeTemplate: getStructureTypeTemplate(state),
        particularDetailsTemplate: getParticularDetailsTemplate(state),
        entrustedTaskTypesTemplate: getEntrustedTaskTypesTemplate(state),
        userProfile: getUserProfile(state),
    };
}

const mapDispatchToProps = dispatch => ({
    onCreate: (wo) => dispatch(tryCreate(wo)),
    onClientContactAdd: (clientContact) => dispatch(tryAddClientContactInWorkOrder(clientContact,'add')),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad()),
    tryGetClientChildren: (clientId) => dispatch(tryGetClientChildren(clientId)),
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkOrdersAddPage);