import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { LOGIN_URI,RESET_PASSWORD_URI } from 'Src/routes';
import { Alert,Spinner } from 'react-bootstrap'

import { tryResetPassword } from 'Src/redux/thunk-creators/loginActionCreator';
import { getIsFetching, getIsLoggedIn } from "Src/redux/reducers/loginReducer";

class ResetPasswordPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { error: false, loading:false, isFetching:false };
        this.onResetPassword = this.onResetPassword.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onPasswordChangeConfir = this.onPasswordChangeConfir.bind(this);
        this.onKeyPressed = this.onKeyPressed.bind(this);
    }

    onPasswordChange(event) {
        this.setState({
            password: event.target.value
        });
    }

    onPasswordChangeConfir(event) {
        this.setState({
            passwordConfir: event.target.value
        });
    }

    onResetPassword(e) {
        let token = window.location.pathname.replace(RESET_PASSWORD_URI+'/','');
        if(this.state.password != this.state.passwordConfir && !token){
            this.setState({
                error: 'error',
                loading:null,
                isFetching:false
            });
            return false;
        }
        this.setState({
            error: false,
            loading:<Spinner animation="grow" size='sm' />,
            isFetching:true
        }); 
        this.props.tryResetPassword(this.state.password,token);
    }

    onKeyPressed(event) {
        if (event.key === "Enter") {
            this.onResetPassword(event);
        }
    }

    render() {
        let loadingWheel =this.state.loading; 
        return (
            <div className="container">
                <div className='row'>
                    <div className="card login-card shadow-lg">
                        <article className="card-body">
                            <div className="row h-100 justify-content-center align-items-center">
                                <div className="logo mb-4 mt-1"></div>
                            </div>

                            <h4 className="card-title text-center mb-4 mt-1">
                                Restaurar contraseña
                            </h4>
                            <hr />
                            
                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="fa fa-lock">**</i>
                                        </span>
                                    </div>
                                    <input className="form-control" placeholder="******" type="password"
                                        value={this.state && this.state.password}
                                        onChange={this.onPasswordChange}
                                        onKeyPress={this.onKeyPressed} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="fa fa-lock">**</i>
                                        </span>
                                    </div>
                                    <input className="form-control" placeholder="******" type="password"
                                        value={this.state && this.state.passwordConfir}
                                        onChange={this.onPasswordChangeConfir}
                                        onKeyPress={this.onKeyPressed} />                                        
                                </div>
                                {this.state.error ?
                                            <Alert key="error" variant="danger">
                                                {this.state.error}
                                            </Alert>
                                        : null}
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary btn-block"
                                    onClick={this.onResetPassword}
                                    disabled={this.state.isFetching}>
                                    Enviar  {loadingWheel}
                                </button>

                            </div>
                            <div className="form-group">
                            <Link to={LOGIN_URI}>Volver al login</Link>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        tryResetPassword: (password,token) => dispatch(tryResetPassword(password,token)),
    }
};

export default connect(
    null,
    mapDispatchToProps
)(ResetPasswordPage);