import {
  TASKSCONFIGURATIONS_CARDS_REQUEST,
  TASKSCONFIGURATIONS_CARDS_SUCCESS,
  TASKSCONFIGURATIONS_CARDS_FAILTURE,
  TASKSCONFIGURATIONS_CARDS_CLEAN,
  TASKSCONFIGURATIONS_FILTER_SET,
  TASKSCONFIGURATIONS_PAGE_SET,
  TASKSCONFIGURATIONS_FORM_ADD_REQUEST,
  TASKSCONFIGURATIONS_FORM_ADD_SUCCESS,
  TASKSCONFIGURATIONS_FORM_ADD_FAILTURE,
  TASKSCONFIGURATIONS_FORM_DELETE_REQUEST,
  TASKSCONFIGURATIONS_FORM_DELETE_SUCCESS,
  TASKSCONFIGURATIONS_FORM_DELETE_FAILTURE,
  TASKSCONFIGURATIONS_FORM_GET_REQUEST,
  TASKSCONFIGURATIONS_FORM_GET_SUCCESS,
  TASKSCONFIGURATIONS_FORM_GET_FAILTURE,
  TASKSCONFIGURATIONS_FORM_EDIT_REQUEST,
  TASKSCONFIGURATIONS_FORM_EDIT_SUCCESS,
  TASKSCONFIGURATIONS_FORM_EDIT_FAILTURE,
  TASKSCONFIGURATIONS_FORM_LOAD_REQUEST,
  TASKSCONFIGURATIONS_FORM_LOAD_SUCCESS, 
  TASKSCONFIGURATIONS_FORM_LOAD_FAILTURE,
  TASKSCONFIGURATIONS_CARDS_FILTER_SUCCESS
} from "./tasksConfigurationsActionTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const setFilter = (filter) => ({
  type: TASKSCONFIGURATIONS_FILTER_SET,
  payload: {
    filter
  }
});


export const getRequest = (taskConfigurationId) => ({
  type: TASKSCONFIGURATIONS_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    taskConfigurationId
  }
});

export const getSuccess = (response) => ({
  type: TASKSCONFIGURATIONS_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const getFailture = (response) => ({
  type: TASKSCONFIGURATIONS_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});


export const addRequest = (taskConfiguration) => ({
  type: TASKSCONFIGURATIONS_FORM_ADD_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    taskConfiguration
  }
});

export const addSuccess = (response) => ({
  type: TASKSCONFIGURATIONS_FORM_ADD_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const addFailture = (response) => ({
  type: TASKSCONFIGURATIONS_FORM_ADD_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const editRequest = (taskConfigurationId) => ({
  type: TASKSCONFIGURATIONS_FORM_EDIT_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    taskConfigurationId
  }
});

export const editSuccess = (response) => ({
  type: TASKSCONFIGURATIONS_FORM_EDIT_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const editFailture = (response) => ({
  type: TASKSCONFIGURATIONS_FORM_EDIT_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const deleteRequest = (taskConfigurationId) => ({
  type: TASKSCONFIGURATIONS_FORM_DELETE_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    taskConfigurationId
  }
});

export const deleteSuccess = (taskConfigurationId) => ({
  type: TASKSCONFIGURATIONS_FORM_DELETE_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    taskConfigurationId
  }
});

export const deleteFailture = (response) => ({
  type: TASKSCONFIGURATIONS_FORM_DELETE_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const getCardsRequest = () => ({
  type: TASKSCONFIGURATIONS_CARDS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getCardsSuccess = (response) => ({
  type: TASKSCONFIGURATIONS_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response.records,
    metadata: response.metadata
  }
});

export const getCardsFilterSuccess = (response) => ({
  type: TASKSCONFIGURATIONS_CARDS_FILTER_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response
  }
});

export const getCardsFailture = (response) => ({
  type: TASKSCONFIGURATIONS_CARDS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const setPage = (page) => ({
  type: TASKSCONFIGURATIONS_PAGE_SET,
  payload: {
    page
  }
});


export const formLoadRequest = () =>({
  type: TASKSCONFIGURATIONS_FORM_LOAD_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
})



export const formLoadSuccess = (structureTypeOption,areasOptions,entrustedTasksOptions,operativeTasksOptions) => ({
  type: TASKSCONFIGURATIONS_FORM_LOAD_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    structureTypeOption,
    areasOptions,
    entrustedTasksOptions,
    operativeTasksOptions
  }
})

export const formLoadFailture = (response) =>({
  type: TASKSCONFIGURATIONS_FORM_LOAD_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
})