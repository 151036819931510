export const CLIENTCONTACTS_CARDS_REQUEST = "CLIENTCONTACTS_CARDS_REQUEST";
export const CLIENTCONTACTS_CARDS_SUCCESS = "CLIENTCONTACTS_CARDS_SUCCESS";
export const CLIENTCONTACTS_CARDS_FAILTURE = "CLIENTCONTACTS_CARDS_FAILTURE";
export const CLIENTCONTACTS_CARDS_CLEAN = "CLIENTCONTACTS_CARDS_CLEAN";

export const CLIENTCONTACTS_FILTER_SET = "CLIENTCONTACTS_FILTER_SET";
export const CLIENTCONTACTS_PAGE_SET = "CLIENTCONTACTS_PAGE_SET";

export const CLIENTCONTACTS_FORM_LOAD_REQUEST = "CLIENTCONTACTS_FORM_LOAD_REQUEST";
export const CLIENTCONTACTS_FORM_LOAD_SUCCESS = "CLIENTCONTACTS_FORM_LOAD_SUCCESS";
export const CLIENTCONTACTS_FORM_LOAD_FAILTURE = "CLIENTCONTACTS_FORM_LOAD_FAILTURE";

export const CLIENTCONTACTS_FORM_ADD_REQUEST = "CLIENTCONTACTS_FORM_ADD_REQUEST";
export const CLIENTCONTACTS_FORM_ADD_SUCCESS = "CLIENTCONTACTS_FORM_ADD_SUCCESS";
export const CLIENTCONTACTS_FORM_ADD_FAILTURE = "CLIENTCONTACTS_FORM_ADD_FAILTURE";

export const CLIENTCONTACTS_FORM_DELETE_REQUEST = "CLIENTCONTACTS_FORM_DELETE_REQUEST";
export const CLIENTCONTACTS_FORM_DELETE_SUCCESS = "CLIENTCONTACTS_FORM_DELETE_SUCCESS";
export const CLIENTCONTACTS_FORM_DELETE_FAILTURE = "CLIENTCONTACTS_FORM_DELETE_FAILTURE";

export const CLIENTCONTACTS_FORM_GET_REQUEST = "CLIENTCONTACTS_FORM_GET_REQUEST";
export const CLIENTCONTACTS_FORM_GET_SUCCESS = "CLIENTCONTACTS_FORM_GET_SUCCESS";
export const CLIENTCONTACTS_FORM_GET_FAILTURE = "CLIENTCONTACTS_FORM_GET_FAILTURE";

export const CLIENTCONTACTS_FORM_EDIT_REQUEST = "CLIENTCONTACTS_FORM_EDIT_REQUEST";
export const CLIENTCONTACTS_FORM_EDIT_SUCCESS = "CLIENTCONTACTS_FORM_EDIT_SUCCESS";
export const CLIENTCONTACTS_FORM_EDIT_FAILTURE = "CLIENTCONTACTS_FORM_EDIT_FAILTURE";