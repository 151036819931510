import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';
import LoadingCard from 'Src/components/loadingCard';

import { ConnectedCreateRegionForm } from 'Src/components/regions/RegionForm'

import { tryAddRegion,tryFormLoad } from 'Src/redux/thunk-creators/regionsCardsActionCreator';
import {
    getClientsOption
} from "Src/redux/reducers/regionsCardsReducer";

class RegionsAddPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.tryFormLoad();
    }

    render() {
        const{
            clientsOptions
        }=this.props;
        return (
            <Container className="Regions-container">
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard/>} >
                            <ConnectedCreateRegionForm 
                                title="Crear Region"
                                submitText="Crear"
                                onSubmit={region => this.props.onRegionAdd(region)} 
                                clientsOptions={clientsOptions}
                                onCancel={this.props.goBack}/>
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        clientsOptions: getClientsOption(state),
    };
}

const mapDispatchToProps = dispatch => ({
    onRegionAdd: (region) => dispatch(tryAddRegion(region)),
    goBack: () => dispatch(goBack()),
    tryFormLoad: () => dispatch(tryFormLoad())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegionsAddPage);