import React, { Suspense ,Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';
import LoadingCard from 'Src/components/loadingCard';

import { ConnectedCreateAreaForm } from 'Src/components/areas/areaForm';

import { tryAddArea } from 'Src/redux/thunk-creators/areasActionCreator';


class AreasAddPage extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const{
            onAreaAdd
        }=this.props;
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard/>} >
                            <ConnectedCreateAreaForm 
                                title="Crear Area"
                                submitText="Crear"
                                onSubmit={area => onAreaAdd(area)} 
                                onCancel={this.props.goBack}/>
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    onAreaAdd: (area) => dispatch(tryAddArea(area)),
    goBack: () => dispatch(goBack())
})


export default connect(
    null,
    mapDispatchToProps
)(AreasAddPage);