import {
  OPERATIVETASKS_CARDS_REQUEST,
  OPERATIVETASKS_CARDS_SUCCESS,
  OPERATIVETASKS_CARDS_FAILTURE,
  OPERATIVETASKS_CARDS_CLEAN,
  OPERATIVETASKS_FILTER_SET,
  OPERATIVETASKS_PAGE_SET,
  OPERATIVETASKS_FORM_GET_REQUEST,
  OPERATIVETASKS_FORM_GET_SUCCESS,
  OPERATIVETASKS_FORM_GET_FAILTURE,
  OPERATIVETASKS_FORM_LOAD_REQUEST,
  OPERATIVETASKS_FORM_LOAD_SUCCESS,
  OPERATIVETASKS_FORM_LOAD_FAILTURE,
  OPERATIVETASKS_CARDS_FILTER_SET,
  OPERATIVETASKS_OPTIONS_REQUEST,
  OPERATIVETASKS_OPTIONS_SUCCESS,
  OPERATIVETASKS_OPTIONS_FAILTURE,
  OPERATIVETASKS_FORM_EDIT_REQUEST,
  OPERATIVETASKS_FORM_EDIT_SUCCESS,
  OPERATIVETASKS_FORM_EDIT_FAILTURE,
  OPERATIVETASKS_FORMS_EDIT_REQUEST,
  OPERATIVETASKS_FORMS_EDIT_SUCCESS,
  OPERATIVETASKS_FORMS_EDIT_FAILTURE,
  OPERATIVETASKS_NEWDESING_REQUEST,
  OPERATIVETASKS_NEWDESING_SUCCESS,
  OPERATIVETASKS_NEWDESING_FAILTURE,
  OPERATIVETASKS_COMPLEMENTARYWORK_REQUEST,
  OPERATIVETASKS_COMPLEMENTARYWORK_SUCCESS,
  OPERATIVETASKS_COMPLEMENTARYWORK_FAILTURE,
  OPERATIVETASKS_UPDATED_END_DATE_STATE,
  OPERATIVETASKS_SELECTED_TO_SAVE
} from "./operativeTasksCardsActionTypes";

export const buildAction = (type, payload) => ({
  type,
  payload
})

export const setFilter = (filter) => ({
  type: OPERATIVETASKS_FILTER_SET,
  payload: {
    filter
  }
});

export const getCardsRequest = () => ({
  type: OPERATIVETASKS_CARDS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getCardsSuccess = (response, justifications, responsibleForArea) => ({
  type: OPERATIVETASKS_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response,
    justifications,
    responsibleForArea
  }
});

export const getCardsFailture = (response) => ({
  type: OPERATIVETASKS_CARDS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const setPage = (page) => ({
  type: OPERATIVETASKS_PAGE_SET,
  payload: {
    page
  }
});

export const setFiltertaskEntrusted = (cardData) => ({
  type: OPERATIVETASKS_CARDS_FILTER_SET,
  payload: {
    isFetching: false,
    hasError: false,
    cardData
  }
});


export const getOptionsRequest = () => ({
  type: OPERATIVETASKS_OPTIONS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});



export const getOptionsSuccess = (response) => ({
  type: OPERATIVETASKS_OPTIONS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response: response.records
  }
});

export const getOptionsFailture = (response) => ({
  type: OPERATIVETASKS_OPTIONS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});





export const getRequest = (operativeTaskId) => ({
  type: OPERATIVETASKS_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    operativeTaskId
  }
});

export const getSuccess = (response) => ({
  type: OPERATIVETASKS_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const getFailture = (response) => ({
  type: OPERATIVETASKS_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});


export const editRequest = (operativeTaskId) => ({
  type: OPERATIVETASKS_FORM_EDIT_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    operativeTaskId
  }
});

export const editSuccess = (response) => ({
  type: OPERATIVETASKS_FORM_EDIT_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const editFailture = (response) => ({
  type: OPERATIVETASKS_FORM_EDIT_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const editEndDateState = (operativeTask) => ({
  type: OPERATIVETASKS_UPDATED_END_DATE_STATE,
  payload: {
    operativeTask
  }
})


export const newDesingRequest = (WorkOrderId) => ({
  type: OPERATIVETASKS_NEWDESING_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    WorkOrderId
  }
});

export const newDesingSuccess = (response) => ({
  type: OPERATIVETASKS_NEWDESING_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const newDesingFailture = (response) => ({
  type: OPERATIVETASKS_NEWDESING_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});


export const complementaryWorkRequest = (WorkOrderId) => ({
  type: OPERATIVETASKS_COMPLEMENTARYWORK_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    WorkOrderId
  }
});

export const complementaryWorkSuccess = (response) => ({
  type: OPERATIVETASKS_COMPLEMENTARYWORK_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const complementaryWorkFailture = (response) => ({
  type: OPERATIVETASKS_COMPLEMENTARYWORK_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});



export const cleanCards = () => ({
  type: OPERATIVETASKS_CARDS_CLEAN,
  payload: {
    isFetching: false,
    hasError: false
  }
});

export const formLoadRequest = () =>
  buildAction(
    OPERATIVETASKS_FORM_LOAD_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )



export const formLoadSuccess = (clientsOptions, structureTypeTemplate, tasksEntrusted) =>
  buildAction(
    OPERATIVETASKS_FORM_LOAD_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      clientsOptions,
      structureTypeTemplate,
      tasksEntrusted
    }
  )

export const formLoadFailture = (response) =>
  buildAction(
    OPERATIVETASKS_FORM_LOAD_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )

  export const setSelectedToSave = (selectedToSave) => ({
    type: OPERATIVETASKS_SELECTED_TO_SAVE,
    payload: {
      selectedToSave
    }
  });

  export const editTasksRequest = (operativeTasks) => ({
    type: OPERATIVETASKS_FORMS_EDIT_REQUEST,
    payload: {
      isFetching: true,
      hasError: false,
      operativeTasks
    }
  });
  
  export const editTasksSuccess = (response) => ({
    type: OPERATIVETASKS_FORMS_EDIT_SUCCESS,
    payload: {
      isFetching: false,
      hasError: false,
      response
    }
  });
  
  export const editTasksFailture = (response) => ({
    type: OPERATIVETASKS_FORMS_EDIT_FAILTURE,
    payload: {
      isFetching: false,
      hasError: true,
      response
    }
  });