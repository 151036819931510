import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';
import LoadingCard from 'Src/components/loadingCard';

import { ConnectedCreateClientForm } from 'Src/components/clients/ClientForm'

import { tryAddClient } from 'Src/redux/thunk-creators/clientsCardsActionCreator';

class ClientsAddPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container className="clients-container">
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard/>} >
                            <ConnectedCreateClientForm 
                                title="Crear Cliente"
                                submitText="Crear"
                                onSubmit={values => this.props.onClientAdd(values)} 
                                onCancel={this.props.goBack}/>
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    onClientAdd: (client) => dispatch(tryAddClient(client)),
    goBack: () => dispatch(goBack())
})


export default connect(
    null,
    mapDispatchToProps
)(ClientsAddPage);