import React from 'react'

import {
    Form,Row,Col
} from 'react-bootstrap'



export const DropdownFilterField = ({
    name, options,label,value,handleChange
}) => (    
    <Form.Group as={Row} controlId={name}>
        <Form.Label column sm={4}>{label}</Form.Label>        
        <Col sm={8}>
            <Form.Control  as="select" onChange={handleChange} >
                {options ?  
                    options.map((o) => (
                        <option key={o.key} selected={value==o.key?'true':false} value={o.key}>{o.text}</option>
                    ))
                :null}
            </Form.Control>

            
        </Col>
        
        
    </Form.Group>
)
