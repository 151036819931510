import {
    JUSTIFICATIONDELAYAREAS_CARDS_REQUEST,
    JUSTIFICATIONDELAYAREAS_CARDS_SUCCESS,
    JUSTIFICATIONDELAYAREAS_CARDS_FAILTURE,
    JUSTIFICATIONDELAYAREAS_CARDS_CLEAN,
    JUSTIFICATIONDELAYAREAS_FILTER_SET,
    JUSTIFICATIONDELAYAREAS_PAGE_SET,
    JUSTIFICATIONDELAYAREAS_FORM_DELETE_SUCCESS,
    JUSTIFICATIONDELAYAREAS_FORM_GET_SUCCESS,
    JUSTIFICATIONDELAYAREAS_FORM_EDIT_SUCCESS,
    JUSTIFICATIONDELAYAREAS_FORM_LOAD_SUCCESS
} from "Src/redux/actions/justificationDelayAreasActionTypes";

const initialMetadata = {
    perPage: 6,
    page: 1
};


const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: ""
    },
    metadata: initialMetadata,
    cards: [],
    JustificationDelayAreaToEdit: undefined,
    areasOptions: undefined
};

const justificationDelayAreasReducer = (state = initialState, action) => {
    switch (action.type) {
        case JUSTIFICATIONDELAYAREAS_FILTER_SET: {
            return {
                ...state,
                filter: {
                    text: action.payload.filter
                },
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case JUSTIFICATIONDELAYAREAS_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case JUSTIFICATIONDELAYAREAS_CARDS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                cards: state.cards.concat(action.payload.records),
                metadata: action.payload.metadata
            };
        }
        case JUSTIFICATIONDELAYAREAS_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case JUSTIFICATIONDELAYAREAS_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        case JUSTIFICATIONDELAYAREAS_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        case JUSTIFICATIONDELAYAREAS_FORM_DELETE_SUCCESS: {
            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload.justificationDelayAreaId),
                metadata: {
                    ...state.metadata,
                    totalCount: state.metadata.totalCount - 1
                }
            };
        }
        case JUSTIFICATIONDELAYAREAS_FORM_GET_SUCCESS: {
            return {
                ...state,
                JustificationDelayAreaToEdit: action.payload.response
            };
        }
        case JUSTIFICATIONDELAYAREAS_FORM_EDIT_SUCCESS: {
            return {
                ...state,
                JustificationDelayAreaToEdit: undefined
            };
        }
        case JUSTIFICATIONDELAYAREAS_FORM_LOAD_SUCCESS: {
            let newAreasOptions = [{ key: -1, text: "Ninguno" }]

            const areasFilter = action.payload.areasOptions.filter(x => x.id < 7 && x.id > 2)

            const areasOptions = areasFilter.map(c => ({
                key: c.id,
                text: c.name
            }))

            return {
                ...state,
                areasOptions: newAreasOptions.concat(areasOptions)
            }
        }
        default: {
            return state;
        }
    }
};

export default justificationDelayAreasReducer;

export const getJustificationDelayAreasState = (state) => state.justificationDelayAreasReducer;

export const getFilter = (state) => getJustificationDelayAreasState(state).filter;

export const getIsFetching = (state) => getJustificationDelayAreasState(state).isFetching;

export const getCards = (state) => getJustificationDelayAreasState(state).cards;

export const getMetadata = (state) => getJustificationDelayAreasState(state).metadata;

export const getJustificationDelayAreaToEdit = (state) => getJustificationDelayAreasState(state).JustificationDelayAreaToEdit;

export const getAreasOptions = (state) => getJustificationDelayAreasState(state).areasOptions;