import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    getRequest, getSuccess, getFailture,
    addRequest, addSuccess, addFailture,
    editRequest, editSuccess, editFailture,
    getCardsRequest,getCardsFailture,getCardsSuccess,
    setFilter,setPage,
    deleteRequest, deleteFailture, deleteSuccess,
    formLoadRequest, formLoadFailture, formLoadSuccess
} from "Src/redux/actions/justificationDelayAreasActions";

import {
    get, getById, put, post, deleteById
} from 'Src/services/justificationDelayAreasService';


import { JUSTIFICATIONDELAYAREAS_FORM_URI,JUSTIFICATIONDELAYAREAS_URI } from "Src/routes";

import { get as getAreas } from 'Src/services/areasService';

const justificationDelayAreasCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5

export const setCardsMount = () => {
    return async (dispatch, getState) => {
        const { justificationDelayAreasReducer } = getState();

        dispatch(setFilter());

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            '',
            justificationDelayAreasReducer.metadata.perPage,
            1));

    };
};

export const setCardsFilter = (filterText) => {
    return async (dispatch, getState) => {
        const { justificationDelayAreasReducer } = getState();

        if (filterText.length < 1
            || filterText == justificationDelayAreasReducer.filter.text) {
                dispatch(setFilter(filterText));
            return;
        }

        dispatch(setFilter(filterText));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText,
            justificationDelayAreasReducer.metadata.perPage,
            1));

    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, justificationDelayAreasReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            justificationDelayAreasReducer.filter.text,
            justificationDelayAreasReducer.metadata.perPage,
            1));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, justificationDelayAreasReducer } = getState();

        if (!justificationDelayAreasReducer.metadata.hasMore) {
            return;
        }

        const nextPage = justificationDelayAreasReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            justificationDelayAreasReducer.filter.text,
            justificationDelayAreasReducer.metadata.perPage,
            nextPage));

    };
};

export const tryGetCardsAction = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {
        dispatch(getCardsRequest());

        let response = await get(accessToken, filter, pageSize, page,0);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...JustificationDelayAreasCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }

        dispatch(getCardsSuccess(response));
    }
};

export const tryGetJustificationDelayArea = (justificationDelayAreaId) => {
    return async (dispatch, getState) => {
        if(!justificationDelayAreaId) return;
        
        dispatch(getRequest(justificationDelayAreaId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await getById(access_token, justificationDelayAreaId);
        if (response.hasOwnProperty("error")) {
            dispatch(getFailture(response));
            dispatch(error({
                ...justificationDelayAreasCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar la justificación de retraso. Detalle: '${response.error.message}'`
            }));
            return;
        }
        response.name = response.justificationDelayType.name
        dispatch(getSuccess(response));

        dispatch(push(JUSTIFICATIONDELAYAREAS_FORM_URI + "/" + justificationDelayAreaId));
    }
}

export const tryDeleteJustificationDelayArea = (justificationDelayAreaId) => {
    return async (dispatch, getState) => {
        let shouldDelete = confirm("Desea borrar justificación de retraso Id: " + justificationDelayAreaId);
        if (!shouldDelete) {
            return;
        }

        dispatch(deleteRequest(justificationDelayAreaId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await deleteById(access_token, justificationDelayAreaId);
        if (response.hasOwnProperty("error")) {
            dispatch(deleteFailture(response));
            dispatch(error({
                ...justificationDelayAreasCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Borrar la justificación de retraso. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(deleteSuccess(justificationDelayAreaId));

        dispatch(success({
            ...justificationDelayAreasCardsNotification,
            title: "Justificación de Retraso " + justificationDelayAreaId,
            message: `Eliminado exitosamente!`
        }));
    }
}

export const tryEditJustificationDelayArea = (justificationDelayArea) => {
    return async (dispatch, getState) => {
        dispatch(editRequest(justificationDelayArea));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await put(access_token, justificationDelayArea);
        if (response.hasOwnProperty("error")) {
            dispatch(editFailture(response));
            dispatch(error({
                ...justificationDelayAreasCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar la justificación de retraso. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(editSuccess(response));

        justificationDelayArea.id = response.id;

        dispatch(success({
            ...justificationDelayAreasCardsNotification,
            title: "Justificación de Retraso " + justificationDelayArea.id,
            message: `Actualizado exitosamente!`
        }));

        dispatch(push(JUSTIFICATIONDELAYAREAS_URI));
    }
}

export const tryAddJustificationDelayArea = (justificationDelayArea) => {
    return async (dispatch, getState) => {
        dispatch(addRequest(justificationDelayArea));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await post(access_token, justificationDelayArea);

        if (response.code && response.code == 4) {
            dispatch(addFailture(response));
            dispatch(error({
                ...justificationDelayAreasCardsNotification,
                title: "Oops!",
                message: `La justificación de retraso ya existe.`
            }));

            return;
        }        

        if (response.code) {
            dispatch(addFailture(response));
            dispatch(error({
                ...justificationDelayAreasCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear una justificación de retraso. Detalle: '${response.message}'`
            }));

            return;
        }

        if (response.hasOwnProperty("error")) {
            dispatch(addFailture(response));
            dispatch(error({
                ...justificationDelayAreasCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear una justificación de retraso. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(addSuccess(response));

        justificationDelayArea.id = response.id;

        dispatch(success({
            ...justificationDelayAreasCardsNotification,
            title: "Justificación de Retraso " + justificationDelayArea.id,
            message: `Creado exitosamente!`
        }));

        dispatch(push(JUSTIFICATIONDELAYAREAS_URI));
    }
}

export const tryFormLoad = () => {
    return async (dispatch, getState) => {
        dispatch(formLoadRequest())

        const { access_token } = getState().loginReducer.authentication

        const areasPromise = getAreas(access_token, '', 1000, 1)

        const areasResponse = await areasPromise
        if(areasResponse){
            const { error, code } = areasResponse
            const hasError = error || Number.isInteger(code)

            if (hasError) {
                dispatch(formLoadFailture(areasResponse))

                let message = `Ha ocurrido un error al Cargar el Formulario de ${ENTITY_NAME}.`

                showError(dispatch, ERROR_TITLE, message)

                return
            }
        }
        
        dispatch(formLoadSuccess(areasResponse.records))
    }
}
