import {
    OPERATIVETASKTYPES_CARDS_REQUEST,
    OPERATIVETASKTYPES_CARDS_SUCCESS,
    OPERATIVETASKTYPES_CARDS_FAILTURE,
    OPERATIVETASKTYPES_CARDS_CLEAN,
    OPERATIVETASKTYPES_FILTER_SET,
    OPERATIVETASKTYPES_PAGE_SET,
    OPERATIVETASKTYPES_FORM_DELETE_SUCCESS,
    OPERATIVETASKTYPES_FORM_GET_SUCCESS,
    OPERATIVETASKTYPES_FORM_EDIT_SUCCESS
} from "Src/redux/actions/operativeTaskTypesActionTypes";

const initialMetadata = {
    perPage: 9,
    page: 1
};

const initialState = {
    hasError: false,
    isFetching: false,
    filter: {
        text: ""
    },
    metadata: initialMetadata,
    cards: [],
    OperativeTaskTypeToEdit: undefined
};

const operativeTaskTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPERATIVETASKTYPES_FILTER_SET: {
            return {
                ...state,
                filter: {
                    text: action.payload.filter
                },
                cards: [],
                metadata: { ...initialMetadata }
            }
        }
        case OPERATIVETASKTYPES_CARDS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }
        case OPERATIVETASKTYPES_CARDS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                cards: state.cards.concat(action.payload.records),
                metadata: action.payload.metadata
            };
        }
        case OPERATIVETASKTYPES_CARDS_FAILTURE: {
            return {
                ...state,
                isFetching: false
            };
        }
        case OPERATIVETASKTYPES_CARDS_CLEAN: {
            return {
                ...state,
                cards: []
            };
        }
        case OPERATIVETASKTYPES_PAGE_SET: {
            const newMetadata = {
                ...state.metadata,
                page: action.payload.page
            }
            return {
                ...state,
                metadata: newMetadata
            }
        }
        case OPERATIVETASKTYPES_FORM_DELETE_SUCCESS: {
            return {
                ...state,
                cards: state.cards.filter(card => card.id !== action.payload.operativeTaskTypeId),
                metadata: {
                    ...state.metadata,
                    totalCount: state.metadata.totalCount - 1
                }
            };
        }
        case OPERATIVETASKTYPES_FORM_GET_SUCCESS: {
            return {
                ...state,
                OperativeTaskTypeToEdit: action.payload.response
            };
        }
        case OPERATIVETASKTYPES_FORM_EDIT_SUCCESS: {
            return {
                ...state,
                OperativeTaskTypeToEdit: undefined
            };
        }
        default: {
            return state;
        }
    }
};

export default operativeTaskTypesReducer;

export const getOperativeTaskTypesState = (state) => state.operativeTaskTypesReducer;

export const getFilter = (state) => getOperativeTaskTypesState(state).filter;

export const getIsFetching = (state) => getOperativeTaskTypesState(state).isFetching;

export const getCards = (state) => getOperativeTaskTypesState(state).cards;

export const getMetadata = (state) => getOperativeTaskTypesState(state).metadata;

export const getOperativeTaskTypeToEdit = (state) => getOperativeTaskTypesState(state).OperativeTaskTypeToEdit;