import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    getRequest, getSuccess, getFailture,
    addRequest, addSuccess, addFailture,
    editRequest, editSuccess, editFailture,
    getCardsRequest,getCardsFailture,getCardsSuccess,
    setFilter,setPage,
    deleteRequest, deleteFailture, deleteSuccess
} from "Src/redux/actions/operativeTaskTypesActions";

import {
    get, getById, put, post, deleteById
} from 'Src/services/operativeTaskTypesService';


import { OPERATIVETASKTYPES_FORM_URI,OPERATIVETASKTYPES_URI } from "Src/routes";


const operativeTaskTypesCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5

export const setCardsMount = () => {
    return async (dispatch, getState) => {
        const { operativeTaskTypesReducer } = getState();

        dispatch(setFilter());

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            '',
            operativeTaskTypesReducer.metadata.perPage,
            1));

    };
};


export const setCardsFilter = (filterText) => {
    return async (dispatch, getState) => {
        const { operativeTaskTypesReducer } = getState();

        if (filterText.length < 1
            || filterText == operativeTaskTypesReducer.filter.text) {
                dispatch(setFilter(filterText));
            return;
        }

        dispatch(setFilter(filterText));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText,
            operativeTaskTypesReducer.metadata.perPage,
            1));

    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, operativeTaskTypesReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            operativeTaskTypesReducer.filter.text,
            operativeTaskTypesReducer.metadata.perPage,
            1));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, operativeTaskTypesReducer } = getState();

        if (!operativeTaskTypesReducer.metadata.hasMore) {
            return;
        }

        const nextPage = operativeTaskTypesReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            operativeTaskTypesReducer.filter.text,
            operativeTaskTypesReducer.metadata.perPage,
            nextPage));

    };
};

export const tryGetCardsAction = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {
        dispatch(getCardsRequest());

        let response = await get(accessToken, filter, pageSize, page);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...OperativeTaskTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }

        dispatch(getCardsSuccess(response));
    }
};



export const tryGetoperativeTaskType = (operativeTaskTypeId) => {
    return async (dispatch, getState) => {
        if(!operativeTaskTypeId) return;
        
        dispatch(getRequest(operativeTaskTypeId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await getById(access_token, operativeTaskTypeId);
        if (response.hasOwnProperty("error")) {
            dispatch(getFailture(response));
            dispatch(error({
                ...operativeTaskTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el operativeTaskTypee. Detalle: '${response.error.message}'`
            }));
            return;
        }
        dispatch(getSuccess(response));

        dispatch(push(OPERATIVETASKTYPES_FORM_URI + "/" + operativeTaskTypeId));
    }
}

export const tryDeleteOperativeTaskType = (operativeTaskTypeId) => {
    return async (dispatch, getState) => {
        let shouldDelete = confirm("Desea borrar la tarea operativa Id: " + operativeTaskTypeId);
        if (!shouldDelete) {
            return;
        }

        dispatch(deleteRequest(operativeTaskTypeId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await deleteById(access_token, operativeTaskTypeId);
        if (response.hasOwnProperty("error")) {
            dispatch(deleteFailture(response));
            dispatch(error({
                ...operativeTaskTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Borrar la tarea operativa. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(deleteSuccess(operativeTaskTypeId));

        dispatch(success({
            ...operativeTaskTypesCardsNotification,
            title: "Tarea operativa " + operativeTaskTypeId,
            message: `Eliminado exitosamente!`
        }));
    }
}

export const tryEditOperativeTaskType = (operativeTaskType) => {
    return async (dispatch, getState) => {
        dispatch(editRequest(operativeTaskType));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await put(access_token, operativeTaskType);
        if (response.hasOwnProperty("error")) {
            dispatch(editFailture(response));
            dispatch(error({
                ...operativeTaskTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar la tarea operativa. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(editSuccess(response));

        operativeTaskType.id = response.id;

        dispatch(success({
            ...operativeTaskTypesCardsNotification,
            title: "Tarea operativa " + operativeTaskType.id,
            message: `Actualizado exitosamente!`
        }));

        dispatch(push(OPERATIVETASKTYPES_URI));
    }
}


export const tryAddOperativeTaskType = (operativeTaskType) => {
    return async (dispatch, getState) => {
        dispatch(addRequest(operativeTaskType));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await post(access_token, operativeTaskType);

        if (response.code && response.code == 4) {
            dispatch(addFailture(response));
            dispatch(error({
                ...operativeTaskTypesCardsNotification,
                title: "Oops!",
                message: `La tarea operativa ya existe.`
            }));

            return;
        }        

        if (response.code) {
            dispatch(addFailture(response));
            dispatch(error({
                ...operativeTaskTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear una tarea operativa. Detalle: '${response.message}'`
            }));

            return;
        }

        if (response.hasOwnProperty("error")) {
            dispatch(addFailture(response));
            dispatch(error({
                ...operativeTaskTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear una tarea operativa. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(addSuccess(response));

        operativeTaskType.id = response.id;

        dispatch(success({
            ...operativeTaskTypesCardsNotification,
            title: "Tarea operativa " + operativeTaskType.id,
            message: `Creado exitosamente!`
        }));

        dispatch(push(OPERATIVETASKTYPES_URI));
    }
}
