import config from 'Config';

import { 
    get as _get, 
    post as _post, 
    getById as _getById, 
    deleteById as _deleteById, 
    put as _put } from 'Src/services/entityService'

const API_ENDPOINT = `${config.apis.coreApi.URL}/api`
const ENTITY_URI = `${API_ENDPOINT}/JustificationDelayAreas`;

let get_Cancelation = undefined;
export const get = async (accessToken, filterText, pageSize, page,areaId) => 
                        _get(accessToken, ENTITY_URI, get_Cancelation, filterText, pageSize, page,{ areaId })

export const getById = async (accessToken, id) => 
                            _getById(accessToken, ENTITY_URI, id)

export const deleteById = async (accessToken, id) => 
                            _deleteById(accessToken, ENTITY_URI, id)

export const put = async (accessToken, justificationDelayAreas) =>{
    justificationDelayAreas.justificationDelayType.name = justificationDelayAreas.name;
    return _put(accessToken, ENTITY_URI, justificationDelayAreas)
} 
                        

export const post = async (accessToken, justificationDelayAreas) => {
    const params = {
        "name": justificationDelayAreas.name,
        "areaFK": justificationDelayAreas.areaFK
    }

    return _post(accessToken, ENTITY_URI, params)
}
