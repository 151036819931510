import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';

import {
    getRequest, getSuccess, getFailture,
    addRequest, addSuccess, addFailture,
    editRequest, editSuccess, editFailture,
    getCardsRequest,getCardsFailture,getCardsSuccess,
    setFilter,setPage,
    deleteRequest, deleteFailture, deleteSuccess
} from "Src/redux/actions/entrustedTaskTypesActions";

import {
    get, getById, put, post, deleteById
} from 'Src/services/entrustedTaskTypesService';


import { ENTRUSTEDTASKTYPES_FORM_URI, ENTRUSTEDTASKTYPES_URI } from "Src/routes";


const entrustedTaskTypesCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5

export const setCardsMount = () => {
    return async (dispatch, getState) => {
        const { entrustedTaskTypesReducer } = getState();

        dispatch(setFilter());

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            '',
            entrustedTaskTypesReducer.metadata.perPage,
            1));

    };
};


export const setCardsFilter = (filterText) => {
    return async (dispatch, getState) => {
        const { entrustedTaskTypesReducer } = getState();

        if (filterText.length < 1
            || filterText == entrustedTaskTypesReducer.filter.text) {
                dispatch(setFilter(filterText));
            return;
        }

        dispatch(setFilter(filterText));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText,
            entrustedTaskTypesReducer.metadata.perPage,
            1));

    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, entrustedTaskTypesReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            entrustedTaskTypesReducer.filter.text,
            entrustedTaskTypesReducer.metadata.perPage,
            1));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, entrustedTaskTypesReducer } = getState();

        if (!entrustedTaskTypesReducer.metadata.hasMore) {
            return;
        }

        const nextPage = entrustedTaskTypesReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            entrustedTaskTypesReducer.filter.text,
            entrustedTaskTypesReducer.metadata.perPage,
            nextPage));

    };
};

export const tryGetCardsAction = (accessToken, filter, pageSize, page) => {
    return async (dispatch) => {
        dispatch(getCardsRequest());

        let response = await get(accessToken, filter, pageSize, page);

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...entrustedTaskTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar los tipos de tareas encomendadas. Detalle: '${response.error.message}'`
            }));

            return;
        }

        dispatch(getCardsSuccess(response));
    }
};



export const tryGetEntrustedTaskType = (entrustedTaskTypeId) => {
    return async (dispatch, getState) => {
        if(!entrustedTaskTypeId) return;
        
        dispatch(getRequest(entrustedTaskTypeId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await getById(access_token, entrustedTaskTypeId);
        if (response.hasOwnProperty("error")) {
            dispatch(getFailture(response));
            dispatch(error({
                ...entrustedTaskTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido al cargar el tipo de tarea encomendada. Detalle: '${response.error.message}'`
            }));
            return;
        }
        dispatch(getSuccess(response));

        dispatch(push(ENTRUSTEDTASKTYPES_FORM_URI + "/" + entrustedTaskTypeId));
    }
}

export const tryDeleteEntrustedTaskType = (entrustedTaskTypeId) => {
    return async (dispatch, getState) => {
        let shouldDelete = confirm("Desea borrar el tipo de tarea encomendada Id: " + entrustedTaskTypeId);
        if (!shouldDelete) {
            return;
        }

        dispatch(deleteRequest(entrustedTaskTypeId));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await deleteById(access_token, entrustedTaskTypeId);
        if (response.hasOwnProperty("error")) {
            dispatch(deleteFailture(response));
            dispatch(error({
                ...entrustedTaskTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Borrar el tipo de tarea encomendada. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(deleteSuccess(entrustedTaskTypeId));

        dispatch(success({
            ...entrustedTaskTypesCardsNotification,
            title: "Tipo de tarea encomendada " + entrustedTaskTypeId,
            message: `Eliminado exitosamente!`
        }));
    }
}

export const tryEditEntrustedTaskType = (entrustedTaskType) => {
    return async (dispatch, getState) => {
        dispatch(editRequest(entrustedTaskType));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await put(access_token, entrustedTaskType);
        if (response.hasOwnProperty("error")) {
            dispatch(editFailture(response));
            dispatch(error({
                ...entrustedTaskTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar el tipo de tarea encomendada. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(editSuccess(response));

        entrustedTaskType.id = response.id;

        dispatch(success({
            ...entrustedTaskTypesCardsNotification,
            title: "Tipo de tarea encomendada " + entrustedTaskType.id,
            message: `Actualizado exitosamente!`
        }));

        dispatch(push(ENTRUSTEDTASKTYPES_URI));
    }
}


export const tryAddEntrustedTaskType = (entrustedTaskType) => {
    return async (dispatch, getState) => {
        dispatch(addRequest(entrustedTaskType));

        const { loginReducer } = getState();
        const { access_token } = loginReducer.authentication;

        let response = await post(access_token, entrustedTaskType);

        if (response.code && response.code == 4) {
            dispatch(addFailture(response));
            dispatch(error({
                ...entrustedTaskTypesCardsNotification,
                title: "Oops!",
                message: `El tipo de tarea encomendada ya existe.`
            }));

            return;
        }        

        if (response.code) {
            dispatch(addFailture(response));
            dispatch(error({
                ...entrustedTaskTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear un tipo de tarea encomendada. Detalle: '${response.message}'`
            }));

            return;
        }

        if (response.hasOwnProperty("error")) {
            dispatch(addFailture(response));
            dispatch(error({
                ...entrustedTaskTypesCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Crear un tipo de tarea encomendada. Detalle: '${response.error.message}'`
            }));

            return;
        }
        
        dispatch(addSuccess(response));

        entrustedTaskType.id = response.id;

        dispatch(success({
            ...entrustedTaskTypesCardsNotification,
            title: "Tipo de tarea encomendada " + entrustedTaskType.id,
            message: `Creado exitosamente!`
        }));

        dispatch(push(ENTRUSTEDTASKTYPES_URI));
    }
}
