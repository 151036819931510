import {
  RESPONSIBLEFORAREA_CARDS_REQUEST,
  RESPONSIBLEFORAREA_CARDS_SUCCESS,
  RESPONSIBLEFORAREA_CARDS_FAILTURE,
  RESPONSIBLEFORAREA_CARDS_CLEAN,
  RESPONSIBLEFORAREA_FILTER_SET,
  RESPONSIBLEFORAREA_PAGE_SET,
  RESPONSIBLEFORAREA_FORM_ADD_REQUEST,
  RESPONSIBLEFORAREA_FORM_ADD_SUCCESS,
  RESPONSIBLEFORAREA_FORM_ADD_FAILTURE,
  RESPONSIBLEFORAREA_FORM_DELETE_REQUEST,
  RESPONSIBLEFORAREA_FORM_DELETE_SUCCESS,
  RESPONSIBLEFORAREA_FORM_DELETE_FAILTURE,
  RESPONSIBLEFORAREA_FORM_GET_REQUEST,
  RESPONSIBLEFORAREA_FORM_GET_SUCCESS,
  RESPONSIBLEFORAREA_FORM_GET_FAILTURE,
  RESPONSIBLEFORAREA_FORM_EDIT_REQUEST,
  RESPONSIBLEFORAREA_FORM_EDIT_SUCCESS,
  RESPONSIBLEFORAREA_FORM_EDIT_FAILTURE,
  RESPONSIBLEFORAREA_FORM_LOAD_REQUEST,
  RESPONSIBLEFORAREA_FORM_LOAD_SUCCESS, 
  RESPONSIBLEFORAREA_FORM_LOAD_FAILTURE,
} from "./responsibleForAreaTypes";

export const buildAction = (type, payload) => ({ 
  type, 
  payload 
})

export const setFilter = (filter) => ({
  type: RESPONSIBLEFORAREA_FILTER_SET,
  payload: {
    filter
  }
});


export const getRequest = (responsibleForAreaId) => ({
  type: RESPONSIBLEFORAREA_FORM_GET_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    responsibleForAreaId
  }
});

export const getSuccess = (response) => ({
  type: RESPONSIBLEFORAREA_FORM_GET_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const getFailture = (response) => ({
  type: RESPONSIBLEFORAREA_FORM_GET_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});


export const addRequest = (area) => ({
  type: RESPONSIBLEFORAREA_FORM_ADD_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    area
  }
});

export const addSuccess = (response) => ({
  type: RESPONSIBLEFORAREA_FORM_ADD_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const addFailture = (response) => ({
  type: RESPONSIBLEFORAREA_FORM_ADD_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const editRequest = (responsibleForAreaId) => ({
  type: RESPONSIBLEFORAREA_FORM_EDIT_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    responsibleForAreaId
  }
});

export const editSuccess = (response) => ({
  type: RESPONSIBLEFORAREA_FORM_EDIT_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    response
  }
});

export const editFailture = (response) => ({
  type: RESPONSIBLEFORAREA_FORM_EDIT_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const deleteRequest = (responsibleForAreaId) => ({
  type: RESPONSIBLEFORAREA_FORM_DELETE_REQUEST,
  payload: {
    isFetching: true,
    hasError: false,
    responsibleForAreaId
  }
});

export const deleteSuccess = (responsibleForAreaId) => ({
  type: RESPONSIBLEFORAREA_FORM_DELETE_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    responsibleForAreaId
  }
});

export const deleteFailture = (response) => ({
  type: RESPONSIBLEFORAREA_FORM_DELETE_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const getCardsRequest = () => ({
  type: RESPONSIBLEFORAREA_CARDS_REQUEST,
  payload: {
    isFetching: true,
    hasError: false
  }
});

export const getCardsSuccess = (response) => ({
  type: RESPONSIBLEFORAREA_CARDS_SUCCESS,
  payload: {
    isFetching: false,
    hasError: false,
    records: response.records,
    metadata: response.metadata
  }
});

export const getCardsFailture = (response) => ({
  type: RESPONSIBLEFORAREA_CARDS_FAILTURE,
  payload: {
    isFetching: false,
    hasError: true,
    response
  }
});

export const setPage = (page) => ({
  type: RESPONSIBLEFORAREA_PAGE_SET,
  payload: {
    page
  }
});

export const formLoadRequest = () =>
  buildAction(
    RESPONSIBLEFORAREA_FORM_LOAD_REQUEST,
    {
      isFetching: true,
      hasError: false
    }
  )



export const formLoadSuccess = (areasOptions) =>
  buildAction(
    RESPONSIBLEFORAREA_FORM_LOAD_SUCCESS,
    {
      isFetching: false,
      hasError: false,
      areasOptions
    }
  )

export const formLoadFailture = (response) =>
  buildAction(
    RESPONSIBLEFORAREA_FORM_LOAD_FAILTURE,
    {
      isFetching: false,
      hasError: true,
      response
    }
  )