import React, { Component } from 'react'
import config from 'Config';
import {
  Row, Col,Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export class LinkFieldFile extends Component {
  constructor(props) {
    super(props)
  }



  render() {
    const { input:{name},path,onDelete,id,woId } = this.props
    const fileData ={name:name,fileId:id,workOrderId:woId};
    return (
      <div className="link-field-file-container">
        <a target='_blank' href={path} name={name}>{name}</a>
        {' '}
        <Button type="button" variant="danger" onClick={(event) => onDelete(fileData)} >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div>
    );
  }
}
