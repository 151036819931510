import React,{Component} from 'react';
import {
    Card, Button, Spinner,Col,Row
} from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { inputField } from 'Src/components/forms/inputField';
import { dropdownField } from 'Src/components/forms/dropdownField';
import { checkboxField } from 'Src/components/forms/checkboxField';

// TOOD: Move to Generic Validations Functions
const required = value => (value ? undefined : 'Requerido!')
//const requiredselect = value => (value ? undefined: 'Requerido!')

//TODO: Move to a service of BusinessLogic/Validations
const validate = values => {
    const errors = {}
    errors.name = required(values.name)
    errors.email = required(values.email)    
    errors.password = required(values.password) 
    errors.confirPasswd = required(values.confirPasswd) 

    if(values.password && (values.password != values.confirPasswd))
        errors.confirPasswd='Las contraseña no son iguales'
       
    //errors.description = required(values.description)    
    return errors
}

class UserForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleSubmit, pristine, submitting,
            reset, onCancel, title, submitText,
            profilesOptions,
            mode
        } = this.props;

        return (
            <Card className="shadow">
                <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                            <Field name="name" label="Nombre del Usuario" component={inputField} type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Field name="email" label="Correo" component={inputField} type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Field name="password" label="Contraseña" component={inputField} type="password" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Field name="confirPasswd" label="Confirmar Contraseña" component={inputField} type="password" />
                            </Col>
                        </Row>                        
                        <Row>
                            <Col>
                            <Field name="description" label="Descripción" component={inputField} type="text" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Field name="profileId" label="Perfil"
                                    component={dropdownField}
                                    options={profilesOptions} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field label="Habilitar Sábados y Domingos" withoutSize={true} name="isWeekend" component={checkboxField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field label="Notificaciones" withoutSize={true} name="mailNotifications" component={checkboxField} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="primary" type="submit" disabled={pristine || submitting}>
                                    {submitText}
                                    {submitting ? <Spinner animation="grow" size="sm" /> : null}
                                </Button>                            
                            </Col>
                            <Col className="text-right">
                                <Button variant="secondary" disabled={pristine || submitting} onClick={reset}>Limpiar</Button>
                                &nbsp;                            
                                <Button variant="danger" onClick={onCancel}>Cancelar</Button>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        );
    }
}

export const CREATE_USERS_NAME = 'CREATE_USERS_NAME';
export const EDIT_USERS_NAME = 'EDIT_USERS_NAME';

const CreateUserForm = props =>
  <UserForm {...props} mode='create' />;
  
const EditUserForm = props =>
  <UserForm {...props} mode='edit' />;

const ConnectedCreateUserForm = reduxForm({
    form: CREATE_USERS_NAME,
    validate
})(CreateUserForm);

const ConnectedEditUserForm = reduxForm({
    form: EDIT_USERS_NAME,
    validate
})(EditUserForm);

export {
    ConnectedCreateUserForm,
    ConnectedEditUserForm
}