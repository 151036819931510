import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { goBack } from 'connected-react-router';
import LoadingCard from 'Src/components/loadingCard';

import { ConnectedEditContactTypeForm } from 'Src/components/contactTypes/ContactTypeForm'

import { getContactTypeToEdit } from "Src/redux/reducers/contactTypesCardsReducer";

import { tryEditContactType } from 'Src/redux/thunk-creators/contactTypesCardsActionCreator';

class ContactTypeEditPage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {      
        return (
            <Container className="container">
                <Row>
                    <Col sm={12}>
                        <Suspense fallback={<LoadingCard />} >
                            <ConnectedEditContactTypeForm
                                title="Editar Tipo de Contacto"
                                submitText="Editar"
                                onSubmit={values => this.props.onContactTypeEdit(values)}
                                onCancel={this.props.goBack}
                                initialValues={this.props.contactTypeToEdit}
                            />
                        </Suspense>
                        <br />
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        contactTypeToEdit: getContactTypeToEdit(state)
    };
}

const mapDispatchToProps = dispatch => ({
    onContactTypeEdit: (contactType) => dispatch(tryEditContactType(contactType)),
    goBack: () => dispatch(goBack())
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactTypeEditPage);
