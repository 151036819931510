import { push } from 'connected-react-router';
import { error, success } from 'react-notification-system-redux';
import { getUserProfile } from "../reducers/loginReducer";

import {
    setFilter, getCardsRequest, getCardsSuccess, getCardsFailture,
    setPage, setFiltertaskEntrusted, getOptionsRequest, getOptionsSuccess, getOptionsFailture,
    editRequest, editSuccess, editEndDateState, getFailture,
    newDesingRequest, newDesingSuccess, newDesingFailture,
    complementaryWorkRequest, complementaryWorkSuccess, complementaryWorkFailture,
    setSelectedToSave, editTasksRequest, editTasksFailture, editTasksSuccess
} from "Src/redux/actions/operativeTasksActions";

import {
    get as getWorkOrderTracking,
    getEntrustedTasks,
    getOperativeTasks,
    putOperativeTask,
    putOperativeTasks,
    putNewDesing,
    putComplementaryWork
} from 'Src/services/workOrderTracking';

import {
    get as getJustifications
} from 'Src/services/justificationDelayAreasService';

import {
    get as getResponsibleForArea
} from 'Src/services/responsibleForAreaService';

import { OPERATIVETASKS_URI, ENTRUSTEDTASKS_URI, WORKORDERSTRACKING_URI } from "Src/routes";

import { formatDate, addWeekdays, addWeekenddays } from 'Src/services/formatService';

const OperativeTaskCardsNotification = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr',
};

const ERROR_TITLE = "Oops!";
//
// ERROR CODES -> 02 09 19
//
// INSERT_ERROR = 1,
// UPDATE_ERROR = 2,
// DELETE_ERROR = 3,
// DUPLICATE_ERROR = 4,
// NOT_FOUND_ERROR = 5

export const setShowCards = (EntrustedTaskId) => {
    return async (dispatch, getState) => {
        const { workOrderTrackingCardsReducer } = getState();

        dispatch(setFilter(EntrustedTaskId));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            EntrustedTaskId));



    };
};


export const setCardsFilter = (filterText) => {
    return async (dispatch, getState) => {
        const { operativeTasksCardsReducer } = getState();

        dispatch(setFilter(filterText));

        const { loginReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            filterText));

    };
};

export const refreshCardsFilter = () => {
    return async (dispatch, getState) => {
        const { loginReducer, operativeTasksCardsReducer } = getState();

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            operativeTasksCardsReducer.filter.text));

    };
};

export const setCardsNextPage = () => {
    return async (dispatch, getState) => {

        const { loginReducer, operativeTasksCardsReducer } = getState();

        if (!operativeTasksCardsReducer.metadata.hasMore) {
            return;
        }

        const nextPage = operativeTasksCardsReducer.metadata.page + 1;

        dispatch(setPage(nextPage));

        dispatch(tryGetCardsAction(
            loginReducer.authentication.access_token,
            operativeTasksCardsReducer.filter.text));

    };
};

export const tryGetCardsAction = (accessToken, EntrustedTaskId) => {
    return async (dispatch, getState) => {
        const { entrustedTasksCardsReducer } = getState();
        const WorkOrderId = entrustedTasksCardsReducer.metadataTranckingTask.WorkOrderId

        dispatch(getCardsRequest());

        const entrustedTasksFilter = entrustedTasksCardsReducer.cards.filter(x => x.id == EntrustedTaskId);
        const areaNameUser = entrustedTasksFilter[0].area;
        let areaId = undefined;
        switch (areaNameUser) {
            case 'Ingeniería':
                areaId = 3;
                break;
            case 'Obras':
                areaId = 4;
                break;
            case 'Fábrica':
                areaId = 5;
                break;
            case 'Despacho':
                areaId = 6;
                break;
            default:
                areaId = null;
                break;
        }

        let response = await getOperativeTasks(accessToken, WorkOrderId, EntrustedTaskId);

        let justifications = await getJustifications(accessToken, null, 1000, 1, areaId)

        let responsibleForArea = await getResponsibleForArea(accessToken, null, 1000, 1, areaId)

        if (response.cancelled) { return; }

        if (response.hasOwnProperty("error")) {
            dispatch(getCardsFailture(response));

            dispatch(error({
                ...OperativeTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Cards. Detalle: '${response.error.message}'`
            }));

            return;
        }

        if (justifications.cancelled) { return; }

        if (justifications.hasOwnProperty("error")) {
            dispatch(error({
                ...OperativeTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar las Justificaciones de retraso. Detalle: '${response.error.message}'`
            }));

            return;
        }

        if (responsibleForArea.cancelled) { return; }

        if (responsibleForArea.hasOwnProperty("error")) {
            dispatch(error({
                ...OperativeTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al cosultar los responsables por Area. Detalle: '${response.error.message}'`
            }));

            return;
        }




        dispatch(getCardsSuccess(response, justifications.records, responsibleForArea.records));
    }
};

export const sendToOperativesTaskCards = (EntrustedTaskId) => {
    return async (dispatch, getState) => {

        const { loginReducer, entrustedTasksCardsReducer } = getState();
        const WorkOrderId = entrustedTasksCardsReducer.metadataTranckingTask.WorkOrderId

        dispatch(setFiltertaskEntrusted(entrustedTasksCardsReducer.metadataTranckingTask));


        dispatch(tryGetOptionsToList(
            loginReducer.authentication.access_token,
            WorkOrderId));


        dispatch(setFilter(EntrustedTaskId));

        dispatch(push(WORKORDERSTRACKING_URI + "/" + WorkOrderId + ENTRUSTEDTASKS_URI + "/" + EntrustedTaskId + OPERATIVETASKS_URI))

    };
};

export const tryGetfilterEntrustedTask = (EntrustedTaskId) => {
    return async (dispatch, getState) => {

        const { loginReducer, entrustedTasksCardsReducer } = getState();
        const WorkOrderId = entrustedTasksCardsReducer.metadataTranckingTask.WorkOrderId

        dispatch(setFiltertaskEntrusted(entrustedTasksCardsReducer.metadataTranckingTask));

        dispatch(setShowCards(EntrustedTaskId));

        dispatch(push(WORKORDERSTRACKING_URI + "/" + WorkOrderId + ENTRUSTEDTASKS_URI + "/" + EntrustedTaskId + OPERATIVETASKS_URI))

    };
};


export const tryGetOptionsToList = (accessToken, WorkOrderId) => {
    return async (dispatch) => {
        dispatch(getOptionsRequest())

        let response = await getEntrustedTasks(accessToken, WorkOrderId)

        if (response
            && response.cancelled) return

        const { error } = response

        if (error) {
            dispatch(getOptionsFailture(response));

            showError(dispatch, ERROR_TITLE,
                `Ha ocurrido un error al cosultar las Cards. Detalle: '${error.message}'`);

            return
        }

        dispatch(getOptionsSuccess(response))
    }
}

let CONTADOR = 0;

let AUX_START_DATE = 0;

export const tryEditOperativeTask = (operativeTask) => {
    return async (dispatch, getState) => {
        const { loginReducer, operativeTasksCardsReducer } = getState();
        const WorkOrderId = operativeTasksCardsReducer.metadataTranckingTask.WorkOrderId
        let EntrustedTaskFK = operativeTask.workOrderEntrustedTaskFK;
        let days = operativeTask.days;
        const { isWeekend } = loginReducer.authentication;
        if (CONTADOR == 0) { // Se ingresa la primera vez
            AUX_START_DATE = operativeTask.startDate;
            CONTADOR++;
        }
        let endDate;
        if (isWeekend) {
            if (AUX_START_DATE != operativeTask.startDate && operativeTask.endDate != null) {
                endDate = formatDate(addWeekenddays(formatDate(operativeTask.startDate), days));
                AUX_START_DATE = operativeTask.startDate;
            } else if (operativeTask.endDate == null) {
                endDate = formatDate(addWeekenddays(formatDate(operativeTask.startDate), days));
            } else {
                endDate = formatDate(operativeTask.endDate);
            }
        } else {
            if (AUX_START_DATE != operativeTask.startDate && operativeTask.endDate != null) {
                endDate = formatDate(addWeekdays(formatDate(operativeTask.startDate), days));
                AUX_START_DATE = operativeTask.startDate;
            } else if (operativeTask.endDate == null) {
                endDate = formatDate(addWeekdays(formatDate(operativeTask.startDate), days));
            } else {
                endDate = formatDate(operativeTask.endDate);
            }
        }

        let parms = {};
        parms.id = operativeTask.id
        parms.done = operativeTask.done
        parms.workOrderEntrustedTaskFK = operativeTask.workOrderEntrustedTaskFK
        parms.operativeTaskType = operativeTask.operativeTaskType
        parms.days = days
        parms.advancePercentage = operativeTask.advancePercentage
        parms.responsablePerson = operativeTask.responsablePerson
        parms.justificationDelayTypeFK = operativeTask.justificationDelayTypeFK;
        parms.startDate = operativeTask.startDate ? formatDate(operativeTask.startDate) : null;
        parms.endDate = endDate;

        if (operativeTask.newEndDate && operativeTask.justificationDelayTypeFK > 0)
            parms.newEndDate = formatDate(operativeTask.newEndDate);

        if (operativeTask.newEndDataOld && operativeTask.newEndDate == null)
            parms.newEndDate = formatDate(operativeTask.newEndDataOld);

        dispatch(editRequest(operativeTask));

        const { access_token } = loginReducer.authentication;

        let response = await putOperativeTask(access_token, parms, EntrustedTaskFK, WorkOrderId);

        if (response.hasOwnProperty("error")) {
            dispatch(editFailture(EntrustedTaskFK));
            dispatch(error({
                ...OperativeTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar la tarea operativa. Detalle: '${response.error.message}'`
            }));

            return;
        }

        dispatch(editSuccess(EntrustedTaskFK));

        dispatch(success({
            ...OperativeTaskCardsNotification,
            title: "Tarea Operativa " + operativeTask.id,
            message: `Actualizado exitosamente!`
        }));

        dispatch(tryGetfilterEntrustedTask(EntrustedTaskFK));

    }
}

export const setSelectedToSaveItems = (selectedToSave) => {
    return async (dispatch) => {   
        dispatch(setSelectedToSave(selectedToSave));
    }
}

const parseOperativeTask = (operativeTask,isWeekend) => {
    let days = operativeTask.days;
    if (CONTADOR == 0) { // Se ingresa la primera vez
        AUX_START_DATE = operativeTask.startDate;
        CONTADOR++;
    }
    let endDate;
    if (isWeekend) {
        if (AUX_START_DATE != operativeTask.startDate && operativeTask.endDate != null) {
            endDate = formatDate(addWeekenddays(formatDate(operativeTask.startDate), days));
            AUX_START_DATE = operativeTask.startDate;
        } else if (operativeTask.endDate == null) {
            endDate = formatDate(addWeekenddays(formatDate(operativeTask.startDate), days));
        } else {
            endDate = formatDate(operativeTask.endDate);
        }
    } else {
        if (AUX_START_DATE != operativeTask.startDate && operativeTask.endDate != null) {
            endDate = formatDate(addWeekdays(formatDate(operativeTask.startDate), days));
            AUX_START_DATE = operativeTask.startDate;
        } else if (operativeTask.endDate == null) {
            endDate = formatDate(addWeekdays(formatDate(operativeTask.startDate), days));
        } else {
            endDate = formatDate(operativeTask.endDate);
        }
    }

    let parms = {};
    parms.id = operativeTask.id
    parms.done = operativeTask.done
    parms.workOrderEntrustedTaskFK = operativeTask.workOrderEntrustedTaskFK
    parms.operativeTaskType = operativeTask.operativeTaskType
    parms.days = days
    parms.advancePercentage = operativeTask.advancePercentage
    parms.responsablePerson = operativeTask.responsablePerson
    parms.justificationDelayTypeFK = operativeTask.justificationDelayTypeFK;
    parms.startDate = operativeTask.startDate ? formatDate(operativeTask.startDate) : null;
    parms.endDate = endDate;

    if (operativeTask.newEndDate && operativeTask.justificationDelayTypeFK > 0)
        parms.newEndDate = formatDate(operativeTask.newEndDate);

    if (operativeTask.newEndDataOld && operativeTask.newEndDate == null)
        parms.newEndDate = formatDate(operativeTask.newEndDataOld);
    return parms;
}

//lay
export const tryEditOperativeTasks = () => {
    return async (dispatch, getState) => {
       
        const { loginReducer: {
                    authentication:{isWeekend, access_token}
                }, 
                operativeTasksCardsReducer: {
                    selectedToSave, metadataTranckingTask: {WorkOrderId}
                }, 
                form 
            } = getState();
        const forms = []
        let EntrustedTaskFK = 0;
        Object.keys(selectedToSave).map((key) => {
            if (selectedToSave[key]){
                const formkey = `TasksOperativeForm_${key}`;
                if(!EntrustedTaskFK){EntrustedTaskFK = form[formkey].values.workOrderEntrustedTaskFK}
                forms.push(parseOperativeTask(form[formkey].values,isWeekend))
            }
        })
        if (!forms.length) { return;}
        const body = { workOrderOperativeTasks: forms }

        
        dispatch(editTasksRequest(body));
        
        let response = await putOperativeTasks(access_token, body, EntrustedTaskFK, WorkOrderId);

        if (response.hasOwnProperty("error")) {
            dispatch(editTasksFailture(body));
            dispatch(error({
                ...OperativeTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar la tarea operativa. Detalle: '${response.error.message}'`
            }));

            return;
        }
        dispatch(editTasksSuccess(body));

        dispatch(success({
            ...OperativeTaskCardsNotification,
            title: "Tareas Operativas ",
            message: `Actualizado exitosamente!`
        }));

        dispatch(tryGetfilterEntrustedTask(EntrustedTaskFK));
        
    }
}


export const tryEditNewDesign = (WorkOrderId) => {
    return async (dispatch, getState) => {
        const { loginReducer, operativeTasksCardsReducer } = getState();

        let EntrustedTaskFK = operativeTasksCardsReducer.filter.text;

        dispatch(newDesingRequest(WorkOrderId));

        const { access_token } = loginReducer.authentication;

        let response = await putNewDesing(access_token, WorkOrderId);

        if (response.hasOwnProperty("error")) {
            dispatch(newDesingFailture(WorkOrderId));
            dispatch(error({
                ...OperativeTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar la tarea operativa. Detalle: '${response.error.message}'`
            }));

            return;
        }

        dispatch(newDesingSuccess(WorkOrderId));

        dispatch(success({
            ...OperativeTaskCardsNotification,
            title: "Tarea Encomendada " + EntrustedTaskFK,
            message: `Actualizado exitosamente!`
        }));

        dispatch(tryGetfilterEntrustedTask(EntrustedTaskFK));

    }
}

export const tryEditComplementaryWork = (WorkOrderId) => {
    return async (dispatch, getState) => {
        const { loginReducer, operativeTasksCardsReducer } = getState();

        let EntrustedTaskFK = operativeTasksCardsReducer.filter.text;

        dispatch(complementaryWorkRequest(WorkOrderId));

        const { access_token } = loginReducer.authentication;

        let response = await putComplementaryWork(access_token, WorkOrderId);

        if (response.hasOwnProperty("error")) {
            dispatch(complementaryWorkFailture(WorkOrderId));
            dispatch(error({
                ...OperativeTaskCardsNotification,
                title: "Oops!",
                message: `Ha ocurrido un error al Actualizar la tarea operativa. Detalle: '${response.error.message}'`
            }));

            return;
        }

        dispatch(complementaryWorkSuccess(WorkOrderId));

        dispatch(success({
            ...OperativeTaskCardsNotification,
            title: "Tarea Encomendada " + EntrustedTaskFK,
            message: `Actualizado exitosamente!`
        }));

        dispatch(tryGetfilterEntrustedTask(EntrustedTaskFK));

    }
}




const NOTIFICATION = {
    title: 'Generic Title',
    message: 'Generic Message',
    position: 'tr'
}

const showSuccess = (dispatch, title, message) =>
    dispathNotification(dispatch, success, title, message)

const showError = (dispatch, title, message) =>
    dispathNotification(dispatch, error, title, message)

const dispathNotification = (dispatch, typeFunc, title, message) =>
    dispatch(typeFunc({
        ...NOTIFICATION,
        title,
        message
    }))
